import { PasswordField, TextField } from "@aws-amplify/ui-react";

const FormFields = () => {
  return (
    <>
      <TextField
        name='username'
        label='Work Email'
        type='email'
        placeholder='hi@example.com'
      />
      <PasswordField
        name='password'
        label='Password'
        placeholder='*****'
        crossOrigin={"anonymous"}
      />
    </>
  );
};

export default FormFields;