import { Col, Row } from 'antd';
import IntegrationCardComponent from './integration-card-component';
import { INTEGRATION_DETAILS } from 'constants/setting-integration';

import './index.scss';

const dataTestIdPrefix = "Settings-IntegrationComponent";

interface IProps {
    isActive: boolean;
}

const IntegrationsComponent = ({ isActive }: IProps) => {

    return (
        <div className='g-integrations-component-container' >
            <div className='g-integrations-container'>
                <Row gutter={[16, { xs: 16, sm: 16, md: 32, lg: 40 }]}>
                    {INTEGRATION_DETAILS.map((integration,index) =>
                        <Col className="gutter-row" key={index} xs={{ span: 24 }} lg={{ span: 12 }} data-testid={`${dataTestIdPrefix}-IntegrationCard`}>
                            <IntegrationCardComponent integration={integration} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default IntegrationsComponent;