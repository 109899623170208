import { IInteraction, EQuestionType, EChoiceNextQuestionMetaRedirect } from "interfaces/schema";

export const isInvalidQuestionValidator = (interaction: IInteraction) => {
    for (const question of interaction.questions) {
      if (question?.type === EQuestionType.SHORT_ANSWER) {
        const isInvalidUrl = question?.choices[0]?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect && !question?.choices[0]?.redirectUrl;
        if (!question?.choices[0]?.text || !question?.text || isInvalidUrl) {
          return question;
        }
      } else if (question.type === EQuestionType.MULTIPLE_CHOICE) {
        if (!question?.text) {
          return question;
        }
      } else if (question.type === EQuestionType.PICTURE_CHOICE) {
        if (!question?.text) {
          return question;
        }
      } else if (question?.type === EQuestionType.CALL_TO_ACTION) {
        const isInvalidUrl = question?.choices[0]?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect && !question?.choices[0]?.redirectUrl;
        if (!question?.text || isInvalidUrl) {
          return question;
        }
      } else if (question?.type === EQuestionType.ADD_TO_CART) {
        if (!question?.text || !question?.choices[0]?.productUrl) {
          return question;
        }
      }
    }
    return null;
  };