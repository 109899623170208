import { Typography } from "antd";
import { IGetInteractionQuestionAnalysisOverviewData } from "interfaces/snowflake";
import Avatar from "assets/img/black-and-white-avatar.jpg";

import "./index.scss";

const dataTestIdPrefix = "QuestionAnalysis";

const { Text } = Typography;

interface Props {
    data: IGetInteractionQuestionAnalysisOverviewData[];
}

const QuestionAnalysis = ({ data }: Props) => {
    return (
        <div className="g-interaction-edit-insights-card-analysis-card-container">
            <div className="g-interaction-edit-insights-card-analysis-card">
                <div className="g-interaction-edit-insights-card-analysis-card-header">
                    <div className="g-interaction-edit-insights-card-analysis-card-title-container">
                        <Text className="g-interaction-edit-insights-card-analysis-card-title" data-testid={`${dataTestIdPrefix}-Title`}>
                            Question Analysis
                        </Text>
                        <Text className="g-interaction-edit-insights-card-analysis-card-sub-title" data-testid={`${dataTestIdPrefix}-Subtitle`}>
                            Preview a summary of your responses below
                        </Text>
                    </div>
                    <div className="g-interaction-edit-insights-card-analysis-card-legend">
                        <div className="g-interaction-edit-insights-card-analysis-card-legend-item" data-testid={`${dataTestIdPrefix}-Submitted-Text`}>
                            <div className="g-interaction-edit-insights-card-analysis-card-legend-submitted-icon" />
                            Submitted
                        </div>
                        <div className="g-interaction-edit-insights-card-analysis-card-legend-item" data-testid={`${dataTestIdPrefix}-Views-Text`}>
                            <div className="g-interaction-edit-insights-card-analysis-card-legend-views-icon" />
                            Views
                        </div>
                    </div>
                </div>
                <div className="g-interaction-edit-insights-card-analysis-card-body">
                    {data.map(({ META: meta, TEXT: text, SUBMITTED_COUNT: submittedCount, VIEWED_COUNT: viewedCount }) => {
                        const submissionPercentage = viewedCount === 0 ? 0 : (submittedCount / viewedCount) * 100;

                        return (
                            <div key={meta} className="g-interaction-edit-insights-card-analysis-card-question-container">
                                <div className="g-interaction-edit-insights-card-analysis-card-question-header">
                                    <Text
                                        className="g-interaction-edit-insights-card-analysis-card-question-title"
                                        data-testid={`${dataTestIdPrefix}-Question-Title`}
                                    >
                                        {text}
                                    </Text>
                                    <div className="g-interaction-edit-insights-card-analysis-card-response-container">
                                        <div className="g-interaction-edit-insights-card-analysis-card-image-container">
                                            <img className="g-interaction-edit-insights-card-analysis-card-image" alt="views" src={Avatar} />
                                        </div>
                                        <Text
                                            className="g-interaction-edit-insights-card-analysis-card-question-response-count"
                                            data-testid={`${dataTestIdPrefix}-Question-Submissions-Text`}
                                        >
                                            {submittedCount} submissions
                                        </Text>
                                    </div>
                                </div>
                                <div className="g-interaction-edit-insights-card-analysis-card-question-views" />
                                <div
                                    className="g-interaction-edit-insights-card-analysis-card-question-submitted"
                                    style={{ width: `${submissionPercentage}%` }}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default QuestionAnalysis;
