import { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "antd";
import InteractionResponsesTableColumns from "./interaction-responses-table-columns-component";
import { toast } from "utils/toast";
import { apiGenerateOfflineInteractionReport } from "services/dashboard.service";
import { IResponsesPageMetrics } from "..";
import { DATE_FORMAT } from "interfaces";
import { IInteraction } from "interfaces/schema";
import { DownloadOutlined } from "@ant-design/icons";
import Avatar from "assets/img/black-and-white-avatar.jpg";
import * as ROUTE_PATHS from "constants/routes";

import "./index.scss";

const dataTestIdPrefix = "ResponsesView";

interface Props {
    interaction: IInteraction;
    responsesPageMetrics: IResponsesPageMetrics | undefined;
}

const ResponsesView = ({ interaction, responsesPageMetrics }: Props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [controller] = useState<AbortController>(new AbortController());

    if (responsesPageMetrics === undefined) {
        return (
            <div className="g-h-100 g-d-flex g-justify-center g-align-center">
                Sorry, something went wrong. Click&nbsp;
                <Button type="link" className="g-padding-0" onClick={() => navigate(ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH)}>
                    <u>here</u>
                </Button>
                &nbsp;to go back.
            </div>
        );
    }

    const handleClick = async () => {
        try {
            setIsLoading(true);
            const payload = {
                startDate: moment().format(DATE_FORMAT),
                endDate: moment().format(DATE_FORMAT),
                interactionId: interaction.meta,
            };
            const response = (await apiGenerateOfflineInteractionReport(payload, controller)) as any;
            if (response?.toAddress) {
                toast.success("Report is being created and will be sent to your email.");
            } else {
                toast.error("No data available yet, please try again later.");
            }
        } catch (e) {
            toast.error("Sorry, something went wrong. Please try again.");
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const data = responsesPageMetrics.interactionResponses;
    return (
        <div className="g-interaction-edit-responses-card-container">
            <div className="g-interaction-edit-responses-card-header">
                <div className="g-interaction-edit-responses-card-title-container">
                    <div className="g-interaction-edit-responses-card-title" data-testid={`${dataTestIdPrefix}-Title`}>Responses</div>
                    <div className="g-interaction-edit-responses-card-sub-title" data-testid={`${dataTestIdPrefix}-Subtitle`}>
                        <div className="g-interaction-edit-responses-card-sub-title-image-container">
                            <img className="g-interaction-edit-responses-card-sub-title-image" alt="views" src={Avatar} />
                        </div>
                        {responsesPageMetrics.interactionResponses.length} Entries
                    </div>
                </div>
                <Button
                    className="g-interaction-edit-responses-card-header-button"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleClick}
                    loading={isLoading}
                    data-testid={`${dataTestIdPrefix}-Download-Button`}
                />
            </div>
            <div className="g-interaction-edit-responses-card-table-container">
                <Table
                    className="g-interaction-edit-responses-card-table"
                    columns={InteractionResponsesTableColumns({ data })}
                    dataSource={data}
                    rowKey="INTERACTION_RESPONSE_ID"
                    rowClassName="g-interaction-edit-responses-card-table-row"
                    pagination={false}
                    size="small"
                    data-testid={`${dataTestIdPrefix}-Table`}
                />
            </div>
        </div>
    );
};

export default ResponsesView;
