import { IProduct } from "interfaces";
import { useEffect, useState } from "react";

interface Props {
  products: IProduct[];
  dataTestId: string;
}

const PreviewProductsComponent = ({ products, dataTestId }: Props) => {
  const [previewProducts, setPreviewProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    setPreviewProducts(products);
  }, [products]);

  return (
    <div>
      {previewProducts.map(({ id, variantId, thumbnail, isDeleted }) => {
        if (!thumbnail || isDeleted) {
          return null;
        }

        return (
          <div key={variantId || id} className="">
            <div
              className="g-product-preview-overlay-product-image-container"
              data-testid={`${dataTestId}-ImageContainer`}
            >
              <img
                className="g-product-preview-overlay-product-image"
                alt={variantId || id}
                src={thumbnail}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PreviewProductsComponent