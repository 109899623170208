import { ReactNode, useContext, useEffect, useState } from "react";
import { Button, Select, Spin, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useDebounce from "hooks/useDebounce";
import LinkedPagesListItemComponent from "../../linked-pages-list-item-component";
import { toast } from "utils/toast";
import { apiGetPages } from "services/pages.services";
import { IPage } from "interfaces";
import { getURL } from "app/pages/videos/utils";
import { AppContext } from "context/app-context";
import { IVideo } from "interfaces/schema";

import "./index.scss";

const { Option } = Select;

const dataTestIdPrefix = "Videos-EditVideoDetailsComponent-AddExistingPage";

interface Props {
    videoDetails: IVideo;
    disabled: boolean;
    onSelectPage: (page: IPage) => Promise<void>;
    onCreatePage: (newPageUrl: string) => void;
}

const getFilterName = (debouncedInput: string, shopDomain: string) => {
    const { url, isValid } = getURL(debouncedInput);

    if (isValid && url!.hostname === shopDomain) {
        return url!.protocol + url!.hostname + url!.pathname;
    }

    return debouncedInput;
};

const LinkVideoToExistingPageComponent = ({ videoDetails, disabled, onSelectPage, onCreatePage }: Props) => {
    const { shopDetails } = useContext(AppContext);
    const [input, setInput] = useState("");
    const [pages, setPages] = useState<IPage[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const debouncedInput = useDebounce(input, 500);

    useEffect(() => {
        if (debouncedInput) {
            setIsSearching(true);
            const filterName = getFilterName(debouncedInput, shopDetails?.domain!);
            apiGetPages({ filterName })
                .then((response) => {
                    const allPages = response.data;
                    const linkedPages = videoDetails.pages.map((page) => page.meta);
                    const pagesNotLinked = allPages.filter((page: IPage) => {
                        return !linkedPages.includes(page?.meta);
                    });

                    setPages(response?.data?.length > 0 ? pagesNotLinked : []);
                    setIsSearching(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    toast.error(e?.message);
                    setIsSearching(false);
                });
        } else {
            setPages([]);
        }
    }, [debouncedInput]);

    const handleOnSelect = async (pageMeta: string) => {
        const newPage = pages.find((page) => page?.meta === pageMeta);

        onSelectPage(newPage!);

        setPages([]);
        setInput("");
    };

    const notFoundContentComponent = input === debouncedInput && debouncedInput && (
        <div className="g-add-edit-page-details-no-found-content">No page found for input URL</div>
    );

    const dropdownRenderComponent = (originNode: ReactNode) =>
        isSearching ? (
            <div className="g-add-edit-page-details-spinner-container">
                <Spin className="g-mx-8" size="small" />
            </div>
        ) : (
            <div className="g-add-edit-page-details-items-container">
                {originNode}
                {pages.length === 0 && (
                    <>
                        <Divider className="g-add-edit-page-details-no-page-divider" />
                        <Button
                            data-testid={`${dataTestIdPrefix}-CreatePageButton`}
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => onCreatePage(debouncedInput)}
                        >
                            Create page
                        </Button>
                    </>
                )}
            </div>
        );

    return (
        <div className="g-add-edit-page-details-link-page-container">
            <Select
                data-testid={`${dataTestIdPrefix}-Select`}
                className="g-custom-search-select"
                size="large"
                showSearch
                disabled={disabled}
                value={input || "Enter Page URL"}
                placeholder="Enter Page URL"
                notFoundContent={notFoundContentComponent}
                showArrow={false}
                filterOption={false}
                dropdownRender={dropdownRenderComponent}
                onSearch={setInput}
                onSelect={handleOnSelect}
            >
                {pages.map((page) => (
                    <Option key={page?.meta}>
                        <LinkedPagesListItemComponent row={page} isOpenLink={false} />
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default LinkVideoToExistingPageComponent;
