import { useEffect, useState } from "react";
import { Spin } from "antd";
import AddInteractionForm from "./add-interaction-form-component";
import { toast } from "utils/toast";
import { apiGetInteraction, apiGetInteractions } from "services/interactions";
import { apiUpdateVideo } from "services/video.services";
import { IVideo } from "interfaces/schema";
import { IInteraction } from "interfaces/schema";
import { MAX_INTERACTIONS_PER_SEARCH } from "constants/interactions";
import { IProduct } from "interfaces";
import VideoDetailDrawerPreviewContainer from "../video-detail-drawer-preview-container";

const dataTestIdPrefix = "Videos-AddInteractionComponent";

interface Props {
    viewVideoDetailsDrawer: IVideo;
    products: IProduct[];
}

const AddInteractionTab = ({ viewVideoDetailsDrawer, products }: Props) => {
    const [selectedInteraction, setSelectedInteraction] = useState<IInteraction>();
    const [interactions, setInteractions] = useState<IInteraction[]>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initialLoad();
    }, [viewVideoDetailsDrawer]);

    const initialLoad = async () => {
        try {
            setIsLoading(true);
            const interactionId = viewVideoDetailsDrawer.GSI1PK?.split("#")?.[1];
            const [getInteractionsResponse, getInteractionResponse] = await Promise.all([
                apiGetInteractions({ limit: MAX_INTERACTIONS_PER_SEARCH }),
                (interactionId ? apiGetInteraction({ id: interactionId }) : null)
            ]);
            setInteractions(getInteractionsResponse.interactions);
            setSelectedInteraction(getInteractionResponse?.interaction);
        } catch (e) {
            console.log(e);
            toast.error("Sorry, something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveInteraction = async () => {
        try {
            setIsLoading(true);
            await apiUpdateVideo({ GSI1PK: "INTERACTION#" }, viewVideoDetailsDrawer.meta);
            setSelectedInteraction(undefined);
        } catch (e: any) {
            console.log(e);
            toast.error("Sorry, something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="g-video-details-drawer-body-wrapper" data-testid={`${dataTestIdPrefix}-DrawerBody`}>
            {isLoading && (
                <div className="g-add-edit-video-details-drawer-loader-container">
                    <Spin size="large" className="g-add-edit-video-details-drawer-loader" />
                </div>
            )}
            <AddInteractionForm
                viewVideoDetailsDrawer={viewVideoDetailsDrawer}
                selectedInteraction={selectedInteraction}
                interactions={interactions}
                isLoading={isLoading}
                setSelectedInteraction={setSelectedInteraction}
                setInteractions={setInteractions}
                setIsLoading={setIsLoading}
                handleRemoveInteraction={handleRemoveInteraction}
            />
            <div className="g-video-details-drawer-preview-container">
                <VideoDetailDrawerPreviewContainer viewVideoDetailsDrawer={viewVideoDetailsDrawer} dataTestIdPrefix={dataTestIdPrefix} products={products} selectedInteraction={selectedInteraction} />
            </div>
        </div>
    );
};

export default AddInteractionTab;
