import "./index.scss";

const VideoProcessingLoader = () => {
    return (
        <div className="g-video-processing-loader-wrapper">
            <div className="g-video-processing-loader">
                <div className="loader">
                    <svg className="circular-loader" viewBox="25 25 50 50" >
                        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="black" strokeWidth="2" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default VideoProcessingLoader