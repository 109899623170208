export const productMock = {
    id: "6966824960209",
    title: "Mock Product",
    handle: "acid-mantle-repair",
    vendor: "Gander Demo Store",
    price: 7500,
    available: true,
    price_varies: false,
    compare_at_price: 8500,
    variants: [
        {
            id: 40847555854545,
            title: "Default Title",
            option1: "Default Title",
            available: true,
            name: "Acid Mantle Repair",
            options: ["Default Title"],
            price: 7500,
            compare_at_price: null,
        },
    ],
    featured_image: "//cdn.shopify.com/s/files/1/0616/9624/5969/products/624723b533ee14d8c3d1469f_lordjones1.png?v=1648834020",
    options: [
        {
            name: "Title",
            position: 1,
            values: ["Default Title"],
        },
    ],
    url: "/products/acid-mantle-repair",
};
