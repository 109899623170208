import { UploadContext } from "context/upload-context";
import { useContext, useEffect } from "react";

const UploadRouterGuard: any = ({ children }: { children: any }) => {
    const { fileList } = useContext(UploadContext);

    useEffect(() => {
        if (fileList?.length > 0) {
            window.onbeforeunload = () => true;
        } else if (fileList?.length === 0) {
            window.onbeforeunload = null;
        }

        return () => { window.onbeforeunload = null; };
    }, [fileList])


    return children;
};

export default UploadRouterGuard;