import { IQuestion } from "interfaces/schema";

import "./index.scss";

const CSS_PREFIX = 'g-preview-cta-interaction';

const sizeToButtonContainerCSS = {
  default: `${CSS_PREFIX}-button-container-default`,
  large: `${CSS_PREFIX}-button-container-large`,
}

const sizeToTextCSS = {
  default: `${CSS_PREFIX}-text-default`,
  large: `${CSS_PREFIX}-text-large`,
}

interface Props {
  question: IQuestion;
  size?: "default" | "large";
}

const PreviewCallToActionInteraction = ({ question, size = "default" }: Props) => {
  return (
    <div className="g-interation-edit-preview-panel-cta-interaction-container">
      <div
        className={`g-interation-edit-preview-panel-cta-interaction-button-container ${size && sizeToButtonContainerCSS[size]}`}
        style={{
          background: question.choices[0].buttonColor,
        }}
      >
        <div className="g-interation-edit-preview-panel-cta-interaction-button">
          <div
            className={`g-interation-edit-preview-panel-cta-interaction-text ${sizeToTextCSS[size]}`}
            style={{ color: question.choices[0].buttonTextColor }}
          >
            {question.text}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewCallToActionInteraction;