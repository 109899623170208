import { useState } from "react";
import moment from "moment";
import { Button, Typography } from "antd";
import { toast } from "utils/toast";
import { apiGenerateOfflineInteractionReport } from "services/dashboard.service";
import { IInteraction } from "interfaces/schema";
import { DATE_FORMAT } from "interfaces";
import { DownloadOutlined } from "@ant-design/icons";
import BlueReport from "assets/img/blue-report.png";

import "./index.scss";

const dataTestIdPrefix = "GenerateReport";

const { Text } = Typography;

interface Props {
    interaction: IInteraction;
}

const GenerateReport = ({ interaction }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [controller] = useState<AbortController>(new AbortController());

    const handleClick = async () => {
        try {
            setIsLoading(true);
            const payload = {
                startDate: moment().format(DATE_FORMAT),
                endDate: moment().format(DATE_FORMAT),
                interactionId: interaction.meta,
            };
            const response = (await apiGenerateOfflineInteractionReport(payload, controller)) as any;
            if (response?.toAddress) {
                toast.success("Report is being created and will be sent to your email.");
            } else {
                toast.error("No data available yet, please try again later.");
            }
        } catch (e) {
            toast.error("Sorry, something went wrong. Please try again.");
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="g-interaction-edit-summary-card-side-container">
            <div className="g-interaction-edit-summary-card-side-card">
                <div className="g-interaction-edit-summary-card-side-image-container">
                    <img className="g-interaction-edit-summary-card-side-image" alt="views" src={BlueReport} data-testid={`${dataTestIdPrefix}-Image`} />
                </div>
                <Text className="g-interaction-edit-summary-card-side-text" data-testid={`${dataTestIdPrefix}-Text`}>Request a spreadsheet with a sumary of your responses.</Text>
                <Button
                    className="g-interaction-edit-summary-card-side-button-text"
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={isLoading}
                    onClick={handleClick}
                    data-testid={`${dataTestIdPrefix}-Button`}
                >
                    <span>Generate Report</span>
                </Button>
            </div>
        </div>
    );
};

export default GenerateReport;
