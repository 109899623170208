import { Button, Form, FormInstance, Input, Tooltip, Typography } from 'antd';
import GanderVideo from 'components/gander-video';
import { EPreviewPlayButtonSize } from 'interfaces';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { VideosTableEditIcon } from 'components/icons';
import moment from 'moment';
import { IVideo } from 'interfaces/schema';

const { Title } = Typography;

const dataTestIdPrefix = "Videos-EditVideoDetailsComponent";

interface IProps {
    form: FormInstance<any>;
    viewVideoDetailsDrawer: IVideo;
    editVideoDisplayName: () => void;
    viewEditDisplayNameInput: boolean;
    onFinish: () => Promise<void>;
    isLoading: boolean;
    setCurrentDisplayName: React.Dispatch<React.SetStateAction<string>>;
    onPrevViewDetailsEvent: () => void;
    onNextViewDetailsEvent: () => void;
}

const DrawerTitleComponent = ({ form, viewVideoDetailsDrawer, editVideoDisplayName, viewEditDisplayNameInput, onFinish, isLoading, setCurrentDisplayName, onPrevViewDetailsEvent, onNextViewDetailsEvent }: IProps) => {
    return (
        <div className='g-d-flex g-justify-between'>
            <div className='g-add-edit-video-details-drawer-title-container'>
                <div className="g-title-video-player">
                    <GanderVideo video={viewVideoDetailsDrawer} buttonSize={EPreviewPlayButtonSize.SMALL} />
                </div>
                <div className="g-title-video-name">
                    <div className='g-d-flex g-align-center'>
                        {viewEditDisplayNameInput === true ? (
                            <Form form={form} onFinishFailed={() => console.log('onFinishFailed')} onFinish={onFinish}>
                                <Form.Item name="name">
                                    <Input type="text" className='g-manage-content-add-edit-video-details-drawer-title-input'
                                        value={viewVideoDetailsDrawer.displayName}
                                        onChange={(e) => setCurrentDisplayName(e?.target?.value)}
                                        disabled={isLoading}
                                        data-testid={`${dataTestIdPrefix}-DisplayNameInput`}
                                    />
                                </Form.Item>
                            </Form>
                        ) : (
                            <>
                                <Tooltip title={viewVideoDetailsDrawer.displayName}>
                                    <Title
                                        level={2}
                                        className="g-manage-content-add-edit-video-details-drawer-title"
                                        data-testid={`${dataTestIdPrefix}-DisplayNameLabel`}
                                    >
                                        {viewVideoDetailsDrawer.displayName}
                                    </Title>
                                </Tooltip>
                                <div
                                    className="g-manage-content-add-edit-video-details-drawer-title-edit-icon"
                                    onClick={editVideoDisplayName}
                                    data-testid={`${dataTestIdPrefix}-DisplayNameEditButton`}
                                >
                                    <VideosTableEditIcon />
                                </div>
                            </>
                        )}
                    </div>

                    {viewVideoDetailsDrawer?.updatedAt &&
                        <span
                            className='g-description-text'
                            data-testid={`${dataTestIdPrefix}-LastModified`}
                        >
                            Last modified {moment(viewVideoDetailsDrawer?.updatedAt).fromNow()}
                        </span>
                    }
                </div>
            </div>

            <div>
                <Button
                    className='g-mx-8'
                    size='large'
                    icon={<LeftOutlined />}
                    onClick={onPrevViewDetailsEvent}
                    disabled={isLoading}
                    data-testid={`${dataTestIdPrefix}-PreviousVideoDetail`}
                />
                <Button
                    className='g-mx-8'
                    size='large'
                    icon={<RightOutlined />}
                    onClick={onNextViewDetailsEvent}
                    disabled={isLoading}
                    data-testid={`${dataTestIdPrefix}-NextVideoDetail`}
                />
            </div>
        </div>
    )
}

export default DrawerTitleComponent;