import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const SHOPIFY_API_URL = `${REACT_APP_API_URL}/shopify`;

export const apiGetProductsByInput = async (input: string): Promise<any> => {
  try {
    const url = `${SHOPIFY_API_URL}/products`;
    const response = await axios.post(url, { input });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};


export const apiBatchGetProducts = async (idsArr: string[]): Promise<any> => {
  const ids = idsArr.join(',');
  try {
    const url = `${SHOPIFY_API_URL}/batch/products`;
    const response = await axios.get(url, { params: { ids } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiSyncShopifyDetails = async (shop: string, accessToken: string): Promise<any> => {
  try {
    const url = `${REACT_APP_API_URL}/shop/sync-shop`;
    const response = await axios.post(url, { shop, accessToken });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const getShopifyProductNamebyProductUrl = async (url: string | undefined) => {
  try {
    if (!url) {
      return;
    }
    const response = await fetch(`${url?.split('?')[0]}.js`);
    const data = await response.json();
    return data?.title || url;
  } catch (e: any) {
    console.log("🚀 ~ file: shopify.service.ts ~ line 45 ~ getProductDetailsFromShopifyProductUrl ~ e", e);
    return url;
  }
}