import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const VIDEO_API_URL = `${REACT_APP_API_URL}/videos`;
const SHOP_API_URL = `${REACT_APP_API_URL}/shop`;

export const apiPostBulkUploadVideo = async (payload: any): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}/create-bulk-video`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiPutUpdateExternalVideoUploadId = async (payload: { externalVideoUploadId: string | null }): Promise<any> => {
  try {
    const url = `${SHOP_API_URL}/update-external-video-upload-id`;
    const response = await axios.put(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};