import { Avatar } from "antd";
import { IMetricValue, TMetricValue } from "interfaces";
import { DashOutlined, FallOutlined, RiseOutlined } from "@ant-design/icons";

export const getPercentageChange = (before: TMetricValue | undefined, after: TMetricValue | undefined) => {
  let totalValue = 0;
  let totalValuePrev = 0;

  if (before && after) {
    before.forEach((val: IMetricValue) => {
      totalValue += val.VALUE;
    });

    after.forEach((val: IMetricValue) => {
      totalValuePrev += val.VALUE;
    });
  }

  if (totalValuePrev === 0) {
    return undefined;
  } else {
    return (((totalValue - totalValuePrev) / totalValuePrev) * 100);
  }
};

export const getStatisticColorIcon = (percentageChange: number | undefined) => {
  let color, icon;
  let size = 24;

  if (percentageChange === null || percentageChange === undefined) {
    icon = <Avatar size={size} icon={<DashOutlined />} />;
  } else {
    if (percentageChange > 0) {
      color = "#3f8600";
      icon = <Avatar size={size} icon={<RiseOutlined />} style={{ backgroundColor: color }} />;
    } else if (percentageChange < 0) {
      color = "#FF6424";
      icon = <Avatar size={size} icon={<FallOutlined />} style={{ backgroundColor: color }} />;
    } else {
      color = "#000000";
      icon = <Avatar size={size} icon={<DashOutlined />} style={{ backgroundColor: color }} />;
    }
  };

  return { color, icon };
};