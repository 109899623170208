import { TMetricValue } from "interfaces";

export const getPerformanceChartConfig = (value: number | undefined) => {
  return {
    percent: (value || 0) / 10,
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    axis: {
      label: {
        formatter(v: any) {
          return Number(v) * 10;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      title: {
        offsetY: 42,
        style: {
          fontSize: "28px",
          color: "#4B535E",
        },
        formatter: () => `${(value || (0 as number)).toFixed(2)} sec`,
      },
      content: {
        offsetY: 64,
        style: {
          fontSize: "18px",
          lineHeight: "44px",
          color: "#4B535E",
        },
        formatter: () => "View time per shopper",
      },
    }
  };
};

export const getVideoAnalyticsChartConfig = (value: TMetricValue | undefined) => {
  return {
    data: value ?? [],
    xField: "TIMESTAMP",
    yField: "VALUE",
    seriesField: "ID",
  };
};