import { Form, Input } from 'antd';
import { PagesContext } from 'context/pages-context';
import { IPage } from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { apiUpdatePage } from 'services/pages.services';
import { toast } from 'utils/toast';
import './index.scss';

const dataTestIdPrefix = "Pages-EditPageNameInputComponent";


interface IProps {
    editPageNameInput: IPage;
    setEditPageNameInput: React.Dispatch<React.SetStateAction<IPage | undefined>>;
  
}

const EditPageNameInputComponent = ({ editPageNameInput, setEditPageNameInput }: IProps) => {
    const [newName, setNewName] = useState(editPageNameInput.name);
    const [form] = Form.useForm();
    const { fetchPages} = useContext(PagesContext);


    const handleChange = (event: any) => {
        setNewName(event.target.value);
    }


    useEffect(() => {
        if (editPageNameInput) {
            form?.setFieldValue('name', editPageNameInput.name);
        }
     
    }, [editPageNameInput]);

    const onFinish = async () => {
        try {
            const payload = {
                meta: editPageNameInput?.meta,
                name: newName,
                mobileSettings: editPageNameInput?.mobileSettings,
                desktopSettings: editPageNameInput?.desktopSettings,
            }
            await apiUpdatePage(payload);
            toast.success("Successfully update page title");
            fetchPages();
            setEditPageNameInput(undefined);
        } catch (e: any) {
            console.log("🚀 ~ file: edit-page-name-input/index.tsx ~ onFinish ~ exception", e);
            toast.error(e?.message);
        } 
    };

    return (
        <Form form={form} onFinishFailed={() => console.log('onFinishFailed')} onFinish={onFinish}> 
            <Form.Item name="name">            
                <Input type="text" onChange={handleChange} data-testid={`${dataTestIdPrefix}-EditPageNameInput`}/>
            </Form.Item>
        </Form>
    )
}

export default EditPageNameInputComponent;