import { useEffect, useState } from "react";
import moment from "moment";
import { Card, Spin } from "antd";
import Header from "./header-component";
import InsightsView from "./insights-view-component";
import SummaryView from "./summary-view-component";
import ResponsesView from "./responses-view-component";
import { IInteraction } from "interfaces/schema";
import { DATE_FORMAT, EInteractionResultsTabs } from "interfaces";
import {
    apiGetInteractionQuestionAnalysisOverview,
    apiGetInteractionResponseOverview,
    apiGetInteractionResponses,
    apiGetQuestionResponsesOverview,
} from "services/dashboard.service";
import { toast } from "utils/toast";
import {
    IGetInteractionQuestionAnalysisOverviewData,
    IGetInteractionResponseOverviewData,
    IGetInteractionResponsesData,
    IGetQuestionResponsesOverviewData,
} from "interfaces/snowflake";

import "./index.scss";

export interface IInsightsPageMetrics {
    interactionResponseOverview: IGetInteractionResponseOverviewData;
    questionAnalysisOverview: IGetInteractionQuestionAnalysisOverviewData[];
}

export interface ISummaryPageMetrics {
    questionResponsesOverview: IGetQuestionResponsesOverviewData[];
}

export interface IResponsesPageMetrics {
    interactionResponses: IGetInteractionResponsesData[];
}

interface Props {
    interaction: IInteraction;
}

const ResultsView = ({ interaction }: Props) => {
    const [insightsPageMetrics, setInsightsPageMetrics] = useState<IInsightsPageMetrics>();
    const [summaryPageMetrics, setSummaryPageMetrics] = useState<ISummaryPageMetrics>();
    const [responsesPageMetrics, setResponsesPageMetrics] = useState<IResponsesPageMetrics>();
    const [activeTab, setActiveTab] = useState(EInteractionResultsTabs.INSIGHTS);
    const [controller] = useState<AbortController>(new AbortController());
    const [isCardLoading, setCardIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setCardIsLoading(true);
                const dateObj = {
                    startDate: moment().format(DATE_FORMAT),
                    endDate: moment().format(DATE_FORMAT),
                    interactionId: interaction.meta,
                };
                if (activeTab === EInteractionResultsTabs.INSIGHTS && insightsPageMetrics === undefined) {
                    const [interactionResponseOverviewResponse, questionAnalysisOverviewResponse] = await Promise.all([
                        apiGetInteractionResponseOverview<IGetInteractionResponseOverviewData>(dateObj, controller),
                        apiGetInteractionQuestionAnalysisOverview<IGetInteractionQuestionAnalysisOverviewData>(dateObj, controller),
                    ]);
                    setInsightsPageMetrics({
                        interactionResponseOverview: interactionResponseOverviewResponse?.data?.[0] || [],
                        questionAnalysisOverview: questionAnalysisOverviewResponse?.data || [],
                    });
                } else if (activeTab === EInteractionResultsTabs.SUMMARY && summaryPageMetrics === undefined) {
                    const questionResponsesOverviewResponse = await apiGetQuestionResponsesOverview<IGetQuestionResponsesOverviewData>(dateObj, controller);
                    setSummaryPageMetrics({ questionResponsesOverview: questionResponsesOverviewResponse?.data || [] });
                } else if (activeTab === EInteractionResultsTabs.RESPONSES && responsesPageMetrics === undefined) {
                    const interactionResponsesResponse = await apiGetInteractionResponses<IGetInteractionResponsesData>(dateObj, controller);
                    setResponsesPageMetrics({ interactionResponses: interactionResponsesResponse?.data || [] });
                }
            } catch (e) {
                toast.error("Sorry, something went wrong. Please try again.");
                console.log(e);
            } finally {
                setCardIsLoading(false);
            }
        };

        fetchData();
    }, [activeTab]);

    let activeComponent;
    switch (activeTab) {
        case EInteractionResultsTabs.INSIGHTS:
            activeComponent = <InsightsView interaction={interaction} insightsPageMetrics={insightsPageMetrics} />;
            break;
        case EInteractionResultsTabs.SUMMARY:
            activeComponent = <SummaryView interaction={interaction} summaryPageMetrics={summaryPageMetrics} setActiveTab={setActiveTab} />;
            break;
        case EInteractionResultsTabs.RESPONSES:
            activeComponent = <ResponsesView interaction={interaction} responsesPageMetrics={responsesPageMetrics} />;
            break;
    }

    return (
        <div className="g-interaction-edit-results-card-container">
            <Card
                className="g-interaction-edit-results-card"
                title={<Header activeTab={activeTab} isCardLoading={isCardLoading} setActiveTab={setActiveTab} />}
            >
                {isCardLoading ? (
                    <div className="g-d-flex g-justify-center g-align-center g-interaction-loader">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="g-interaction-edit-sub-card-container">{activeComponent}</div>
                )}
            </Card>
        </div>
    );
};

export default ResultsView;
