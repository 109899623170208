import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PRIVATE_ROUTES } from "constants/routes";
import { Dropdown, MenuProps } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import { ToggleMenuArrowWithCircleIcon } from "components/icons";
import HeaderRightUserInfoComponent from "app/layouts/common/header-right-user-info";

// assets import
import { ReactComponent as ArrowDownBold } from "assets/img/arrow-down-bold.svg";

import "./index.scss";

const dataTestIdPrefix = "AuthenticatedHeader-HeaderComponent";

interface IProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  Header: React.ForwardRefExoticComponent<BasicProps & React.RefAttributes<HTMLElement>>;
  onLogOut: () => void;
}

const HeaderComponent = ({ collapsed, setCollapsed, Header, onLogOut }: IProps) => {
  let location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  useEffect(() => {
    //init
    getPageTitleByCurrentLocation();
  }, [location]);

  const getPageTitleByCurrentLocation = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, routeValue] of Object.entries(PRIVATE_ROUTES)) {
      if (location?.pathname.startsWith(routeValue?.PATH)) {
        setPageTitle(routeValue?.TITLE);
      }
    }
  };

  const items: MenuProps['items'] = [
    {
      key: `${PRIVATE_ROUTES.DASHBOARD.PATH}`,
      label: <Link to={PRIVATE_ROUTES.DASHBOARD.PATH}>{PRIVATE_ROUTES.DASHBOARD.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.VIDEOS.PATH}`,
      label: <Link to={PRIVATE_ROUTES.VIDEOS.PATH}>{PRIVATE_ROUTES.VIDEOS.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.PAGES.PATH}`,
      label: <Link to={PRIVATE_ROUTES.PAGES.PATH}>{PRIVATE_ROUTES.PAGES.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.INTERACTIONS.PATH}`,
      label: <Link to={PRIVATE_ROUTES.INTERACTIONS.PATH}>{PRIVATE_ROUTES.INTERACTIONS.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.CUSTOMIZATION.PATH}`,
      label: <Link to={PRIVATE_ROUTES.CUSTOMIZATION.PATH}>{PRIVATE_ROUTES.CUSTOMIZATION.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.REQUEST_CONTENT.PATH}`,
      label: <Link to={PRIVATE_ROUTES.REQUEST_CONTENT.PATH}>{PRIVATE_ROUTES.REQUEST_CONTENT.TITLE}</Link>,
    },
    {
      key: `${PRIVATE_ROUTES.SETTINGS.PATH}`,
      label: <Link to={PRIVATE_ROUTES.SETTINGS.PATH}>{PRIVATE_ROUTES.SETTINGS.TITLE}</Link>,
    },
  ];

  return (
    <Header className="g-site-layout-background" style={{ padding: 0 }}>
      <div className="g-trigger" onClick={() => setCollapsed(!collapsed)}>
        <ToggleMenuArrowWithCircleIcon style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </div>
      <div className="g-header-container">
        <div className="g-header-left g-no-select">
          <Dropdown menu={{ items }} trigger={["click"]} placement="bottomLeft" overlayClassName="g-header-left-menu-dropdown-override">
            <div className="g-header-left-dropdown">
              <div className="g-header-left-dropdown-title" data-testid={`${dataTestIdPrefix}-Title`}>{pageTitle}</div>
              <ArrowDownBold />
            </div>
          </Dropdown>
        </div>
        <HeaderRightUserInfoComponent />
      </div>
    </Header>
  );
};

export default HeaderComponent;
