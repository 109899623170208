export const REQUEST_CONTENT_GET_UGC_CONTENT_BANNER_LEARN_MORE_LINK = "https://www.notion.so/gogander/Set-up-external-uploads-f66bbdc5e2bb49f08744183c57e10867?pvs=25"
export const MANAGE_CONTENT_SOME_LICENSE_PURCHASE_CONFIRMATION_MODAL_LEARN_MORE_LINK = "https://www.notion.so/gogander/FAQ-ec9585c909f34409afb47c5c279407b9#42b0df49ba4d4fbdb5b43f54e418c9e0";
export const SETTINGS_PAGE_SUBSCRIPTION_CUSTOM_PLAN_CONTACT_US_LINK = "https://calendly.com/gander-demo/gander-intro-call-clone?month=2023-01";
export const SETTINGS_PAGE_SUBSCRIPTION_FREQUENTLY_ASKED_AND_CUSTOMER_SUPPORT_LINK = "https://www.notion.so/gogander/Billing-Usage-7b5c50a969b54c61bdd09920b51a3f1c?pvs=25";
export const SETTINGS_PAGE_PRICING_LINK = "https://www.gogander.io/pricing";
export const CUSTOMIZATION_PAGE_EMBEDDED_HELP_LINK = "https://www.notion.so/gogander/How-to-Locate-Where-to-Paste-your-Embedded-Code-Snippet-e422722124c049b9b8f18f351451c1fc?pvs=25";
export const HELP_CENTER_LINK = "https://www.notion.so/gogander/d23620b7e3fe4104858bd95db2282b46?v=6eb32393aae54146ae789fec88858e6f&pvs=4";
export const SHOPIFY_SET_UP_SUPPORT_LINK = "https://www.notion.so/gogander/Set-Up-Gander-in-Shopify-9844ba4ff2fd497ba6b869a378be5bdc?pvs=25";
export const WORDPRESS_SET_UP_SUPPORT_LINK = "https://www.notion.so/gogander/Set-Up-Gander-in-Wordpress-WooCommerce-34ad973a9e944adf87276394f25701b3?pvs=25";
export const WEBFLOW_SET_UP_SUPPORT_LINK = "https://www.notion.so/gogander/Set-Up-Gander-in-Webflow-bc72db82fe8c4e8caf57aa5d33996d28?pvs=25";
export const WIX_SET_UP_SUPPORT_LINK = "https://www.notion.so/gogander/Set-Up-Gander-in-Wix-a09d4d3fe10f4310bd2c659812fd4909?pvs=25";
export const CUSTOM_SITE_SET_UP_SUPPORT_LINK = "https://www.notion.so/gogander/Set-Up-Gander-on-Custom-Site-659cb8db552f4948a3b3257ebbb0a6c6?pvs=25";