import { MouseEvent } from "react";
import { Select } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { EMetricTimeString } from "interfaces";

const { Option } = Select;

interface IProps {
  currentSelectKey: string;
  handleChange: (value: string) => void;
  onClick: () => void;
}
// TODO: select date range component
const SelectComponent = ({ currentSelectKey, handleChange, onClick }: IProps) => {

  const handleSelectOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick()
  }
  return (
    <Select
      suffixIcon={<CalendarOutlined />}
      key="1"
      size="large"
      defaultValue={currentSelectKey}
      className="g-header-extra-selector"
      value={currentSelectKey}
      onSelect={handleChange}
    >
      <Option value={EMetricTimeString.LAST_DAY}>{EMetricTimeString.LAST_DAY}</Option>
      <Option value={EMetricTimeString.LAST_7_DAYS}>{EMetricTimeString.LAST_7_DAYS}</Option>
      <Option value={EMetricTimeString.LAST_28_DAYS}>{EMetricTimeString.LAST_28_DAYS}</Option>
      <Option value={EMetricTimeString.LAST_3_MONTHS}>{EMetricTimeString.LAST_3_MONTHS}</Option>
      <Option value={EMetricTimeString.LAST_6_MONTHS}>{EMetricTimeString.LAST_6_MONTHS}</Option>
      <Option value={EMetricTimeString.LAST_12_MONTHS}>{EMetricTimeString.LAST_12_MONTHS}</Option>
      <Option value={EMetricTimeString.CUSTOM_TIME}>
        {/* TODO : styling */}
        <div className="g-ant-select-item-a">
          <a
            href="/"
            onClick={handleSelectOnClick}
          >
            {" "}
            {EMetricTimeString.CUSTOM_TIME}
          </a></div>
      </Option>
    </Select>
  );
};

export default SelectComponent;
