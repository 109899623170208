import { Button, Spin, Typography } from "antd";
import { SETTINGS_PAGE_PRICING_LINK, SETTINGS_PAGE_SUBSCRIPTION_FREQUENTLY_ASKED_AND_CUSTOMER_SUPPORT_LINK } from "constants/hyper-links";
import { AppContext } from "context/app-context";
import { EBillingCycle, ISubscriptionPlan } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { apiCreateCustomerPortalSession } from "services/stripe";
import { apiGetPlanSelectRedirectLink } from "services/subscription.services";
import { PRIVATE_ROUTES } from "constants/routes";
import { toast } from "utils/toast";
import { getPlansAndParameters } from "./utils";
import CancelPlanConfirmationModal from "./cancel-plan-confirmation-modal";
import StripeSubscriptionUpdateConfirmationModal from "./stripe-subscription-update-confirmation-modal";
import SelectPlanTableV1V2 from "components/billing-plan/select-plan-table-v1-v2";
import SelectPlanTableV3 from "components/billing-plan/select-plan-table-v3";
import SelectPlanTableBillingCycleSwitch from "app/pages/common/select-plan-table-billing-cycle-switch";

import "./index.scss";

const { Title } = Typography;
const dataTestIdPrefix = "Settings-Billing-Subscription-Component";

interface IProps {
  isActive: boolean;
}

const BillingSubscriptionComponent = ({ isActive }: IProps) => {
  const { setIsAppLoading, activeSubscription, shopDetails } = useContext(AppContext);
  const [isSubscriptionPlansLoading, setIsSubscriptionPlansLoading] = useState(false);
  const [plans, setPlans] = useState<ISubscriptionPlan[] | undefined>();
  const [isCancelCurrentPlanConfirmation, setIsCancelCurrentPlanConfirmation] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionPlan | undefined>();
  const [planSelectApiLoading, setPlanSelectApiLoading] = useState(false);
  const [showStripeConfirmationModal, setShowStripeConfirmationModal] = useState(false);

  const [billingCycleType, setBillingCycleType] = useState<EBillingCycle>(EBillingCycle.ANNUALLY);
  const [isShowBillingCycleContent, setIsShowBillingCycleContent] = useState(false);
  const [isShowNewPlansTable, setIsShowNewPlansTable] = useState<boolean>();

  useEffect(() => {
    if (isActive) {
      getPlans();
    }
  }, [isActive]);

  const getPlans = async () => {
    try {
      setIsSubscriptionPlansLoading(true);
      let { plans, defaultBillingCycle, isShowBillingCycleContent, isV3PlansFlow } = await getPlansAndParameters(shopDetails!, activeSubscription!);
      setPlans(plans);
      setBillingCycleType(defaultBillingCycle);
      setIsShowBillingCycleContent(isShowBillingCycleContent);
      setIsShowNewPlansTable(isV3PlansFlow);
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    } finally {
      setIsSubscriptionPlansLoading(false);
    }
  };

  const selectPlan = async (plan: ISubscriptionPlan) => {
    setSelectedPlan(plan);
    try {
      if (shopDetails?.platform === "shopify") {
        setPlanSelectApiLoading(true);
        const response = await apiGetPlanSelectRedirectLink(plan?.id, plan?.meta);

        if (response?.confirmationUrl) {
          window.open(response?.confirmationUrl, "_self");
        }
        setPlanSelectApiLoading(false);
      } else {
        setShowStripeConfirmationModal(true);
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    }
  };

  const redirectToCustomerPortal = async () => {
    try {
      setIsAppLoading(true);
      const responseData = await apiCreateCustomerPortalSession(shopDetails!.stripeCustomerId!, PRIVATE_ROUTES?.SETTINGS?.PATH);
      window.open(responseData?.customerPortalSession.url, "_self");
    } catch (e) {
      console.log(e);
      toast.error("Failed to redirect to customer portal");
      setIsAppLoading(false);
    }
  }

  return (
    <div className="g-billing-subscription-component">
      <div className="g-billing-usage-cycle-wrapper">
        <div>
          <Title level={3}>Billing and Usage</Title>
          <div className="g-billing-subscription-subtitle">
            Take your brand to the next level with Gander. See more details about our pricing plans&nbsp;
            <Button
              type="link"
              className="g-padding-0"
              href={SETTINGS_PAGE_PRICING_LINK}
              target="_blank"
              data-testid={`${dataTestIdPrefix}-morePricingPlansDetails`}
            >
              <u>here</u>
            </Button>
            .
          </div>

          {/* Cancel plan and edit plan links ---   starts */}
          {shopDetails?.platform === "shopify" && (
            <div className="g-billing-subscription-subtitle">
              If you would like to cancel your account, you can do so&nbsp;
              <Button type="link" className="g-padding-0" onClick={() => setIsCancelCurrentPlanConfirmation(true)} data-testid={`${dataTestIdPrefix}-cancelPlanLink`}>
                <u>here</u>
              </Button>
              .
            </div>
          )}
          {shopDetails?.platform !== "shopify" && (
            <div className="g-billing-subscription-subtitle">
              If you would like to edit your account or payment details, you can do so&nbsp;
              <Button type="link" className="g-padding-0" onClick={() => redirectToCustomerPortal()} data-testid={`${dataTestIdPrefix}-editAccountLink`}>
                <u>here</u>
              </Button>
              .
            </div>
          )}
          {/* Cancel plan and edit plan links ---   ends */}
        </div>
        <div>
          {!isSubscriptionPlansLoading && isShowBillingCycleContent &&
            <SelectPlanTableBillingCycleSwitch billingCycleType={billingCycleType} setBillingCycleType={setBillingCycleType} />
          }
        </div>
      </div>
      <div className="g-subscription-table-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={4}>Subscription Plans</Title>
        </div>
        {isSubscriptionPlansLoading && (
          <div className="g-subscription-plans-loading">
            <Spin size="default"></Spin>
          </div>
        )}

        {!isSubscriptionPlansLoading && isShowNewPlansTable &&
          <SelectPlanTableV3
            subscriptionPlans={plans}
            selectedPlan={selectedPlan}
            isSubscriptionSelectPlansLoading={planSelectApiLoading}
            selectPlan={selectPlan}
            isHideStartForFreeButton={true}
            billingCycleType={billingCycleType}
          />
        }

        {!isSubscriptionPlansLoading && !isShowNewPlansTable &&
          <SelectPlanTableV1V2
            subscriptionPlans={plans}
            selectedPlan={selectedPlan}
            isSubscriptionSelectPlansLoading={planSelectApiLoading}
            selectPlan={selectPlan}
            isHideStartForFreeButton={true}
          />
        }

        <div>
          <span className="g-subscription-billing-question-title">Billing question?</span>
          <span className="g-subscription-billing-question-detail">
            See{" "}
            <a
              href={SETTINGS_PAGE_SUBSCRIPTION_FREQUENTLY_ASKED_AND_CUSTOMER_SUPPORT_LINK}
              target="_blank"
              rel="noreferrer"
              data-testid={`${dataTestIdPrefix}-helpCenterArticleLink`}
            >
              Read our help center article here.
            </a>
          </span>
        </div>
      </div>

      <CancelPlanConfirmationModal
        isActive={isCancelCurrentPlanConfirmation}
        closeModal={setIsCancelCurrentPlanConfirmation}
      />

      {showStripeConfirmationModal && selectedPlan && (
        <StripeSubscriptionUpdateConfirmationModal
          currentPlan={activeSubscription?.activePlan}
          selectedPlan={selectedPlan}
          refetchPlans={getPlans}
          redirectToCustomerPortal={redirectToCustomerPortal}
          closeModal={() => setShowStripeConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default BillingSubscriptionComponent;
