import { Button } from "antd"
import { PlayerCloseIcon, PlayerPlayIcon } from "components/icons";
import ReactPlayer from "react-player";
import { useState } from "react";
import { IContentRequest } from "interfaces";
import { IVideo } from "interfaces/schema";

import './index.scss';

const dataTestIdPrefix = "ReviewVideoPopup";

interface IProps {
    isContentRequestUpdating: boolean;
    selectedContentRequest?: IContentRequest;
    setSelectedContentRequest?: React.Dispatch<React.SetStateAction<IContentRequest | undefined>>,
    setIsShowReviewVideoPopup?: React.Dispatch<React.SetStateAction<boolean>>,
    onApproveReject: (type: string) => void;
    selectedReviewVideo?: IVideo;
    setSelectedReviewVideo?: React.Dispatch<React.SetStateAction<IVideo | undefined>>;
}

const ReviewVideoPopup = ({ isContentRequestUpdating, selectedContentRequest, setSelectedContentRequest, setIsShowReviewVideoPopup, onApproveReject, selectedReviewVideo, setSelectedReviewVideo }: IProps) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [currentResponseText, setCurrentResponseText] = useState("");

    const closeVideoPlayer = () => {
        if (isContentRequestUpdating) {
            // return if api call is in process
            return;
        }

        if (setSelectedContentRequest && setIsShowReviewVideoPopup) {
            setIsPlaying(false);
            setIsShowReviewVideoPopup(false);
            setSelectedContentRequest(undefined);
        } else if (setSelectedReviewVideo) {
            setSelectedReviewVideo(undefined);
        }
    };

    const onVideoPreviewResponse = (resText: string) => {
        onApproveReject(resText);
        setCurrentResponseText(resText);
    }
    
    return (
        <div className="g-review-video-popup-wrapper">
            <div className="g-review-video-popup-video-player-container">
                <div
                    className="g-review-video-popup-video-player-close-icon"
                    onClick={closeVideoPlayer}
                    data-testid={`${dataTestIdPrefix}-CloseButton`}
                >
                    <PlayerCloseIcon />
                </div>
                <div className={"g-review-video-popup-video-player-play-icon " + (isPlaying && "g-hidden")} onClick={() => setIsPlaying(true)}>
                    <PlayerPlayIcon />
                </div>
                <ReactPlayer
                    playsinline
                    playsInline
                    className="g-react-video-player"
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onError={(error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
                        console.log(" error => ", error, "\n", "data => ", data, "\n", "hlsInstance => ", hlsInstance, "\n", "hlsGlobal => ", hlsGlobal);
                        if (error.name === "AbortError" || error.name === "NotAllowedError") {
                            setIsPlaying(false);
                        } else if (error === "hlsError" && data?.response?.code === 404) {
                            closeVideoPlayer();
                        }
                    }}
                    url={(selectedContentRequest?.video as IVideo)?.playbackId || selectedReviewVideo?.playbackId}
                    controls={true}
                    muted={false}
                    playing={isPlaying}
                    pip={false}
                    height={"100%"}
                    width={"100%"}
                />
                <div className="g-review-video-popup-footer-btns">
                    <Button
                        data-testid={`${dataTestIdPrefix}-RejectButton`}
                        block
                        danger
                        type="primary"
                        size="large"
                        onClick={() => onVideoPreviewResponse('reject')}
                        loading={isContentRequestUpdating && currentResponseText === "reject"}
                        disabled={isContentRequestUpdating && currentResponseText === "approve"}
                    >
                        Reject
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        data-testid={`${dataTestIdPrefix}-ApproveButton`}
                        block
                        type="primary"
                        size="large"
                        onClick={() => onVideoPreviewResponse('approve')}
                        loading={isContentRequestUpdating && currentResponseText === "approve"}
                        disabled={isContentRequestUpdating && currentResponseText === "reject"}
                    >
                        Approve
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ReviewVideoPopup;