import { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Drawer, Form, Input, List, Modal, Select, Spin, Tooltip, Typography } from 'antd';
import { ArrowWithCircleIconBackground, VideosTableEditIcon } from 'components/icons';
import { LeftOutlined, LinkOutlined, RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges, } from '@dnd-kit/modifiers';
import SortableConnectedVideoListItemComponent from './sortable-item-component';
import { AppContext } from 'context/app-context';
import { apiCreatePage, apiDeletePage, apiUpdatePage } from 'services/pages.services';
import { ICreatePage, IPage } from 'interfaces';
import { toast } from 'utils/toast';
import moment from 'moment';
import { cloneDeep, sortBy } from 'lodash';
import { apiLinkVideoAndPage, apiUnlinkVideoPage } from 'services/page.services';
import { apiGetVideos } from 'services/video.services';
import { getURL } from 'app/pages/pages/utils';
import { PagesContext } from 'context/pages-context';
import { EVideoSource, IVideo } from 'interfaces/schema';

import './index.scss';

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const dataTestIdPrefix = "Pages-EditPageDetailsComponent";

interface IProps {
    viewPageDetailsDrawer: any;
    setViewPageDetailsDrawer: React.Dispatch<React.SetStateAction<IPage | null | undefined | boolean>>;
    onNextViewDetailsEvent: any;
    onPrevViewDetailsEvent: any;
}

const AddEditPageDetailsComponent = ({ viewPageDetailsDrawer, setViewPageDetailsDrawer, onNextViewDetailsEvent, onPrevViewDetailsEvent }: IProps) => {
    const { shopDetails, setIsShowV3FreePlanConnectLimitModel } = useContext(AppContext);
    const { fetchPages } = useContext(PagesContext);
    const [form] = Form.useForm();
    const [videos, setVideos] = useState<IVideo[]>([]);
    const [isSearchVideoLoading, setIsSearchVideoLoading] = useState(false);
    const [searchedVideos, setSearchedVideos] = useState<IPage[]>([]);
    const [selectedSearchedVideo, setSelectedSearchedVideo] = useState<string | null>(null);
    const [editableName, setEditableName] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const items = useMemo(() => videos?.map((vid: any) => vid?.id), [videos]);

    const linkedVideoMetas = viewPageDetailsDrawer?.videos?.map((video: any) => video?.meta);

    useEffect(() => {
        if (viewPageDetailsDrawer) {
            form?.setFieldValue('name', viewPageDetailsDrawer?.name);
            form?.setFieldValue('url', viewPageDetailsDrawer?.url ? shopDetails?.domain + viewPageDetailsDrawer?.url : null);
            form?.setFieldValue('type', viewPageDetailsDrawer?.type);
            if (viewPageDetailsDrawer?.videos) {
                let vids = viewPageDetailsDrawer.videos;
                const orderedVids = viewPageDetailsDrawer?.videoOrdering;
                vids.forEach((vid: any, index: number) => {
                    if (vid) {
                        vid.id = vid?.meta;
                        if (orderedVids) {
                            const i = orderedVids.findIndex((v: string) => v === vid.meta);
                            vid.id = (i > -1 ? i : vids.length + index).toString();
                            return vid;
                        }
                    }
                });
                vids = sortBy(vids, 'id');
                setVideos(vids);
            }
        }
        setSearchedVideos([]);
        setIsSearchVideoLoading(true);
        fetchAndUpdateSearchValues();
    }, [viewPageDetailsDrawer]);

    useEffect(() => {
        if (!!selectedSearchedVideo && selectedSearchedVideo?.length > 1) {
            setIsSearchVideoLoading(true);
            const pagesSearchTimeout = setTimeout(() => {
                fetchAndUpdateSearchValues(selectedSearchedVideo);
            }, 1000)
            return () => {
                clearTimeout(pagesSearchTimeout);
                setIsSearchVideoLoading(false);
            }
        }
    }, [selectedSearchedVideo]);

    const editPageName = () => {
        setEditableName(viewPageDetailsDrawer?.name);
    }

    const onRemoveVideo = async (record: any) => {
        setIsLoading(true);
        try {
            const pageVideoMeta = `${viewPageDetailsDrawer.meta}_${record?.meta}`;
            const removeVidRes = await apiUnlinkVideoPage(pageVideoMeta);
            toast.success(removeVidRes?.message);
            fetchPages();
            // update state and context states here after api success
            const videos = cloneDeep(viewPageDetailsDrawer?.videos);
            const index = videos.findIndex((video: IVideo) => video?.meta === record.meta);
            if (index > -1) {
                videos.splice(index, 1);
                const updatePageObj = { ...viewPageDetailsDrawer, videos: [...videos] };
                setViewPageDetailsDrawer(updatePageObj);
            }
        } catch (e: any) {
            console.log("🚀 ~ file: add-edit-page-details-component/index.tsx ~ onFinish ~ exception", e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeletePage = () => {
        confirm({
            title: 'Delete Page',
            icon: <ExclamationCircleOutlined />,
            content: `Do you want to delete this page?`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            async onOk() {
                await onClickDeletePage();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onClickDeletePage = async () => {
        setIsDeleteLoading(true);
        try {
            const meta = viewPageDetailsDrawer?.meta;
            const res = await apiDeletePage(meta);
            toast.success(res?.message);
            fetchPages();
            setViewPageDetailsDrawer(false);
        } catch (e: any) {
            console.log("🚀 ~ file: add-edit-page-details-component/index.tsx ~ onClickDeletePage ~ exception", e);
            toast.error(e?.message);
        } finally {
            setIsDeleteLoading(false);
        }
    }

    const sensors = useSensors(
        // Note: to enable pointer events
        // useSensor(PointerSensor, {
        //     activationConstraint: {
        //         delay: 250,
        //         tolerance: 1,
        //     }
        // }),
        useSensor(MouseSensor, {
            // Note: if you need to add delays on dragging
            // activationConstraint: {
            //     delay: 90,
            //     tolerance: 5,
            // }
        }),
        // Note: to enable keyboard sensor
        // useSensor(KeyboardSensor, {
        //     coordinateGetter: sortableKeyboardCoordinates,
        // })
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active && over && active?.id !== over?.id) {
            setVideos((data: any) => {
                const oldIndex = items.indexOf(active?.id);
                const newIndex = items.indexOf(over?.id);
                return arrayMove(data, oldIndex, newIndex);
            });
        }
    }

    const onFinish = async (values: ICreatePage) => {
        setIsLoading(true);
        try {
            const meta = viewPageDetailsDrawer?.meta;
            const videoOrdering = videos.map((vid) => vid.meta);
            const response = getURL(values.url);
            const pathname = response.url!.pathname;
            const payload = {
                meta: viewPageDetailsDrawer?.meta,
                ...values,
                name: editableName,
                videoOrdering,
                url: decodeURI(pathname),
                mobileSettings: viewPageDetailsDrawer?.mobileSettings,
                desktopSettings: viewPageDetailsDrawer?.desktopSettings,
            }
            const pageRes = meta
                ? await apiUpdatePage(payload)
                : await apiCreatePage({ ...values, name: values?.name ? values.name : decodeURI(pathname), url: decodeURI(pathname) });
            toast.success(pageRes?.message);
            fetchPages();
            setViewPageDetailsDrawer(false);
        } catch (e: any) {
            console.log("🚀 ~ file: add-edit-page-details-component/index.tsx ~ onFinish ~ exception", e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    };

    // handle page linking api call here
    const handleVideoSelect = async (meta: string) => {
        if (meta) {
            try {
                setIsLoading(true);
                const apiPayload = [{
                    "pageMeta": viewPageDetailsDrawer?.meta,
                    "videoMeta": meta
                }];
                await apiLinkVideoAndPage(apiPayload);
                fetchPages();
                toast.success('Video Linked to page successfully');
                // update state and context states here after api call succeed
                const videoToUpdateInState: any = searchedVideos.find((video) => video?.meta === meta);
                const updatePageObj = { ...viewPageDetailsDrawer, videos: [...viewPageDetailsDrawer?.videos, videoToUpdateInState!] };
                setViewPageDetailsDrawer(updatePageObj);
            } catch (e: any) {
                console.log(e);
                if (e?.data?.type === 'upgradePlanError') {
                    setIsShowV3FreePlanConnectLimitModel(true);
                } else {
                    toast.error(e?.message);
                }
            } finally {
                setSelectedSearchedVideo(null);
                setSearchedVideos([]);
                setIsLoading(false);
            }
        } else {
            setSelectedSearchedVideo(null);
        }
    };

    const handleVideoSearch = async (newValue: string) => {
        if (newValue) {
            setSelectedSearchedVideo(newValue);
        } else {
            setSelectedSearchedVideo(null);
            setSearchedVideos([]);
        }
    };

    const fetchAndUpdateSearchValues = async (filterName?: string) => {
        try {
            const searchVideoRes = await apiGetVideos({ filterName });
            if (searchVideoRes?.data && searchVideoRes?.data?.length) {
                const searchVideoWithoutExternalUploaded = searchVideoRes?.data.filter((video: IVideo) => {
                    if (video?.externalUploadDetails?.approved === false && video?.source === EVideoSource.EXTERNAL_UPLOAD) {
                        return false;
                    }
                    return true;
                });

                if (linkedVideoMetas?.length) {
                    // filter videos to exclude already linked videos (to avoid linking again)
                    const filteredPagesExcludingLinked = searchVideoWithoutExternalUploaded?.filter((video: IVideo) => {
                        return !linkedVideoMetas.includes(video?.meta);
                    })
                    setSearchedVideos(filteredPagesExcludingLinked);
                    return;
                }
                setSearchedVideos(searchVideoWithoutExternalUploaded);
                return;
            }
            setSearchedVideos([]);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsSearchVideoLoading(false);
        }
    }

    return (
        <Drawer
            title={
                <>
                    {viewPageDetailsDrawer?.meta ?
                        (
                            <div className='g-d-flex g-justify-between'>
                                <div className='g-manage-content-add-edit-page-details-drawer-title-container'>

                                    <div className='g-d-flex g-align-center'>
                                        {editableName !== undefined ? (
                                            <>
                                                <Input size='large' className='g-manage-content-add-edit-page-details-drawer-title-input'
                                                    value={editableName}
                                                    onChange={(e) => setEditableName(e?.target?.value)}
                                                    disabled={isLoading}
                                                    data-testid={`${dataTestIdPrefix}-NameInput`}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Tooltip title={viewPageDetailsDrawer?.name}>
                                                    <Title level={2} className="g-manage-content-add-edit-page-details-drawer-title" data-testid={`${dataTestIdPrefix}-NameLabel`}>{viewPageDetailsDrawer?.name}</Title>
                                                </Tooltip>
                                                <div className="g-manage-content-add-edit-page-details-drawer-title-edit-icon" onClick={editPageName} data-testid={`${dataTestIdPrefix}-NameEditButton`}>
                                                    <VideosTableEditIcon />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <span className='g-description-text' data-testid={`${dataTestIdPrefix}-LastModified`}>Last modified {moment(viewPageDetailsDrawer?.updatedAt).fromNow()}</span>
                                </div>

                                <div>
                                    <Button className='g-mx-8' type="primary" htmlType="submit" size='large' onClick={() => form.submit()} loading={isLoading} disabled={isLoading || isDeleteLoading}>Save Changes</Button>
                                    <Button className='g-mx-8' size='large' icon={<LeftOutlined />} onClick={onPrevViewDetailsEvent} disabled={isLoading || isDeleteLoading || isSearchVideoLoading} data-testid={`${dataTestIdPrefix}-PreviousPageDetail`} />
                                    <Button className='g-mx-8' size='large' icon={<RightOutlined />} onClick={onNextViewDetailsEvent} disabled={isLoading || isDeleteLoading || isSearchVideoLoading} data-testid={`${dataTestIdPrefix}-NextPageDetail`} />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Title level={2}>Create Page </Title>
                            </div>
                        )}
                </>
            }
            className="g-manage-content-add-edit-page-details-drawer"
            placement="right"
            onClose={() => setViewPageDetailsDrawer(null)}
            open={!!(viewPageDetailsDrawer)}
            width={821}
            footerStyle={{ padding: 0 }}
            height={100}
            closable={false}
            bodyStyle={{ maxWidth: 821, overflow: 'hidden' }}
        >
            <ArrowWithCircleIconBackground style={{ transform: viewPageDetailsDrawer ? 'rotate(0deg)' : 'rotate(180deg)' }} onClick={() => setViewPageDetailsDrawer(null)} />
            <div className='g-add-edit-page-details-drawer-content-container g-d-flex' data-testid={`${dataTestIdPrefix}-DrawerBody`}>
                <div className="g-add-edit-page-details-form">
                    <div>
                        <Title level={3}>Page Details</Title>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={() => console.log('onFinishFailed')}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="url"
                                className='g-form-item-url'
                                rules={[
                                    { required: true, whitespace: true, type: 'string', message: 'This field is required' },
                                    {
                                        validator: async (_rule, value: string) => {
                                            const response = getURL(value);

                                            if (!response.isValid) {
                                                throw new Error("It looks like this is not a valid url");
                                            }

                                            const baseUrl = response.url!.hostname;
                                            if (!shopDetails?.domain.endsWith(baseUrl) && !baseUrl.endsWith(shopDetails!.domain)) {
                                                throw new Error(`The domain name of the shop does not match that of the page. Please ensure the page name starts with ${shopDetails?.domain}`);
                                            }
                                        }
                                    }
                                ]}
                                requiredMark={false}
                                label={
                                    <div className='g-d-flex g-justify-between g-w-100 g-align-center'>
                                        <span>Url</span>
                                        <Paragraph copyable={{ icon: <><LinkOutlined /></>, onCopy: () => navigator.clipboard.writeText(form.getFieldValue('url')) }}></Paragraph>
                                    </div>
                                }
                            >
                                <Input
                                    type="url"
                                    size="large"
                                    placeholder="Enter new Page URL"
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    {/* Edit Flow */}
                    {viewPageDetailsDrawer?.meta ? (
                        <div>
                            <Button danger={true} block size='large' onClick={handleDeletePage} disabled={isLoading || isDeleteLoading} loading={isDeleteLoading}>Delete Page</Button>
                        </div>
                    ) : null}

                    {/* Create Flow */}
                    {!viewPageDetailsDrawer?.meta ? (
                        <div className='g-d-flex g-justify-end'>
                            <Button type="primary" htmlType="submit" size='large' onClick={() => form?.submit()} loading={isLoading}>Save</Button>
                        </div>
                    ) : null}
                </div>

                {viewPageDetailsDrawer?.meta &&
                    <div className='g-add-edit-page-details-connected-video-section'>
                        <div className='g-d-flex g-justify-between g-align-center'>
                            <Title level={3}>Connect Videos</Title>
                            {isSearchVideoLoading ? <Spin className='g-mx-8' size="small" /> : null}
                        </div>

                        <Select
                            className='g-add-edit-video-details-form-select-page g-w-100 g-mb-16 g-custom-search-select'
                            size='large'
                            showSearch
                            autoClearSearchValue
                            value={"Select video to connect"}
                            placeholder="Select video to connect"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleVideoSearch}
                            onChange={handleVideoSelect}
                            notFoundContent={null}
                            data-testid={`${dataTestIdPrefix}-SearchVideos`}
                        >
                            {searchedVideos?.map((video: any) => (
                                <Option key={video?.meta} data-testid={`${dataTestIdPrefix}-SearchVideosCardContainer`}>
                                    <div className='g-list-with-video-previews g-list-with-video-previews-fixed-height g-d-flex g-w-100'>
                                        <div className='g-list-with-video-preview-avatar'>
                                            <Avatar shape="square" size={52} src={video?.thumbnail} />
                                        </div>
                                        <div className='g-list-with-video-preview-title-description'>
                                            <Title ellipsis level={5} title={video?.displayName}>{video?.displayName}</Title>
                                            <span>Last modified {moment(video?.updatedAt).fromNow()}</span>
                                        </div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                        <div className="g-mb-16">
                            <Text type="secondary">Note: Unlinking video will also disable Page A/B test functionality, if its enabled.</Text>
                        </div>
                        <div className='g-list-header'>
                            <span>Video Order</span>
                            <span>Action</span>
                        </div>
                        {videos?.length > 0 ?
                            <div className='g-add-edit-page-details-list'>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                                >
                                    <SortableContext items={videos} strategy={verticalListSortingStrategy}>
                                        <List
                                            data-testid={`${dataTestIdPrefix}-LinkedVideosCardContainer`}
                                            className='g-list-with-video-previews g-w-100'
                                            itemLayout="horizontal"
                                            dataSource={videos}
                                            renderItem={(video: any) => (<SortableConnectedVideoListItemComponent key={video?.meta} row={video} onRemoveVideo={onRemoveVideo} isLoading={isLoading} />)}
                                        />
                                    </SortableContext>
                                </DndContext>
                            </div>
                            :
                            <div className='g-add-edit-page-details-no-video-list'>
                                <Title level={5}>No Videos Linked</Title>
                            </div>
                        }
                    </div>
                }
            </div>
        </Drawer>
    )
}

export default AddEditPageDetailsComponent;