import axios from "axios";
import { IInteraction } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  id: string;
  name?: string;
  questionOrder?: string[];
  isNameUpdatedByUser?: boolean;
}

interface Response {
  interaction: IInteraction;
  message: string;
}

const apiUpdateInteraction = async (payload: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/interactions`;
    const response = await axios.put<Response>(url, payload);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiUpdateInteraction;