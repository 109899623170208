import { Typography } from "antd";
import { IChoiceResponse } from "interfaces/snowflake";

const { Text } = Typography;

const dataTestIdPrefix = "MultipleChoiceResponseCell";

interface Props {
  choiceResponses: IChoiceResponse[];
}

const MultipleChoiceResponseCell = ({ choiceResponses }: Props) => {
  return (
    <Text data-testid={`${dataTestIdPrefix}-Text`}>
      {choiceResponses.map(
        ({ choice_text: choiceText }, index) => choiceResponses.length === index + 1 ? choiceText : `${choiceText}, `
      )}
    </Text>
  );
};

export default MultipleChoiceResponseCell;