import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Card, Modal, Spin } from "antd";
import Header from "./header-component";
import Preview from "./preview-component";
import InteractionEdit from "./interaction-edit-component";
import ResultsView from "./results-view-component";
import { toast } from "utils/toast";
import { apiGetInteraction } from "services/interactions";
import { IInteraction, IQuestion } from "interfaces/schema";
import { EInteractionEditTabs } from "interfaces";
import * as ROUTE_PATHS from 'constants/routes';
import { isInvalidQuestionValidator } from "./interaction-edit-component/utils/is-invalid-question-validator";
import { useNavigationBlocker } from "hooks/useNavigationBlocker";

import "./index.scss";

const setInitialTab = (tabValue: string | null) => {
  if (tabValue && (Object).values<any>(EInteractionEditTabs).includes(tabValue)) {
    return tabValue as EInteractionEditTabs;
  }

  return EInteractionEditTabs.EDIT_QUESTIONS;
};

const InteractionView = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [interaction, setInteraction] = useState<IInteraction>();
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | undefined>();
  const [activeTab, setActiveTab] = useState(setInitialTab(searchParams.get("tab")));
  const [isLoading, setIsLoading] = useState(true);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [invalidQuestion, setInvalidQuestion] = useState<IQuestion | null>(null);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [isValidateForm, setIsValidateForm] = useState(false);
  const navigateAway = useRef<any>();

  useEffect(() => {
    const getFirstQuestion = ({ questionOrder, questions }: IInteraction) => {
      if (questionOrder.length === 0) {
        return undefined;
      }
      const firstQuestion = questions.find(({ meta }) => meta === questionOrder[0]);
      return firstQuestion;
    }

    const fetchInteraction = async (id: string) => {
      try {
        const response = await apiGetInteraction({ id });
        setInteraction(response.interaction);
        setSelectedQuestion(getFirstQuestion(response.interaction));
      } catch (e) {
        console.log(e);
        toast.error("Sorry, something went wrong with fetching interaction. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }

    if (id) {
      fetchInteraction(id);
    }
  }, []);

  useEffect(() => {
    if (interaction) {
      const invalidQ = isInvalidQuestionValidator(interaction);
      setInvalidQuestion(invalidQ);
    }
  }, [interaction]);

  const handleNavigateAway = (nAway: () => void) => {
    setIsShowConfirmationPopup(true);
    navigateAway.current = nAway;
  };

  const onStay = () => {
    setSelectedQuestion(invalidQuestion as IQuestion);
    setIsShowConfirmationPopup(false);
    setTimeout(() => {
      setIsValidateForm(true);
    }, 100);
  };

  const onLeave = () => {
    navigateAway?.current();
    setIsShowConfirmationPopup(false);
  };
  useNavigationBlocker(!!invalidQuestion, handleNavigateAway);

  if (isLoading) {
    return (
      <div className="g-h-100 g-d-flex g-justify-center g-align-center">
        <Spin size="large" />
      </div>
    );
  }

  if (interaction === undefined) {
    return (
      <div className="g-h-100 g-d-flex g-justify-center g-align-center">
        Sorry, the interaction does not exist. Click&nbsp;
        <Button type="link" className="g-padding-0" onClick={() => navigate(ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH)}>
          <u>here</u>
        </Button>
        &nbsp;to go back.
      </div>
    );
  }

  return (
    <div className="g-interaction-edit-container g-h-100">
      <div className="g-interaction-edit-card-container">
        <Card
          title={(
            <Header
              interaction={interaction}
              activeTab={activeTab}
              isCardLoading={isCardLoading}
              setInteraction={setInteraction}
              setActiveTab={setActiveTab}
              setIsCardLoading={setIsCardLoading}
              invalidQuestion={invalidQuestion}
            />
          )}
        >
          {isCardLoading && (
            <div className="g-h-100 g-d-flex g-justify-center g-align-center">
              <Spin size="large" />
            </div>
          )}
          {!isCardLoading && activeTab === EInteractionEditTabs.EDIT_QUESTIONS && (
            <div className="g-interactions-settings-card-body-container">
              <InteractionEdit
                interaction={interaction}
                selectedQuestion={selectedQuestion}
                setInteraction={setInteraction}
                setSelectedQuestion={setSelectedQuestion}
                isValidateForm={isValidateForm}
                setIsValidateForm={setIsValidateForm}
              />
              <Card.Grid className="g-interactions-settings-sub-card-container" hoverable={false}>
                <Preview selectedQuestion={selectedQuestion} />
              </Card.Grid>
            </div>
          )}
          {!isCardLoading && activeTab === EInteractionEditTabs.RESULTS && (
            <ResultsView
              interaction={interaction}
            />
          )}
        </Card>
      </div>
      {isShowConfirmationPopup &&
        <Modal open centered closable={false} onCancel={onLeave} onOk={onStay} okText='Stay' cancelText='Leave' cancelButtonProps={{ danger: true }}>
          <div>
            One of the fields in your interaction is incomplete. Please fill out all required fields.
          </div>
        </Modal>
      }
    </div>
  );
}

export default InteractionView;