import axios from "axios";
import { EQuestionSelectionType, EQuestionType, IChoice, IQuestion } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  id: string;
  type: EQuestionType;
  text: string;
  selectionType?: EQuestionSelectionType;
  choices: IChoice[];
}

interface Response {
  question: IQuestion;
  message: string;
}

const apiUpdateQuestion = async (payload: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/questions`;
    const response = await axios.put<Response>(url, payload);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiUpdateQuestion;