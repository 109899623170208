import { EConversionRateType, EMetricTimeString, TMetric } from "interfaces";
import moment from "moment";
import { createContext, useState } from "react";
import { findStartDateFromString } from "utils/metrics";

export interface IDashboardContext {
  currentSelectKey: EMetricTimeString;
  setCurrentSelectKey: React.Dispatch<React.SetStateAction<EMetricTimeString>>;

  startDate: moment.Moment;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>;

  endDate: moment.Moment;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>>;

  controller: AbortController;

  metricValues: TMetric | undefined;
  setMetricValues: React.Dispatch<React.SetStateAction<TMetric | undefined>>;

  metricValuesPrev: TMetric | undefined;
  setMetricValuesPrev: React.Dispatch<React.SetStateAction<TMetric | undefined>>;

  currentConversionRateByDeviceType: EConversionRateType;
  setCurrentConversionRateByDeviceType: React.Dispatch<React.SetStateAction<EConversionRateType>>;

  currentConversionRateByVisitorType: EConversionRateType;
  setCurrentConversionRateByVisitorType: React.Dispatch<React.SetStateAction<EConversionRateType>>;
}

const defaultContext: IDashboardContext = {
  currentSelectKey: EMetricTimeString.LAST_28_DAYS,
  setCurrentSelectKey: () => ({}),
  startDate: findStartDateFromString(moment(), EMetricTimeString.LAST_28_DAYS),
  setStartDate: () => ({}),
  endDate: moment(),
  setEndDate: () => ({}),
  controller: new AbortController(),
  metricValues: undefined,
  setMetricValues: () => { },
  metricValuesPrev: undefined,
  setMetricValuesPrev: () => { },
  currentConversionRateByDeviceType: EConversionRateType.CVR,
  setCurrentConversionRateByDeviceType: () => { },
  currentConversionRateByVisitorType: EConversionRateType.CVR,
  setCurrentConversionRateByVisitorType: () => { },
};

export const DashboardContext = createContext<IDashboardContext>(defaultContext);

export const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentSelectKey, setCurrentSelectKey] = useState<EMetricTimeString>(EMetricTimeString.LAST_28_DAYS);
  const [startDate, setStartDate] = useState<moment.Moment>(findStartDateFromString(moment(), EMetricTimeString.LAST_28_DAYS));
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [controller] = useState<AbortController>(new AbortController());
  const [metricValues, setMetricValues] = useState<TMetric | undefined>();
  const [metricValuesPrev, setMetricValuesPrev] = useState<TMetric | undefined>();
  const [currentConversionRateByDeviceType, setCurrentConversionRateByDeviceType] = useState<EConversionRateType>(EConversionRateType.CVR);
  const [currentConversionRateByVisitorType, setCurrentConversionRateByVisitorType] = useState<EConversionRateType>(EConversionRateType.CVR);

  return (
    <DashboardContext.Provider
      value={{
        currentSelectKey,
        setCurrentSelectKey,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        controller,
        metricValues,
        setMetricValues,
        metricValuesPrev,
        setMetricValuesPrev,
        currentConversionRateByDeviceType,
        setCurrentConversionRateByDeviceType,
        currentConversionRateByVisitorType,
        setCurrentConversionRateByVisitorType
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};