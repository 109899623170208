import axios from "axios";
import { IGetVideoApiPayload, IUpdateVideoApiPayload } from "interfaces";
import { IVideo } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;
const VIDEO_API_URL = `${REACT_APP_API_URL}/videos`;

export const apiGetRecentlyAddedVideos = async (noOfVideos?: number): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}/get-videos`;
    const response = await axios.post(url, { limit: noOfVideos });
    return response?.data?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiGetVideos = async (payload: IGetVideoApiPayload): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}/get-videos`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiUpdateVideo = async (updatePayload: IUpdateVideoApiPayload, meta: string): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}`;
    const response = await axios.put(url, { ...updatePayload, meta });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiUnlinkAllPagesFromVideo = async (videoMeta: string): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}/unlink-page-video`;
    const response = await axios.delete(url, { params: { id: videoMeta } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiDeleteVideo = async (videoMeta: string): Promise<any> => {
  try {
    const url = `${VIDEO_API_URL}`;
    const response = await axios.delete(url, { params: { id: videoMeta } });
    return response?.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiGetVideoByMeta = async (videoMeta: string, abortController: AbortController): Promise<IVideo> => {
  try {
    const url = `${VIDEO_API_URL}/${videoMeta}`;
    const response = await axios.get(url, { signal: abortController.signal });
    return response?.data?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiPutReviewExternalUploadedVideo = async (payload: { meta?: string; action: string; }): Promise<{ message: string; }> => {
  try {
    const url = `${REACT_APP_API_URL}/external-uploads/review-video`;
    const response = await axios.put(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};