import { Button, List, Typography } from "antd";
import LinkedProductsListItemComponent from "../linked-products-list-item-component";
import { IProduct } from "interfaces";
import { apiUpdateVideo } from "services/video.services";
import { updateVideoArrayByMeta } from "utils/common-functions";
import { toast } from "utils/toast";
import { IVideo } from "interfaces/schema";

const { Title } = Typography;

interface IProps {
    viewVideoDetailsDrawer: IVideo;
    openViewVideoDetailsDrawer: (vid: IVideo | undefined) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    products: IProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
    setVideos: React.Dispatch<React.SetStateAction<IVideo[]>>;
    videos: IVideo[]
}

const ConnectedProductsComponent = ({ setIsLoading, products, setProducts, viewVideoDetailsDrawer, openViewVideoDetailsDrawer, setVideos, videos }: IProps) => {
    const dataTestIdPrefix = "Videos-AttachProductsComponent-ConnectedProductsComponent";

    const onRemoveProduct = async (productToRemove: IProduct) => {
        try {
            setIsLoading(true);
            let updatedProducts: IProduct[] = [];
            if (productToRemove?.variantId) {
                updatedProducts = products.filter(product => product.variantId !== productToRemove.variantId)
            } else {
                updatedProducts = products.filter(product => product.id !== productToRemove.id)
            }
            setProducts(updatedProducts);
            const updateVideoPayload = { products: updatedProducts };
            await apiUpdateVideo(updateVideoPayload, viewVideoDetailsDrawer.meta);
            const updateVideoObj = { ...viewVideoDetailsDrawer, products: updatedProducts };
            openViewVideoDetailsDrawer(updateVideoObj);
            setVideos(updateVideoArrayByMeta(videos, updateVideoObj));

        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }

    const onRemoveAllProducts = async () => {
        try {
            setIsLoading(true);
            const updateVideoPayload = { products: [] };
            await apiUpdateVideo(updateVideoPayload, viewVideoDetailsDrawer.meta);
            const updateVideoObj = { ...viewVideoDetailsDrawer, products: [] };
            setProducts([]);
            openViewVideoDetailsDrawer(updateVideoObj);
            setVideos(updateVideoArrayByMeta(videos, updateVideoObj));
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="g-add-edit-video-details-form-field-title">
                <Title level={3} data-testid={`${dataTestIdPrefix}-TitleText`}>
                    Connected Products
                </Title>
                {/* TODO: Add bubble with product count */}
                {viewVideoDetailsDrawer.products.length > 0 &&
                    <Button
                        danger
                        type="text"
                        onClick={onRemoveAllProducts}
                        data-testid={`${dataTestIdPrefix}-DeleteAllButton`}
                    >
                        Remove All
                    </Button>
                }
            </div>
            <div className='g-add-edit-video-details-connected-video-section'>
                <div className='g-list-header' data-testid={`${dataTestIdPrefix}-ListHeaderDiv`}>
                    <span>Product</span>
                    <span>Action</span>
                </div>
                <div className='g-add-edit-video-details-product-list'>
                    <List
                        data-testid={`${dataTestIdPrefix}-CardContainer`}
                        className='g-list-with-video-previews g-w-100'
                        itemLayout="horizontal"
                        locale={{ emptyText: 'No products attached' }}
                        dataSource={products}
                        renderItem={(product: IProduct) => (
                            <LinkedProductsListItemComponent key={product.id} row={product} onRemoveProduct={onRemoveProduct} />
                        )}
                    />
                </div>
            </div>
        </>
    )
}

export default ConnectedProductsComponent;