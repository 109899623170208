import { Row, Statistic, Typography, Col, Button, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { apiGetAverageOrderValueByDeviceReport, apiGetAverageOrderValueByVisitorTypeReport } from "services/dashboard.service";
import moment from "moment";
import { DATE_FORMAT, EMetricValues } from "interfaces";
import { DashboardContext } from "context/dashboard-context";

const { Text } = Typography;

interface IMetricCardTableMetricsWithDownloadButtonComponent {
  currentSelectKey: string;
  valuePrev?: any[] | undefined;
  value?: any[] | undefined;
  keys: any[];
  prefix: string;
  postfix: string;
  precision: number;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  controller: AbortController;
  type: EMetricValues;
  topValue: number;
  topValuePrev: number;
}

export const MetricCardTableMetricsWithDownloadButtonComponent = ({ value, precision, startDate, endDate, controller, type, topValue, topValuePrev }: IMetricCardTableMetricsWithDownloadButtonComponent) => {
  const [percentageChange, setPercentageChange] = useState<number | null>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentSelectKey } = useContext(DashboardContext);

  useEffect(() => {
    setPercentageChange(((topValue - topValuePrev) / topValuePrev) * 100)
  }, [topValue, topValuePrev]);

  if (value === undefined || value.length === 0) {
    return <></>;
  }

  if (percentageChange === null) {
    return <></>;
  }

  return (
    <>
      <Row justify={"end"}>
        <Col style={{ float: "right" }}>
          <Statistic
            value={(percentageChange || 0).toFixed(precision)}
            precision={precision}
            valueStyle={{ float: "right", marginRight: "14px", color: "#FF6424", fontSize: "14px" }}
            suffix={"%"}
          />&nbsp;&nbsp;<Text style={{ float: "right", fontSize: "14px", color: "#84818A" }}>{"$"}{topValuePrev?.toFixed(precision)} in {currentSelectKey.toLocaleLowerCase()}</Text>
        </Col>
        <Col>
          <Button disabled={loading ? true : false} style={{ width: "200px", height: "42px", fontWeight: "bold" }} onClick={async (_e) => {
            let csvContent = "data:text/csv;charset=utf-8,";
            if (!value || !startDate || !endDate) { return (<></>); }

            const dateObj = {
              startDate: startDate.format(DATE_FORMAT),
              endDate: endDate.format(DATE_FORMAT),
            };
            setLoading(true);
            switch (type) {
              case EMetricValues.AVERAGE_ORDER_VALUE_BY_DEVICE:
                await Promise.all([
                  apiGetAverageOrderValueByDeviceReport(dateObj, controller),
                ]).then(async (response) => {
                  setLoading(false);
                  csvContent += `${(Object.keys(response[0]?.data[0]).join(","))}\r\n`;
                  response[0]?.data.forEach((row: any) => {
                    csvContent += `${Object.values(row).join(",")}\r\n`;
                  });

                  const encodedUri = encodeURI(csvContent);
                  let link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "data.csv");
                  document.body.appendChild(link);
                  link.click();
                });
                break;
              case EMetricValues.AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE:
                await Promise.all([
                  apiGetAverageOrderValueByVisitorTypeReport(dateObj, controller),
                ]).then(async (response) => {
                  setLoading(false);
                  csvContent += `${(Object.keys(response[0]?.data[0]).join(","))}\r\n`;
                  response[0]?.data.forEach((row: any) => {
                    csvContent += `${Object.values(row).join(",")}\r\n`;
                  });

                  const encodedUri = encodeURI(csvContent);
                  let link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "data.csv");
                  document.body.appendChild(link);
                  link.click();
                });
                break;
              default:
                break;
            }
          }}>{loading ? <><Spin style={{ paddingTop: "5px" }} />&nbsp;&nbsp;</> : <DownloadOutlined />} Download Order ID </Button>
        </Col>
      </Row>
    </>
  );
};
