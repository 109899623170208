import { Button, Col, Row, Skeleton } from "antd";
import { StarFilled } from "@ant-design/icons";
import MetricCardComponent from "./MetricCardComponent";
import { Link } from "react-router-dom";
import { PRIVATE_ROUTES } from "constants/routes";

export default function MetricStatisticComponentBlocked(props: any) {
    const { loading, title, icon, color, information } = props;

    return (
        <MetricCardComponent
            title={title}
            icon={icon}
            color={color}
            body={
                <div>
                    {loading ? (
                        <Skeleton active={true} paragraph={{ rows: 2 }} />
                    ) : (
                        <Row>
                            <Col>
                                <Button type="primary" className="g-metrics-component-blocked-button" size={"small"} icon={<StarFilled />}>PRO</Button>
                            </Col> &nbsp; &nbsp;
                            <Col className='g-metrics-component-blocked-col-flex'>
                                <Link to={PRIVATE_ROUTES.SETTINGS.PATH + '#billing'}><u>upgrade</u></Link>&nbsp;now
                            </Col>
                        </Row>
                    )}
                </div>
            }
            information={information}
        />
    );
}
