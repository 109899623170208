import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Select, Spin, Typography } from "antd";
import { AppContext } from "context/app-context";
import { toast } from "utils/toast";
import CreateAddToCartButtonForm from "./create-add-to-cart-button-form-component";
import useDebounce from "hooks/useDebounce";
import { apiCreateInteraction, apiGetInteractions } from "services/interactions";
import { apiUpdateVideo } from "services/video.services";
import { EInteractionCreationType, IVideo } from "interfaces/schema";
import { IInteraction } from "interfaces/schema";
import { MAX_INTERACTIONS_PER_SEARCH } from "constants/interactions";
import * as ROUTE_PATHS from "constants/routes";
import { PlusOutlined } from "@ant-design/icons";
import { PLATFORM } from "constants/store";

import "./index.scss";

const dataTestIdPrefix = "Videos-AddInteractionFormComponent";

const { Text, Title } = Typography;
const { Option } = Select;

interface Props {
  viewVideoDetailsDrawer: IVideo;
  selectedInteraction: IInteraction | undefined;
  interactions: IInteraction[] | undefined;
  isLoading: boolean;
  setSelectedInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
  setInteractions: React.Dispatch<React.SetStateAction<IInteraction[] | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleRemoveInteraction: () => void;
}

const AddInteractionForm = ({
  viewVideoDetailsDrawer,
  selectedInteraction,
  interactions,
  isLoading,
  setSelectedInteraction,
  setInteractions,
  setIsLoading,
  handleRemoveInteraction,
}: Props) => {
  const { shopDetails } = useContext(AppContext);
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const debouncedInput = useDebounce(input, 500);
  const didMountRef = useRef(false);
  const [isShowCreateAddToCartForm, setIsShowCreateAddToCartForm] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);

  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        setIsSelectLoading(true);
        const response = await apiGetInteractions(debouncedInput.length === 0 ? { limit: MAX_INTERACTIONS_PER_SEARCH } : { filterName: debouncedInput });
        setInteractions(response.interactions);
      } catch (e) {
        console.log(e);
        toast.error("Sorry, something went wrong. Please try again.");
      } finally {
        setIsSelectLoading(false);
      }
    };

    if (didMountRef.current && input === debouncedInput) {
      fetchInteractions();
    }

    didMountRef.current = true;
  }, [debouncedInput]);

  const handleSelect = async (value: string) => {
    try {
      setIsLoading(true);
      setSelectedInteraction(interactions?.find(({ meta }) => meta === value));
      await apiUpdateVideo(
        {
          GSI1PK: `INTERACTION#${value}`,
          GSI1SK: `VIDEO#${viewVideoDetailsDrawer.meta}`,
        },
        viewVideoDetailsDrawer.meta
      );
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setInput(value);
    setIsSelectLoading(true);
  };

  const handleNewInteractionButtonClick = async () => {
    try {
      setIsLoading(true);
      const response = await apiCreateInteraction({ type: EInteractionCreationType.EMPTY });
      await apiUpdateVideo(
        {
          GSI1PK: `INTERACTION#${response.interaction.meta}`,
          GSI1SK: `VIDEO#${viewVideoDetailsDrawer.meta}`,
        },
        viewVideoDetailsDrawer.meta
      );
      navigate(`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/${response.interaction.meta}`);
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="g-add-edit-video-details-drawer-interaction-select-container">
      <div className="g-add-edit-video-details-interaction-form">
        <div className="g-add-edit-video-details-drawer-interaction-select-header">
          <Title className="g-add-edit-video-details-drawer-interaction-select-title" level={3}>
            Select Interaction
          </Title>
          {selectedInteraction && (
            <Button
              type="text"
              className="g-add-edit-video-details-drawer-interaction-select-remove-button"
              onClick={handleRemoveInteraction}
              data-testid={`${dataTestIdPrefix}-RemoveButton`}
            >
              Remove
            </Button>
          )}
        </div>
        <Select
          data-testid={`${dataTestIdPrefix}-AddInteractionForm`}
          className="g-add-edit-video-details-drawer-interaction-select"
          bordered={false}
          size="large"
          showSearch
          disabled={isShowCreateAddToCartForm}
          value={selectedInteraction?.name}
          optionLabelProp="label"
          placeholder="Select an interaction"
          onSelect={handleSelect}
          onSearch={handleSearch}
          filterOption={(input, option) => ((option?.label as string) ?? "").toLowerCase().includes(input.toLowerCase())}
          dropdownRender={(menu) =>
            isSelectLoading ? (
              <Spin className="g-d-flex g-justify-center g-align-center g-padding-x-20" size="small" />
            ) : (
              <>{menu}</>
            )
          }
        >
          {interactions?.map(({ meta, name, updatedAt }) => {
            const isSelectedInteraction = meta === selectedInteraction?.meta;
            return (
              <Option
                className={`g-add-edit-video-details-drawer-interaction-select-option ${isSelectedInteraction && "g-add-edit-video-details-drawer-interaction-select-option-disabled"
                  }`}
                key={meta}
                value={meta}
                label={name}
                disabled={isSelectedInteraction}
                data-testid={`${dataTestIdPrefix}-AddInteractionForm-Option-${meta}`}
              >
                <Text className="g-add-edit-video-details-drawer-interaction-select-option-name">{name}</Text>
                <br></br>
                <Text className="g-add-edit-video-details-drawer-interaction-select-option-date">
                  Last modified {moment(updatedAt).format("D MMM YYYY h:mm A")}
                </Text>
              </Option>
            );
          })}
        </Select>
        {isShowCreateAddToCartForm ? (
          <CreateAddToCartButtonForm
            viewVideoDetailsDrawer={viewVideoDetailsDrawer}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsShowCreateAddToCartForm={setIsShowCreateAddToCartForm}
            setSelectedInteraction={setSelectedInteraction}
          />
        ) : (
          <>
            {shopDetails && (shopDetails["platform"].toLowerCase() === PLATFORM?.Shopify.toLowerCase() || shopDetails["platform"].toLowerCase() === PLATFORM?.ShopifyV2.toLowerCase()) && (
              <Button
                className="g-title-level-6 g-mt-24"
                type="primary"
                icon={<PlusOutlined />}
                size="large"
                onClick={() => setIsShowCreateAddToCartForm(true)}
                loading={isLoading || isSelectLoading}
              >
                Create Add To Cart Button
              </Button>)
            }
            <Button
              className="g-title-level-6 g-mt-24"
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              onClick={() => handleNewInteractionButtonClick()}
              loading={isLoading || isSelectLoading}
              data-testid={`${dataTestIdPrefix}-CreateNewInteractionButton`}
            >
              Create Interaction
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AddInteractionForm;
