import toggleFullscreenImg from '../../../../assets/img/toggle-full-screen.svg';
import './index.scss';

const dataTestIdPrefix = "small-browser-overlay";
const SmallBrowserOverlayComponent = () => {
    return (
        <div className="g-small-browser-overlay-component-container" data-testid={`${dataTestIdPrefix}-container`}>
            <div className="g-small-browser-overlay-content" data-testid={`${dataTestIdPrefix}-content`}>
                <img src={toggleFullscreenImg} alt="fullscreen-icon" />
                <h3>Your browser is too small</h3>
                <p>Resize your browser to be at least <br /> 900px wide to see your Gander <br /> Dashboard</p>
            </div>
        </div>
    );
};

export default SmallBrowserOverlayComponent;
