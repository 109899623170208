import { Typography } from "antd";
import moment from "moment";
import MultipleChoiceResponseCell from "./multiple-choice-response-cell-component";
import PictureChoiceResponseCell from "./picture-choice-response-cell-component";
import ShortAnswerResponseCell from "./short-answer-response-cell-component";
import BasicResponseCell from "./basic-response-cell-component";
import { EQuestionType } from "interfaces/schema";
import { IGetInteractionResponsesData } from "interfaces/snowflake";

import "./index.scss";

const { Text } = Typography;

const CELL_WIDTH = 200;

interface Props {
  data: IGetInteractionResponsesData[];
}

const InteractionResponsesTableColumns = ({ data }: Props) => {
  if (data.length === 0) {
    return [];
  }

  const columnHeaders = data?.[0]?.QUESTION_RESPONSES?.map(({ id, question_text }) => ({ id, question_text }));
  return [
    {
      title: <Text className="g-interaction-edit-responses-card-table-header-row">Started date</Text>,
      width: CELL_WIDTH,
      key: 'started_date',
      render: ({ STARTED_DATE }: IGetInteractionResponsesData) => <Text>{moment(STARTED_DATE).format("D MMM YYYY h:mm A")}</Text>,
    },
    {
      title: <Text className="g-interaction-edit-responses-card-table-header-row">Submitted date</Text>,
      width: CELL_WIDTH,
      key: 'submitted_date',
      render: ({ SUBMITTED_DATE }: IGetInteractionResponsesData) => (
        SUBMITTED_DATE ? <Text>{moment(SUBMITTED_DATE).format("D MMM YYYY h:mm A")}</Text> : <div className="g-d-flex g-justify-center g-align-center">-</div>
      ),
    },
    ...columnHeaders.map(({ id, question_text }, index) => {
      return {
        title: <Text className="g-interaction-edit-responses-card-table-header-row">{question_text}</Text>,
        width: CELL_WIDTH,
        key: id,
        render: ({ QUESTION_RESPONSES: questionResponses }: IGetInteractionResponsesData) => {
          const { choice_responses: choiceResponses, question_type: questionType } = questionResponses[index];
          if (!choiceResponses || choiceResponses.length === 0) {
            return (
              <div className="g-d-flex g-justify-center g-align-center">-</div>
            );
          }

          let responseRenderComponent: JSX.Element;
          switch (questionType) {
            case EQuestionType.CALL_TO_ACTION:
            case EQuestionType.ADD_TO_CART:
              responseRenderComponent = <BasicResponseCell />;
              break;
            case EQuestionType.PICTURE_CHOICE:
              responseRenderComponent = <PictureChoiceResponseCell choiceResponses={choiceResponses} />;
              break;
            case EQuestionType.SHORT_ANSWER:
              responseRenderComponent = <ShortAnswerResponseCell choiceResponses={choiceResponses} />;
              break;
            default:
              responseRenderComponent = <MultipleChoiceResponseCell choiceResponses={choiceResponses} />;
          }
          return (
            <div className="g-interaction-edit-responses-card-table-cell">
              {responseRenderComponent}
            </div>
          );
        },
      };
    })
  ];
};

export default InteractionResponsesTableColumns;