export enum PlayerCustomizationSettings {
  EMBEDDED_PREVIEW_PLAYER_SIZE_MAX = 100,
  EMBEDDED_PREVIEW_PLAYER_SIZE_DEFAULT = 50,
  EMBEDDED_PREVIEW_CORNER_RADIUS_MAX = 50,
  EMBEDDED_PREVIEW_CORNER_RADIUS_DEFAULT = 25,
  EMBEDDED_INFRAME_CORNER_RADIUS_MAX = 5,
  EMBEDDED_HORIZONTAL_SCROLL_PREVIEW_MIN_HEIGHT = 70,
  EMBEDDED_GRID_PREVIEW_MIN_HEIGHT = 230,
  OPEN_OVERLAY_PORTRAIT_LANDSCAPE_MIN_HEIGHT_WIDTH = 380,
  MOBILE_PREVIEW_OVERLAY_PORTRAIT_MIN_HEIGHT = 163,
  PREVIEW_OVERLAY_PORTRAIT_MIN_HEIGHT = 207,
}