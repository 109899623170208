import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Typography } from 'antd';
import { toast } from 'utils/toast';
import useDebounce from 'hooks/useDebounce';
import { apiGetInteractions } from 'services/interactions';
import { PlusOutlined } from '@ant-design/icons';
import { searchSvg } from 'components/icons';
import { MAX_INTERACTIONS_PER_PAGE } from 'constants/interactions';
import UpgradeYourAccountModal from 'app/pages/common/upgrade-your-account-modal-component';
import { InteractionsContext } from 'context/interactions-context';

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = "InteractionsHeader";

interface Props {
  setShowCreateInteractionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFiltered: React.Dispatch<React.SetStateAction<boolean>>;
}

const InteractionsHeader = ({
  setIsFiltered,
  setShowCreateInteractionModal,
}: Props) => {
  const {
    isLoading,
    isAllowedCreateNew,
    totalInteractionsCreated,
    setLastEvaluatedMeta,
    setInteractions,
    setIsLoading,
  } = useContext(InteractionsContext);
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const didMountRef = useRef(false);
  const [isShowUpgradePopup, setIsShowUpgradePopup] = useState(false);

  useEffect(() => {
    const fetchFilteredInteractions = async () => {
      try {
        setIsLoading(true);
        const response = await apiGetInteractions(debouncedInput.length === 0 ? { limit: MAX_INTERACTIONS_PER_PAGE } : { filterName: debouncedInput });
        setIsFiltered(Boolean(debouncedInput));
        setInteractions(response.interactions);
        setLastEvaluatedMeta(response.lastEvaluatedMeta);
      } catch (e) {
        console.log(e);
        toast.error("Sorry, something went wrong with searching interactions. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }

    if (didMountRef.current && input === debouncedInput) {
      fetchFilteredInteractions();
    }

    didMountRef.current = true;
  }, [debouncedInput]);

  const openCreateNewInteractionsModel = () => {
    if (!isAllowedCreateNew) {
      setIsShowUpgradePopup(true);
      return;
    }
    setShowCreateInteractionModal(true);
  }

  return (
    <div className="g-d-flex g-justify-between g-padding-x-20" data-testid="Interactions-Header">
      <Title level={3} data-testid={`${dataTestIdPrefix}-Title`}>Manage Interactions</Title>
      <div className="g-interactions-page-header-right-container g-d-flex g-justify-between g-align-center">
        <Input
          size="large"
          placeholder="Search"
          prefix={searchSvg}
          value={input}
          disabled={isLoading}
          allowClear
          onChange={(e) => setInput(e?.target?.value)}
          data-testid={`${dataTestIdPrefix}-SearchInteractionsInput`}
        />
        <Button
          className="g-title-level-6"
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          disabled={isLoading}
          onClick={() => openCreateNewInteractionsModel()}
          data-testid={`${dataTestIdPrefix}-CreateInteractionButton`}
        >
          Add New Interaction
        </Button>
      </div>

      {isShowUpgradePopup &&
        <UpgradeYourAccountModal
          setIsShowUpgradePopup={setIsShowUpgradePopup}
          modalTitle={"Upgrade Your Account!"}
          modalDescription={`You have created ${totalInteractionsCreated} interaction, which is the maximum number of interactions allowed on your current plan. If you'd like to add more interactions, please upgrade to a Professional plan on your settings page.`}
          isClosable={true}
        />}
    </div>
  );
}

export default InteractionsHeader;