import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { notification, Spin } from "antd";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { AppContext } from "./context/app-context";
import AllRoutes from "./routes";
import { apiGetCurrentActivePlan } from "services/subscription.services";
import { ganderAccessPostCheckout } from "services/stripe";
import "@aws-amplify/ui-react/styles.css";
import { toast } from "utils/toast";
import { apiSyncStatus } from "services/shop";
import OnboardingModal from "components/onboarding-modal";
import UpgradeYourAccountModal from "app/pages/common/upgrade-your-account-modal-component";

import "./App.scss";

const ifStripePostCheckout = (searchParams: URLSearchParams) => {
    return searchParams.get("success");
};

const App = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const { route } = useAuthenticator((context) => [context.route]);
    const {
        isAppLoading, isOnboardingModal, setIsOnboardingModal, setIsAppLoading, setCurrentUserInfo,
        shopDetails, setActiveSubscription, setShopDetails,
        isShowV3FreePlanConnectLimitModel, setIsShowV3FreePlanConnectLimitModel
    } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const ldClient = useLDClient();
    // notification message config
    notification.config({ duration: 2 });

    useEffect(() => {
        // registering interceptor
        interceptAxiosResponse();
    }, []);

    useEffect(() => {
        const isCurrentUserAvailable = !!user;
        setAuthToken(user);
        if (route !== "idle") {
            if (route === "signOut") {
                setActiveSubscription(false);
                setShopDetails(null);
            } else if (isCurrentUserAvailable && route === "authenticated") {
                postAuthentication();
            } else if (!isCurrentUserAvailable && (route === "setup" || route === "signIn" || route === "signUp" || route === "resetPassword")) {
                setIsAppLoading(false);
            }
        }
    }, [route]);

    useEffect(() => {
        if (!!ldClient && shopDetails) {
            ldClient.identify({
                key: "shop",
                email: shopDetails?.domain,
                anonymous: false,
            });
        }
    }, [ldClient, shopDetails]);

    const setAuthToken = (usr: any) => {
        const token = (usr as any)?.signInUserSession?.idToken?.jwtToken;
        if (token) {
            axios.defaults.headers.common["Authorization"] = `${token}`;
            setCurrentUserInfo(usr);
        } else {
            (axios as any).defaults.headers.common["Authorization"] = null;
        }
    };

    const postAuthentication = async () => {
        try {
            setIsAppLoading(true);
            if (ifStripePostCheckout(searchParams)) {
                await ganderAccessPostCheckout(searchParams.get("session_id")!);
                const user = await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                });
                setCurrentUserInfo(user);
            }
            const response = await apiGetCurrentActivePlan();
            if (!response?.shopDetails?.isHideOnboardingPopup) {
                await checkToShowOnboardingPopup();
            }
            setActiveSubscription(response?.activeSubscription);
            setShopDetails(response?.shopDetails);
        } catch (e: any) {
            console.log("postAuthentication catch e: ", e);
            if (e?.message?.includes("required parameters")) {
                toast.error(e.message);
            }
            if (e?.shopDetails) {
                setShopDetails(e.shopDetails);
            }
        } finally {
            setIsAppLoading(false);
        }
    };

    const checkToShowOnboardingPopup = async () => {
        try {
            const response = await apiSyncStatus();
            setIsOnboardingModal(!response.connected);
        } catch (error) {
            console.log(error);
        }
    };

    const interceptAxiosResponse = () => {
        axios.interceptors.response.use(
            (response: any) => response,
            async (error: any) => {
                const errorResponse = error.response;
                // if token expired we get unauthorized error with 401 status, in that case signout the user
                if (errorResponse && errorResponse.status === 401) {
                    await Auth.signOut();
                }
                // Reject promise if usual error
                return Promise.reject(error);
            }
        );
    };

    return (
        <div className="g-app-container">
            {isAppLoading ? (
                <div className="g-fullscreen-loader">
                    <Spin size="large" />
                </div>
            ) : (
                <AllRoutes />
            )}
            {isOnboardingModal && <OnboardingModal handleClose={() => setIsOnboardingModal(false)} />}
            {isShowV3FreePlanConnectLimitModel &&
                <UpgradeYourAccountModal
                    modalTitle={"Upgrade Your Account!"}
                    modalDescription={`
                    You’ve connected the maximum number of videos for the tier you're currently on. You can connect more videos by clicking upgrade plan below.
                    `}
                    setIsShowUpgradePopup={setIsShowV3FreePlanConnectLimitModel}
                />
            }
        </div>
    );
};

export default App;
