import { useContext } from "react";
import { Button, Checkbox, Input, Space, Typography } from "antd";
import { AppContext } from "context/app-context";
import { EPlayerShape, EPreviewTextPosition, IDeviceSettings } from "interfaces/schema";
import { EOverridePreviewState } from "interfaces";
import { convertNumbertoPixelString, convertPixelStringToNumber, convertPercentageStringToNumber } from "utils/type-conversion-functions";
import { Plan } from "utils/plans";

import "./index.scss";

const { Text } = Typography;
const DATA_TEST_ID_PREFIX = "EmbeddedTextSettings";

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const EmbeddedTextSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const { activeSubscription } = useContext(AppContext);

    const handleUpdateDeviceSettings = (dirtyWidgetSettings: Partial<IDeviceSettings>) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            ...dirtyWidgetSettings,
        }, EOverridePreviewState.PREVIEW_OVERLAY);
    };

    return (
        <>
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Header-Text`}>Header</Text>
            <Input
                className="g-customization-input"
                size="large"
                value={deviceSettings?.previewTextContent}
                onChange={(event) => handleUpdateDeviceSettings({ previewTextContent: event.target.value })}
                data-testid={`${DATA_TEST_ID_PREFIX}-Header-Input`}
            />
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Font-Size-Text`}>Font Size</Text>
            <div>
                <Input
                    className="g-customization-input"
                    size="large"
                    value={convertPixelStringToNumber(deviceSettings?.textFontSize)}
                    onChange={(event) => handleUpdateDeviceSettings({ textFontSize: convertNumbertoPixelString(Number(event.target.value)) })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Font-Size-Input`}
                />
            </div>
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Alignment-Text`}>Alignment</Text>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    type="text"
                    className={
                        deviceSettings?.textAlignment === EPreviewTextPosition.LEFT
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    onClick={() => handleUpdateDeviceSettings({ textAlignment: EPreviewTextPosition.LEFT })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Alignment-Left-Button`}
                >
                    Left
                </Button>
                <Button
                    type="text"
                    className={
                        deviceSettings?.textAlignment === EPreviewTextPosition.CENTER
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    onClick={() => handleUpdateDeviceSettings({ textAlignment: EPreviewTextPosition.CENTER })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Alignment-Center-Button`}
                >
                    Center
                </Button>
                <Button
                    type="text"
                    className={
                        deviceSettings?.textAlignment === EPreviewTextPosition.RIGHT
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    onClick={() => handleUpdateDeviceSettings({ textAlignment: EPreviewTextPosition.RIGHT })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Alignment-Right-Button`}
                >
                    Right
                </Button>
            </div>
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Other-Text-Text`}>Other Text</Text>
            <Space className="g-other-text-checkbox-group" direction="vertical" size="middle">
                <Checkbox
                    checked={deviceSettings?.textIsShowDuration}
                    onChange={(event) => handleUpdateDeviceSettings({ textIsShowDuration: event.target.checked })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Other-Text-Show-Duration-Checkbox`}
                >
                    Show Video Duration
                </Checkbox>
                <Checkbox
                    checked={deviceSettings?.textIsShowViewCount}
                    disabled={
                        deviceSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE ||
                        activeSubscription?.activePlan?.planName?.toLowerCase() === Plan.FREE ||
                        activeSubscription?.activePlan?.planName?.toLowerCase() === Plan.BASIC ||
                        activeSubscription?.activePlan?.planName?.toLowerCase() === Plan.GROWTH ||
                        convertPercentageStringToNumber(deviceSettings?.previewCornerRadius) > 5
                    }
                    onChange={(event) => handleUpdateDeviceSettings({ textIsShowViewCount: event.target.checked })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Other-Text-Show-Views-Checkbox`}
                >
                    Show Number of Views
                </Checkbox>
            </Space>
        </>
    );
};

export default EmbeddedTextSettings;
