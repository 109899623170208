import { MAX_INTERACTIONS_PER_PAGE } from "constants/interactions";
import { IInteraction } from "interfaces/schema";
import { createContext, useState } from "react";
import { apiGetInteractions } from "services/interactions";
import { toast } from "utils/toast";

export interface IInteractionsContext {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    interactions: IInteraction[];
    setInteractions: React.Dispatch<React.SetStateAction<IInteraction[]>>;
    fetchInteractions: (isLoadMore?: boolean) => void;
    isAllowedCreateNew: boolean;
    setAllowedCreateNew: React.Dispatch<React.SetStateAction<boolean>>;
    lastEvaluatedMeta: string | undefined;
    setLastEvaluatedMeta: React.Dispatch<React.SetStateAction<string | undefined>>;
    totalInteractionsCreated: number;
    setTotalInteractionsCreated: React.Dispatch<React.SetStateAction<number>>;
}

const defaultContext = {
    isLoading: false,
    setIsLoading: () => ({}),
    interactions: [],
    setInteractions: () => ({}),
    fetchInteractions: () => ({}),
    isAllowedCreateNew: true,
    setAllowedCreateNew: () => ({}),
    lastEvaluatedMeta: undefined,
    setLastEvaluatedMeta: () => ({}),
    totalInteractionsCreated: 0,
    setTotalInteractionsCreated: () => ({}),
};

export const InteractionsContext = createContext<IInteractionsContext>(defaultContext);

export const InteractionsContextProvider: React.FC<{ initialValues?: Partial<IInteractionsContext>, children: React.ReactNode }> = ({ initialValues, children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(initialValues?.isLoading !== undefined ? initialValues.isLoading : true);
    const [interactions, setInteractions] = useState<IInteraction[]>(initialValues?.interactions || []);
    const [lastEvaluatedMeta, setLastEvaluatedMeta] = useState<string | undefined>(initialValues?.lastEvaluatedMeta || undefined);
    const [isAllowedCreateNew, setAllowedCreateNew] = useState(initialValues?.isAllowedCreateNew !== undefined ? initialValues.isAllowedCreateNew : true);
    const [totalInteractionsCreated, setTotalInteractionsCreated] = useState(initialValues?.totalInteractionsCreated || 0);

    const fetchInteractions = async (fetchMore = false) => {
        try {
            setIsLoading(true);
            const response = await apiGetInteractions({
                limit: MAX_INTERACTIONS_PER_PAGE,
                lastEvaluatedMeta: fetchMore ? lastEvaluatedMeta : undefined
            });
            setInteractions(fetchMore ? [...interactions, ...response.interactions] : response.interactions);
            setLastEvaluatedMeta(response.lastEvaluatedMeta);
            setAllowedCreateNew(response.allowedCreateNew);
            setTotalInteractionsCreated(response.interactionsCount ?? 0);
        } catch (e) {
            console.log(e);
            toast.error("Sorry, something went wrong with fetching interactions. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <InteractionsContext.Provider
            value={{
                isLoading,
                interactions,
                fetchInteractions,
                isAllowedCreateNew,
                lastEvaluatedMeta,
                setAllowedCreateNew,
                setInteractions,
                setIsLoading,
                setLastEvaluatedMeta,
                setTotalInteractionsCreated,
                totalInteractionsCreated,
            }}
        >
            {children}
        </InteractionsContext.Provider>
    );
};
