import { Col, Row, Statistic, Typography } from "antd";
import { RiseOutlined, FallOutlined, DashOutlined } from "@ant-design/icons";
import { IMetricStatisticProps } from "interfaces";
import Avatar from "antd/lib/avatar/avatar";

const { Title, Text } = Typography;

export default function MetricStatisticComponent({ metricValue, metricValuePrev, suffix, prefix, metricChangeTimePeriod }: IMetricStatisticProps) {

  if (!metricValue && !metricValuePrev) {
    // Return dashed display
    return (
      <div className='g-metrics-component'>
        <div className='g-metrics-component-title-no-data'>
          <Title level={5}>
            No data
          </Title>
        </div>
        <Row>
          <Col flex={1}> </Col>
          <Col flex={1} className='g-metric-component-col-flex'>
            {" "}
            <Text type="secondary">-</Text>
          </Col>
        </Row>
      </div>
    );
  }

  const percentageChange =
    metricValuePrev && metricValuePrev !== 0
      ? (((metricValue as number) - (metricValuePrev as number)) / (metricValuePrev as number)) * 100
      : 0;

  let color;
  let icon;
  if (percentageChange > 0) {
    color = "#3f8600";
    icon = <Avatar icon={<RiseOutlined />} style={{ backgroundColor: color }} />;
  } else if (percentageChange === 0) {
    color = "#000000";
    icon = <Avatar icon={<DashOutlined />} style={{ backgroundColor: color }} />;
  } else {
    color = "#FF6424";
    icon = <Avatar icon={<FallOutlined />} style={{ backgroundColor: color }} />;
  }

  if (metricValuePrev === null || metricValuePrev === 0 || metricValuePrev === undefined) {
    return (
      <div className='g-metrics-component'>
        <Title>
          {prefix}
          {(metricValue || (0 as number)).toFixed(2) || ""}
          {suffix}
        </Title>
      </div>
    );
  }
  return (
    <div className='g-metrics-component'>
      <Title>
        {prefix}
        {(metricValue || (0 as number)).toFixed(2) || ""}
        {suffix}
      </Title>

      <Row>
        <Col>
          {percentageChange === 0 ? (
            <Statistic value={percentageChange} valueStyle={{ color: color }} prefix={icon} suffix={suffix === "%" ? "%" : ""} />
          ) : (
            <Statistic value={percentageChange} precision={2} valueStyle={{ color: color }} prefix={icon} suffix="%" />
          )}
        </Col>
        &nbsp;&nbsp;&nbsp;
      </Row>
      <Row className="g-metrics-component-text">
        <Col>
          <Text type="secondary">
            {prefix}
            {(metricValuePrev || (0 as number)).toFixed(2) || ""}
            {suffix} &nbsp; {metricChangeTimePeriod}
          </Text>
        </Col>
      </Row>
    </div>
  );
}
