import { Button, Modal, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GetColumns } from './columns';
import { useContext, useEffect, useState } from 'react';
import { IProduct } from 'interfaces';
import { AppContext } from 'context/app-context';
import { apiDeleteVideo, apiPutReviewExternalUploadedVideo } from 'services/video.services';
import { toast } from 'utils/toast';
import AddEditPageDetailsComponent from './add-edit-page-details-component';
import PurchaseSoMeLicenseModal from './purchase-social-media-license-modal';
import { VideosContext } from 'context/videos-context';
import ReviewVideoPopup from 'app/pages/request-content/review-video-popup';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IVideo } from 'interfaces/schema';

import './index.scss';

const { confirm } = Modal;

const dataTestIdPrefix = "Videos-VideosTableComponent";

const VideosTableComponent = () => {

    const { isVideosLoading, setIsVideosLoading, videos, videoRequestLastEvaluatedKey, fetchVideos, fetchRecentlyAddedVideos } = useContext(VideosContext);
    const { shopDetails, activeSubscription, setIsShowUpgradePlanBanner } = useContext(AppContext);

    const [viewVideoDetailsDrawer, setViewVideoDetailsDrawer] = useState<IVideo>();
    const [editVideoNameInput, setEditVideoNameInput] = useState<IVideo | undefined>();
    const [products, setProducts] = useState<IProduct[]>([]);

    const [purchaseSoMeLicenseRequestMeta, setPurchaseSoMeLicenseRequestMeta] = useState<string | undefined>(undefined);
    const [purchaseSoMeLicenseVideoMeta, setPurchaseSoMeLicenseVideoMeta] = useState<string | undefined>(undefined);
    const [selectedReviewVideo, setSelectedReviewVideo] = useState<IVideo>();
    const [isExternalVideoReviewUpdating, setIsExternalVideoReviewUpdating] = useState(false);

    useEffect(() => {
        const currentSelectedVideoData = videos.find((obj: { meta: string }) => obj?.meta === (viewVideoDetailsDrawer as IVideo)?.meta);
        if (currentSelectedVideoData) {
            setViewVideoDetailsDrawer(currentSelectedVideoData);
        }
    }, [videos]);

    const openViewVideoDetailDrawer = (vid: IVideo | undefined) => {
        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
            return;
        }
        setViewVideoDetailsDrawer(vid);
    }

    const onNextViewDetailsEvent = () => {
        const currentIndex = videos.findIndex((obj: { meta: string }) => obj?.meta === (viewVideoDetailsDrawer as IVideo)?.meta);
        if (videos[currentIndex + 1]) {
            setViewVideoDetailsDrawer(videos[currentIndex + 1]);
        } else {
            setViewVideoDetailsDrawer(videos[0]);
        }
        setProducts([]);
    };

    const handleEditVideoName = (videoObj: IVideo) => {
        if (editVideoNameInput !== videoObj) {
            // Toggle on edit name input
            setEditVideoNameInput(videoObj);
        } else {
            // Toggle off edit name input
            setEditVideoNameInput(undefined);
        }
    }

    const onPrevViewDetailsEvent = () => {
        const currentIndex = videos.findIndex((obj: { meta: string }) => obj?.meta === viewVideoDetailsDrawer?.meta);
        if (videos[currentIndex - 1]) {
            setViewVideoDetailsDrawer(videos[currentIndex - 1]);
        } else {
            setViewVideoDetailsDrawer(videos[videos.length - 1]);
        }
        setProducts([]);
    };

    const deleteVideo = async (videoObj: IVideo) => {
        if (videoObj && !videoObj?.meta) {
            return;
        }
        try {
            setIsVideosLoading(true);
            const removeAllPagesRes = await apiDeleteVideo(videoObj.meta);
            toast.success(removeAllPagesRes?.message);
            fetchVideos();
            fetchRecentlyAddedVideos(false);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsVideosLoading(false);
        }
    }

    const openPurchaseSoMeLicenseModal = (crMeta: string, videoMeta: string) => {
        setPurchaseSoMeLicenseRequestMeta(crMeta);
        setPurchaseSoMeLicenseVideoMeta(videoMeta)
    }

    const closePurchaseSoMeLicenseModal = (isSucceed?: boolean) => {
        setPurchaseSoMeLicenseRequestMeta(undefined);
        setPurchaseSoMeLicenseVideoMeta(undefined);
        if (isSucceed) {
            fetchVideos();
        }
    }

    const onApproveReject = async (resType: string) => {
        try {
            setIsExternalVideoReviewUpdating(true);
            const payload = {
                meta: selectedReviewVideo?.meta,
                action: resType
            }
            const reviewRes = await apiPutReviewExternalUploadedVideo(payload);
            toast.success(reviewRes?.message);
            setSelectedReviewVideo(undefined);
            fetchVideos();
            fetchRecentlyAddedVideos(false);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsExternalVideoReviewUpdating(false);
        }
    }

    const processExternalUploadedVideoReview = async (resType: string) => {
        if (resType === "reject") {
            setSelectedReviewVideo(undefined);
            confirm({
                title: "Reject Video",
                icon: <ExclamationCircleOutlined />,
                width: "600px",
                content: <div>
                    <span>Rejecting a video will permanently delete it from your Gander dashboard</span>
                    <br />
                    <span>Are you sure you want to continue?</span>
                </div>,
                okText: <span data-testid={`${dataTestIdPrefix}-RejectConfirm`}>Continue</span>,
                okType: "danger",
                cancelText: "Go Back",
                centered: true,
                async onOk() {
                    await onApproveReject(resType);
                }
            });
        } else if (resType === "approve") {
            onApproveReject(resType);
        }
    }

    return (
        <div className='g-manage-content-video-table-container'>
            {selectedReviewVideo && (
                <ReviewVideoPopup
                    setSelectedReviewVideo={setSelectedReviewVideo}
                    selectedReviewVideo={selectedReviewVideo}
                    onApproveReject={processExternalUploadedVideoReview}
                    isContentRequestUpdating={isExternalVideoReviewUpdating}
                />
            )}

            <Table
                columns={GetColumns(
                    openViewVideoDetailDrawer,
                    setEditVideoNameInput,
                    deleteVideo,
                    shopDetails?.domain || '',
                    openPurchaseSoMeLicenseModal,
                    editVideoNameInput,
                    handleEditVideoName,
                    setSelectedReviewVideo
                )}
                dataSource={videos} pagination={false} rowKey="meta" bordered={false} loading={isVideosLoading} />
            <br />
            {!!videoRequestLastEvaluatedKey &&
                <Button icon={<PlusOutlined />} block onClick={() => fetchVideos(true)} loading={isVideosLoading} data-testid={`${dataTestIdPrefix}-LoadMore`}>
                    Load More
                </Button>
            }

            {/* Page details drawer */}
            {viewVideoDetailsDrawer && <AddEditPageDetailsComponent
                viewVideoDetailsDrawer={viewVideoDetailsDrawer}
                openViewVideoDetailDrawer={openViewVideoDetailDrawer}
                onNextViewDetailsEvent={onNextViewDetailsEvent}
                onPrevViewDetailsEvent={onPrevViewDetailsEvent}
                products={products}
                setProducts={setProducts}
            />}
            {/* Page details drawer */}

            {(purchaseSoMeLicenseRequestMeta && purchaseSoMeLicenseVideoMeta) &&
                <PurchaseSoMeLicenseModal
                    crMeta={purchaseSoMeLicenseRequestMeta}
                    videoMeta={purchaseSoMeLicenseVideoMeta}
                    closeModal={closePurchaseSoMeLicenseModal}
                />
            }
        </div>
    );
}

export default VideosTableComponent;