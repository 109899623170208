import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import * as ROUTE_PATHS from 'constants/routes';

import "./index.scss";

const dataTestId = "UpgradeYourPlanBanner";

interface IProps {
    modalTitle: string;
    modalDescription: string;
    setIsShowUpgradePopup?: React.Dispatch<React.SetStateAction<boolean>>;
    isClosable?: boolean;
}

const UpgradeYourAccountModal = ({ modalTitle, modalDescription, setIsShowUpgradePopup, isClosable = false }: IProps) => {
    const navigate = useNavigate();

    return (
        <Modal
            centered={true}
            width={463}
            open={true}
            closable={isClosable}
            maskClosable={false}
            onCancel={() => { setIsShowUpgradePopup && setIsShowUpgradePopup(false) }}
            footer={
                <div className="g-upgrade-your-account-modal-footer">
                    <Button
                        className="g-upgrade-your-account-modal-footer-button"
                        onClick={() => {
                            setIsShowUpgradePopup && setIsShowUpgradePopup(false);
                            navigate(ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.PATH + '#billing')
                        }}
                        type="primary"
                    >
                        Upgrade Plan
                    </Button>
                </div>
            }
        >
            <div className="g-upgrade-your-account-modal-body" data-testid={`${dataTestId}-ModalBody`}>
                <div className="g-upgrade-your-account-modal-title" data-testid={`${dataTestId}-Title`}>
                    {modalTitle}
                </div>
                <div className="g-upgrade-your-account-modal-title-secondary" data-testid={`${dataTestId}-Description`}>
                    {modalDescription}
                </div>
            </div>
        </Modal>
    );
};

export default UpgradeYourAccountModal;
