import { Spin } from "antd";
import { IProduct } from "interfaces";
import LinkVideoToProductComponent from "./link-video-to-product-component";
import { toast } from "utils/toast";
import { VideosContext } from "context/videos-context";
import { useContext } from "react";
import { apiUpdateVideo } from "services/video.services";
import { updateVideoArrayByMeta } from "utils/common-functions";
import VideoDetailDrawerPreviewContainer from "../video-detail-drawer-preview-container";
import { IVideo } from "interfaces/schema";
import ConnectedProductsComponent from "./connected-products-component";

const dataTestIdPrefix = "Videos-AttachProductsComponent";

interface Props {
    isLoading: boolean;
    viewVideoDetailsDrawer: IVideo;
    openViewVideoDetailsDrawer: (vid: IVideo | undefined) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    products: IProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
}

const AttachProductsTab = ({ isLoading, viewVideoDetailsDrawer, openViewVideoDetailsDrawer, setIsLoading, products, setProducts }: Props) => {
    const { videos, setVideos } = useContext(VideosContext);

    const handleSelectProduct = async (selectedProduct: IProduct) => {
        try {
            setIsLoading(true);
            const oldProductsObjects = viewVideoDetailsDrawer.products;
            const updateVideoObj = { ...viewVideoDetailsDrawer, products: [...oldProductsObjects, selectedProduct!] };
            const updateVideoPayload = { products: [...oldProductsObjects, selectedProduct] };
            setProducts([...products, selectedProduct]);
            await apiUpdateVideo(updateVideoPayload, viewVideoDetailsDrawer.meta);
            openViewVideoDetailsDrawer(updateVideoObj);
            setVideos(updateVideoArrayByMeta(videos, updateVideoObj));
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className='g-video-details-drawer-body-wrapper' data-testid={`${dataTestIdPrefix}-DrawerBody`}>
                {isLoading &&
                    <div className="g-add-edit-video-details-drawer-loader-container">
                        <Spin size='large' className='g-add-edit-video-details-drawer-loader' />
                    </div>
                }
                <div className='g-add-edit-video-details-drawer-content-container g-d-flex'>
                    <div className="g-add-edit-video-details-form" data-testid={`${dataTestIdPrefix}-ProductsForm`}>
                        <LinkVideoToProductComponent
                            dataTestId={dataTestIdPrefix}
                            onSelectProduct={handleSelectProduct}
                            products={products}
                        />
                        <ConnectedProductsComponent
                            setIsLoading={setIsLoading}
                            products={products}
                            setProducts={setProducts}
                            viewVideoDetailsDrawer={viewVideoDetailsDrawer}
                            openViewVideoDetailsDrawer={openViewVideoDetailsDrawer}
                            setVideos={setVideos}
                            videos={videos}
                        />
                    </div>
                </div>
                <div className="g-video-details-drawer-preview-container">
                    <VideoDetailDrawerPreviewContainer viewVideoDetailsDrawer={viewVideoDetailsDrawer} dataTestIdPrefix={dataTestIdPrefix} products={products} />
                </div>
            </div>
        </>
    );
}

export default AttachProductsTab;