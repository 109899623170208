import { Button, List, Tooltip, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { LinkOutlined } from '@ant-design/icons';
import { IProduct } from 'interfaces';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Videos-AttachedProductsListItemComponent";

interface IProps {
    row: IProduct;
    onRemoveProduct?: (productRecord: IProduct) => void;
    isOpenLink?: boolean;
}

const LinkedProductsListItemComponent = ({ row, onRemoveProduct, isOpenLink = true }: IProps) => {
    const testIdValue = isOpenLink ? 'AttachedProductsCardContainer' : 'SearchProductsCardContainer'
    return (
        <List.Item className='g-sortable-connected-video-component' data-testid={`${dataTestIdPrefix}-${testIdValue}`}>
            <List.Item.Meta
                title={
                    <Tooltip title={row.isDeleted && 'Shopify product not found'} placement="topLeft">
                        <div className='g-d-flex g-align-center g-justify-between' data-testid={`${dataTestIdPrefix}-AttachedProductsCard`} style={{ opacity: row.isDeleted ? 0.7 : 1 }}>
                            <div className='g-sortable-title-wrapper g-manage-content-edit-video-list-linked-pages-container' data-testid={`${dataTestIdPrefix}-LinkedPagesCardTitleContainer`}>
                                <Title ellipsis level={5} data-testid={`${dataTestIdPrefix}-LinkedPagesCardTitle`}>
                                    <Tooltip title={row.isDeleted ? '' : row?.name} placement="topLeft">
                                        {row.isDeleted ? <s>{row?.name}</s> : row?.name}
                                    </Tooltip>
                                </Title>
                                <Button title={row.productUrl} type='link' href={isOpenLink ? row.productUrl : undefined} target="_blank" icon={<LinkOutlined />}>
                                    <span className="g-video-list-page-link-text">{row.productUrl}</span>
                                </Button>
                            </div>
                            {!!onRemoveProduct &&
                                <div
                                    className='g-sortable-connected-video-button'
                                    data-testid={`${dataTestIdPrefix}-AattachedProductsCardDeleteButton`}
                                >
                                    <Button
                                        type='text'
                                        htmlType='button'
                                        danger={true}
                                        size='large'
                                        onClick={(e) => onRemoveProduct(row)}
                                        icon={<DeleteOutlined />}
                                    >
                                    </Button>
                                </div>
                            }
                        </div>
                    </Tooltip>
                }
            />
        </List.Item>
    );
}

export default LinkedProductsListItemComponent;