import { Button, Typography } from "antd";
import ResetPasswordComponent from "../reset-password-component";
import { useState } from "react";

const { Title } = Typography;

interface IProps {
    lastPasswordUpdatedAt: string | undefined;
    currentUserInfo: unknown;
    updateCognitoUserAttributes: (userAttributes: any) => Promise<IResponse>;
}

interface IResponse {
    res: any;
    error: any;
}

const PasswordSectionComponent = ({ lastPasswordUpdatedAt, currentUserInfo, updateCognitoUserAttributes }: IProps) => {
    const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState<boolean>(false);

    return (
        <div className="g-account-reset-password">
            <Title level={5} className="g-account-title-5">Password</Title>
            {lastPasswordUpdatedAt &&
                <div className="g-account-subtitle">
                    You password was last changed on&nbsp;
                    <span className="g-account-subtitle-date-container">{lastPasswordUpdatedAt}</span>
                </div>
            }
            <Button type="primary" className="g-account-save-button" size="large" onClick={() => setIsResetPasswordModalVisible(true)}>
                Change Password
            </Button>

            {isResetPasswordModalVisible && (
                <ResetPasswordComponent
                    currentUserInfo={currentUserInfo}
                    setIsResetPasswordModalVisible={setIsResetPasswordModalVisible}
                    updateCognitoUserAttributes={updateCognitoUserAttributes}
                />
            )}
        </div>
    )
}

export default PasswordSectionComponent