import GanderWithTagLineLogo from 'assets/img/gander-logo-tag-line.png';
import { IShopDetailsPayload } from '../interface';

import "./index.scss";

interface IProps {
    shopDetail: IShopDetailsPayload | undefined
}

const dataTestIdPrefix = "ExternalUpload-Header";

const UploadAndLinkVideosHeader = ({ shopDetail }: IProps) => {
    return <div className="g-public-external-video-upload-header">
        <div className="g-public-external-video-upload-header-left">
            <img src={GanderWithTagLineLogo} alt="gander logo" className="g-public-external-video-upload-header-left-logo" />
        </div>
        <div className="g-public-external-video-upload-header-right">
            <div className="g-public-external-video-upload-header-user-info">
                {shopDetail?.domain && <>
                    <div className="g-public-external-video-upload-header-right-user-detail-container">
                        <div
                            className="g-public-external-video-upload-header-right-user-name"
                            data-testid={`${dataTestIdPrefix}-TitleWithDomain`}
                        >
                            {shopDetail?.domain}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </div>;
};

export default UploadAndLinkVideosHeader;
