import AppLayout from "app/layouts/app-layout";
import { Routes, Route } from "react-router-dom";
import * as ROUTE_PATHS from "constants/routes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AuthLayout from "app/layouts/auth-layout";
import LoginPage from "app/pages/auth/login-page";
import DashboardPage from "app/pages/dashboard";
import NoMatchPage from "app/pages/no-match-page";
import CustomizationPage from "app/pages/customization";
import RequestContentPage from "app/pages/request-content";
import SettingsPage from "app/pages/settings";
import PagesPage from "app/pages/pages";
import VideosPage from "app/pages/videos";
import InteractionView from "app/pages/interactions/interaction-view-component";
import AccountSetupPage from "app/pages/account-setup";
import InteractionsPage from "app/pages/interactions";
import AppFullscreenLayout from "app/layouts/app-fullscreen-layout";
import PlansMiddleware from "./PlansMiddleware";
import UploadRouterGuard from "./UploadRouterGuard";
import UploadAndLinkVideos from "app/pages/public/upload-and-link-videos";

const AllRoutes = () => {
    return (
        <Routes>
            {/* PUBLIC ROUTES */}
            <Route path={ROUTE_PATHS.PUBLIC_ROUTES.PUBLIC_ROOT}>
                <Route path={ROUTE_PATHS.PUBLIC_ROUTES.EXTERNAL_VIDEO_UPLOAD_AND_LINK_TO_PAGE} element={<UploadAndLinkVideos />} />
            </Route>
            {/* PUBLIC ROUTES */}

            {/* AUTH ROUTES */}
            <Route
                path={ROUTE_PATHS.AUTH_ROUTES.AUTH_ROOT}
                element={
                    <PublicRoute>
                        <AuthLayout />
                    </PublicRoute>
                }
            >
                <Route path={ROUTE_PATHS.AUTH_ROUTES.LOGIN} element={<LoginPage />} />
                <Route path={ROUTE_PATHS.AUTH_ROUTES.SIGNUP} element={<LoginPage state="signUp" />} />
            </Route>
            {/* AUTH ROUTES */}

            {/* PRIVATE ROUTES */}
            <Route
                path={ROUTE_PATHS.PRIVATE_ROUTES.ROOT.PATH}
                element={
                    <PrivateRoute>
                        <AppFullscreenLayout />
                    </PrivateRoute>
                }
            >
                <Route
                    path={ROUTE_PATHS.PRIVATE_ROUTES_FULL_PAGE.ACCOUNT_SETUP.PATH}
                    element={
                        <PrivateRoute>
                            <AccountSetupPage />
                        </PrivateRoute>
                    }
                />
            </Route>

            <Route
                path={ROUTE_PATHS.PRIVATE_ROUTES.ROOT.PATH}
                element={
                    <PrivateRoute>
                        <PlansMiddleware>
                            <UploadRouterGuard>
                                <AppLayout />
                            </UploadRouterGuard>
                        </PlansMiddleware>
                    </PrivateRoute>
                }
            >
                <Route index element={<VideosPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH} element={<VideosPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.PAGES.PATH} element={<PagesPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.CUSTOMIZATION.PATH} element={<CustomizationPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.PATH} element={<DashboardPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.REQUEST_CONTENT.PATH} element={<RequestContentPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.PATH} element={<SettingsPage />} />
                <Route path={ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH} element={<InteractionsPage />} />
                <Route path={`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/:id`} element={<InteractionView />} />
                <Route path="*" element={<NoMatchPage />} />
            </Route>
            {/* PRIVATE ROUTES */}
        </Routes>
    );
};

export default AllRoutes;
