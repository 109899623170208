import { Button, Modal, Table } from "antd";
import { IGetVideoApiPayload, ILastEvaluatedKey } from "interfaces";
import { useEffect, useState } from "react";
import { apiDeleteVideo, apiGetVideos, apiPutReviewExternalUploadedVideo } from "services/video.services";
import { toast } from "utils/toast";
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ExternalUploadedContentColumns from "./external-uploaded-content-columns";
import ReviewVideoPopup from "../review-video-popup";
import { IVideo } from "interfaces/schema";

interface IProps {
  searchTerm: string;
  isActive: boolean;
}

let timeout: NodeJS.Timer;

const { confirm } = Modal;

const ExternalUploadedContentTable = ({ searchTerm, isActive }: IProps) => {
  const [externalUploadedContentData, setExternalUploadedContentData] = useState<IVideo[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<ILastEvaluatedKey | undefined>();
  const [externalUploadedContentLoading, setExternalUploadedContentLoading] = useState(false);
  const [selectedReviewVideo, setSelectedReviewVideo] = useState<IVideo>();
  const [isExternalVideoReviewUpdating, setIsExternalVideoReviewUpdating] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      if (!isActive) {
        return;
      }
      timeout = setTimeout(() => {
        handleLoadMoreAndSearch();
      }, 1000);
    } else {
      fetchExternalUploadedContentData({ isExternalOnly: true }, false)
    }
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  const fetchExternalUploadedContentData = async (reqPayload: IGetVideoApiPayload, isLoadMore: boolean = false) => {
    setExternalUploadedContentLoading(true);
    try {
      const res = await apiGetVideos(reqPayload);
      setLastEvaluatedKey(res?.LastEvaluatedKey);
      setExternalUploadedContentData(isLoadMore ? [...externalUploadedContentData, ...res?.data] : res?.data);
    }
    catch (e: any) {
      console.log("🚀 ~ file: request-content.tsx ~ fetchContentRequest ~ catch e", e);
      setExternalUploadedContentData([]);
      toast.error(e?.message);
    } finally {
      setExternalUploadedContentLoading(false);
    }
  }

  const handleLoadMoreAndSearch = (isLoadMore?: boolean) => {
    const reqPayload: IGetVideoApiPayload = {
      isExternalOnly: true,
      requestLastEvaluatedKey: lastEvaluatedKey
    }
    if (searchTerm) {
      reqPayload.filterName = searchTerm;
    }
    fetchExternalUploadedContentData(reqPayload, isLoadMore);
  }

  const processExternalUploadedVideoReview = async (resType: string) => {
    if (resType === "reject") {
      setSelectedReviewVideo(undefined);
      confirm({
        title: "Reject Video",
        icon: <ExclamationCircleOutlined />,
        width: "600px",
        content: <div>
          <span>Rejecting a video will permanently delete it from your Gander dashboard</span>
          <br />
          <span>Are you sure you want to continue?</span>
        </div>,
        okText: <span>Continue</span>,
        okType: "danger",
        cancelText: "Go Back",
        centered: true,
        async onOk() {
          await onApproveReject(resType);
        }
      });
    } else if (resType === "approve") {
      onApproveReject(resType);
    }
  }

  const onApproveReject = async (resType: string) => {
    try {
      setIsExternalVideoReviewUpdating(true);
      const payload = {
        meta: selectedReviewVideo?.meta,
        action: resType
      }
      const reviewRes = await apiPutReviewExternalUploadedVideo(payload);
      toast.success(reviewRes?.message);
      setSelectedReviewVideo(undefined);
      fetchExternalUploadedContentData({ isExternalOnly: true, filterName: searchTerm });
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    } finally {
      setIsExternalVideoReviewUpdating(false);
    }
  }

  const deleteVideo = async (videoObj: IVideo) => {
    if (videoObj && !videoObj?.meta) {
      return;
    }
    try {
      setExternalUploadedContentLoading(true);
      const removeAllPagesRes = await apiDeleteVideo(videoObj.meta);
      toast.success(removeAllPagesRes?.message);
      fetchExternalUploadedContentData({ isExternalOnly: true, filterName: searchTerm });
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    } finally {
      setExternalUploadedContentLoading(false);
    }
  }

  return (<>
    <Table
      columns={ExternalUploadedContentColumns(setSelectedReviewVideo, deleteVideo)}
      dataSource={externalUploadedContentData}
      pagination={false}
      rowKey="meta"
      bordered={false}
      loading={externalUploadedContentLoading}
    />
    <br />
    {lastEvaluatedKey && <Button icon={<PlusOutlined />} block onClick={() => handleLoadMoreAndSearch(true)} disabled={externalUploadedContentLoading}>Load More</Button>}

    {selectedReviewVideo && (
      <ReviewVideoPopup
        setSelectedReviewVideo={setSelectedReviewVideo}
        selectedReviewVideo={selectedReviewVideo}
        onApproveReject={processExternalUploadedVideoReview}
        isContentRequestUpdating={isExternalVideoReviewUpdating}
      />
    )}
  </>)
}

export default ExternalUploadedContentTable;