export enum SubscriptionStatus {
  ACTIVE = "active",
  PAST_DUE = "past_due",
  INCOMPLETE = "incomplete",
  TRIALING = "trialing",
}

export enum ProductType {
  GANDER_ACCESS = "gander-access",
  VIDEO_LICENSE = "video-license",
}