import InteractionsView from "./interactions-view-component";
import { InteractionsContextProvider } from "context/interactions-context";

const InteractionsPage = () => {
  return (
    <div className="g-h-100">
      <InteractionsContextProvider>
        <InteractionsView />
      </InteractionsContextProvider>
    </div>
  );
};

export default InteractionsPage;
