import { Typography, Input } from 'antd';
import VideosTableComponent from './videos-table-component';
import { useContext } from 'react';
import { searchSvg } from 'components/icons';
import { VideosContext } from 'context/videos-context';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Videos-VideosComponent";

const ManageContentComponent = () => {
    const { videoSearchKeyword, setVideoSearchKeyword } = useContext(VideosContext);

    return (
        <div className="g-manage-content-component">
            <div className="g-manage-content-component-title-wrapper">
                <div className='g-manage-content-title-segment-div'>
                    <Title level={3}>Manage Videos</Title>
                </div>
                <div className='g-manage-content-search-add-btn-div'>
                    <Input size="large" placeholder="Search" prefix={searchSvg} onChange={(e) => setVideoSearchKeyword(e?.target?.value)} value={videoSearchKeyword} />
                </div>
            </div>
     
            <div className="g-manage-content-videos-table-wrapper" data-testid={`${dataTestIdPrefix}-VideosTableContainer`}>
                <VideosTableComponent />
            </div>

        </div>
    )
}
export default ManageContentComponent;