import { ChangeEvent, useEffect, useState } from "react";
import { Typography } from "antd";
import { CheckboxField, Link, Loader, SelectField, TextField } from "@aws-amplify/ui-react";
import NewPasswordFormFields from 'components/auth/new-password-form-fields';
import { PLATFORM } from "constants/store";
import { getURL } from "app/pages/pages/utils";
import { useSearchParams } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import apiCheckDomainExists from "services/public/check-domain-exists";
import { isPublicEmailDomain } from "./util";

import "./index.scss";

const { Text } = Typography;

const FormFields = () => {
  const [searchParams] = useSearchParams();
  const [platform, setPlatform] = useState('');
  const [dataToPrefill, setDataToPrefill]: any = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState<string>();
  const [websiteInput, setWebsiteInput] = useState<string>();
  const [isCheckingDomainExists, setIsCheckingDomainExists] = useState(false);
  const debouncedWebsiteInput = useDebounce(websiteInput, 500);

  useEffect(() => {
    const d = searchParams.get('data');
    if (d) {
      const data = JSON.parse(decodeURIComponent(d));
      setDataToPrefill(data);
    }
  }, [searchParams]);

  useEffect(() => {
    setPlatform(dataToPrefill?.platform);
  }, [dataToPrefill])

  useEffect(() => {
    if (debouncedWebsiteInput && debouncedWebsiteInput.length > 0) {
      checkIfDomainExists(debouncedWebsiteInput);
    }
  }, [debouncedWebsiteInput]);

  const checkIfDomainExists = async (debouncedWebsiteInput: string) => {
    try {
      setIsCheckingDomainExists(true);
      await apiCheckDomainExists({ domain: debouncedWebsiteInput });
      setWebsiteErrorMessage(undefined);
    } catch (e) {
      console.log(e);
      setWebsiteErrorMessage("Domain already has an associated account.");
    } finally {
      setIsCheckingDomainExists(false);
    }
  };

  const validateURL = (event: ChangeEvent<HTMLInputElement>) => {
    const url = getURL(event.currentTarget.value);

    if (url.isValid) {
      setWebsiteInput(event.currentTarget.value);
    } else {
      setWebsiteErrorMessage("Please input valid url.");
    }
  };

  const validateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const splitEmail = event.currentTarget.value.split("@");

    if (splitEmail.length === 2 && isPublicEmailDomain(splitEmail[1])) {
      setEmailErrorMessage("Please use a work email or custom domain.");
    } else {
      setEmailErrorMessage(undefined);
    }
  };

  const checkboxLabel = (
    <span className='g-sign-up-checkbox-text'>
      By clicking Sign Up, I agree that I have read and accepted the&nbsp;
      <Link href='https://gogander.io/terms' color="#3C8EF9" target='_blank'>Terms of Use</Link>
      &nbsp;and&nbsp;
      <Link href='https://gogander.io/privacy' color="#3C8EF9" target='_blank'>Privacy Policy</Link>.
    </span>
  );

  const platforms = [
    Object.values(PLATFORM)
      .filter((value) => !(value === PLATFORM.Shopify))
      .map((value) =>
        <option key={value} value={value.toLowerCase()}>
          {value === PLATFORM?.ShopifyV2 ? PLATFORM?.Shopify : value}
        </option>)
  ];

  return (
    <>
      <TextField
        name='username'
        label='Work Email'
        type='email'
        placeholder='hi@example.com'
        isRequired={true}
        hasError={!!(emailErrorMessage)}
        errorMessage={emailErrorMessage}
        defaultValue={dataToPrefill?.email}
        onChange={validateEmail}
      />
      <NewPasswordFormFields />
      <TextField
        name='website'
        label='Website/Store URL'
        placeholder='gogander.io'
        outerStartComponent={<Text className='g-sign-up-website-prefix-text'>https://</Text>}
        isRequired={true}
        hasError={!!(websiteErrorMessage)}
        errorMessage={websiteErrorMessage}
        onChange={validateURL}
        defaultValue={dataToPrefill?.domain}
        className={dataToPrefill?.domain ? 'g-disabled' : ""}
      />
      {isCheckingDomainExists && <Loader />}
      <SelectField
        name='custom:platform'
        label='eCommerce Platform'
        placeholder="Select a platform"
        isRequired={true}
        value={platform}
        onChange={(event) => setPlatform(event.target.value)}
        className={dataToPrefill?.platform ? 'g-disabled' : ""}
      >
        {platforms}
      </SelectField>
      <CheckboxField
        className="g-sign-up-acknowledgement-checkbox"
        name="acknowledgement"
        value="yes"
        label={checkboxLabel}
        labelPosition="end"
        isRequired={true}
        crossOrigin={"anonymous"}
      />
    </>
  );
};

export default FormFields;