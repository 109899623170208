import { useContext, useState } from "react";
import { Button, Modal, Typography } from "antd";
import { AppContext } from "context/app-context";
import { INTEGRATION_DETAILS } from "constants/setting-integration";
import { PLATFORM } from "constants/store";
import { apiUpdateShop } from "services/shop";
import { toast } from "utils/toast";

import "./index.scss";

const dataTestIdPrefix = "OnboardingModal";

const { Title, Text } = Typography;

interface IProps {
    handleClose: () => void;
}

const OnboardingModal = ({ handleClose }: IProps) => {
    const { shopDetails, setShopDetails } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const isShopify = shopDetails?.platform === PLATFORM.Shopify.toLowerCase() || shopDetails?.platform === PLATFORM.ShopifyV2;
    const filteredIntegrationDetails = INTEGRATION_DETAILS.filter(
        ({ platformCompability }) =>
            platformCompability === "all" || (platformCompability === "shopify" && isShopify) || (platformCompability === "non-shopify" && !isShopify)
    );

    const handleCancel = async () => {
        try {
            setIsLoading(true);
            const response = await apiUpdateShop({ isHideOnboardingPopup: true });
            setShopDetails(response.data);
        } catch (e) {
            console.log(e);
            toast.error("Sorry something went wrong, please try again later");
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <Modal wrapClassName="g-onboarding-modal" zIndex={1001} width={1110} centered open closable={false} footer={null}>
            <div className="g-onboarding-modal-content">
                <Title level={4} data-testid={`${dataTestIdPrefix}-Title`}>
                    Welcome to Gander!
                </Title>
                <Text className="g-onboarding-modal-subtitle" data-testid={`${dataTestIdPrefix}-Subtitle`}>
                    It's time to humanize your website. In order to get started, follow the instructions in the following link to paste the code snippets that
                    will render your videos.
                </Text>
                <div className="g-onboarding-modal-select-list">
                    {filteredIntegrationDetails.map(({ title, extraDetails, logo, logoHeight, logoWidth, connectLink }) => {
                        return (
                            <div className="g-onboarding-modal-select-node" key={title} data-testid={`${dataTestIdPrefix}-Node`}>
                                <div className="g-onboarding-modal-select-node-logo">
                                    <img src={logo} alt={title} style={{ width: logoWidth, height: logoHeight }} />
                                </div>
                                <div className="g-onboarding-modal-select-node-content">
                                    <Text className="g-onboarding-modal-select-node-title" data-testid={`${dataTestIdPrefix}-Node-Title`}>
                                        {title}
                                    </Text>
                                    <Text className="g-onboarding-modal-select-node-subtitle" data-testid={`${dataTestIdPrefix}-Node-Subtitle`}>
                                        {extraDetails}
                                    </Text>
                                    <Button
                                        className="g-onboarding-modal-select-node-button"
                                        type="default"
                                        target="_blank"
                                        href={connectLink}
                                        disabled={isLoading}
                                        data-testid={`${dataTestIdPrefix}-Node-Button`}
                                    >
                                        Connect app
                                        <span>
                                            <svg width="8" height="9" viewBox="0 0 8 9" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.60437 5.00011H6.18937L3.74937 7.44011C3.55437 7.63511 3.55437 7.95511 3.74937 8.15011C3.94437 8.34511 4.25937 8.34511 4.45437 8.15011L7.74937 4.85511C7.94437 4.66011 7.94437 4.34511 7.74937 4.15011L4.45937 0.850107C4.26437 0.655107 3.94937 0.655107 3.75437 0.850107C3.55937 1.04511 3.55937 1.36011 3.75437 1.55511L6.18937 4.00011H0.60437C0.32937 4.00011 0.10437 4.22511 0.10437 4.50011C0.10437 4.77511 0.32937 5.00011 0.60437 5.00011Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="g-onboarding-modal-footer">
                    <Button size="large" loading={isLoading} onClick={() => handleCancel()} data-testid={`${dataTestIdPrefix}-Cancel-Button`}>
                        Don't show this again
                    </Button>
                    <Button type="primary" size="large" loading={isLoading} onClick={() => handleClose()} data-testid={`${dataTestIdPrefix}-Close-Button`}>
                        I'll do it later
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default OnboardingModal;
