import { ISettingScreenIntegration } from "interfaces";
import ShopifyIntegrationLogo from 'assets/img/integration-images/shopify.png';
import WooCommerceIntegrationLogo from 'assets/img/integration-images/woo.png';
import WebFlowIntegrationLogo from 'assets/img/integration-images/web-flow.png';
import WixIntegrationLogo from 'assets/img/integration-images/wix.png';
import CustomIntegrationLogo from 'assets/img/integration-images/custom.png';
import { CUSTOM_SITE_SET_UP_SUPPORT_LINK, SHOPIFY_SET_UP_SUPPORT_LINK, WEBFLOW_SET_UP_SUPPORT_LINK, WIX_SET_UP_SUPPORT_LINK, WORDPRESS_SET_UP_SUPPORT_LINK } from "./hyper-links";

export const INTEGRATION_DETAILS: ISettingScreenIntegration[] = [
    {
        title: 'Shopify',
        extraDetails: "No.1 eCommerce Platform for All Businesses. Start, Run + Grow Your Business with Shopify®",
        logo: ShopifyIntegrationLogo,
        logoWidth: '50px',
        logoHeight: '50px',
        connectLink: SHOPIFY_SET_UP_SUPPORT_LINK,
        platformCompability: "shopify",
    },
    {
        title: 'WooCommerce / Wordpress',
        extraDetails: "WooCommerce is a customizable, open-source eCommerce platform built on WordPress.",
        logo: WooCommerceIntegrationLogo,
        logoWidth: '50px',
        logoHeight: '30px',
        connectLink: WORDPRESS_SET_UP_SUPPORT_LINK,
        platformCompability: "non-shopify",
    },
    {
        title: 'Webflow',
        extraDetails: "Build custom blogs, portfolios, e-commerce stores, and more with a flexible CMS. No code website builder.",
        logo: WebFlowIntegrationLogo,
        logoWidth: '60px',
        logoHeight: '60px',
        connectLink: WEBFLOW_SET_UP_SUPPORT_LINK,
        platformCompability: "non-shopify",
    },
    {
        title: 'Wix',
        extraDetails: "Create a website with Wix's robust website builder. With 900+ strategically designed templates and advanced SEO tools.",
        logo: WixIntegrationLogo,
        logoWidth: '60px',
        logoHeight: '24px',
        connectLink: WIX_SET_UP_SUPPORT_LINK,
        platformCompability: "non-shopify",
    },
    {
        title: 'Custom Site / Other',
        extraDetails: "If your brand uses custom code or none of the above platforms apply, use this snippet to connect Gander.",
        logo: CustomIntegrationLogo,
        logoWidth: '30px',
        logoHeight: '30px',
        connectLink: CUSTOM_SITE_SET_UP_SUPPORT_LINK,
        platformCompability: "all",
    }
];