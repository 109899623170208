import { useState, useContext, useEffect } from "react";
import { Divider, Typography } from "antd";
import ProfilePictureComponent from "./profile-picture-component";
import AccountSettingsComponent from "./account-settings-component";
import { AppContext } from "context/app-context";
import { Auth } from "aws-amplify";
import { toast } from "utils/toast";
import moment from "moment";
import PasswordSectionComponent from "./password-section-component";

import "./index.scss";

const { Title } = Typography;

interface IProp {
    isActive: boolean;
}

interface IResponse {
    res: any;
    error: any;
}

const AccountComponent = ({ isActive }: IProp) => {
    const { currentUserInfo, setCurrentUserInfo, shopDetails } = useContext(AppContext);
    let { attributes: userInfo } = currentUserInfo;
    const [lastPasswordUpdatedAt, setLastPasswordUpdatedAt] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isActive) {
            getLastPasswordUpdatedAt();
        }
    }, [isActive, currentUserInfo, userInfo]);

    const resetAppContextOnFieldChange = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            setCurrentUserInfo(user);
            userInfo = user.attributes;
        } catch (error) {
            console.log("🚀 ~ file: index.tsx ~ resetAppContextOnFieldChange ~ error", error);
            toast.error(`Sorry, something went wrong with getting current authenticated user. Please try again later.`, 10);
            throw new Error(String(error));
        }
    };

    const updateCognitoUserAttributes = async (userAttributes: any) => {
        const result: IResponse = { res: "", error: null };
        try {
            result.res = await Auth.updateUserAttributes(currentUserInfo, userAttributes);
        } catch (error) {
            result.error = error;
            console.log(`Error updating user attributes:${error}`);
        } finally {
            resetAppContextOnFieldChange();
        }
        return result;
    };

    const getLastPasswordUpdatedAt = () => {
        try {
            const timeStamp = currentUserInfo.attributes["custom:passwordUpdatedAt"] || String(shopDetails?.createdAt);
            const passwordUpdatedAt = moment(Number(timeStamp)).format("Do MMMM YYYY")
            setLastPasswordUpdatedAt(passwordUpdatedAt);
        } catch {
            setLastPasswordUpdatedAt(undefined);
        }
    };

    return (
        <div className="g-account">
            <Title level={3} className="g-account-title-3">Account Settings</Title>

            <div className="g-account-subtitle">Manage your account settings</div>

            <ProfilePictureComponent userInfo={userInfo} updateCognitoUserAttributes={updateCognitoUserAttributes} />

            <AccountSettingsComponent userInfo={userInfo} resetAppContextOnFieldChange={resetAppContextOnFieldChange} />

            <Divider className="g-account-divider" orientation="left"></Divider>

            <PasswordSectionComponent
                lastPasswordUpdatedAt={lastPasswordUpdatedAt}
                currentUserInfo={currentUserInfo}
                updateCognitoUserAttributes={updateCognitoUserAttributes}
            />
        </div>
    );
};

export default AccountComponent;
