export const getURL = (url: string) => {
  const urlWithProtocol = !/^https?:\/\//i.test(url) ? `https://${url}` : url;

  try {
    return {
      url: new URL(urlWithProtocol),
      isValid: true,
    }
  } catch (e) {
    return {
      url: null,
      isValid: false,
    }
  }
}