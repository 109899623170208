import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Divider, Modal, Spin, Typography } from "antd";
import TiktokModalFirstStepContent from "./tiktok-modal-first-step-content-component/TiktokModalFIrstStepContentComponent";
import TiktokModalSecondStepContent from "./tiktok-modal-second-step-content-component/TiktokModalSecondStepContentComponent";
import { apiGetUserVideos } from "services/tiktok";
import { toast } from "utils/toast";
import { ITiktokVideo } from "interfaces/tiktok";
import TiktokNoBackground from "assets/img/tiktok-no-background.jpg";

import "./index.scss";

const { Title } = Typography;
const { confirm } = Modal;
const dataTestIdPrefix = "tiktok-modal";

const STEPS = [
    { id: "search-username-videos", title: "Connect Your Tiktok Account" },
    { id: "select-videos-to-import", title: "Select Videos You Want to Import" },
];

interface IProps {
    setIsShowTikTokModal: Dispatch<SetStateAction<boolean>>;
}

const TikTokModal = ({ setIsShowTikTokModal }: IProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState<string>();
    const [videos, setVideos] = useState<ITiktokVideo[]>([]);
    const [selectedVideos, setSelectedVideos] = useState<ITiktokVideo[]>([]);
    const [cursor, setCursor] = useState<string>();

    const fetchTiktokVideos = useCallback(
        async (username?: string, cursor?: string) => {
            try {
                if (username === undefined || username.length === 0) {
                    toast.warning("Username required");
                    return;
                }
                setIsLoading(true);
                const response = await apiGetUserVideos({ username, cursor });
                if (response.videos.length > 0) {
                    setVideos(response.videos);
                    setCursor(response.cursor);
                    setCurrentStep(1);
                } else {
                    toast.error("Sorry, something went wrong. Please try a different username.");
                }
            } catch (e) {
                toast.error("Sorry, something went wrong. Please try again.");
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        },
        [username, cursor]
    );

    const handleCancel = () => {
        if (currentStep === 1) {
            confirm({
                title: "Are you sure you want to leave?",
                content: "All progress will be lost.",
                okText: <span>Confirm</span>,
                okType: "default",
                cancelText: "Cancel",
                centered: true,
                async onOk() {
                    setIsShowTikTokModal(false);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
            return;
        }

        setIsShowTikTokModal(false);
    };

    return (
        <Modal open centered width={730} footer={null} onCancel={handleCancel}>
            {isLoading ? (
                <div className="g-tiktok-modal-spinner">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="g-tiktok-modal-content" data-testid={`${dataTestIdPrefix}-body`}>
                    {currentStep === 0 && <img className="g-tiktok-modal-title-icon" alt="views" src={TiktokNoBackground} />}
                    <Title level={3}>{STEPS[currentStep].title}</Title>
                    <Divider style={{ margin: "10px 0 24px" }} />
                    {currentStep === 0 ? (
                        <TiktokModalFirstStepContent username={username} cursor={cursor} setUsername={setUsername} handleNext={fetchTiktokVideos} />
                    ) : (
                        <TiktokModalSecondStepContent
                            username={username}
                            cursor={cursor}
                            videos={videos}
                            selectedVideos={selectedVideos}
                            setCursor={setCursor}
                            setVideos={setVideos}
                            setSelectedVideos={setSelectedVideos}
                            setIsLoading={setIsLoading}
                            setIsShowTikTokModal={setIsShowTikTokModal}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export default TikTokModal;
