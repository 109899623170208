import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { VideosContextProvider } from "context/videos-context";
import ManageContentComponent from "./manage-content-component";
import RecentlyAddedComponent from "./recently-added-component";
import UpgradeYourAccountModal from "../common/upgrade-your-account-modal-component";
import UploadVideoMultipartComponent from "./upload-video-component/UploadVideoMultipartComponent";
import TikTokModal from "./tiktok-modal-component/TiktokModalComponent";
import UploadVideosHeader from "./upload-videos-header-component";
import InstagramModal from "./instagram-modal-component/InstagramModalComponent";

const VideosPage = () => {
    const { isShowUpgradePlanBanner, setIsShowUpgradePlanBanner } = useContext(AppContext);
    const [isShowTikTokModal, setIsShowTikTokModal] = useState(false);
    const [isShowInstagramModal, setIsShowInstagramModal] = useState(false);

    useEffect(() => {
        setIsShowUpgradePlanBanner(false);
    }, []);

    return (
        <div>
            <VideosContextProvider>
                <RecentlyAddedComponent />
                <UploadVideosHeader setIsShowTikTokModal={setIsShowTikTokModal} setIsShowInstagramModal={setIsShowInstagramModal} />
                <UploadVideoMultipartComponent />
                <ManageContentComponent />
                {isShowTikTokModal && <TikTokModal setIsShowTikTokModal={setIsShowTikTokModal} />}
                {isShowInstagramModal && <InstagramModal setIsShowInstagramModal={setIsShowInstagramModal} />}
            </VideosContextProvider>
            {isShowUpgradePlanBanner && (
                <UpgradeYourAccountModal
                    modalTitle={"Upgrade Your Account!"}
                    modalDescription={
                        "You are currently on a Content Only plan which does not give you access to data & analytics. If you'd like to change your plan, you can do so on the settings page or by clicking upgrade plan below."
                    }
                />
            )}
        </div>
    );
};

export default VideosPage;
