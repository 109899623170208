import { Typography } from "antd";
import ChoiceBreakdown from "./choice-breakdown-component";
import { EQuestionType } from "interfaces/schema";
import GreenPicture from "assets/img/green-picture.png";
import { IGetQuestionResponsesOverviewData } from "interfaces/snowflake";
import { EInteractionResultsTabs } from "interfaces";

import "./index.scss";

const dataTestIdPrefix = "QuestionChoiceAnalysis";

const { Text, Title } = Typography;

const questionTypeToTextMap = {
    [EQuestionType.CALL_TO_ACTION]: "Call to Action",
    [EQuestionType.ADD_TO_CART]: "Add to Cart",
    [EQuestionType.MULTIPLE_CHOICE]: "Multiple Choice",
    [EQuestionType.PICTURE_CHOICE]: "Picture Choice",
    [EQuestionType.SHORT_ANSWER]: "Short Answer Question",
};

const questionTypeToCSSMap = {
    [EQuestionType.CALL_TO_ACTION]: "g-interaction-edit-summary-card-main-card-type-cta-color",
    [EQuestionType.ADD_TO_CART]: "g-interaction-edit-summary-card-main-card-type-add-to-cart-color",
    [EQuestionType.MULTIPLE_CHOICE]: "g-interaction-edit-summary-card-main-card-type-mc-color",
    [EQuestionType.PICTURE_CHOICE]: "g-interaction-edit-summary-card-main-card-type-pc-color",
    [EQuestionType.SHORT_ANSWER]: "g-interaction-edit-summary-card-main-card-type-saq-color",
};

interface Props {
    questionResponse: IGetQuestionResponsesOverviewData;
    setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const QuestionChoiceAnalysis = ({
    questionResponse: {
        QUESTION_TYPE: type,
        QUESTION_TEXT: text,
        VIEWED_COUNT: viewedCount,
        SUBMITTED_COUNT: submittedCount,
        CHOICE_RESPONSES: choiceResponses,
    },
    setActiveTab,
}: Props) => {
    return (
        <div className="g-interaction-edit-summary-card-main-card">
            <div className="g-interaction-edit-summary-card-main-card-type-container">
                <Text className={`g-interaction-edit-summary-card-main-card-type ${questionTypeToCSSMap[type]}`} data-testid={`${dataTestIdPrefix}-Text`}>
                    {questionTypeToTextMap[type]}
                </Text>
                <div className="g-interaction-edit-summary-card-main-card-type-image-container">
                    <img
                        className="g-interaction-edit-summary-card-main-card-type-image"
                        alt="views"
                        src={GreenPicture}
                        data-testid={`${dataTestIdPrefix}-Image`}
                    />
                </div>
            </div>
            <Title className="g-interaction-edit-summary-card-main-card-title" level={4} data-testid={`${dataTestIdPrefix}-Title`}>
                {text}
            </Title>
            <Text className="g-interaction-edit-summary-card-main-card-sub-title" data-testid={`${dataTestIdPrefix}-Subtitle`}>
                {submittedCount} out of {viewedCount} people answered this question
            </Text>
            {choiceResponses.map((choiceResponse) => {
                return (
                    <ChoiceBreakdown
                        key={choiceResponse.id}
                        choiceResponse={choiceResponse}
                        totalViewedCount={viewedCount}
                        totalSubmittedCount={submittedCount}
                        type={type}
                        setActiveTab={setActiveTab}
                    />
                );
            })}
        </div>
    );
};

export default QuestionChoiceAnalysis;
