import { Modal } from 'antd';
import { IUploadFile } from 'interfaces';
import { useEffect, useState } from 'react';

import './index.scss';

interface IProps {
    showPreviewVideo: IUploadFile;
    setShowPreviewVideo: React.Dispatch<React.SetStateAction<IUploadFile | undefined>>;
}

const dataTestIdPrefix = "ExternalUpload-UploadedVideoPreviewModal";

const SelectedUploadVideoPreviewModal = ({ showPreviewVideo, setShowPreviewVideo }: IProps) => {
    const [previewBlob, setPreviewBlob] = useState<any>(undefined);

    useEffect(() => {
        generateAndShowPreviewVideo();

        return () => {
            setPreviewBlob(undefined);
        }
    }, [showPreviewVideo]);

    const generateAndShowPreviewVideo = async () => {
        const previewRes = await previewFileVideo(showPreviewVideo?.originFileObj);
        setPreviewBlob(previewRes);
    }

    const previewFileVideo = (selectedFile: File) => {
        try {
            return new Promise((resolve) => {
                const reader = new FileReader();

                // Gettting Selected File (user can select multiple but we are choosing only one)
                if (selectedFile) {
                    reader.readAsDataURL(selectedFile);
                }

                // As the File loaded then set the stage as per the file type
                reader.onload = (readerEvent) => {
                    return resolve(readerEvent?.target?.result);
                };
            });
        } catch (e) {
            console.log("Error : ", e);
        }
    };

    return (
        <Modal
            open={!!showPreviewVideo}
            title={`${showPreviewVideo?.originFileObj?.name}`}
            onCancel={() => setShowPreviewVideo(undefined)}
            footer={null}
        >
            <video
                controls
                src={previewBlob}
                className="g-external-upload-preview-video"
                data-testid={`${dataTestIdPrefix}-VideoTag`}
            />
        </Modal>
    )
}

export default SelectedUploadVideoPreviewModal