import axios from "axios";
import { EInteractionCreationType, IChoice, IInteraction } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface ICreateAddToCartInteractionPayload {
  interactionName: string;
  questionAttributes: {
    text: string;
    choices: IChoice[];
  }
}

interface Payload {
  type: EInteractionCreationType;
  payload?: ICreateAddToCartInteractionPayload;
}

interface Response {
  interaction: IInteraction;
  message: string;
}

const apiCreateInteraction = async (videoPayload: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/interactions`;
    const response = await axios.post<Response>(url, videoPayload);
    return response.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiCreateInteraction;