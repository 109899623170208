import { ILoadMoreVideoLastEvaluatedKey, IPage, IGetPageApiPayload } from "interfaces";
import { createContext, useEffect, useState } from "react";
import { apiGetPages } from "services/pages.services";
import { toast } from "utils/toast";

export interface IPagesContext {
    isPagesLoading: boolean,
    pages: IPage[],
    pagesSearchKeyword: string,
    setPagesSearchKeyword: React.Dispatch<React.SetStateAction<string>>,
    fetchPages: (isLoadMore?: boolean) => void,
    pageRequestLastEvaluatedKey: ILoadMoreVideoLastEvaluatedKey | undefined,
}

const defaultContext = {
    isPagesLoading: false,
    pages: [],
    pagesSearchKeyword: '',
    setPagesSearchKeyword: () => ({}),
    fetchPages: () => ({}),
    pageRequestLastEvaluatedKey: undefined,
};

export const PagesContext = createContext<IPagesContext>(defaultContext);
let pagesSearchTimeout: any;

export const PagesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPagesLoading, setIsPagesLoading] = useState(true);

    const [pagesSearchKeyword, setPagesSearchKeyword] = useState('');
    const [pages, setPages] = useState<IPage[]>([]);
    const [pageRequestLastEvaluatedKey, setPageRequestLastEvaluatedKey] = useState<ILoadMoreVideoLastEvaluatedKey | undefined>(undefined);

    useEffect(() => {
        pagesSearchTimeout = setTimeout(() => {
            fetchPages();
        }, 1000);
        return () => clearTimeout(pagesSearchTimeout);
    }, [pagesSearchKeyword]);

    const fetchPages = async (isLoadMore?: boolean) => {
        setIsPagesLoading(true);
        try {
            const reqPayload: IGetPageApiPayload = {}

            if (isLoadMore) {
                // only if isLoadMore is present we need to pass this parameter
                reqPayload.requestLastEvaluatedKey = pageRequestLastEvaluatedKey
            }

            if (pagesSearchKeyword) {
                reqPayload.filterName = pagesSearchKeyword;
            }

            const pageRes = await apiGetPages(reqPayload);
            setPageRequestLastEvaluatedKey(pageRes?.LastEvaluatedKey);

            if (isLoadMore) {
                setPages([...pages, ...pageRes?.data]);
            } else {
                setPages(pageRes?.data);
            }

        } catch (e: any) {
            console.log("🚀 ~ file: pages-context.tsx ~ fetchPages ~ catch e", e);
            toast.error(e?.message);
        } finally {
            setIsPagesLoading(false);
        }
    }

    return (
        <PagesContext.Provider value={{
            isPagesLoading,
            pages, fetchPages,
            pagesSearchKeyword, setPagesSearchKeyword, pageRequestLastEvaluatedKey
        }}>
            {children}
        </PagesContext.Provider>
    );
};