import BillingPlanCard from "./billing-plan-card-v1-v2-component";
import { ISubscriptionPlan } from 'interfaces';
import { AccountSetupPageFeatureCheckMarkIcon } from "components/icons";
import { Button, Tooltip } from "antd";
import { AppContext } from "context/app-context";
import { useContext } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getPlanActionButtonHrefLink } from "app/pages/settings/billing-subscription-component/utils";

import './index.scss';

interface IProps {
    subscriptionPlans: ISubscriptionPlan[] | undefined;
    selectedPlan: ISubscriptionPlan | undefined;
    isSubscriptionSelectPlansLoading: boolean;
    selectPlan: (plan: ISubscriptionPlan) => Promise<void>;
    isHideStartForFreeButton?: boolean;
}

const dataTestIdPrefix = "SelectPlanTableV1V2Component";

const SelectPlanTableV1V2 = ({ subscriptionPlans, selectedPlan, isSubscriptionSelectPlansLoading, selectPlan, isHideStartForFreeButton }: IProps) => {
    const { activeSubscription } = useContext(AppContext);

    if (!subscriptionPlans?.length) {
        return <></>;
    }

    return (
        <div className="g-select-plan-table-container">
            <table className="g-select-plan-table" data-testid={`${dataTestIdPrefix}-PlanTable`}>
                <tbody data-testid={`${dataTestIdPrefix}-PlanTableBody`}>
                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanCardRow`}>
                        <th className="g-select-plan-table-heading"></th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data g-select-plan-table-card-data" key={index} data-testid={`${dataTestIdPrefix}-PlanCardRowData`}>
                                <BillingPlanCard
                                    key={"Plan_Card_" + index}
                                    subscriptionPlan={plan}
                                    isSelectPlanLoading={
                                        isSubscriptionSelectPlansLoading && selectedPlan?.id === plan?.id && selectedPlan?.meta === plan?.meta
                                    }
                                    disableSelectPlan={
                                        isSubscriptionSelectPlansLoading || plan?.planName === activeSubscription?.activePlan?.planName?.toLowerCase()
                                    }
                                    selectPlan={selectPlan}
                                    style={{ width: "294px" }}
                                />
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanTrainDaysRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanTrainDaysRowHeading`}>
                            Free Trial
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanTrainDaysRowData`}>
                                {plan?.trialDays ?
                                    <div data-testid={`${dataTestIdPrefix}-PlanTrainDaysRowDataChecked`}>{plan?.trialDays} Days</div>
                                    :
                                    <div data-testid={`${dataTestIdPrefix}-PlanTrainDaysRowDataDash`}>N/A</div>
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanIncludesViewRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanIncludesViewRowHeading`}>
                            Included Views
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanIncludesViewRowData`}>
                                <div data-testid={`${dataTestIdPrefix}-PlanIncludesViewRowDataIncludedViews`}>
                                    {plan?.videoEmbeddingIncludedVisit === 0 || !plan?.videoEmbeddingIncludedVisit
                                        ? (plan?.isContactSalesPlan ? "Custom" : "N/A")
                                        : plan?.videoEmbeddingIncludedVisit?.toLocaleString("en-US")}
                                </div>
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRowHeading`}>
                            HTML5 Video Player
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRowData`}>
                                {plan?.isAllowOverlayPlayer && plan?.isAllowEmbedPlayer &&
                                    <div className="g-select-plan-table-data-text-long" data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRowDataValue`}>
                                        Standard Player (Video Overlay) + Embedded Player
                                    </div>
                                }
                                {plan?.isAllowOverlayPlayer && !plan?.isAllowEmbedPlayer &&
                                    <div className="g-select-plan-table-data-text-long" data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRowDataValue`}>
                                        Standard Player (Video Overlay)
                                    </div>
                                }
                                {!plan?.isAllowOverlayPlayer && !plan?.isAllowEmbedPlayer &&
                                    <div data-testid={`${dataTestIdPrefix}-PlanHtmlPlayerRowDataValue`}>
                                        N/A
                                    </div>
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanAnalyticsRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanAnalyticsRowHeading`}>
                            Analytics & Reporting
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanAnalyticsRowData`}>
                                <div className="g-select-plan-table-data-text" data-testid={`${dataTestIdPrefix}-PlanAnalyticsRowDataAnalyticsText`}>
                                    {!plan?.analyticsText || plan?.analyticsText === "" ? "-" : plan?.analyticsText}
                                </div>
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanSupportRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanSupportRowHeading`}>
                            Support
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanSupportRowData`}>
                                <div className="g-select-plan-table-data-text" data-testid={`${dataTestIdPrefix}-PlanSupportRowDataAnalyticsText`}>
                                    {plan?.isPrioritySupportProvided ? "Priority Support" : "General Support"}
                                </div>
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanMaxInteractionRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanMaxInteractionRowHeading`}>
                            <div className="g-d-flex">
                                Included Interactions
                                <Tooltip title="Allows you to add quizzes, forms, call to action, and add to cart to videos.">
                                    <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center" />
                                </Tooltip>
                            </div>
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanMaxInteractionRowData`}>
                                <div className="g-select-plan-table-data-text first-letter-capital" data-testid={`${dataTestIdPrefix}-PlanMaxInteractionRowDataAnalyticsText`}>
                                    {plan?.maxInteractions ?? "-"}
                                </div>
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanInteractiveVideoRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanInteractiveVideoRowHeading`}>
                            <div className="g-d-flex">
                                Interactive Videos
                                <Tooltip title="Allows you to add quizzes, forms, call to action, and add to cart to videos.">
                                    <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center" />
                                </Tooltip>
                            </div>
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanInteractiveVideoRowData`}>
                                {plan?.isInteractiveVideo ?
                                    <AccountSetupPageFeatureCheckMarkIcon data-testid={`${dataTestIdPrefix}-PlanInteractiveVideoRowDataChecked`} />
                                    :
                                    <div data-testid={`${dataTestIdPrefix}-PlanInteractiveVideoRowDataNotAvailable`}>
                                        N/A
                                    </div>
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanRemoveGanderBrandingRow`} >
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanRemoveGanderBrandingRowHeading`} >
                            Remove Gander Branding
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanRemoveGanderBrandingRowData`} >
                                {plan?.isForceBranding ?
                                    <div></div>
                                    :
                                    <AccountSetupPageFeatureCheckMarkIcon data-testid={`${dataTestIdPrefix}-PlanRemoveGanderBrandingRowDataChecked`} />
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanCreativeABTestingRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanCreativeABTestingRowHeading`}>
                            <div className="g-d-flex">
                                Creative A/B Testing
                                <Tooltip title="Allows you to see which video performs better.">
                                    <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center" />
                                </Tooltip>
                            </div>
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanCreativeABTestingRowData`}>
                                {plan?.isPageABTest ?
                                    <AccountSetupPageFeatureCheckMarkIcon data-testid={`${dataTestIdPrefix}-PlanCreativeABTestingRowDataChecked`} />
                                    :
                                    <div></div>
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanGlobalAbTestingRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanGlobalAbTestingRowHeading`}>
                            <div className="g-d-flex">
                                Global A/B Testing
                                <Tooltip title="Allows you to see how your website performs with or without Gander.">
                                    <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center" />
                                </Tooltip>
                            </div>  </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanGlobalAbTestingRowData`}>
                                {plan?.isGlobalABTest ?
                                    <AccountSetupPageFeatureCheckMarkIcon data-testid={`${dataTestIdPrefix}-PlanGlobalAbTestingRowDataChecked`} />
                                    :
                                    <div></div>
                                }
                            </td>
                        ))}
                    </tr>

                    <tr className="g-select-plan-table-row" data-testid={`${dataTestIdPrefix}-PlanDCSMRow`}>
                        <th className="g-select-plan-table-heading" data-testid={`${dataTestIdPrefix}-PlanDCSMRowHeading`}>
                            Dedicated Customer Success Manager
                        </th>
                        {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                            <td className="g-select-plan-table-data" key={index} data-testid={`${dataTestIdPrefix}-PlanDCSMRowData`}>
                                {plan?.isDedicatedCustomerSuccessManager ?
                                    <AccountSetupPageFeatureCheckMarkIcon data-testid={`${dataTestIdPrefix}-PlanDCSMRowDataChecked`} />
                                    :
                                    <div></div>
                                }
                            </td>
                        ))}
                    </tr>

                    {!isHideStartForFreeButton &&
                        <tr className="g-select-plan-table-row">
                            <th className="g-select-plan-table-heading"></th>
                            {subscriptionPlans?.map((plan: ISubscriptionPlan, index: number) => (
                                <td className="g-select-plan-table-data" key={index}>
                                    <Button
                                        className="g-select-plan-table-start-button"
                                        type={"primary"}
                                        onClick={() => getPlanActionButtonHrefLink(plan) ?? selectPlan(plan)}
                                        loading={isSubscriptionSelectPlansLoading && selectedPlan?.id === plan?.id && selectedPlan?.meta === plan?.meta}
                                        disabled={
                                            isSubscriptionSelectPlansLoading || plan?.planName === activeSubscription?.activePlan?.planName?.toLowerCase()
                                        }
                                        href={getPlanActionButtonHrefLink(plan)}
                                    >
                                        Start For Free
                                    </Button>
                                </td>
                            ))}
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SelectPlanTableV1V2;