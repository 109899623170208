import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import * as ROUTE_PATHS from 'constants/routes';
import GanderLogoImg from 'assets/img/gander-logo-tag-line.png';
import GanderRoundLogoImg from 'assets/img/gander-round-logo.png';
import {
  CustomizationIcon,
  DashboardIcon,
  PagesContentIcon,
  RequestContentIcon,
  SettingsIcon,
  VideosContentIcon,
  InteractionsContentIcon
} from "components/icons";

import "./index.scss";

const SidebarComponent = ({ collapsed, Sider }: any) => {
  let location = useLocation();

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={270}>
      <div className="g-logo">
        {/* To show fade in animation properly we need to separate imgs  */}
        {collapsed && <img src={GanderRoundLogoImg} alt="gander logo" />}
        {!collapsed && <img src={GanderLogoImg} alt="gander logo" />}
      </div>
      <div className="g-menu-group-text">
        <span>HOME</span>
      </div>
      <Menu
        className="g-sidebar-menu"
        mode="inline"
        defaultSelectedKeys={[location?.pathname === "/" ? ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH : location?.pathname]}
        selectedKeys={[location?.pathname === "/" ? ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH : location?.pathname]}
        items={[
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.PATH}`,
            icon: <DashboardIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.TITLE}</Link>,
          },
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH}`,
            icon: <VideosContentIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.TITLE}</Link>,
          },
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.PAGES.PATH}`,
            icon: <PagesContentIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.PAGES.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.PAGES.TITLE}</Link>,
          },
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}`,
            icon: <InteractionsContentIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.TITLE}</Link>,
          },
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.CUSTOMIZATION.PATH}`,
            icon: <CustomizationIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.CUSTOMIZATION.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.CUSTOMIZATION.TITLE}</Link>,
          },
        ]}
      />
      <div className="g-menu-group-text">
        <span>OTHERS</span>
      </div>
      <Menu
        className="g-sidebar-menu"
        mode="inline"
        defaultSelectedKeys={[`${location?.pathname}`]}
        selectedKeys={[`${location?.pathname}`]}
        items={[
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.REQUEST_CONTENT.PATH}`,
            icon: <RequestContentIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.REQUEST_CONTENT.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.REQUEST_CONTENT.TITLE}</Link>,
          },
          {
            key: `${ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.PATH}`,
            icon: <SettingsIcon />,
            label: <Link to={ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.PATH}>{ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.TITLE}</Link>,
          },
        ]}
      />
    </Sider>
  );
};

export default SidebarComponent;
