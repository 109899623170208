import { Button } from 'antd'
import { EBillingCycle } from 'interfaces';
import "./index.scss";

interface IProp {
    billingCycleType: EBillingCycle;
    setBillingCycleType: React.Dispatch<React.SetStateAction<EBillingCycle>>;
}

const SelectPlanTableBillingCycleSwitch = ({ billingCycleType, setBillingCycleType }: IProp) => {
    return (
        <div className="g-select-plan-table-billing-cycle-switch">
            <Button
                className={`g-select-plan-table-billing-cycle-switch-button ${billingCycleType === EBillingCycle.MONTHLY && "is-active"}`}
                onClick={() => setBillingCycleType(EBillingCycle.MONTHLY)}
            >
                Monthly
            </Button>
            <Button
                className={`g-select-plan-table-billing-cycle-switch-button ${billingCycleType === EBillingCycle.ANNUALLY && "is-active"}`}
                onClick={() => setBillingCycleType(EBillingCycle.ANNUALLY)}
            >
                Annual
            </Button>
        </div>
    )
}

export default SelectPlanTableBillingCycleSwitch