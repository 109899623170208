import { Button, Input, Modal, Form } from "antd";
import { Auth } from "aws-amplify";
import { ChangeEvent, useState } from "react";
import { toast } from "utils/toast";
import { ExclamationCircleOutlined, LockOutlined } from "@ant-design/icons";

interface IProps {
    currentUserInfo: any;
    setIsResetPasswordModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    updateCognitoUserAttributes: (userAttributes: any) => Promise<{ res: any; error: any; }>;
}

interface IUserProp {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const dataTestIdPrefix = "ResetPasswordComponent";

const ResetPasswordComponent = ({ currentUserInfo, setIsResetPasswordModalVisible, updateCognitoUserAttributes }: IProps) => {
    const [form] = Form.useForm<Pick<IUserProp, "oldPassword" | "newPassword" | "confirmPassword">>();
    const [modal, contextHolder] = Modal.useModal();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const handleOldPassword = (evt: ChangeEvent<HTMLInputElement>) => {
        setOldPassword(evt.target.value);
        form.validateFields(["oldPassword"]);
    };

    const handleNewPassword = (evt: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(evt.target.value);
        form.validateFields(["newPassword", "confirmPassword"]);
    };

    const handleConfirmPassword = (evt: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(evt.target.value);
        form.validateFields(["newPassword", "confirmPassword"]);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("🚀 ~ file: index.tsx ~ onFinishFailed ~ errorInfo:", errorInfo);
        setError(errorInfo);
        toast.error("Reset password failed", 5);
    };

    const onFinish = (values: any) => {
        console.log("Success:", values);
    };

    const checkIsValidPassword = (password: string) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
        const isValidPassword = regex.test(password);
        return isValidPassword;
    };

    const validatePassword = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }
        const isValidPassword = checkIsValidPassword(value);
        if (!isValidPassword) {
            return Promise.reject(
                "Password must be at least 8 characters and include at least one special character, uppercase character, and lowercase character."
            );
        }
        return Promise.resolve();
    };

    const validateNewPassword = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }
        if (value === oldPassword) {
            return Promise.reject("New password can't be same as old password.");
        }
        return Promise.resolve();
    };

    const validateConfirmPassword = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }
        if (value !== newPassword) {
            return Promise.reject("Confirmation password must match to new password.");
        }
        return Promise.resolve();
    };

    const handlePasswordChange = async () => {
        try {
            const isValidPassword = checkIsValidPassword(newPassword);
            if (!isValidPassword) {
                toast.error("Password must be at least 8 characters and include at least one special character, uppercase character, and lowercase character.", 10);
                return;
            }
            await Auth.changePassword(currentUserInfo, oldPassword, newPassword);
            const res = await updateCognitoUserAttributes({ "custom:passwordUpdatedAt": String(Date.now()) });
            if (res.error) {
                throw new Error(String(res.error));
            }
            toast.success("Password successfully changed", 5);
        } catch (error) {
            toast.error(`Error changing password: ${error}`, 5);
        } finally {
            setIsResetPasswordModalVisible(false);
        }
    };

    const handleConfirm = () => {
        modal.confirm({
            title: "Confirm password change",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to change your password?",
            okText: "Confirm",
            cancelText: "Cancel",
            onOk: handlePasswordChange,
            onCancel: () => {
                setIsResetPasswordModalVisible(false);
            },
        });
    };

    return (
        <div className="g-account-reset-password">
            <Modal
                title="Change password"
                open={true}
                onCancel={() => setIsResetPasswordModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsResetPasswordModalVisible(false)} data-testid={`${dataTestIdPrefix}-CancelButton`}>
                        Cancel
                    </Button>,
                    <Button
                        key="change"
                        type="primary"
                        onClick={handleConfirm}
                        disabled={
                            !oldPassword || !newPassword || newPassword !== confirmPassword || oldPassword === newPassword || !checkIsValidPassword(newPassword)
                        }
                        data-testid={`${dataTestIdPrefix}-ChangePasswordButton`}
                    >
                        Change Password
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                    <Form.Item
                        name="oldPassword"
                        className="g-account-form-item"
                        label="Old Password"
                        rules={[
                            { required: true, message: "Please input your old password!" },
                        ]}
                        data-testid={`${dataTestIdPrefix}-OldPassword`}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            size="large"
                            onFocus={(elm) => elm.target.select()}
                            onChange={handleOldPassword}
                            data-testid={`${dataTestIdPrefix}-OldPasswordInput`}
                        />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        className="g-account-form-item"
                        label="New Password"
                        rules={[
                            { required: true, message: "Please input your new password!" },
                            { validator: validatePassword, },
                            { validator: validateNewPassword },
                        ]}
                        data-testid={`${dataTestIdPrefix}-NewPassword`}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            size="large"
                            onFocus={(elm) => elm.target.select()}
                            onChange={handleNewPassword}
                            data-testid={`${dataTestIdPrefix}-NewPasswordInput`}
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        className="g-account-form-item"
                        label="Confirm New Password"
                        rules={[
                            { required: true, message: "Please input your new password again!" },
                            { validator: validateConfirmPassword },
                        ]}
                        data-testid={`${dataTestIdPrefix}-NewPasswordConfirm`}
                    >
                        <Input.Password 
                            prefix={<LockOutlined />} 
                            size="large" 
                            onFocus={(elm) => elm.target.select()} 
                            onChange={handleConfirmPassword} 
                            data-testid={`${dataTestIdPrefix}-NewPasswordConfirmInput`}
                        />
                    </Form.Item>
                    {error && <p>{error}</p>}
                </Form>
            </Modal>
            {contextHolder}
        </div>
    );
};

export default ResetPasswordComponent;
