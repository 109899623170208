import MetricStatisticComponent from "./MetricStatisticComponent";
import MetricCardComponent from "./MetricCardComponent";
import { Skeleton } from "antd";

export const MetricComponent = (props: any) => {
  const { prefix, loading, title, icon, color, suffix, metricValue, metricValuePrev, currentSelectKey, information } = props;

  return (
    <MetricCardComponent
      title={title}
      icon={icon}
      color={color}
      body={
        <div>
          {loading ? (
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          ) : (
            <MetricStatisticComponent
              prefix={prefix}
              suffix={suffix}
              metricValue={metricValue}
              metricValuePrev={metricValuePrev}
              metricChangeTimePeriod={currentSelectKey}
            />
          )}
        </div>
      }
      information={information}
    />
  );
};
