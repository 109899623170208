import { useEffect } from 'react';
import { Button, Card, Input, Typography, Form } from 'antd';
import { EInteractionCreationType, IInteraction, IQuestion, IVideo } from 'interfaces/schema';
import { ADD_TO_CART_TEMPLATE } from "constants/question-templates"
import { toast } from 'utils/toast';
import { getURL } from "app/pages/videos/utils";
import { apiCreateInteraction, apiGetInteraction } from 'services/interactions';
import { apiUpdateVideo } from 'services/video.services';

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = "Videos-AddInteractionFormComponent-CreateAddToCartButton";

interface Props {
  viewVideoDetailsDrawer: IVideo;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowCreateAddToCartForm: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
}

const CreateAddToCartButtonForm = ({ viewVideoDetailsDrawer, isLoading, setIsLoading, setIsShowCreateAddToCartForm, setSelectedInteraction }: Props) => {
  const [form] = Form.useForm<Pick<IQuestion, "text" | "choices">>();

  useEffect(() => {
    form.setFieldValue("text", ADD_TO_CART_TEMPLATE.text);
    form.setFieldValue("choices", ADD_TO_CART_TEMPLATE.choices);
  }, [form])

  const handleCreateAddToCartButton = async (values: Pick<IQuestion, "text" | "choices">) => {
    try {
      setIsLoading(true);
      const urlResponse = getURL(values?.choices[0]?.productUrl as string);
      const path = urlResponse.isValid ? urlResponse.url!.origin + urlResponse.url!.pathname : values.choices[0]?.productUrl;
      values.choices[0].productUrl = path;
      const pathSplitByBackslash = path!.split("/");

      const response = await apiCreateInteraction({
        type: EInteractionCreationType.ADD_TO_CART,
        payload: {
          interactionName: `Add to Cart (${pathSplitByBackslash[pathSplitByBackslash.length - 1]})`,
          questionAttributes: {
            text: values.text,
            choices: values.choices,
          }
        },
      });
      const [interactionResponse] = await Promise.all([
        apiGetInteraction({ id: response.interaction.meta }),
        apiUpdateVideo(
          {
            GSI1PK: `INTERACTION#${response.interaction.meta}`,
            GSI1SK: `VIDEO#${viewVideoDetailsDrawer.meta}`,
          },
          viewVideoDetailsDrawer.meta
        ),
      ]);

      setSelectedInteraction(interactionResponse.interaction);
      setIsShowCreateAddToCartForm(false);
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message, 2);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      data-testid={`${dataTestIdPrefix}-Card`}
      className="g-add-edit-video-details-new-add-to-cart-button-card"
      loading={isLoading}
    >
      <div className="g-add-edit-video-details-new-add-to-cart-button-form-container">
        <Title data-testid={`${dataTestIdPrefix}-Title`} level={5}>
          Create Add To Cart Button
        </Title>
        <Form
          data-testid={`${dataTestIdPrefix}-Form`}
          form={form}
          layout="vertical"
          onFinish={handleCreateAddToCartButton}
          autoComplete="off"
        >
          <Form.Item
            name="text"
            label="Button Text"
            shouldUpdate
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input
              name="text"
              size="large"
              placeholder="Add To Cart"
            />
          </Form.Item>
          {!isLoading && (
            <Form.List name="choices">
              {(choices) => {
                return (
                  <Form.Item
                    {...choices[0]}
                    key="productUrl"
                    name={[choices[0]?.name, 'productUrl']}
                    label="Product URL"
                    shouldUpdate
                    rules={[
                      { required: true, message: 'This field is required' },
                      { message: 'Invalid Url', pattern: /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/ },
                    ]}
                  >
                    <Input name="productUrl" size="large" placeholder="Enter a URL" />
                  </Form.Item>
                )
              }}
            </Form.List>
          )}
        </Form>
        <div className="g-add-edit-video-details-new-add-to-cart-button-form-buttons-container">
          <Button type="default" onClick={() => setIsShowCreateAddToCartForm(false)} disabled={isLoading}>Back</Button>
          <Button type="primary" onClick={() => form.submit()} disabled={isLoading}>Confirm</Button>
        </div>
      </div>
    </Card >
  );
}

export default CreateAddToCartButtonForm;