import { useCallback, useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { Auth } from "aws-amplify";
import HeaderComponent from "./header-component";
import SidebarComponent from "./sidebar-component";
import { AppContext } from "context/app-context";
import { PRIVATE_ROUTES } from "constants/routes";
import SmallBrowserOverlayComponent from "./small-browser-overlay-component";

import "./index.scss";

const { Header, Sider, Content } = Layout;

const AppLayout = () => {
    const { collapsed, setCollapsed } = useContext(AppContext);
    const location = useLocation();

    const onLogOut = useCallback(async () => {
        try {
            // TODO: Cleanup remove this if we are not using
            await Auth.signOut();
        } catch (e) {
            console.log(e);
        }
    }, [Auth]);

    // on customization we need to collapse sidebar
    useEffect(() => {
        if (location) {
            if (location.pathname === PRIVATE_ROUTES.CUSTOMIZATION.PATH ||
                location.pathname === PRIVATE_ROUTES.DASHBOARD.PATH ||
                location.pathname.startsWith(`${PRIVATE_ROUTES.INTERACTIONS.PATH}/`)
            ) {
                setCollapsed(true);
            }
        }
    }, [location]);

    return (
        <Layout className="g-app-layout">
            <SidebarComponent onLogOut={onLogOut} collapsed={collapsed} Sider={Sider} />
            <Layout className="g-site-layout">
                <SmallBrowserOverlayComponent />
                <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} Header={Header} onLogOut={onLogOut} />
                <Content className="g-site-layout-background">
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AppLayout;
