import axios from "axios";
import { IGetContentRequestApiPayload } from "interfaces";

const { REACT_APP_API_URL } = process.env;
const PAGES_API_URL = `${REACT_APP_API_URL}/content-request`;

export const apiGetContentRequest = async (payload: IGetContentRequestApiPayload | undefined) => {
  try {
    const url = `${PAGES_API_URL}/get-content-requests`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};
