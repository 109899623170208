import { IActiveSubscription, IShopDetails } from "interfaces";
import { SubscriptionStatus } from "interfaces/stripe";

export const isActivePlan = (shopDetails: IShopDetails | null, activeSubscription: IActiveSubscription | null) => {
  if (shopDetails?.platform !== "shopify") {
    return activeSubscription && 
      (activeSubscription?.status === SubscriptionStatus.ACTIVE ||
        activeSubscription?.status === SubscriptionStatus.TRIALING ||
        activeSubscription?.status === SubscriptionStatus.PAST_DUE);
  } else {
    return activeSubscription && activeSubscription?.status?.toUpperCase() === "ACTIVE";
  }
}