import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import HeaderRightUserInfoComponent from '../common/header-right-user-info';

import './index.scss';

const { Header } = Layout;

const AppFullscreenLayout = () => {

    return (
        <>
            <Header className="g-site-fullscreen-layout-background" style={{ padding: 0, position: "relative" }}>
                <div className="g-header-left-title">Account Set Up</div>
                <HeaderRightUserInfoComponent />
            </Header>
            <Outlet />
        </>
    )
}

export default AppFullscreenLayout