import { Typography, Slider, Button, Tooltip, Space, Checkbox } from "antd";
import { EEmbeddedLayout, EEmbeddedPlayerStyle, EOverlayOpenState, EPlayerShape, EWidgetType, IDeviceSettings } from "interfaces/schema";
import {
    convertNumbertoPercentageString,
    convertNumbertoVhString,
    convertPercentageStringToNumber,
    convertVhStringToNumber,
} from "../../../../../../utils/type-conversion-functions";
import { EDeviceType } from "interfaces";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PlayerCustomizationSettings } from "constants/numbers";
import { Unit, Text as TextConstant } from "constants/strings";
import { applyPreviewCornerRadiusOverride, previewStateMinHeight } from "utils/customization-settings";

import "./index.scss";

const { Text } = Typography;
const DATA_TEST_ID_PREFIX = "EmbeddedPreviewStateSettings";

interface Props {
    device: EDeviceType;
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings) => void;
}

const EmbeddedPreviewStateSettings = ({ device, deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const handleUpdateDeviceSettings = (dirtyWidgetSettings: Partial<IDeviceSettings>) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            ...dirtyWidgetSettings,
        });
    };

    const handlePlayerSizeChange = (value: number) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            previewPlayerSize: convertNumbertoVhString(value),
        });
    };

    const handleCornerRadiusChange = (value: number) => {
        const isEmbeddedInFrameMode = deviceSettings?.widgetType === EWidgetType.EMBEDDED && deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME;

        handleDeviceSettingsUpdate({
            ...deviceSettings,
            previewCornerRadius: isEmbeddedInFrameMode ? applyPreviewCornerRadiusOverride(convertNumbertoPercentageString(value)) : convertNumbertoPercentageString(value),
            textIsShowViewCount: value > 5 ? false : deviceSettings?.textIsShowViewCount,
        });
    };

    const handleOrientationButtonClick = (dirtyPreviewOrientation: EPlayerShape) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            previewOrientation: dirtyPreviewOrientation,
            textIsShowViewCount: false,
            openStateType: deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME ? EOverlayOpenState.OVERLAY : deviceSettings?.openStateType,
        });
    };

    return (
        <>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-GIF-Style-Text`}>GIF Style</Text>
                <Tooltip title={TextConstant.EMBEDDED_PREVIEW_GIF_STYLE_HEADER}>
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={
                        deviceSettings?.previewEmbedGifStyle === EEmbeddedPlayerStyle.SIMULTANEOUS
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleUpdateDeviceSettings({ previewEmbedGifStyle: EEmbeddedPlayerStyle.SIMULTANEOUS })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-GIF-Style-Simultaneous-Button`}
                >
                    Simultaneous
                </Button>
                <Button
                    className={
                        deviceSettings?.previewEmbedGifStyle === EEmbeddedPlayerStyle.ON_HOVER
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    disabled={device === EDeviceType.MOBILE}
                    onClick={() => handleUpdateDeviceSettings({ previewEmbedGifStyle: EEmbeddedPlayerStyle.ON_HOVER })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-GIF-Style-Play-On-Hover-Button`}
                >
                    Play on hover
                </Button>
            </div>
            {deviceSettings?.embedType === EEmbeddedLayout.HORIZONTAL_SCROLL && (
                <>
                    <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Text`}>Orientation</Text>
                    <div className="g-customization-toggle-buttons-container">
                        <Button
                            className={
                                deviceSettings?.previewOrientation === EPlayerShape.PORTRAIT_LANDSCAPE
                                    ? "g-customization-toggle-button-selected"
                                    : "g-customization-toggle-button"
                            }
                            type="text"
                            onClick={() => handleUpdateDeviceSettings({ previewOrientation: EPlayerShape.PORTRAIT_LANDSCAPE })}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Portrait-Landscape-Button`}
                        >
                            Portrait/Landscape
                        </Button>
                        <Button
                            className={
                                deviceSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE
                                    ? "g-customization-toggle-button-selected"
                                    : "g-customization-toggle-button"
                            }
                            type="text"
                            onClick={() => handleOrientationButtonClick(EPlayerShape.SQUARE_CIRCLE)}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Square-Circle-Button`}
                        >
                            Square/Circle
                        </Button>
                    </div>
                </>
            )}
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Height-Text`}>Height</Text>
                <Tooltip title={`Minimum height: ${previewStateMinHeight(device, deviceSettings)}${Unit.PIXEL}`}>
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-slider-container">
                <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Height-LeftItem-Text`}>Small</Text>
                <Slider
                    max={PlayerCustomizationSettings.EMBEDDED_PREVIEW_PLAYER_SIZE_MAX}
                    value={deviceSettings?.previewPlayerSize?.endsWith(Unit.PIXEL) ? PlayerCustomizationSettings.EMBEDDED_PREVIEW_PLAYER_SIZE_DEFAULT : convertVhStringToNumber(deviceSettings?.previewPlayerSize)}
                    onChange={(value) => handlePlayerSizeChange(value)}
                    className={`${DATA_TEST_ID_PREFIX}-Height-Slider`}
                />
                <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Height-RightItem-Text`}>Large</Text>
            </div>
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-Text`}>Corner Style</Text>
            <div className="g-customization-slider-container">
                <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-LeftItem-Text`}>Square</Text>
                <Slider
                    max={PlayerCustomizationSettings.EMBEDDED_PREVIEW_CORNER_RADIUS_MAX}
                    tooltip={{ formatter: ((value?: number) => value! * 2) }}
                    value={deviceSettings?.previewCornerRadius?.includes(Unit.PIXEL) ? PlayerCustomizationSettings.EMBEDDED_PREVIEW_CORNER_RADIUS_DEFAULT : convertPercentageStringToNumber(deviceSettings?.previewCornerRadius)}
                    onChange={(value) => handleCornerRadiusChange(value)}
                    className={`${DATA_TEST_ID_PREFIX}-Corner-Radius-Slider`}
                />
                <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-RightItem-Text`}>Circular</Text>
            </div>
            <Space className="g-customization-checkbox-group" direction="vertical" size="middle">
                <div className="g-embedded-preview-state-add-border-div">
                    <Checkbox
                        checked={deviceSettings?.previewIsShowBorder}
                        onChange={(e) => handleUpdateDeviceSettings({ previewIsShowBorder: e.target.checked, previewIsBorderPadding: false })}
                        data-testid={`${DATA_TEST_ID_PREFIX}-Add-Border-Checkbox`}
                    >
                        Add Border
                    </Checkbox>
                    <div
                        className={`g-embedded-preview-state-border-input-div ${!deviceSettings?.previewIsShowBorder ? 'disabled' : ''}`}
                        onClick={() => document.getElementById('previewBorderColor')?.click()}
                    >
                        <input
                            type="color"
                            name="previewBorderColor"
                            id="previewBorderColor"
                            value={deviceSettings?.previewBorderColor}
                            disabled={!deviceSettings?.previewIsShowBorder}
                            onInput={(e) => handleUpdateDeviceSettings({ previewBorderColor: e.currentTarget.value })}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Border-Color-Input`}
                        />
                        <span>{deviceSettings?.previewBorderColor}</span>
                    </div>
                </div>
                <Checkbox
                    checked={deviceSettings?.previewIsBorderPadding}
                    disabled={!deviceSettings?.previewIsShowBorder}
                    onChange={(e) => handleUpdateDeviceSettings({ previewIsBorderPadding: e.target.checked })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Border-Padding-Checkbox`}
                >
                    Include Border Padding
                </Checkbox>
            </Space>
        </>
    );
};

export default EmbeddedPreviewStateSettings;
