import ManageContentComponent from "./manage-content-component";
import { PagesContextProvider } from "context/pages-context";
import CustomizePageComponent from "./customize-page-component";
import { useState } from "react";
import { IPage } from "interfaces";
import { useContext, useEffect } from "react";
import { AppContext } from "context/app-context";
import UpgradeYourAccountModal from "../common/upgrade-your-account-modal-component";


const PagesPage = () => {
    const [viewCustomizePageComponent, setViewCustomizePageComponent] = useState<IPage | undefined>();
    const { isShowUpgradePlanBanner, setIsShowUpgradePlanBanner } = useContext(AppContext);

    useEffect(() => {
        setIsShowUpgradePlanBanner(false);
    }, []);

    return (
        <PagesContextProvider >
            {viewCustomizePageComponent !== undefined ? (
                <CustomizePageComponent viewCustomizePageComponent={viewCustomizePageComponent} setViewCustomizePageComponent={setViewCustomizePageComponent} />
            ) : (
                <ManageContentComponent setViewCustomizePageComponent={setViewCustomizePageComponent} />
            )}
            {isShowUpgradePlanBanner &&
                <UpgradeYourAccountModal
                    modalTitle={"Upgrade Your Account!"}
                    modalDescription={"You are currently on a Content Only plan which does not give you access to data & analytics. If you'd like to change your plan, you can do so on the settings page or by clicking upgrade plan below."}
                />
            }
        </PagesContextProvider>
    )
}

export default PagesPage;