import { Dropdown, MenuProps, Tooltip, Avatar } from 'antd';
import { ReactComponent as ArrowDown } from "assets/img/arrow-down.svg";
import { Auth } from 'aws-amplify';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/app-context';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { UserOutlined } from "@ant-design/icons";
import './index.scss';

const HeaderRightUserInfoComponent = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const { shopDetails, currentUserInfo } = useContext(AppContext);
    const [profilePictureUrl, setProfilePictureUrl] = useState<string>("");

    const onLogOut = useCallback(async () => {
        try {
            await Auth.signOut();
        } catch (e) {
            console.log(e);
        }
    }, [Auth]);

    useEffect(() => {
        const picture = currentUserInfo?.attributes?.["custom:profilePictureUrl"] || "";
        setProfilePictureUrl(picture);
    }, [currentUserInfo]);

    const items: MenuProps['items'] = [
        {
            label: <div onClick={onLogOut}>Logout</div>,
            key: "3",
        },
    ];

    return (
        <div className="g-header-right g-noselect">
            <Dropdown menu={{ items }} trigger={["click"]}>
                <div className="g-header-menu-dropdown">
                    <div className="g-header-right-user-avatar">
                        {profilePictureUrl ?
                            <Avatar size={36} style={{ backgroundColor: "#1890ff" }} src={profilePictureUrl} /> :
                            <Avatar size={36} style={{ backgroundColor: "#1890ff" }} icon={<UserOutlined />} />}
                    </div>
                    <div className="g-header-right-user-detail-container">
                        <Tooltip title={user?.attributes?.email} placement='left'>
                            <div className="g-header-right-user-name" title={user?.attributes?.name}>{user?.attributes?.name}</div>
                        </Tooltip>
                        <div className="g-header-right-user-type" title={shopDetails?.domain}>{shopDetails?.domain}</div>
                    </div>
                    <div className="g-header-right-dropdown">
                        <ArrowDown />
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default HeaderRightUserInfoComponent;