import { Button, Typography } from "antd";
import { EcomMadeEasierIcon, ProFeatureStartFilledIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import * as ROUTE_PATHS from 'constants/routes';
import "../index.scss";
const { Title } = Typography;

interface IProps {
  property: JSX.Element;
  isShowFloatingBackground?: boolean;
}

export default function MetricCardTableBlockedComponent({ property, isShowFloatingBackground = false }: IProps) {
  const navigate = useNavigate();

  return (
    <div className="g-metric-card-table-blocked-div">
      <Button className="g-metric-card-table-button" icon={<ProFeatureStartFilledIcon />}>
        PRO Feature
      </Button>
      <Title level={3} className="g-metric-card-table-blocked-title">
        {property}
      </Title>
      <Button className="g-metric-card-table-upgrade-button" size="large" onClick={() => navigate(ROUTE_PATHS.PRIVATE_ROUTES.SETTINGS.PATH)}>
        Upgrade Now
      </Button>
      <div className="g-metric-card-table-empty-div"></div>
      <div className="g-metric-card-table-empty-div"></div>
      {isShowFloatingBackground &&
        <div className="g-metric-card-table-floating-div">
          <EcomMadeEasierIcon />
        </div>
      }
    </div>
  );
}
