import { Modal } from 'antd';
import { useContext, useState } from 'react';
import { apiGetCurrentActivePlan, apiPostCancelActivePlan } from 'services/subscription.services';
import { AppContext } from 'context/app-context';
import { toast } from 'utils/toast';

interface IProps {
    isActive: boolean;
    closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const dataTestIdPrefix = "SettingsBillingSubscriptionComponent-CancelPlanConfirmationModal";

const CancelPlanConfirmationModal = ({ isActive, closeModal }: IProps) => {
    const { activeSubscription, setActiveSubscription } = useContext(AppContext);
    const [cancelPlanLoading, setCancelPlanLoading] = useState(false);

    const cancelPlanConfirmed = async () => {
        try {
            setCancelPlanLoading(true);
            const cancelPlanRes = await apiPostCancelActivePlan(activeSubscription?.id!);
            closeModal(false);
            toast.success(cancelPlanRes?.message);
            refetchActivePlan();
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message ?? "Something went wrong!");
            setCancelPlanLoading(false);
        }
    }

    const refetchActivePlan = async () => {
        try {
            const response = await apiGetCurrentActivePlan();
            setActiveSubscription(response?.activeSubscription);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message ?? "Something went wrong!");
        } finally {
            setCancelPlanLoading(false);
        }
    }

    return (
        <Modal
            title={`Cancel Plan`}
            centered={true}
            width={700}
            open={isActive}
            maskClosable={false}
            okText="Confirm"
            okType="default"
            onCancel={() => closeModal(false)}
            okButtonProps={{ type: "primary" }}
            confirmLoading={cancelPlanLoading}
            cancelButtonProps={{ disabled: cancelPlanLoading }}
            onOk={cancelPlanConfirmed}
            data-testid={`${dataTestIdPrefix}-Modal`}
        >
            <div data-testid={`${dataTestIdPrefix}-CancelPlanConfirmationModalBody`}>
                <span>Are you sure you want to cancel your current plan?</span>
                {activeSubscription?.activePlan?.displayName &&
                    <p>Plan Name : <b data-testid={`${dataTestIdPrefix}-PlanDisplayName`}>{activeSubscription?.activePlan?.displayName}</b></p>
                }
            </div>
        </Modal>
    )
}

export default CancelPlanConfirmationModal;