import { Card } from "antd";
import YourQuestionsPanel from "./your-questions-panel-component";
import QuestionEdit from "./question-edit-component";
import { IInteraction, IQuestion } from "interfaces/schema";

import "./index.scss";

interface Props {
  interaction: IInteraction;
  selectedQuestion: IQuestion | undefined;
  setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
  isValidateForm: boolean;
  setIsValidateForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const InteractionEdit = ({ interaction, selectedQuestion, setInteraction, setSelectedQuestion, isValidateForm, setIsValidateForm }: Props) => {
  return (
    <>
      <Card.Grid className="g-interactions-settings-sub-card-container" hoverable={false}>
        <YourQuestionsPanel
          interaction={interaction}
          selectedQuestion={selectedQuestion}
          setInteraction={setInteraction}
          setSelectedQuestion={setSelectedQuestion}
        />
      </Card.Grid>
      <Card.Grid className="g-interactions-settings-sub-card-container g-no-padding" hoverable={false}>
        {selectedQuestion && (
          <QuestionEdit
            interaction={interaction}
            question={selectedQuestion}
            setInteraction={setInteraction}
            setSelectedQuestion={setSelectedQuestion}
            isValidateForm={isValidateForm}
            setIsValidateForm={setIsValidateForm}
          />
        )}
      </Card.Grid>
    </>
  );
};

export default InteractionEdit;
