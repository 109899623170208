export const convertPixelStringToNumber = (pixelString: string) => {
  return Number(pixelString.replace("px", ""));
};

export const convertNumbertoPixelString = (number: number) => {
  return String(number).concat("px");
};

export const convertVhStringToNumber = (vhString: string) => {
  return Number(vhString.replace("vh", ""));
};

export const convertNumbertoVhString = (number: number) => {
  return String(number).concat("vh");
};

export const convertPercentageStringToNumber = (pixelString: string) => {
  return Number(pixelString.replace("%", ""));
};

export const convertNumbertoPercentageString = (number: number) => {
  return String(number).concat("%");
};

export const removeSubstring = (string: string, substringToRemove: string) => {
  return string.replace(substringToRemove, "");
};