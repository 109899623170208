import { useEffect, useState } from "react";
import { Select, Space } from "antd";
import { RgbaColorPicker } from "react-colorful";
import EyeDropperPicker from "./eye-dropper-picker-component";
import HexColorInput from "./hex-color-input-component";
import { HEX2RGB, RGB2HEX } from "utils/common-functions";
import { EOverlayStartingState, EWidgetType, IDeviceSettings } from "interfaces/schema";
import { EOverridePreviewState } from "interfaces";

import "./index.scss";

declare global {
    interface Window {
        EyeDropper: any;
    }
}

interface IRGBAColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

enum EColorSelectionType {
    TextColor = "text-color",
    ButtonColor = "button-color",
}

const DEFAULT_COLOR = { r: 200, g: 150, b: 35, a: 0.5 };
const COLOR_SELECTION_OPTIONS = [
    {
        label: <span>Text Color</span>,
        value: EColorSelectionType.TextColor,
    },
    {
        label: <span>Button Color</span>,
        value: EColorSelectionType.ButtonColor,
    },
];

const isValidRGB = (RGBA: IRGBAColor) => {
    return !(RGBA?.r < 0 || RGBA?.r > 255 || RGBA?.g < 0 || RGBA?.g > 255 || RGBA?.b < 0 || RGBA?.b > 255 || RGBA?.a < 0 || RGBA?.a > 1);
};

const initializeColor = (textColor: string) => {
    const textColorRgb = HEX2RGB(textColor);
    const RGBA = textColorRgb && { ...textColorRgb, a: 1 };

    if (RGBA && isValidRGB(RGBA)) {
        return RGBA;
    }

    return DEFAULT_COLOR;
};

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const ColorSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const [color, setColor] = useState<IRGBAColor>(initializeColor(deviceSettings?.textColor));
    const [hexColor, setHexColor] = useState(RGB2HEX(color?.r, color?.g, color?.b, false).toUpperCase());
    const [activeColorSelection, setActiveColorSelection] = useState<EColorSelectionType>(EColorSelectionType.TextColor);
    const [isEyeDropperAllowed, setIsEyeDropperAllowed] = useState(true);

    useEffect(() => {
        // On component init check for eye dropper library is supported or not and show button based on that
        if (!window.EyeDropper) {
            setIsEyeDropperAllowed(false);
        }
    }, []);

    useEffect(() => {
        const isShowOpenState =
            deviceSettings?.widgetType === EWidgetType.OVERLAY && deviceSettings?.previewOverlayStartingState === EOverlayStartingState.OPEN_STATE;
        const overridePreview = isShowOpenState ? undefined : EOverridePreviewState.OVERLAY;

        switch (activeColorSelection) {
            case EColorSelectionType.TextColor:
                handleDeviceSettingsUpdate(
                    {
                        ...deviceSettings,
                        textColor: RGB2HEX(color?.r, color?.g, color?.b, true),
                    },
                    overridePreview,
                );
                break;
            case EColorSelectionType.ButtonColor:
                handleDeviceSettingsUpdate(
                    {
                        ...deviceSettings,
                        buttonColor: RGB2HEX(color?.r, color?.g, color?.b, true),
                    },
                    overridePreview,
                );
                break;
        }
    }, [activeColorSelection, color]);

    const updateRGBAPickerValue = (RGBA: IRGBAColor) => {
        // validate RGBA values
        if (!isValidRGB(RGBA)) {
            console.log("not valid rgba : ", RGBA);
            return;
        }
        setColor(RGBA);
        setHexColor(RGB2HEX(RGBA?.r, RGBA?.g, RGBA?.b, false).toUpperCase());
    };

    const setRGBASelectorValueFromColorSelectionType = (value: EColorSelectionType) => {
        switch (value) {
            case EColorSelectionType?.TextColor:
                const textColorRgb = HEX2RGB(deviceSettings?.textColor);
                if (textColorRgb) {
                    updateRGBAPickerValue({ ...textColorRgb, a: 1 });
                }
                break;
            case EColorSelectionType?.ButtonColor:
                const buttonColorRgb = HEX2RGB(deviceSettings?.buttonColor);
                if (buttonColorRgb) {
                    updateRGBAPickerValue({ ...buttonColorRgb, a: 1 });
                }
                break;
        }
    };

    const handleActiveColorSelectionChange = (value: EColorSelectionType) => {
        setActiveColorSelection(value);
        setRGBASelectorValueFromColorSelectionType(value);
    };

    return (
        <>
            <Space direction="vertical" size="middle">
                <Select
                    className="g-customization-color-target-select"
                    size="large"
                    value={activeColorSelection}
                    options={COLOR_SELECTION_OPTIONS}
                    onChange={handleActiveColorSelectionChange}
                />
                <div className="g-customization-color-rgba-selector-container">
                    <RgbaColorPicker
                        color={color}
                        onChange={updateRGBAPickerValue}
                        className={
                            isEyeDropperAllowed
                                ? "g-customization-color-rgba-selector"
                                : "g-customization-color-rgba-selector g-customization-color-rgba-selector-short"
                        }
                    />
                    {isEyeDropperAllowed && <EyeDropperPicker color={color} updateRGBAPickerValue={updateRGBAPickerValue} />}
                    <HexColorInput
                        isEyeDropperAllowed={isEyeDropperAllowed}
                        color={color}
                        hexColor={hexColor}
                        setHexColor={setHexColor}
                        updateRGBAPickerValue={updateRGBAPickerValue}
                    />
                </div>
            </Space>
        </>
    );
};

export default ColorSettings;
