import { Navigate } from 'react-router-dom';
import * as ROUTE_PATHS from 'constants/routes';
import { useAuthenticator } from '@aws-amplify/ui-react';

const PrivateRoute: any = ({ children }: { children: any }) => {
    const { route } = useAuthenticator((context) => [context.route]);

    if (route !== 'authenticated') {
        return <Navigate to={ROUTE_PATHS.AUTH_ROUTES.LOGIN} />;
    }
    return children;
};

export default PrivateRoute;
