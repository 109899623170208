import { useContext, useEffect, useState } from "react";
import InteractionsHeader from "./interactions-header-component";
import InteractionsTable from "./interactions-table-component";
import CreateInteractionModal from "./create-interaction-modal-component";
import { InteractionsContext } from "context/interactions-context";

import "./index.scss";

const InteractionsView = () => {
  const { interactions, fetchInteractions, isLoading, isAllowedCreateNew } = useContext(InteractionsContext);
  const [showCreateInteractionModal, setShowCreateInteractionModal] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    fetchInteractions();
  }, []);

  useEffect(() => {
    if (isLoading || isFiltered) {
      return;
    }
    if (isAllowedCreateNew) {
      setShowCreateInteractionModal(interactions.length === 0);
    }
  }, [interactions]);

  return (
    <div className="g-interactions-view-container g-d-flex g-h-100">
      <InteractionsHeader
        setShowCreateInteractionModal={setShowCreateInteractionModal}
        setIsFiltered={setIsFiltered}
      />
      <InteractionsTable/>
      {showCreateInteractionModal && (
        <CreateInteractionModal setShowCreateInteractionModal={setShowCreateInteractionModal} />
      )}
    </div>
  )
}

export default InteractionsView;