import { DATE_FORMAT, EMetricTimePeriod, EMetricTimeString, IAbTestingDetails, IAbTestingExpandedDetails, IAbTestingMetrics, IAbTestingResults, IConversionRateByDevice, IConversionRateByDeviceTableDetails, IConversionRateByVisitorType, IConversionRateByVisitorTypeTableDetails, IMetricPayload, IMetricValue, TMetric, TMetricGroup, TMetricValue } from "../interfaces";
import moment from "moment";

export const calculateDateBefore = (endDate: moment.Moment, nPeriods: number, timePeriod: EMetricTimePeriod): moment.Moment => {
  switch (timePeriod) {
    case EMetricTimePeriod.YEAR:
      return endDate.subtract(nPeriods, "year");
    case EMetricTimePeriod.MONTH:
      return endDate.subtract(nPeriods, "month");
    case EMetricTimePeriod.WEEK:
      return endDate.subtract(nPeriods, "week");
    case EMetricTimePeriod.DAY:
      return endDate.subtract(nPeriods, "day");
    default:
      throw new Error("Something wrong!");
  }
};

export const findStartDateFromString = (endDate: moment.Moment, inputString: EMetricTimeString): moment.Moment => {
  switch (inputString) {
    case EMetricTimeString.LAST_DAY:
      return calculateDateBefore(endDate, 1, EMetricTimePeriod.DAY);
    case EMetricTimeString.LAST_7_DAYS:
      return calculateDateBefore(endDate, 7, EMetricTimePeriod.DAY);
    case EMetricTimeString.LAST_28_DAYS:
      return calculateDateBefore(endDate, 28, EMetricTimePeriod.DAY);
    case EMetricTimeString.LAST_3_MONTHS:
      return calculateDateBefore(endDate, 3, EMetricTimePeriod.MONTH);
    case EMetricTimeString.LAST_6_MONTHS:
      return calculateDateBefore(endDate, 6, EMetricTimePeriod.MONTH);
    case EMetricTimeString.LAST_12_MONTHS:
      return calculateDateBefore(endDate, 12, EMetricTimePeriod.MONTH);
    case EMetricTimeString.CUSTOM_TIME:
      return calculateDateBefore(endDate, 1, EMetricTimePeriod.DAY);
    default:
      throw new Error("Something wrong! This went wrong in metrics.ts");
  }
};

export const shiftStartEndDateBackForComparison = (startDate: moment.Moment, endDate: moment.Moment, inputString: EMetricTimeString): IMetricPayload => {
  switch (inputString) {
    case EMetricTimeString.LAST_DAY:
      return {
        startDate: calculateDateBefore(startDate, 1, EMetricTimePeriod.DAY).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 1, EMetricTimePeriod.DAY).format(DATE_FORMAT),
      };
    case EMetricTimeString.LAST_7_DAYS:
      return {
        startDate: calculateDateBefore(startDate, 7, EMetricTimePeriod.DAY).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 7, EMetricTimePeriod.DAY).format(DATE_FORMAT),
      };
    case EMetricTimeString.LAST_28_DAYS:
      return {
        startDate: calculateDateBefore(startDate, 28, EMetricTimePeriod.DAY).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 28, EMetricTimePeriod.DAY).format(DATE_FORMAT),
      };
    case EMetricTimeString.LAST_3_MONTHS:
      return {
        startDate: calculateDateBefore(startDate, 3, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 3, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
      };
    case EMetricTimeString.LAST_6_MONTHS:
      return {
        startDate: calculateDateBefore(startDate, 6, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 6, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
      };
    case EMetricTimeString.LAST_12_MONTHS:
      return {
        startDate: calculateDateBefore(startDate, 12, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
        endDate: calculateDateBefore(endDate, 12, EMetricTimePeriod.MONTH).format(DATE_FORMAT),
      };
    case EMetricTimeString.CUSTOM_TIME:
      return {
        startDate: startDate.subtract(1, "days").format(DATE_FORMAT),
        endDate: endDate.subtract(1, "days").format(DATE_FORMAT),
      };
    default:
      throw new Error("Something wrong!");
  }
};

export const extractAndCombineVideoWatchedPurchased = (videoWatched: TMetricValue | undefined, videoPurchased: TMetricValue | undefined) => {
  if (videoWatched && videoPurchased) {
    return [...videoWatched, ...videoPurchased];
  } else if (videoWatched) {
    return videoWatched;
  } else if (videoPurchased) {
    return videoPurchased;
  }
  return [];
};

export const extractAndCombineDevices = (mobile: TMetricGroup | undefined, desktop: TMetricGroup | undefined, others: TMetricGroup | undefined) => {
  if (mobile && desktop && others) {
    return [...mobile, ...desktop, ...others];
  } else if (mobile && desktop) {
    return [...mobile, ...desktop];
  } else if (mobile && others) {
    return [...mobile, ...others];
  } else if (desktop && others) {
    return [...desktop, ...others];
  } else if (mobile) {
    return mobile;
  } else if (desktop) {
    return desktop;
  } else if (others) {
    return others;
  }
  return [];
};

export function round(date: string | number, duration: string | number, method: string) {
  return moment(Math.ceil(+date / +duration) * +duration);
}

export async function getMetric(
  setMetricFunction: React.Dispatch<React.SetStateAction<TMetric | undefined>>,
  apiFunction: (payload: IMetricPayload) => Promise<any>,
  setLoadingFunction: React.Dispatch<React.SetStateAction<boolean>>,
  payload: IMetricPayload,
  toggle?: boolean
) {
  if (toggle) {
    setLoadingFunction(true);
  }
  await Promise.all([apiFunction(payload)]).then(async (response) => {
    if (toggle) {
      setLoadingFunction(false);
    }

    let metricDictionary: TMetric = {};

    (response[0]?.data as Array<IMetricValue>).forEach((datum: IMetricValue) => {
      metricDictionary[datum?.ID] = datum?.VALUE;
    });

    setMetricFunction(metricDictionary);
  });
}

export const findExpandedRows = (expandedRows: { [key: React.Key]: boolean }): React.Key[] => {
  let rowsToReturn = [];
  for (let key in expandedRows) {
    if (expandedRows[key]) {
      rowsToReturn.push(key);
    }
  }

  return rowsToReturn;
}

export const createAbTestingTableData = (abTestResults: IAbTestingResults[], abTestMetrics: IAbTestingMetrics[]): IAbTestingDetails[] => {
  let newArray: IAbTestingDetails[] = [];

  if (abTestResults.length === 0 && abTestMetrics.length === 0) {
    return newArray;
  }
  abTestResults.forEach((result: IAbTestingResults) => {
    let newAbTestVideos: IAbTestingExpandedDetails[] = [];

    abTestMetrics.forEach((metric: IAbTestingMetrics) => {

      if (result["Meta"] === metric["PAGE_META"]) {
        let newAbTestVideo: IAbTestingExpandedDetails = {
          meta: metric["META"],
          name: metric["META"],
          avatarUrl: metric["avatarUrl"],
          CONVERSION_RATE: metric["CONVERSION_RATE"],
          ENGAGEMENT_RATE: metric["ENGAGEMENT_RATE"],
          AVG_VIEW_TIME: metric["AVG_VIEW_TIME"]
        };
        newAbTestVideos.push(newAbTestVideo);
      }
    })
    let newAbTest: IAbTestingDetails = {
      key: result["Meta"],
      meta: result["Meta"],
      name: result["Name"],
      date: "",
      status: result["Status"] as unknown as string,
      details: newAbTestVideos
    }
    if(newAbTestVideos.length > 0) {
        newArray.push(newAbTest);
    }
  });
  return newArray;
}

export const aggregateConversionRateByDevice = (data: IConversionRateByDevice[]): IConversionRateByDeviceTableDetails[] => {
  let result: IConversionRateByDeviceTableDetails[] = [];
  let trackingDict = Object()

  data.forEach((row) => {
    if (trackingDict[row?.PRODUCT_NAME] === undefined) {
      trackingDict[row?.PRODUCT_NAME] = { [row?.EVENT_DEVICE]: row?.CONVERSION_RATE }
    } else if (trackingDict[row?.PRODUCT_NAME][row?.EVENT_DEVICE] === undefined) {
      trackingDict[row?.PRODUCT_NAME][row?.EVENT_DEVICE] = row?.CONVERSION_RATE;
    }
  })

  // eslint-disable-next-line
  for (const [key] of Object.entries(trackingDict)) {
    result.push({
      product_name: key,
      desktop: trackingDict[key]['desktop'],
      mobile: trackingDict[key]['mobile']
    })
  }

  return result;
}

export const aggregateConversionRateByVisitorType = (data: IConversionRateByVisitorType[]): IConversionRateByVisitorTypeTableDetails[] => {
  let result: IConversionRateByVisitorTypeTableDetails[] = [];
  let trackingDict = Object();

  data.forEach((row) => {

    if (trackingDict[row?.DATA_PRODUCT] === undefined) {
      trackingDict[row?.DATA_PRODUCT] = { [row?.VISITOR_TYPE]: row?.VALUE }
    } else if (trackingDict[row?.DATA_PRODUCT][row?.VISITOR_TYPE] === undefined) {
      trackingDict[row?.DATA_PRODUCT][row?.VISITOR_TYPE] = row?.VALUE;
    }
  });

  // eslint-disable-next-line
  for (const [key] of Object.entries(trackingDict)) {
    result.push({
      PRODUCT_NAME: key,
      CONVERSION_NEW: trackingDict[key]['New'],
      CONVERSION_REPEAT: trackingDict[key]['Returning']
    })
  }

  return result;
}