import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Modal, Spin, Typography } from "antd";
import { toast } from "utils/toast";
import { apiCreateInteraction } from "services/interactions";
import { EInteractionCreationType } from "interfaces/schema";
import * as ROUTE_PATHS from "constants/routes";
import { ReactComponent as QuizInteraction } from "assets/img/quiz-interaction.svg";
import { ReactComponent as FormInteraction } from "assets/img/form-interaction.svg";
import { ReactComponent as CTAInteraction } from "assets/img/cta-interaction.svg";
import { ReactComponent as AddToCartSvg } from "assets/img/add-to-cart.svg";
import { PlusOutlined } from "@ant-design/icons";
import { PLATFORM } from "constants/store";
import { AppContext } from "context/app-context";

import "./index.scss";

const { Title, Text } = Typography;

const dataTestIdPrefix = "CreateInteractionModal";

interface Props {
    setShowCreateInteractionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateInteractionModal = ({ setShowCreateInteractionModal }: Props) => {
    const { shopDetails } = useContext(AppContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateNewInteraction = async (type: EInteractionCreationType) => {
        try {
            const response = await apiCreateInteraction({ type });
            navigate(`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/${response.interaction.meta}`);
        } catch (e) {
            console.log(e);
            toast.error("Failed to create new interaction");
        }
    };

    const handleCardClick = (type: EInteractionCreationType) => {
        setIsLoading(true);
        handleCreateNewInteraction(type);
    };

    return (
        <Modal
            className="g-create-interactions-modal"
            data-testid="Interactions-CreateInteractionModal"
            open
            onCancel={() => setShowCreateInteractionModal(false)}
            centered
            width={814}
            bodyStyle={{ height: 443 }}
            title={<Title level={3}>Create a New Interaction</Title>}
            footer={null}
        >
            {isLoading ? (
                <div className="g-create-interactions-body-container g-d-flex g-justify-center g-align-center g-h-100">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="g-create-interactions-body-container g-d-flex g-justify-center">
                    <Card
                        className="g-create-interactions-card-container"
                        hoverable
                        onClick={() => handleCardClick(EInteractionCreationType.EMPTY)}
                        data-testid={`${dataTestIdPrefix}-Card`}
                    >
                        <div className="g-create-interactions-card-empty-image">
                            <PlusOutlined />
                        </div>
                        <div className="g-create-interactions-card-text-container">
                            <Title level={4}>Start from Scratch</Title>
                            <Text type="secondary">Build a new interaction from scratch</Text>
                        </div>
                    </Card>
                    {shopDetails && (shopDetails["platform"].toLowerCase() === PLATFORM?.Shopify.toLowerCase() || shopDetails["platform"].toLowerCase() === PLATFORM?.ShopifyV2.toLowerCase()) && (
                        <Card
                            className="g-create-interactions-card-container"
                            hoverable
                            onClick={() => handleCardClick(EInteractionCreationType.ADD_TO_CART)}
                            data-testid={`${dataTestIdPrefix}-Card`}
                        >
                            <div className="g-create-interactions-card-empty-image">
                                <AddToCartSvg />
                            </div>
                            <div className="g-create-interactions-card-text-container">
                                <Title level={4}>Add To Cart</Title>
                                <Text type="secondary">Enable a user to add items to cart directly from the video</Text>
                            </div>
                        </Card>
                    )}
                    <Card
                        className="g-create-interactions-card-container"
                        hoverable
                        onClick={() => handleCardClick(EInteractionCreationType.QUIZ)}
                        data-testid={`${dataTestIdPrefix}-Card`}
                    >
                        <QuizInteraction />
                        <div className="g-create-interactions-card-text-container">
                            <Title level={4}>Quiz</Title>
                            <Text type="secondary">Get to know your site visitors by asking them questions</Text>
                        </div>
                    </Card>
                    <Card
                        className="g-create-interactions-card-container"
                        hoverable
                        onClick={() => handleCardClick(EInteractionCreationType.CALL_TO_ACTION)}
                        data-testid={`${dataTestIdPrefix}-Card`}
                    >
                        <CTAInteraction />
                        <div className="g-create-interactions-card-text-container">
                            <Title level={4}>Call to Action</Title>
                            <Text type="secondary">Encourage visitors to engage in action (ie add to cart, schedule a demo, etc)</Text>
                        </div>
                    </Card>
                    <Card
                        className="g-create-interactions-card-container"
                        hoverable
                        onClick={() => handleCardClick(EInteractionCreationType.FORM)}
                        data-testid={`${dataTestIdPrefix}-Card`}
                    >
                        <FormInteraction />
                        <div className="g-create-interactions-card-text-container">
                            <Title level={4}>Form</Title>
                            <Text type="secondary">Collect data from shoppers by allowing them to submit a response</Text>
                        </div>
                    </Card>
                </div>
            )}
        </Modal>
    );
};

export default CreateInteractionModal;
