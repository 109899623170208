import { IQuestion } from "interfaces/schema";
import { useEffect, useState } from "react";
import CloseSvgIcon from 'assets/img/close-icon.svg';
import { productMock } from "./mock";
import { toast } from "utils/toast";
import { getURLQueryParamsAsObject } from "utils/common-functions";

import "./index.scss";

interface IProductResponse {
    id: string;
    title: string;
    handle: string;
    vendor: string;
    price: number;
    available: boolean;
    price_varies: boolean;
    compare_at_price: number;
    variants: { id: number; title: string; option1: string; available: boolean; name: string; options: string[]; price: number; compare_at_price: null; }[];
    featured_image: string;
    options: { name: string; position: number; values: string[]; }[];
    url: string;
}

interface IProductObj {
    id: number;
    title: string;
    option1: string;
    available: boolean;
    name: string;
    options: string[];
    price: number;
    compare_at_price: null;
}


interface Props {
    question: IQuestion;
}

const PreviewAddToCartInteraction = ({ question }: Props) => {
    const [productData, setProductData] = useState<IProductResponse>(productMock);
    const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState<IProductObj>();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getProductVariantsAndOptions(question);
    }, [question]);

    const getProductVariantsAndOptions = async (question: IQuestion) => {
        const url = question?.choices[0]?.productUrl;
        if (!url || url.includes('Enter a URL')) {
            setProductData(productMock);
            setSelectedProduct(undefined);
            return;
        }
        try {
            const urlSplit = url?.split('?');
            setIsLoading(true);
            const response = await fetch(`${urlSplit[0]}.js`);
            const data = await response.json();
            setProductData(data);
            if (urlSplit[1]?.includes('variant=')) {
                const obj = getURLQueryParamsAsObject(urlSplit[1]);
                if (obj?.variant) {
                    const variantObj = data?.variants?.find((variant: any) => variant?.id === Number(obj?.variant));
                    setSelectedProduct(variantObj || undefined);
                } else {
                    setSelectedProduct(undefined);
                }
            }
        } catch (e: any) {
            // if error setting default value
            setProductData(productMock);
            setSelectedProduct(undefined);
            toast.warning("Invalid Product Url, Please check and update");
        } finally {
            setIsLoading(false);
        }
    }

    const toggleDropdown = () => {
        setIsProductDetailsOpen(!isProductDetailsOpen);
    }

    return (
        <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-container">
            <div className={`g-interaction-product-details ${isProductDetailsOpen ? ' active' : ''}`}>
                <div className="g-interaction-product-details-close" onClick={toggleDropdown}>
                    <img src={CloseSvgIcon} alt="close-icon" />
                </div>
                {!isLoading && <div>
                    <div className="g-interaction-product-details-price-img">
                        <img src={productData?.featured_image} alt={productData.title} />
                        <div className="g-interaction-product-price-div">
                            <span className="g-interaction-product-details-title-span" title={productData?.title}>
                                {productData?.title}
                            </span>
                            {productData?.price && (
                                <span className="g-interaction-product-details-price-span">
                                    ${(selectedProduct?.price || productData?.price) / 100}
                                    {productData?.compare_at_price && (
                                        <span className="g-interaction-product-details-compare-price-span">
                                            ${(selectedProduct?.compare_at_price || productData?.compare_at_price) / 100}
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                    {productData?.variants?.length > 1 && (
                        <div className="g-interaction-product-details-variant-dropdowns">
                            {productData?.options?.map((option, index) => (
                                <div key={option.position + index}>
                                    <select name={`option${index + 1}`} defaultValue={selectedProduct ? (selectedProduct as any)?.[`option${index + 1}`] : ""}>
                                        <option>Select {option.name}</option>
                                        {option?.values?.map((variant) => (
                                            <option key={variant + index} value={variant}>{variant}</option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-button-container" onClick={toggleDropdown} style={{ background: question.choices[0].buttonColor }}>
                        <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-button">
                            <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-text" style={{ color: question.choices[0].buttonTextColor }}>
                                {question.text}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>

            <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-button-container" onClick={toggleDropdown} style={{ background: question.choices[0].buttonColor }}>
                <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-button">
                    <div className="g-interaction-edit-preview-panel-add-to-cart-interaction-text" style={{ color: question.choices[0].buttonTextColor }}>
                        {question.text}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviewAddToCartInteraction;