import axios from "axios";
import { ISubscriptionPlan } from "interfaces";

const { REACT_APP_API_URL } = process.env;

export const apiGetSubscriptionPlans = async (): Promise<{ plans: ISubscriptionPlan[] }> => {
  try {
    const url = `${REACT_APP_API_URL}/plans`;
    const response = await axios.get(url);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiGetPlanSelectRedirectLink = async (planId: string, planMeta: string): Promise<any> => {
  try {
    const url = `${REACT_APP_API_URL}/plans`;
    const response = await axios.post(url, { id: planId, meta: planMeta });
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiGetCurrentActivePlan = async (): Promise<any> => {
  try {
    const url = `${REACT_APP_API_URL}/get-active-plan`;
    const response = await axios.get(url);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiPostCancelActivePlan = async (subscriptionId: string): Promise<any> => {
  try {
    const url = `${REACT_APP_API_URL}/cancel-plan`;
    const response = await axios.post(url, { subscriptionId });
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};