import UploadAndLinkVideosHeader from "./upload-and-link-videos-header";
import UploadAndLinkVideosForm from "./upload-and-link-videos-form";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import apiValidateAndGetShopByExternalUploadId from "services/public/get-shop-detail-from-external-upload-id";
import { IShopDetailsPayload } from "./interface";

import "./index.scss";

interface IShopDetails extends IShopDetailsPayload {
    isFreePlan?: boolean;
}
const UploadAndLinkVideos = () => {
    const [searchParams] = useSearchParams();
    const [shopDetail, setShopDetail] = useState<IShopDetails | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const bodyTag = document.getElementsByTagName('body')[0];
        bodyTag.style.overflow = 'auto';

        fetchAndValidateShopWithExternalUploadId();

        return () => {
            bodyTag.style.overflow = 'hidden';
        };
    }, []);

    const fetchAndValidateShopWithExternalUploadId = async () => {
        try {
            setIsLoading(true);
            const externalUploadId = searchParams.get("externalUploadId");
            if (externalUploadId) {
                const shopDetailRes: IShopDetailsPayload = await apiValidateAndGetShopByExternalUploadId(externalUploadId);
                setShopDetail({ ...shopDetailRes, externalVideoUploadId: externalUploadId })
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="g-public-external-video-upload-page">
            <UploadAndLinkVideosHeader shopDetail={shopDetail} />
            <UploadAndLinkVideosForm shopDetail={shopDetail} setShopDetail={setShopDetail} isShopDetailLoading={isLoading} />
        </div>
    );
};

export default UploadAndLinkVideos;
