import axios from "axios";
import { ISoMeLicenseDetail } from "interfaces";

const { REACT_APP_API_URL } = process.env;

export const apiGetSoMeLicenseDetails = async (crMeta: string): Promise<ISoMeLicenseDetail> => {
  try {
    const url = `${REACT_APP_API_URL}/so-me-license?crMeta=${crMeta}`;
    const response = await axios.get(url);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiPostSoMeLicensePurchase = async (videoMeta: string) => {
  try {
    const url = `${REACT_APP_API_URL}/so-me-license`;
    const response = await axios.post(url, { meta: videoMeta });
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};