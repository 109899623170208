import { Button, List, Spin, Typography } from "antd";
import { IPage } from "interfaces";
import LinkVideoToPageComponent from "./link-video-to-page-component";
import LinkedPagesListItemComponent from "./linked-pages-list-item-component";
import VideoDetailDrawerPreviewContainer from "../video-detail-drawer-preview-container";
import { IVideo } from "interfaces/schema";

const { Title } = Typography;
const dataTestIdPrefix = "Videos-LinkVideoToPageTabComponent";

interface Props {
    onRemoveAllPage: any;
    onRemoveSinglePage: any;
    isLoading: boolean;
    viewVideoDetailsDrawer: IVideo;
    openViewVideoDetailsDrawer: (vid: IVideo | undefined) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkVideoToPageTab = ({ isLoading, viewVideoDetailsDrawer, onRemoveAllPage, onRemoveSinglePage, openViewVideoDetailsDrawer, setIsLoading }: Props) => {
    return (
        <>
            <div className="g-video-details-drawer-body-wrapper" data-testid={`${dataTestIdPrefix}-DrawerBody`}>
                {isLoading && (
                    <div className="g-add-edit-video-details-drawer-loader-container">
                        <Spin size="large" className="g-add-edit-video-details-drawer-loader" />
                    </div>
                )}
                <div className="g-add-edit-video-details-drawer-content-container g-d-flex">
                    <div className="g-add-edit-video-details-form">
                        <LinkVideoToPageComponent
                            viewVideoDetailsDrawer={viewVideoDetailsDrawer}
                            openViewVideoDetailDrawer={openViewVideoDetailsDrawer}
                            setDetailsLoading={setIsLoading}
                        />
                        <div className="g-add-edit-video-details-form-field-title">
                            <Title level={3}>Connected Pages</Title>
                            {/* TODO: Add bubble with page count */}
                            {viewVideoDetailsDrawer?.pages?.length > 0 && (
                                <Button danger type="text" onClick={onRemoveAllPage} data-testid={`${dataTestIdPrefix}-LinkedPagesCardDeleteAllButton`}>
                                    Remove All
                                </Button>
                            )}
                        </div>
                        <div className="g-add-edit-video-details-connected-video-section">
                            <div className="g-list-header">
                                <span>Linked To</span>
                                <span>Action</span>
                            </div>
                            <div className="g-add-edit-video-details-list">
                                <List
                                    data-testid={`${dataTestIdPrefix}-LinkedPagesCardContainer`}
                                    className="g-list-with-video-previews g-w-100"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "No pages linked" }}
                                    dataSource={viewVideoDetailsDrawer?.pages}
                                    renderItem={(page: IPage) => (
                                        <LinkedPagesListItemComponent key={page?.meta} row={page} onRemoveVideo={onRemoveSinglePage} />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="g-video-details-drawer-preview-container">
                    <VideoDetailDrawerPreviewContainer viewVideoDetailsDrawer={viewVideoDetailsDrawer} dataTestIdPrefix={dataTestIdPrefix} />
                </div>
            </div>
        </>
    );
};

export default LinkVideoToPageTab;
