import { ReactNode, useEffect, useState } from "react";
import { Avatar, Select, Spin, Tooltip } from "antd";
import useDebounce from "hooks/useDebounce";
import { IProduct } from "interfaces";
import { apiGetProductsByInput } from "services/shopify.service";
import { toast } from "utils/toast";
import { DefaultOptionType } from "antd/lib/select";
import { MAX_PRODUCTS } from "../../../constants";
import { IProductsResponse } from "interfaces/schema";
import { shopifyGIDdecode } from "utils/common-functions";

import './index.scss';

const { Option } = Select;
const dataTestIdPrefix = "Videos-EditVideoDetailsComponent-AttachProducts";


interface Props {
  onSelectProduct: (product: IProduct) => Promise<void>;
  products: IProduct[];
}

const LinkVideoToExistingProductComponent = ({ onSelectProduct, products }: Props) => {
  const [input, setInput] = useState("");
  const [productsResponse, setProductsResponse] = useState<IProductsResponse[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedInput = useDebounce(input, 500);

  useEffect(() => {
    if (debouncedInput) {
      getProductByInput();
    } else {
      setProductsResponse([]);
    }
  }, [debouncedInput]);

  const getProductByInput = async () => {
    try {
      setIsSearching(true);
      const { data } = await apiGetProductsByInput(debouncedInput);
      setIsSearching(false);
      const filteredData = data.map((product: IProductsResponse) => {
        const nodes: any = product.variants.nodes.filter((node) => {
          return !products.find((p) => p.variantId === node.id);
        });
        product.variants.nodes = nodes;
        return product;
      });
      setProductsResponse(filteredData);
    } catch (e: any) {
      console.log(e);
      setIsSearching(false);
      toast.error(e?.message);
    }
  };

  const notFoundContentComponent = input === debouncedInput && debouncedInput && (
    <div className='g-add-edit-page-details-no-found-content' datatest-id={`${dataTestIdPrefix}-Not-Found`}>
      No product found for input name
    </div>
  );

  const dropdownRenderComponent = (originNode: ReactNode) => isSearching
    ? (
      <div className='g-add-edit-page-details-spinner-container'>
        <Spin className='g-mx-8' size="small" />
      </div>
    ) : (
      <div className='g-add-edit-page-details-items-container'>
        {originNode}
      </div>
    );

  const handleOnSelect = async (_key: string, { id, title, featuredImage, onlineStoreUrl, onlineStorePreviewUrl, variantId }: DefaultOptionType) => {
    const newProduct = {
      id: id,
      name: title,
      thumbnail: featuredImage,
      productUrl: `${onlineStoreUrl ? onlineStoreUrl : onlineStorePreviewUrl}?variant=${shopifyGIDdecode(variantId)?.id}`,
      variantId: variantId
    }
    onSelectProduct(newProduct);
    setInput("");
  }


  const getProductName = (productTitle: string, variantTitle: string) => {
    if (variantTitle === 'Default Title') {
      return `${productTitle}`;
    }
    return `${productTitle} - ${variantTitle}`;
  }


  return (
    <Tooltip title={products.length >= MAX_PRODUCTS ? "Maximum number of products allowed" : undefined}>
      <Select
        data-testid={`${dataTestIdPrefix}-Select`}
        className="g-custom-search-select"
        size="large"
        showSearch
        autoClearSearchValue
        placeholder="Enter Product Name"
        notFoundContent={notFoundContentComponent}
        showArrow={false}
        filterOption={false}
        dropdownRender={dropdownRenderComponent}
        onSearch={setInput}
        onSelect={handleOnSelect}
        disabled={products.length >= MAX_PRODUCTS}
        value={input ? '' : null}
      >
        {productsResponse.map(({ title: productTitle, id: productId, featuredImage, onlineStoreUrl, onlineStorePreviewUrl, variants }) => (
          variants.nodes.map(({ title: variantTitle, id: variantId, image: variantImage }) =>
            <Option
              key={variantId}
              id={productId}
              title={getProductName(productTitle, variantTitle)}
              onlineStoreUrl={onlineStoreUrl}
              onlineStorePreviewUrl={onlineStorePreviewUrl}
              featuredImage={variantImage?.url || featuredImage?.url}
              data-testid={`${dataTestIdPrefix}-Option`}
              variantId={variantId}
            >
              <Avatar shape='square' src={variantImage?.url || featuredImage?.url} />
              &nbsp;{getProductName(productTitle, variantTitle)}
            </Option>
          )
        ))}
      </Select>
    </Tooltip>
  );
}

export default LinkVideoToExistingProductComponent;


