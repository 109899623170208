import axios from "axios";
import { IGetPageApiPayload } from "interfaces";

const { REACT_APP_API_URL } = process.env;
const PAGES_API_URL = `${REACT_APP_API_URL}/pages`;

export const apiGetPages = async (payload: IGetPageApiPayload): Promise<any> => {
  try {
    const url = `${PAGES_API_URL}/get-pages`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiUnlinkVideoPage = async (pageVideoMeta: string): Promise<any> => {
  try {
    const url = `${PAGES_API_URL}/unlink-video-page`;
    const response = await axios.delete(url, { params: { pageVideoMeta: pageVideoMeta } });
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiLinkVideoAndPage = async (payload: any): Promise<any> => {
  try {
    const url = `${PAGES_API_URL}/link-video-page`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};
