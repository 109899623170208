import axios from "axios";
import { IWidgetSettings } from "interfaces";

const { REACT_APP_API_URL } = process.env;
const WIDGET_SETTINGS_API_URL = `${REACT_APP_API_URL}/widget`;

type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T;

export const apiGetWidgetSettings = async (): Promise<any> => {
    try {
        const url = `${WIDGET_SETTINGS_API_URL}/get-global-widget-settings`;
        const response = await axios.get(url);
        return response?.data;
    } catch (e: any) {
        console.log(e);
        throw e?.response?.data ? e?.response?.data : e;
    }
};

interface IRawWidgetSettings extends IWidgetSettings {
    id: string;
    meta: string;
    dataType: string;
    createdAt: number;
    updatedAt: number;
}

interface Response {
    data: IRawWidgetSettings;
    message: string;
}

export const apiUpdateGlobalWidgetSettings = async (updatePayload: DeepPartial<IWidgetSettings>): Promise<Response> => {
    try {
        const url = `${WIDGET_SETTINGS_API_URL}/update-global-widget-setting`;
        const response = await axios.put(url, { ...updatePayload });
        return response?.data;
    } catch (e: any) {
        throw e?.response?.data ? e?.response?.data : e;
    }
};
