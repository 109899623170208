const PUBLIC_ROUTES = {
    PUBLIC_ROOT: '/public',
    EXTERNAL_VIDEO_UPLOAD_AND_LINK_TO_PAGE: '/public/upload-and-link-videos',
};

const AUTH_ROUTES = {
    AUTH_ROOT: '/auth',
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
};

const PRIVATE_ROUTES = {
    ROOT: { PATH: '/', TITLE: 'Videos' },
    DASHBOARD: { PATH: '/dashboard', TITLE: 'Dashboard' },
    VIDEOS: { PATH: '/videos', TITLE: 'Videos' },
    PAGES: { PATH: '/pages', TITLE: 'Pages' },
    CUSTOMIZATION: { PATH: '/customization', TITLE: 'Customization' },
    REQUEST_CONTENT: { PATH: '/request-content', TITLE: 'Request Content' },
    SETTINGS: { PATH: '/settings', TITLE: 'Settings' },
    INTERACTIONS: { PATH: '/interactions', TITLE: 'Interactions' },
}

const PRIVATE_ROUTES_FULL_PAGE = {
    ACCOUNT_SETUP: { PATH: '/account-setup', TITLE: 'Account Set Up' },
};

const isAuthRoutes = (url: any) => {
    return Object.values(AUTH_ROUTES).includes(url);
};

export { PRIVATE_ROUTES, PRIVATE_ROUTES_FULL_PAGE, AUTH_ROUTES, PUBLIC_ROUTES, isAuthRoutes }