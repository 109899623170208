import { useEffect, useState } from "react";
import { Typography, Spin, Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import LayoutSettings from "./layout-settings-component";
import OverlayPreviewStateSettings from "./overlay-preview-state-settings-component";
import EmbeddedPreviewStateSettings from "./embedded-preview-state-settings-component";
import EmbeddedOpenStateSettings from "./embedded-open-state-settings-component";
import OverlayOpenStateSettings from "./overlay-open-state-settings-component";
import PositionSettings from "./position-settings-component";
import ColorSettings from "./color-settings-component";
import EmbeddedTextSettings from "./embedded-text-settings-component";
import OverlayTextSettings from "./overlay-text-settings-component";
import { EOverlayStartingState, EWidgetType, IDeviceSettings } from "interfaces/schema";
import OverlayClosedStateSettings from "./overlay-closed-state-settings-component";
import { EDeviceType, EOverridePreviewState } from "interfaces";

import "./index.scss";

const { Text } = Typography;
const DEFAULT_PANELS = ["layout"];
const EXCLUDE_PANELS_FOR_EMBEDDED = ["closed-state"];

interface IProps {
    isLoading: boolean;
    device: EDeviceType;
    deviceSettings?: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const DeviceSettings = ({ isLoading, device, deviceSettings, handleDeviceSettingsUpdate }: IProps) => {
    const [panels, setPanels] = useState<{ key: string; title: string; children: JSX.Element | null }[]>([]);
    const [activeKeys, setActiveKeys] = useState<string[]>(DEFAULT_PANELS);

    useEffect(() => {
        if (!deviceSettings) {
            return;
        }

        let pans = [
            {
                key: "layout",
                title: "Layout",
                children: <LayoutSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />,
            },
            {
                key: "preview-state",
                title: "Preview State",
                children:
                    deviceSettings?.widgetType === EWidgetType.EMBEDDED ? (
                        <EmbeddedPreviewStateSettings device={device} deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                    ) : (
                        <OverlayPreviewStateSettings device={device} deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                    ),
            },
            {
                key: "open-state",
                title: "Open State",
                children:
                    deviceSettings?.widgetType === EWidgetType.EMBEDDED ? (
                        <EmbeddedOpenStateSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                    ) : (
                        <OverlayOpenStateSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                    ),
            },
            {
                key: "closed-state",
                title: "Closed State",
                children: <OverlayClosedStateSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />,
            },
            {
                key: "position",
                title: "Position",
                children: <PositionSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />,
            },
            {
                key: "colors",
                title: "Colors",
                children: <ColorSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />,
            },
            {
                key: "text",
                title: "Text",
                children:
                    deviceSettings?.widgetType === EWidgetType.EMBEDDED ? (
                        <EmbeddedTextSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                    ) : (
                        deviceSettings?.previewOverlayStartingState === EOverlayStartingState.MUTED_GIF ? (
                            <OverlayTextSettings deviceSettings={deviceSettings} handleDeviceSettingsUpdate={handleDeviceSettingsUpdate} />
                        ) : null
                    ),
            },
        ];

        if (deviceSettings?.widgetType === EWidgetType.EMBEDDED) {
            pans = pans.filter((o) => !EXCLUDE_PANELS_FOR_EMBEDDED.includes(o.key));
        }
        setPanels(pans);
    }, [deviceSettings]);

    return (
        <>
            <div className="g-preview-state-page-settings-container">
                {isLoading ? (
                    <div className="g-preview-state-settings-loader">
                        <Spin />
                    </div>
                ) : (
                    <Collapse activeKey={activeKeys} ghost onChange={(key) => Array.isArray(key) && setActiveKeys(key)}>
                        {panels.map(({ key, title, children }) => {
                            if (children === null) {
                                return null;
                            }

                            return (
                                <CollapsePanel key={key} header={<Text className="g-customization-setting-section-header">{title}</Text>}>
                                    <div className="g-customization-settings-container">{children}</div>
                                </CollapsePanel>
                            );
                        })}
                    </Collapse>
                )}
            </div>
        </>
    );
};

export default DeviceSettings;
