import { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { IShopDetails, ISubscriptionPlan } from "interfaces";
import { toast } from "utils/toast";
import { apiGetPlanSelectRedirectLink } from "services/subscription.services";
import { AppContext } from "context/app-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as ROUTE_PATHS from 'constants/routes';
import { isActivePlan } from "utils/is-active-plan";
import { apiCreateGanderAccessCheckoutSession } from "services/stripe";
import SelectPlanComponent from "./select-plan-component";
import { Auth } from 'aws-amplify';
import { PLATFORM } from "constants/store";
import InstallShopifyAppModal from "./install-app-modal";
import { apiSyncShopifyDetails } from "services/shopify.service";

import './index.scss';

enum ESelectPlanTab {
    SELECT_PLAN = "select-plan",
    CONFIRM_PAYMENT = "confirm-payment",
}

const AccountSetupPage = () => {
    const navigate = useNavigate();
    const { activeSubscription, shopDetails } = useContext(AppContext);
    const [selectedPlan, setSelectedPlan] = useState<ISubscriptionPlan | undefined>();
    const [isSubscriptionSelectPlansLoading, setIsSubscriptionSelectPlansLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShowInstallAppModal, setIsShowInstallAppModal] = useState(false);

    useEffect(() => {
        if (isActivePlan(shopDetails, activeSubscription)) {
            setTimeout(() => {
                navigate(ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.PATH);
            }, 1);
        }
        const shopifyV2State = searchParams.get("shopify-v2-state");
        const shopifyShopUrl = searchParams.get("shop");
        const shopAccessToken = searchParams.get("accessToken");

        // deleting query params
        searchParams.delete('shopify-v2-state');
        searchParams.delete('shop');
        searchParams.delete('accessToken');
        setSearchParams(searchParams);

        if (shopifyV2State === "success" && shopifyShopUrl && shopAccessToken && shopDetails) {
            handleShopifyV2ConnectionSuccess(shopifyShopUrl, shopAccessToken, shopDetails);
        } else if (shopifyV2State === "fail") {
            handleShopifyV2ConnectionFail();
        } else if (shopDetails && !shopDetails?.shopifyUrl && shopDetails.platform === PLATFORM.ShopifyV2) {
            setIsShowInstallAppModal(true);
        }
    }, [shopDetails]);

    useEffect(() => {
        if (selectedPlan?.id && selectedPlan?.meta) {
            getSelectedPlanPurchaseLink(selectedPlan);
        }
    }, [selectedPlan]);

    const handleShopifyV2ConnectionSuccess = async (shopifyShopUrl: string, shopAccessToken: string, shop: IShopDetails) => {
        try {
            await apiSyncShopifyDetails(shopifyShopUrl, shopAccessToken);
            toast.success('Shopify account connected successfully');
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
            return;
        }

        const authenticatedUser = await Auth.currentAuthenticatedUser();

        // to handle logged in as someone user
        if (authenticatedUser.attributes.website !== shop.domain) {
            console.log("🚀 Skipping user attributes update as user must be login as feature-flag user");
            return;
        }

        const updatePayload = {
            "custom:shopifyAccessToken": shopAccessToken,
            "custom:shopifyUrl": shopifyShopUrl
        };
        await Auth.updateUserAttributes(authenticatedUser, updatePayload);

        // Auth refetch from cognito
        Auth.currentAuthenticatedUser({ bypassCache: true });
    }

    const handleShopifyV2ConnectionFail = () => {
        toast.error('Shopify account connection failed.');
        Auth.currentAuthenticatedUser({ bypassCache: true });
    }

    const getSelectedPlanPurchaseLink = async ({ id, meta, trialDays, stripeGanderAccessPriceId }: ISubscriptionPlan) => {
        setIsSubscriptionSelectPlansLoading(true);
        try {
            if (shopDetails && shopDetails["platform"] === "shopify") {
                const response = await apiGetPlanSelectRedirectLink(id, meta);

                if (response?.confirmationUrl) {
                    window.open(response?.confirmationUrl, "_self");
                }
            } else {
                const response = await apiCreateGanderAccessCheckoutSession(meta, trialDays, stripeGanderAccessPriceId);

                if (response?.checkoutSession?.url) {
                    window.open(response?.checkoutSession?.url, "_self");
                }
            }
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            // setIsSubscriptionSelectPlansLoading(false); not disabling loader
        }
    }

    return (
        <>
            <div className='g-account-setup-tabs-main'>
                <Tabs
                    className='g-account-setup-tabs-container'
                    defaultActiveKey={ESelectPlanTab.SELECT_PLAN}
                    items={[
                        {
                            label: "1. Select Plan",
                            key: ESelectPlanTab.SELECT_PLAN,
                            children: <>
                                <SelectPlanComponent
                                    selectedPlan={selectedPlan}
                                    setSelectedPlan={setSelectedPlan}
                                    isSubscriptionSelectPlansLoading={isSubscriptionSelectPlansLoading}
                                />
                            </>
                        },
                        {
                            label: "2. Confirm Payment",
                            key: ESelectPlanTab.CONFIRM_PAYMENT,
                            children: <></>,
                            disabled: true
                        }
                    ]}
                />
            </div>
            {isShowInstallAppModal && <InstallShopifyAppModal shopDetailsDomain={shopDetails?.domain!} />}
        </>
    )
}

export default AccountSetupPage