import { Button, Input, Spin, Table, Upload } from "antd";
import { PlayVideoIcon, UploadBoxIcon, VideoTableDeleteButtonIcon } from "components/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { RcFile } from "antd/lib/upload";
import { toast } from "utils/toast";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import SelectedUploadVideoPreviewModal from "../selected-upload-video-preview-modal";
import apiPostStoreExternalVideoUpload from "services/public/store-external-video-upload";
import { IUploadFile } from "interfaces";
import { getIsVideosProductUrlValid, isValidFullName, isValidUrl, previewFileThumbnail, uploadVideo, validateEmail } from "../utils";
import { IExternalVideoSubmitPayload, IPayloadVideo, IShopDetailsPayload } from "../interface";

import "./index.scss";

const allowedUploadFileTypes = ["video/mp4", "video/mov", "video/quicktime"];
const fileUploadSizeLimitInMB = 300;
const totalUploadAllowed = 5;
const dataTestIdPrefix = "ExternalUpload-Form";


interface IShopDetails extends IShopDetailsPayload {
    isFreePlan?: boolean;
}
interface IProps {
    shopDetail: IShopDetails | undefined;
    setShopDetail: React.Dispatch<React.SetStateAction<IShopDetails | undefined>>;
    isShopDetailLoading: boolean;
}

const UploadAndLinkVideosForm = ({ shopDetail, setShopDetail, isShopDetailLoading }: IProps) => {
    const [fullName, setFullName] = useState({ value: "", validationError: "" });
    const [emailAddress, setEmailAddress] = useState({ value: "", validationError: "" });

    const filesListRef = useRef<IUploadFile[]>([]);
    const [fileList, setFileList] = useState<IUploadFile[]>([]);
    const [ShowPreviewVideo, setShowPreviewVideo] = useState<IUploadFile | undefined>(undefined);
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const isVideoProductUrlValid = useMemo(() => getIsVideosProductUrlValid(fileList), [fileList]);

    useEffect(() => {
        if (formSubmitLoading) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }

        return () => { window.onbeforeunload = null; };
    }, [formSubmitLoading]);

    const columns = [
        {
            title: "VIDEO",
            dataIndex: "video",
            key: "video",
            render: (video: string, vidRec: IUploadFile) => (
                <div className="g-video-preview-container">
                    <img className="g-video-preview-thumbnail" src={vidRec?.hdThumbnail} alt="preview" />
                    <div className="g-video-preview-thumbnail-overlay" onClick={() => setShowPreviewVideo(vidRec)}>
                        <div className={"g-play-button-tiny"}>
                            <PlayVideoIcon />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "PRODUCT URL",
            dataIndex: "productUrl",
            key: "productUrl",
            render: (productUrl: string, video: IUploadFile) => (
                <div className="g-public-upload-product-url-input-container">
                    <div style={{ width: "100%" }}>
                        <Input
                            data-testid={`${dataTestIdPrefix}-videos-table-product-url-input`}
                            className="g-public-upload-product-url-input"
                            style={{ height: "48px" }}
                            placeholder="Product Page URL"
                            value={productUrl}
                            onChange={(e) => updateProductUrl(e?.target?.value, "", video?.uid)}
                            status={video?.productUrlValidationError && "error"}
                            disabled={formSubmitLoading}
                        />
                    </div>
                    <Button
                        ghost
                        size="small"
                        icon={<VideoTableDeleteButtonIcon />}
                        className="g-public-upload-product-url-delete-button"
                        onClick={() => removeVideo(video?.uid)}
                        disabled={formSubmitLoading}
                    />
                </div>
            ),
        },
    ];

    const removeVideo = (videoUid: string) => {
        const filteredFileList = fileList?.filter((fle: IUploadFile) => fle?.uid !== videoUid);
        filesListRef.current = filteredFileList;
        setFileList(filteredFileList);
    };

    const updateProductUrl = (updatedProductUrl: string, updatedProductUrlErrorMsg: string, videoUid: string) => {
        const filteredFileList = fileList?.map((fle: IUploadFile) => {
            if (fle?.uid === videoUid) {
                fle.productUrl = updatedProductUrl;
                fle.productUrlValidationError = updatedProductUrlErrorMsg;
            }
            return fle;
        });
        filesListRef.current = filteredFileList;
        setFileList(filteredFileList);
    };

    const showMaxFileUploadCountValidationError = debounce(() => {
        toast.error(`You can only upload ${totalUploadAllowed} videos simultaneously. Please try again.`);
    }, 1000);

    const beforeUpload = async (file: RcFile, FileList: RcFile[]) => {
        if (FileList?.length > totalUploadAllowed || fileList?.length >= totalUploadAllowed || fileList?.length + FileList?.length > totalUploadAllowed) {
            showMaxFileUploadCountValidationError();
            return false;
        }

        const isFileTypeAllowed = allowedUploadFileTypes.includes(file?.type);
        if (!isFileTypeAllowed) {
            toast.error(`Video ${file?.name} is invalid, You can only upload MP4/MOV video file!`);
            return false;
        }

        if (file?.size === 0) {
            toast.error(`Video ${file?.name} is invalid, Video has no content with size of 0 KB!`);
            return false;
        }

        const isLessThanSizeLimit = file?.size < 1024 * 1024 * fileUploadSizeLimitInMB;
        if (!isLessThanSizeLimit) {
            toast.error(`Video ${file?.name} is invalid, Video must be smaller than ${fileUploadSizeLimitInMB} MB!`);
            return false;
        }
        const hdThumbnail: unknown = await previewFileThumbnail(file);
        const fileObj: IUploadFile = {
            uid: file?.uid,
            originFileObj: file,
            name: file?.name,
            size: file?.size,
            type: file?.type,
            percent: 0,
            productUrl: "",
            productUrlValidationError: "",
            hdThumbnail: hdThumbnail as string,
            meta: uuidv4(),
            status: "uploading",
        };

        filesListRef.current = [...filesListRef.current, fileObj];
        setFileList(filesListRef.current);
    };

    const validateAndSubmitForm = async () => {
        const formObj: IExternalVideoSubmitPayload = {
            externalId: shopDetail?.externalVideoUploadId!,
            uploaderDetails: {
                fullName: fullName?.value.trim(),
                email: emailAddress?.value,
            },
            videos: [],
        };

        formObj.videos = fileList?.map((fileData: IUploadFile) => {
            const fileExtension = fileData?.originFileObj?.name?.split(".").pop();
            const payloadVideo: IPayloadVideo = {
                uid: fileData?.uid,
                meta: fileData?.meta!,
                name: fileData?.originFileObj?.name,
                originalMediaUrl: `${fileData?.meta}.${fileExtension}`,
                size: fileData?.originFileObj?.size,
                productUrl: fileData?.productUrl!,
            };
            return payloadVideo;
        });

        const isValid = await validateForm(formObj);

        if (isValid && formObj?.videos?.length > 0) {
            formObj?.videos?.map((vid: Partial<IPayloadVideo>) => {
                const urlStringUpdated = vid?.productUrl?.includes("https://") ? vid?.productUrl : `https://${vid?.productUrl}`;
                const productPathName = new URL(urlStringUpdated).pathname;
                vid.productUrl = productPathName;
                delete vid?.uid;
                return vid;
            });
            submitExternalVideoUploadForm(formObj);
        }
    };

    const validateForm = (formObj: IExternalVideoSubmitPayload) => {
        return new Promise((resolve, reject) => {
            let isFormValid = true;

            const fullNameValue = formObj?.uploaderDetails?.fullName;
            if (!isValidFullName(fullNameValue)) {
                setFullName({ value: fullNameValue, validationError: "Please enter your full name." });
                isFormValid = false;
            }
            const emailAddressValue = formObj?.uploaderDetails?.email;
            if (!emailAddressValue || emailAddressValue === "" || !validateEmail(emailAddressValue)) {
                setEmailAddress({ value: emailAddressValue, validationError: "Email is not valid." });
                isFormValid = false;
            }

            if (!formObj?.videos?.length) {
                if (isFormValid) {
                    toast.error("Please attach videos and product urls before submitting the form.");
                }
                isFormValid = false;
            } else {
                formObj?.videos?.forEach((vid: IPayloadVideo) => {
                    // console.log('is invalid url',!isValidUrl(vid?.productUrl, shopDetail?.domain!));
                    if (!isValidUrl(vid?.productUrl, shopDetail?.domain!)) {
                        updateProductUrl(vid?.productUrl, "Enter valid product URL.", vid?.uid);
                        isFormValid = false;
                    }
                });
            }

            return resolve(isFormValid);
        });
    };

    const submitExternalVideoUploadForm = async (payload: IExternalVideoSubmitPayload) => {
        try {
            setFormSubmitLoading(true);
            await apiPostStoreExternalVideoUpload(payload);
            startUploadProgress();
        } catch (e: any) {
            toast.error(e?.message);
            setFormSubmitLoading(false);
        }
    };

    const startUploadProgress = async () => {
        const fileUploadPromises = fileList?.map(async (fileRecord: IUploadFile) => {
            return uploadVideo(fileRecord?.originFileObj, fileRecord, filesListRef, setFileList, shopDetail);
        });
        console.log("fileUploadPromises ", fileUploadPromises);
        await Promise.all(fileUploadPromises);
        setFileList([]);
        setFullName({ value: "", validationError: "" });
        setEmailAddress({ value: "", validationError: "" });
        filesListRef.current = [];
        toast.success("External video uploaded and saved successfully.");
        if (shopDetail?.isFreePlan) {
            setShopDetail(undefined);
        }
        setFormSubmitLoading(false);
    };

    return (
        <div className="g-upload-and-link-videos-form-component">
            {isShopDetailLoading ? (
                <div className="g-upload-and-link-videos-form" style={{ height: "400px", justifyContent: "center" }}>
                    <Spin />
                </div>
            ) : (
                <>
                    {!shopDetail?.domain ? (
                        <div className="g-upload-and-link-videos-form" style={{ height: "400px", justifyContent: "center" }}>
                            <div>This external video upload URL is invalid!</div>
                            <div>Please contact shop admin for further assistance.</div>
                        </div>
                    ) : (
                        <div className="g-upload-and-link-videos-form">
                            <div className="g-public-upload-form-main-title">Content Upload</div>
                            <div className="g-public-upload-form-sub-title">
                                Please use this form to upload your video(s) to {shopDetail?.companyName ?? shopDetail?.domain}'s account
                            </div>

                            <div className="g-public-upload-form-input-box">
                                <div className="g-public-upload-form-title">Full Name</div>
                                <Input
                                    data-testid={`${dataTestIdPrefix}-full-name-input`}
                                    className="g-public-upload-form-input"
                                    placeholder="Full Name"
                                    value={fullName?.value}
                                    onChange={(e) => setFullName({ value: e?.target?.value, validationError: "" })}
                                    status={fullName?.validationError && "error"}
                                    disabled={formSubmitLoading}
                                />
                                <div className="g-upload-validation-error-text" data-testid={`${dataTestIdPrefix}-full-name-input-error`}>
                                    {fullName?.validationError}
                                </div>
                            </div>

                            <div className="g-public-upload-form-input-box">
                                <div className="g-public-upload-form-title">Email</div>
                                <Input
                                    data-testid={`${dataTestIdPrefix}-email-input`}
                                    className="g-public-upload-form-input"
                                    placeholder="hi@example.com"
                                    value={emailAddress?.value}
                                    onChange={(e) => setEmailAddress({ value: e?.target?.value.trim(), validationError: "" })}
                                    status={emailAddress?.validationError && "error"}
                                    disabled={formSubmitLoading}
                                />
                                <div className="g-upload-validation-error-text" data-testid={`${dataTestIdPrefix}-email-input-error`}>
                                    {emailAddress?.validationError}
                                </div>
                            </div>

                            <div className="g-upload-video-upload-container" data-testid={`${dataTestIdPrefix}-upload-component`}>
                                <Upload
                                    className="g-upload-video-upload-box"
                                    fileList={fileList as any}
                                    beforeUpload={beforeUpload}
                                    disabled={formSubmitLoading || (shopDetail?.isFreePlan && fileList?.length >= 1)}
                                    listType="text"
                                    multiple={shopDetail?.isFreePlan ? false : true}
                                    showUploadList={formSubmitLoading}
                                >
                                    <div className="g-upload-video-upload-box-content">
                                        <div className="g-upload-video-upload-box-content-icon">
                                            <UploadBoxIcon />
                                        </div>
                                        <div className="g-upload-video-upload-box-content-text">
                                            <div className="g-upload-video-upload-box-title">
                                                <b>Drag & Drop Files, or</b>
                                                <b className="g-primary-description-text g-mx-4">Browse</b>
                                            </div>
                                            <div className="g-upload-video-upload-box-description g-description-text">
                                                Supports MP4 & MOV files with max file size 300mb
                                            </div>
                                        </div>
                                    </div>
                                </Upload>
                            </div>

                            <div className="g-public-upload-form-videos-table">
                                <Table dataSource={fileList} columns={columns} pagination={false} data-testid={`${dataTestIdPrefix}-videos-table`} />
                                <div className="g-upload-validation-error-text g-upload-validation-error-text-video" data-testid={`${dataTestIdPrefix}-videos-table-error`}>
                                    {!isVideoProductUrlValid && `Please provide a valid URL which contains ${shopDetail?.domain}`}
                                </div>
                            </div>

                            <Button
                                data-testid={`${dataTestIdPrefix}-submit-button`}
                                className="g-public-upload-form-submit-button"
                                type="primary"
                                onClick={validateAndSubmitForm}
                                loading={formSubmitLoading}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                </>
            )}
            {!!ShowPreviewVideo && <SelectedUploadVideoPreviewModal showPreviewVideo={ShowPreviewVideo} setShowPreviewVideo={setShowPreviewVideo} />}
        </div>
    );
};

export default UploadAndLinkVideosForm;
