import { Typography, Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PagesTableComponent from './pages-table-component';
import { useContext, useState } from 'react';
import { searchSvg } from 'components/icons';
import { PagesContext } from 'context/pages-context';
import { IPage } from 'interfaces';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Pages-PagesComponent";

interface IProps {
    setViewCustomizePageComponent: React.Dispatch<React.SetStateAction<IPage | undefined>>;
}

const ManageContentComponent = ({setViewCustomizePageComponent}: IProps) => {
    const { pagesSearchKeyword, setPagesSearchKeyword } = useContext(PagesContext);
    const [isCreateNewPage, setIsCreateNewPage] = useState(false);

    return (
        <div className="g-manage-content-component">
            <div className="g-manage-content-component-title-wrapper">
                <div className='g-manage-content-title-segment-div'>
                    <Title level={3}>Manage Pages</Title>
                </div>
                <div className='g-manage-content-search-add-btn-div'>
                    <Input size="large" placeholder="Search" prefix={searchSvg} onChange={(e) => setPagesSearchKeyword(e?.target?.value)} value={pagesSearchKeyword} />

                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={() => setIsCreateNewPage(true)}>
                        Add New Page
                    </Button>

                </div>
            </div>
            <div className="g-manage-content-pages-table-wrapper" data-testid={`${dataTestIdPrefix}-PagesTableContainer`}>
                <PagesTableComponent isCreateNewPage={isCreateNewPage} setIsCreateNewPage={setIsCreateNewPage} setViewCustomizePageComponent={setViewCustomizePageComponent} />
            </div>
        </div>
    )
}
export default ManageContentComponent;