import { Button, Card, Spin, Tooltip, Typography } from 'antd';
import GanderVideo from 'components/gander-video';
import { EPreviewPlayButtonSize } from 'interfaces';
import { useContext } from 'react';
import moment from 'moment';
import { VideosContext } from 'context/videos-context';

import './index.scss';

const { Title } = Typography;
const { Meta } = Card;
const dataTestIdPrefix = "Videos-RecentlyAddedComponent";

const RecentlyAddedComponent = () => {

    const { isRecentlyAddedVideosLoading, recentlyAddedVideos } = useContext(VideosContext);

    return (
        <div>
            <Title level={3} >Recently Added</Title>
            <div className="g-recently-added-videos-container" data-testid={`${dataTestIdPrefix}-Wrapper`}>
                {isRecentlyAddedVideosLoading ?
                    <div className='g-recently-added-videos-loading'>
                        <div>
                            <Spin size='large' />
                        </div>
                    </div> :
                    recentlyAddedVideos?.length ? (
                        recentlyAddedVideos.map(video => (
                            <div className='g-recently-added-video-card-container' key={video?.meta} data-testid={`${dataTestIdPrefix}-VideoCard`}>
                                <Card
                                    className="g-recently-added-video-card"
                                    cover={
                                        <div className="g-recently-added-video">
                                            <GanderVideo video={video} buttonSize={EPreviewPlayButtonSize.MEDIUM} />
                                        </div>
                                    }
                                >
                                    <div className='g-recently-added-video-card-body'>
                                        <Meta
                                            title={
                                                <Tooltip placement="bottomLeft" title={video?.displayName}>
                                                    <span>
                                                        {video?.displayName ? video?.displayName : '-'}
                                                    </span>
                                                </Tooltip>
                                            }
                                            description={"Uploaded " + moment(video?.createdAt).fromNow()} />
                                        <Button data-testid={`${dataTestIdPrefix}-VideoCardPagesBadge`} className='g-dropdown-circle-btn' type="primary" shape="circle" size='small'>{video?.pages ? video?.pages?.length : 0}</Button>
                                    </div>
                                </Card>
                            </div>
                        ))) : (
                        <div className='g-recently-added-no-videos'>
                            <Title level={4}>No Recently Added Videos </Title>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default RecentlyAddedComponent;