import { useNavigate } from "react-router-dom";
import { Button, Card, Table } from "antd";
import InteractionsTableColumns from "./interactions-table-columns-component";
import { IInteraction } from "interfaces/schema";
import { PlusOutlined } from "@ant-design/icons";
import * as ROUTE_PATHS from "constants/routes";
import { useContext, useState } from "react";
import DetailsDrawer from "./details-drawer";
import { InteractionsContext } from "context/interactions-context";

import "./index.scss";

const InteractionsTable = () => {
  const navigate = useNavigate();
  const { interactions, lastEvaluatedMeta, isLoading, fetchInteractions } = useContext(InteractionsContext);
  const [activeInteraction, setActiveInteraction] = useState<IInteraction | undefined>();

  return (
    <div className="g-interactions-page-table-container">
      <Card>
        <Table
          data-testid="Interactions-InteractionsTable"
          dataSource={interactions}
          columns={ InteractionsTableColumns(setActiveInteraction)}
          pagination={false}
          rowKey="meta"
          loading={isLoading}
          onRow={(interaction) => {
            return {
              onClick: () => navigate(`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/${interaction.meta}`),
            };
          }}
          className="g-interactions-page-table"
        />
        <br />
        {!!lastEvaluatedMeta && (
          <Button
            icon={<PlusOutlined />}
            block
            onClick={() => fetchInteractions(true)}
            loading={isLoading}
          >
              Load More
          </Button>
        )}
      </Card>

      {activeInteraction && (
        <DetailsDrawer
          activeInteraction={activeInteraction}
          setActiveInteraction={setActiveInteraction}
          interactions={interactions}
        />
      )}
    </div>
  );
};

export default InteractionsTable;
