import { IShopDetailsPayload } from "app/pages/public/upload-and-link-videos/interface";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const apiValidateAndGetShopByExternalUploadId = async (externalId: string): Promise<IShopDetailsPayload> => {
  try {
    const url = `${REACT_APP_API_URL}/external-uploads/verify-id`;
    const response = await axios.get<IShopDetailsPayload>(url, {
      params: {
        externalId
      }
    });
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiValidateAndGetShopByExternalUploadId;