import { Link } from "react-router-dom";
import * as ROUTE_PATHS from 'constants/routes';

const NoMatchPage = () => {    
    return (
        <div>
            <h3>
                No match for <code>{window.location.pathname}</code>
                <br />
                <Link to={ROUTE_PATHS.PRIVATE_ROUTES.DASHBOARD.PATH}>Back To Dashboard</Link>
            </h3>
        </div>
    );
}

export default NoMatchPage;