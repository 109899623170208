import { Amplify } from 'aws-amplify';
const {
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_S3_BUCKET_NAME
} = process.env;

const awsConfig = Amplify.configure({
  Auth: {
    identityPoolId: REACT_APP_AMPLIFY_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: REACT_APP_AWS_COGNITO_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_AWS_S3_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
      region: REACT_APP_AWS_COGNITO_REGION, //OPTIONAL -  Amazon service region
    }
  }
});

export default awsConfig;