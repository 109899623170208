import Stripe from "stripe";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IParams {
  subscriptionId: string;
  oldSubscriptionItemId: string;
  newStripeGanderAccessPriceId: string;
  newPlanMeta: string;
}

const updateSubscription = async (params: IParams): Promise<{ subscription: Stripe.Subscription, message: string }> => {
  try {
    const url = `${REACT_APP_API_URL}/stripe/subscriptions`;
    const response = await axios.put(url, params);

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default updateSubscription;