import { useState } from "react";
import { Popover } from "antd";
import { HexColorPicker, HexColorInput } from "react-colorful";

import "./index.scss";

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

const ColorPicker = ({ value, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopover = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  }

  const handleColorPickerChange = (newColor: string) => {
    onChange?.(newColor);
  };

  return (
    <Popover
      open={isOpen}
      content={(
        <div className="g-interaction-edit-question-edit-form-field-picker-popover">
          <HexColorPicker color={value} onChange={handleColorPickerChange}/>
          <HexColorInput
            className="g-interaction-edit-question-edit-form-field-picker-popover-input"
            color={value}
            prefixed
            alpha
            onChange={handleColorPickerChange}
          />
        </div>
      )}
      trigger="click"
      onOpenChange={handleOpenPopover}
    >
      <div
        className="g-interaction-edit-question-edit-form-field-input g-interaction-edit-question-edit-form-field-picker"
        style={{ backgroundColor: value }}
        onClick={() => handleOpenPopover(true)}
      />
    </Popover>
  );
}

export default ColorPicker;