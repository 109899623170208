import { Row, Col, Spin, Typography, Avatar, Statistic, Card, Tooltip } from "antd";
import { ROW_GUTTER } from "../../../../constants/dashboard";
import { DATE_FORMAT, EMetricTimeString, TMetricGroup } from "../../../../interfaces";
import { DashboardContext } from "context/dashboard-context";
import { useContext, useEffect, useState } from "react";
import { apiGetOnSiteEngagementGroupShopify, apiGetDeviceBreakdownGroup } from "services/dashboard.service";
import { onSiteEngagementCurrentDummyData, deviceBreakdownCurrentDummyData } from "../dummy-data";
import { AppContext } from "context/app-context";
import { Column, ColumnConfig, Line, LineConfig } from "@ant-design/charts";
import { DEFAULT_LEGEND_VALUE, IPercentageValue, calculateDeviceLegendData } from "./utils";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface ILegendColor {
  Mobile: string;
  Desktop: string;
  [x: string]: string;
}

export const ThirdRowComponent = () => {
  const { currentSelectKey, startDate, endDate, controller, metricValues } = useContext(DashboardContext);
  const { isShowUpgradePlanBanner } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [deviceBreakdownValues, setDeviceBreakdownValues] = useState<TMetricGroup>();

  const [engagementValues, setEngagementValues] = useState<TMetricGroup>();

  const [totalValues, setTotalValues] = useState<IPercentageValue[]>(DEFAULT_LEGEND_VALUE);
  const legendColor: ILegendColor = { Mobile: "#6c96fc", Desktop: "#75daaa" };

  useEffect(() => {
    isShowUpgradePlanBanner ? setDefaultParams() : setParams();
  }, [startDate, endDate, isShowUpgradePlanBanner]);

  const setDefaultParams = () => {
    setEngagementValues(onSiteEngagementCurrentDummyData);
    setDeviceBreakdownValues(deviceBreakdownCurrentDummyData);
  };

  const setParams = async () => {
    const dateObj = {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    };

    try {
      setLoading(true);
      const [engagement, deviceBreakdown] = await Promise.all([
        apiGetOnSiteEngagementGroupShopify(dateObj, controller),
        apiGetDeviceBreakdownGroup(dateObj, controller)
      ])
      setEngagementValues(engagement?.data);
      setDeviceBreakdownValues(deviceBreakdown?.data);
      setTotalValues(calculateDeviceLegendData(deviceBreakdown?.data));
    } catch (e) {
      setEngagementValues(undefined);
      setDeviceBreakdownValues(undefined);
    } finally {
      setLoading(false);
    }
  };

  const configs = {
    rowGutter: { xs: 8, sm: 16, md: 24, lg: 32 },
    colGutter: {
      xs: { span: 24 },
      sm: { span: 24 },
      xl: { span: 12 },
      xxl: { span: 12 }
    },
    onSiteEngagement: {
      data: (engagementValues ?? []) as Record<string, any>[],
      xField: "TIMESTAMP",
      yField: "VALUE",

      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      animation: false,
      tooltip: {
        customItems: (originalItems: any[]) => {
          if (originalItems[0]) {
            const originalItem = originalItems[0];
            if (originalItem?.value && originalItem?.data?.NUMBER_OF_ENGAGED_VISITOR) {
              originalItem.value = `${originalItem?.data?.NUMBER_OF_ENGAGED_VISITOR} (${Number(originalItem?.value)?.toFixed(2)}%)`;
            }
            originalItem.name = "User Engaged";
            originalItems[0] = originalItem;
          }
          return originalItems;
        },
      },
    },
    deviceBreakdown: {
      data: (deviceBreakdownValues ?? []),
      color: ({ ID }: { ID: any }) => {
        return legendColor?.[ID] || "#808080"
      },
      xField: "TIMESTAMP",
      yField: "VALUE",
      seriesField: "ID",
      animation: false,
      smooth: false
    }
  };

  return (
    <Row gutter={ROW_GUTTER}>
      <Col className="gutter-row g-dashboard-state-container" {...configs.colGutter}>
        <Card
          title={<Title level={3}>On Site Engagement</Title>}
          size="default"
          className="g-viz-component"
          extra={
            <Tooltip title={"The % and/or #  of visitors who engaged with a Gander video."} placement="bottomRight" showArrow={false}>
              <InfoCircleOutlined />
            </Tooltip>
          }
        >
          <div>
            <Spin spinning={loading}>
              <Row gutter={configs.rowGutter} align="middle">
                <Col span={24}>
                  <Title level={2}>{(metricValues ? `${metricValues['On Site Engagement']?.toFixed(2)}%` : undefined) ?? ""}</Title>{" "}
                </Col>
              </Row>
              <br />
              <Row gutter={configs.rowGutter}>
                <Col span={24}>
                  <Column {...configs.onSiteEngagement} />
                </Col>
              </Row>
            </Spin>
          </div>
        </Card>
      </Col>
      <Col className="gutter-row g-dashboard-state-container" {...configs.colGutter}>
        <Card
          title={<Title level={3}>Device Breakdown</Title>}
          size="default"
          className="g-viz-component"
          extra={
            <Tooltip title={"A breakdown of users by device."} placement="bottomRight" showArrow={false}>
              <InfoCircleOutlined />
            </Tooltip>
          }>
          <div>
            <Spin spinning={loading}>
              <div className="g-device-breakdown">
                <Row>
                  <Statistic value={0} precision={2} />
                </Row>
                <br />
                <Row gutter={configs.rowGutter}>
                  <Col span={20}>
                    {currentSelectKey === EMetricTimeString.LAST_DAY ? <Column {...configs.deviceBreakdown as unknown as ColumnConfig} /> : <Line {...configs.deviceBreakdown as unknown as LineConfig} />}
                  </Col>
                  <Col span={4}>
                    {totalValues.map((val: IPercentageValue) => (
                      <div key={val?.device} >
                        <Row justify="center" align="middle">
                          <Avatar shape="circle" size={8} style={{ backgroundColor: legendColor?.[val?.device] || "gray" }} />
                          <Text className="g-device-breakdown-text">{val?.device}</Text>
                        </Row>
                        <Row justify="center">
                          <Title className="g-device-breakdown-padding" level={2}>{val?.value}%</Title>
                        </Row>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
