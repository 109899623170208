import QuestionChoiceAnalysis from "./question-choice-analysis-component";
import { EInteractionResultsTabs } from "interfaces";
import { ISummaryPageMetrics } from "../..";

import "./index.scss";

interface Props {
  summaryPageMetrics: ISummaryPageMetrics;
  setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const QuestionChoicesAnalysis = ({ summaryPageMetrics, setActiveTab }: Props) => {
  return (
    <div className="g-interaction-edit-summary-card-main-container">
      {summaryPageMetrics.questionResponsesOverview.map((questionResponse) => {
        return (
          <QuestionChoiceAnalysis key={questionResponse.QUESTION_ID} questionResponse={questionResponse} setActiveTab={setActiveTab} />
        );
      })}
    </div>
  );
}

export default QuestionChoicesAnalysis;