import { Form, FormListFieldData, Input, Typography } from "antd";
import ColorPicker from "../../../color-picker-component";


import "./index.scss";

const { Text } = Typography;

interface Props {
  choice: FormListFieldData;
}

const AddToCartChoiceComponent = ({ choice }: Props) => {
  return (
    <div className="g-interaction-edit-question-edit-add-to-cart-choice-container">
      <div className="g-interaction-edit-question-edit-add-to-cart-color-choices-container">
        <div className="g-interaction-edit-question-edit-add-to-cart-color-choice-container">
          <div className="g-interaction-edit-question-edit-form-field-title-container">
            <Text>Button Color</Text>
          </div>
          <Form.Item
            {...choice}
            className="g-padding-y-1"
            name={[choice.name, 'buttonColor']}
            rules={[{ required: true }]}
          >
            <ColorPicker />
          </Form.Item>
        </div>
        <div className="g-interaction-edit-question-edit-add-to-cart-color-choice-container">
          <div className="g-interaction-edit-question-edit-form-field-title-container">
            <Text>Button Text Color</Text>
          </div>
          <Form.Item
            {...choice}
            className="g-padding-y-1"
            name={[choice.name, 'buttonTextColor']}
            rules={[{ required: true }]}
          >
            <ColorPicker />
          </Form.Item>
        </div>
      </div>
      <div className="g-interaction-edit-question-edit-form-field-title-container">
        <Text>Product URL</Text>
      </div>
      <Form.Item
        {...choice}
        key="productUrl"
        name={[choice.name, 'productUrl']}
        rules={[
          { required: true, message: 'This field is required' },
          { message: 'Invalid Url', pattern: /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/ },
        ]}
      >
        <Input className="g-interaction-edit-question-edit-form-field-input" placeholder="Enter a URL" />
      </Form.Item>
    </div>
  )
}

export default AddToCartChoiceComponent;