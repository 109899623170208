import { List } from "antd";
import { Active, closestCenter, DndContext, DragEndEvent, KeyboardSensor, Over, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import QuestionCard from "./question-card-component";
import { toast } from "utils/toast";
import { IInteraction, IQuestion, EChoiceNextQuestionMetaRedirect, EQuestionType } from "interfaces/schema";
import { FileAddOutlined } from "@ant-design/icons";
import { apiUpdateInteraction } from "services/interactions";
import { getShopifyProductNamebyProductUrl } from "services/shopify.service";

import "./index.scss";

const isInvalidSwap = (active: Active, over: Over | null, interaction: IInteraction) => {
  const activeQuestion = interaction.questions.find(({ meta }) => meta === active?.id);
  const overQuestion = interaction.questions.find(({ meta }) => meta === over?.id);
  if (activeQuestion === undefined || overQuestion === undefined) {
    return false;
  }

  if (activeQuestion.meta === overQuestion.meta) {
    return false;
  }

  const questionOrder = [...interaction.questionOrder];
  const activeQuestionIndex = questionOrder.findIndex((meta) => activeQuestion.meta === meta);
  const overQuestionIndex = questionOrder.findIndex((meta) => overQuestion.meta === meta);
  const indicesBetween = Math.abs(activeQuestionIndex - overQuestionIndex);

  if (activeQuestionIndex > overQuestionIndex) {
    const questionMetasBeforeActiveQuestion = questionOrder.splice(overQuestionIndex, indicesBetween);
    const beforeQuestionNextQuestionMetas = questionMetasBeforeActiveQuestion.flatMap((questionMeta) => {
      const question = interaction.questions.find(({ meta }) => meta === questionMeta);
      if (!question) {
        return [];
      }
      return question.choices.map(({ nextQuestionMeta }) => nextQuestionMeta).filter((meta) => meta !== '' && meta !== EChoiceNextQuestionMetaRedirect);
    });
    return beforeQuestionNextQuestionMetas.includes(activeQuestion.meta);
  } else {
    const questionMetasAfterActiveQuestion = questionOrder.splice(activeQuestionIndex + 1, indicesBetween);
    const activeQuestionNextQuestionMetas = activeQuestion.choices.map(({ nextQuestionMeta }) => nextQuestionMeta).filter(Boolean);
    return activeQuestionNextQuestionMetas.some((meta) => meta && questionMetasAfterActiveQuestion.includes(meta));
  }
};

interface Props {
  orderedQuestions: IQuestion[];
  interaction: IInteraction;
  selectedQuestion: IQuestion | undefined;
  isLoading: boolean;
  setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionCards = ({ orderedQuestions, interaction, selectedQuestion, isLoading, setInteraction, setSelectedQuestion, setIsLoading }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (isInvalidSwap(active, over, interaction)) {
      toast.warning("Question re-order failed. Please ensure question choices do not jump to any previous questions and try again.", 5);
      return;
    }

    if (active && over && active?.id !== over?.id) {
      const currentQuestionOrder = interaction.questionOrder;
      const oldIndex = currentQuestionOrder.findIndex((meta) => meta === active?.id);
      const newIndex = currentQuestionOrder.findIndex((meta) => meta === over?.id);
      const newQuestionOrder = arrayMove(currentQuestionOrder, oldIndex, newIndex);
      const intr = { ...interaction, questionOrder: newQuestionOrder };
      const firstQuestion  = intr.questions.find((q) => q.meta === newQuestionOrder[0]);
      if (firstQuestion && !intr.isNameUpdatedByUser) {
        intr.name = (firstQuestion.type === EQuestionType.ADD_TO_CART ? `Add to Cart - ${await getShopifyProductNamebyProductUrl(firstQuestion.choices[0].productUrl)}` : firstQuestion.text) || intr.name;
      }
      setInteraction(intr);
      await updateQuestionOrder(intr);
    }
  };

  const updateQuestionOrder = async (intr: IInteraction) => {
    try {
      setIsLoading(true);
      const updateInteractionResponse = await apiUpdateInteraction({ id: intr.meta, questionOrder: intr.questionOrder, name: intr.name });
      const interactionResponse = { ...interaction, questionOrder: updateInteractionResponse.interaction.questionOrder, name: updateInteractionResponse.interaction.name };
      if (interactionResponse) {
        setInteraction(interactionResponse);
        toast.success("Question order updated!");
      }
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={orderedQuestions} strategy={verticalListSortingStrategy}>
        <List
          className="g-interaction-edit-question-list-container"
          itemLayout="vertical"
          dataSource={orderedQuestions}
          loading={isLoading}
          locale={{
            emptyText: (
              <div className="g-interaction-edit-question-list-empty-container">
                <FileAddOutlined style={{ fontSize: '200%' }} />
                Add your first question by clicking the button above.
              </div>
            ),
          }}
          renderItem={(question: IQuestion, index) => (
            <QuestionCard
              key={question.meta}
              id={question.meta}
              question={question}
              interaction={interaction}
              index={index}
              selected={question.meta === selectedQuestion?.meta}
              setInteraction={setInteraction}
              setSelectedQuestion={setSelectedQuestion}
              setIsLoading={setIsLoading}
            />
          )}
        />
      </SortableContext>
    </DndContext>
  );
}

export default QuestionCards;