import { Typography } from "antd";

const { Title } = Typography;

const Header = () => {
  return (
    <Title level={4}>
      Email confirmation sent!
    </Title>
  );
}

export default Header;