import { useContext, useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { SettingsSubscriptionCardVideoEmbeddingIcon } from "components/icons";
import { AppContext } from "context/app-context";
import { ISubscriptionPlan } from "interfaces";
import { getPlanActionButtonHrefLink, getPlanActionButtonText, getPlanPriceTextGatingV3, getPlanVideoEmbeddingTextV3 } from "../../../../app/pages/settings/billing-subscription-component/utils";

import "./index.scss";

const { Title } = Typography;
const dataTestIdPrefix = "Settings-PlanCardComponentV3";

interface IProps {
    subscriptionPlan: ISubscriptionPlan;
    isSelectPlanLoading: boolean;
    disableSelectPlan: boolean;
    selectPlan: (plan: ISubscriptionPlan) => Promise<void>;
    style?: {};
}

const BillingPlanCardV3 = ({ subscriptionPlan, isSelectPlanLoading, disableSelectPlan, selectPlan, style }: IProps) => {
    const { activeSubscription } = useContext(AppContext);
    const [isCurrentPlanActive, setIsCurrentPlanActive] = useState(false);

    useEffect(() => {
        if (activeSubscription) {
            setIsCurrentPlanActive(activeSubscription?.activePlan?.meta?.toLowerCase() === subscriptionPlan?.meta?.toLowerCase());
        }
    }, [activeSubscription, subscriptionPlan]);

    const callSelectPlan = () => {
        if (!subscriptionPlan?.isContactSalesPlan && !isCurrentPlanActive) {
            selectPlan(subscriptionPlan);
        }
    };

    return (
        <div className="g-subscription-card-container" style={style} data-testid={`${dataTestIdPrefix}-planCard`}>
            <div className="g-d-flex g-justify-between">
                <Title level={5} data-testid={`${dataTestIdPrefix}-planName`}>
                    {subscriptionPlan?.displayName}
                </Title>
                {subscriptionPlan?.isMostPopular && (
                    <div className="g-subscription-card-most-popular-badge" data-testid={`${dataTestIdPrefix}-mostPopularBadge`}>
                        <span>MOST POPULAR</span>
                    </div>
                )}
            </div>
            <Title level={2} className="g-subscription-card-price" data-testid={`${dataTestIdPrefix}-planPrice`}>
                {getPlanPriceTextGatingV3(subscriptionPlan)}
            </Title>
            <div className="g-subscription-card-detail-box">
                <div>
                    <SettingsSubscriptionCardVideoEmbeddingIcon />
                </div>
                <div className="g-subscription-card-detail-title">
                    <Title level={5}>Video Embedding</Title>
                    <div className="g-subscription-card-detail-title-detail" data-testid={`${dataTestIdPrefix}-planEmbeddingText`}>
                        {getPlanVideoEmbeddingTextV3(subscriptionPlan)}
                    </div>
                </div>
            </div>
            <div className="g-subscription-card-select-button-container">
                <Button
                    data-testid={`${dataTestIdPrefix}-planActionButton`}
                    className="g-subscription-card-select-button"
                    type={isCurrentPlanActive ? "default" : "primary"}
                    size="large"
                    href={getPlanActionButtonHrefLink(subscriptionPlan)}
                    target="_blank"
                    loading={isSelectPlanLoading}
                    disabled={disableSelectPlan}
                    onClick={callSelectPlan}
                >
                    {getPlanActionButtonText(subscriptionPlan, isCurrentPlanActive)}
                </Button>
            </div>
        </div>
    );
};

export default BillingPlanCardV3;
