import { Dispatch, memo, SetStateAction, useState } from "react";
import ReactPlayer from "react-player";
import { Input, Spin } from "antd";
import { ITiktokVideo } from "interfaces/tiktok";

import "./index.scss";

const dataTestIdPrefix = "tiktok-modal";

interface IProps {
    video: ITiktokVideo;
    selected: boolean;
    active: boolean;
    handleAdd: (video: ITiktokVideo) => void;
    handleRemove: (video: ITiktokVideo) => void;
    setActive: Dispatch<SetStateAction<string>>;
}

const TiktokVideoCard = ({ video, selected, active, handleAdd, handleRemove, setActive }: IProps) => {
    const [ready, setReady] = useState<boolean>(false);
    return (
        <div
            key={video.id}
            data-testid={`${dataTestIdPrefix}-modal-card`}
            className={`g-tiktok-modal-card ${selected ? "g-tiktok-modal-card-selected" : ""}`}
            onClick={selected ? () => handleRemove(video) : () => handleAdd(video)}
        >
            {!ready && (
                <div className="g-tiktok-modal-card-video-loader">
                    <Spin />
                </div>
            )}
            <div className="g-tiktok-modal-card-video-wrapper" onMouseEnter={() => setActive(video.id)}>
                <ReactPlayer width={159} height={282} loop playing={active} muted url={video.videoUrl} onReady={() => setReady(true)} />
            </div>
            {ready && (
                <div className="g-tiktok-modal-card-select-checkbox-wrapper">
                    <Input className="g-tiktok-modal-card-select-checkbox" type="checkbox" checked={selected} />
                </div>
            )}
            {ready && <div className="g-tiktok-modal-card-caption-wrapper">{video.title}</div>}
        </div>
    );
};

export default memo(TiktokVideoCard);
