import { Row, Statistic, Avatar, Typography } from "antd";
import { DashOutlined, FallOutlined, RiseOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "context/dashboard-context";

const { Text } = Typography;

interface IMetricCardTableMetricsComponent {
  currentSelectKey: string;
  valuePrev?: any[];
  value?: any[];
  keys: any[];
  prefix: string;
  postfix: string;
  precision: number;
  topValue: number;
  topValuePrev: number;
}

export const MetricCardTableMetricsComponent = ({ value, valuePrev, prefix, postfix, precision, topValue, topValuePrev }: IMetricCardTableMetricsComponent) => {
  const [percentageChange, setPercentageChange] = useState<number | null>(0);
  const { currentSelectKey } = useContext(DashboardContext);

  useEffect(() => {
    setPercentageChange(((topValue - topValuePrev) / topValuePrev) * 100);
  }, [topValue, topValuePrev]);

  if (!value && !valuePrev) {
    return <></>;
  }

  if (percentageChange === null) {
    return <></>;
  }

  let color;
  let icon;
  let size = 16;
  if (percentageChange > 0) {
    color = "#3f8600";
    icon = <Avatar size={size} icon={<RiseOutlined />} style={{ backgroundColor: color, marginBottom: "6px" }} />;
  } else if (percentageChange < 0) {
    color = "#FF6424";
    icon = <Avatar size={size} icon={<FallOutlined />} style={{ backgroundColor: color, marginBottom: "6px" }} />;
  } else {
    color = "#000000";
    icon = <Avatar size={size} icon={<DashOutlined />} style={{ backgroundColor: color, marginBottom: "6px" }} />;
  }
  return (
    <>
      <Row justify={"end"} style={{ marginRight: "12px" }}>
        <Statistic
          value={(topValue || 0).toFixed(precision)}
          precision={precision}
          prefix={prefix}
          suffix={postfix}
        />
      </Row>
      <Row justify={"end"} style={{ float: "right" }}>
        <Statistic
          value={(percentageChange || 0).toFixed(precision)}
          precision={precision}
          valueStyle={{ color: "#FF6424", fontSize: "14px" }}
          prefix={icon}
          suffix="%"
        />
        &nbsp;&nbsp;
        <Text style={{ fontSize: "14px", display: "inline", color: "#84818A" }}>
          {prefix}{(topValuePrev)?.toFixed(precision)}{postfix} in {currentSelectKey.toLocaleLowerCase()}
        </Text>
      </Row>
    </>
  );
};
