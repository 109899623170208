import { useState } from "react";
import { PasswordField } from "@aws-amplify/ui-react";

import "./index.scss";

const NewPasswordFormFields = () => {
  const [password, setPassword] = useState('');
  const validationRegex = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/;

  return (
    <>
      <PasswordField
        name='password'
        label='Password'
        placeholder='*****'
        hasError={password.length !== 0 && validationRegex.test(password)}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        isRequired={true}
        crossOrigin={"anonymous"}
      />
      {password.length !== 0 && validationRegex.test(password) && (
        <div className="g-password-validation-list">
          Password must:
          <br />
          <ul>
            <li>be at least 8 characters</li>
            <li>include at least one special character</li>
            <li>include at least one uppercase character</li>
            <li>include at least one lowercase character</li>
            <li>include at least one numerical character</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default NewPasswordFormFields;