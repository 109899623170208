import { createContext, useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import awsConfig from "config/awsConfig";
import { useRef } from "react";
import uploadVideoS3 from "./uploadVideoS3";
import { v4 as uuidv4 } from "uuid";
import { IUploadFile } from "interfaces";
import { apiPostBulkUploadVideo } from "services/upload.services";
import { debounce } from "lodash";

Amplify.configure(awsConfig);

export interface IUploadContext {
    fileList: IUploadFile[];
    uploadVideo: (uploadEvent: { file: IUploadFile }, shopUuid: string, source?: string) => Promise<void>;
    isNewFileAdded: boolean;
    setIsNewFileAdded: React.Dispatch<React.SetStateAction<boolean>>;
    isFileRecordCreating: boolean;
}

const defaultContext = {
    fileList: [],
    uploadVideo: () => { },
    isNewFileAdded: false,
    setIsNewFileAdded: () => { },
    isFileRecordCreating: false,
};

export const UploadContext = createContext<IUploadContext | any>(defaultContext);

export const UploadContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [fileList, setFileList] = useState<IUploadFile[]>([]);
    const [fileListBulk, setFileListBulk] = useState<any>([]);
    const filesListRef = useRef<IUploadFile[]>([]);
    const filesListBulkRef = useRef<any>([]);
    const [isNewFileAdded, setIsNewFileAdded] = useState(false);
    const [isFileRecordCreating, setIsFileRecordCreating] = useState(false);

    useEffect(() => {
        if (!!fileListBulk && !!fileListBulk?.length) {
            debounceFn();
        }
    }, [fileListBulk]);

    const debounceFn = debounce(async () => {
        if (fileListBulk?.length > 0) {
            await apiPostBulkUploadVideo(fileListBulk);
            setIsFileRecordCreating(false);
            filesListBulkRef.current = [];
            setFileListBulk([]);
            setIsNewFileAdded(true);
        }
    }, 1000);

    const uploadVideo = async (uploadEvent: { file: IUploadFile, onSuccess: any, onError: any }, shopUuid: string, source: string) => {
        const { file, onSuccess, onError } = uploadEvent;
        try {
            const meta = uuidv4();
            const fileExtension = file?.name?.split(".").pop();
            const fileObj = {
                uid: file?.uid,
                lastModified: file?.lastModified,
                lastModifiedDate: file?.lastModifiedDate,
                originFileObj: file,
                name: file?.name,
                size: file?.size,
                type: file?.type,
                percent: 0,
                status: "uploading",
            };

            const fileUpdateObj = {
                meta: meta,
                name: file?.name,
                originalMediaUrl: `${meta}.${fileExtension}`,
                size: file?.size
            };

            filesListRef.current = [...filesListRef.current, fileObj];
            filesListBulkRef.current = [...filesListBulkRef.current, fileUpdateObj];
            setIsFileRecordCreating(true);
            setFileList(filesListRef.current);
            setFileListBulk(filesListBulkRef.current);

            const uploadRes: any = await uploadVideoS3(fileObj, meta, file, filesListRef, setFileList, shopUuid, source);

            if (uploadRes?.status === "success") {
                onSuccess(uploadRes?.message);
            } else if (uploadRes?.status === "error") {
                onError({ e: "ERROR" });
            }
        } catch (error) {
            onError({ error });
            console.log("Error uploading file:--------- ", error);
        }
    };

    return (
        <UploadContext.Provider
            value={{
                fileList,
                uploadVideo,
                isNewFileAdded,
                setIsNewFileAdded,
                isFileRecordCreating,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};
