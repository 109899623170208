import { Tabs } from "antd";
import DeviceSettings from "app/pages/common/widget-customization-page-components/device-settings-component";
import { ECustomizationSettingTabs } from "../types";
import { EDeviceType, EOverridePreviewState } from "interfaces";
import { IDeviceSettings } from "interfaces/schema";

const dataTestIdPrefix = "CustomizationSettingsTabComponent";

interface Props {
    isLoading: boolean;
    currentTab: ECustomizationSettingTabs;
    desktopSettings: IDeviceSettings | undefined;
    mobileSettings: IDeviceSettings | undefined;
    handleTabChange: (selectedTab: ECustomizationSettingTabs) => void;
    handleDesktopSettingsUpdate: (dirtyDesktopSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
    handleMobileSettingsUpdate: (dirtyMobileSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
};

const CustomizationSettingsTab = ({
    isLoading,
    currentTab,
    desktopSettings,
    mobileSettings,
    handleTabChange,
    handleDesktopSettingsUpdate,
    handleMobileSettingsUpdate,
}: Props) => {
    return (
        <Tabs
            className="g-customize-page-tabs-container"
            type="card"
            tabBarGutter={0}
            activeKey={currentTab}
            destroyInactiveTabPane
            onChange={(selectedTab: any) => {
                handleTabChange(selectedTab);
            }}
            items={[
                {
                    label: `Desktop`,
                    key: ECustomizationSettingTabs.DESKTOP,
                    children: (
                        <DeviceSettings
                            isLoading={isLoading}
                            device={EDeviceType.DESKTOP}
                            deviceSettings={desktopSettings}
                            handleDeviceSettingsUpdate={handleDesktopSettingsUpdate}
                        />
                    ),
                },
                {
                    label: `Mobile`,
                    key: ECustomizationSettingTabs.MOBILE,
                    children: (
                        <DeviceSettings
                            isLoading={isLoading}
                            device={EDeviceType.MOBILE}
                            deviceSettings={mobileSettings}
                            handleDeviceSettingsUpdate={handleMobileSettingsUpdate}
                        />
                    ),
                },
            ]}
            data-testid={`${dataTestIdPrefix}-SettingsPanel`}
        />
    );
};

export default CustomizationSettingsTab;