import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { Spin } from "antd";
import InstagramVideoCard from "./instagram-video-card-component";
import { toast } from "utils/toast";
import { UploadContext } from "context/upload-context";
import { IInstagramVideo } from "interfaces/instagram";
import { apiGetUserVideos } from "services/instagram";
import { AppContext } from "context/app-context";
import { EDefaultPlan } from "interfaces";

import "./index.scss";

const THRESHOLD = 200;
const TOTAL_UPLOAD_ALLOWED = 5;
const dataTestIdPrefix = "InstagramVideosSelector";

interface IProps {
    userId: string | undefined;
    cursor: string | undefined;
    videos: IInstagramVideo[];
    selectedVideos: IInstagramVideo[];
    setCursor: Dispatch<SetStateAction<string | undefined>>;
    setVideos: Dispatch<SetStateAction<IInstagramVideo[]>>;
    setSelectedVideos: Dispatch<SetStateAction<IInstagramVideo[]>>;
}

const InstagramVideosSelector = ({ userId, cursor, videos, selectedVideos, setCursor, setVideos, setSelectedVideos }: IProps) => {
    const { fileList } = useContext(UploadContext);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [active, setActive] = useState<string>(videos[0]?.id);
    const { activeSubscription } = useContext(AppContext);
    const isFreePlan = activeSubscription?.activePlan?.meta === EDefaultPlan.NEW_FREE_PLAN;

    const fetchMore = useCallback(
        async (userId: string, cursor: string) => {
            try {
                setIsFetchingMore(true);
                const response = await apiGetUserVideos({ userId, cursor });
                if (response.videos) {
                    setVideos([...videos, ...response.videos]);
                    setCursor(response.cursor);
                } else {
                    toast.error(`Sorry, something went wrong: ${response.message}`);
                }
            } catch (e) {
                toast.error("Sorry, something went wrong. Please try again.");
                console.log(e);
            } finally {
                setIsFetchingMore(false);
            }
        },
        [userId, cursor]
    );

    const handleScroll = async () => {
        const scrollTop = document.querySelector(".g-instagram-modal-card-wrapper")?.scrollTop;
        const scrollHeight = document.querySelector(".g-instagram-modal-card-wrapper")?.scrollHeight;
        const clientHeight = document.querySelector(".g-instagram-modal-card-wrapper")?.clientHeight;

        if (scrollTop === undefined || scrollHeight === undefined || clientHeight === undefined) {
            return;
        }
        if (scrollTop + clientHeight < scrollHeight - THRESHOLD || isFetchingMore) {
            return;
        }
        if (userId === undefined || cursor === undefined || cursor === "") {
            return;
        }
        await fetchMore(userId, cursor);
    };

    const handleAdd = (video: IInstagramVideo) => {
        if(isFreePlan && selectedVideos.length + fileList?.length > 0) {
            setSelectedVideos([video]);
            return;
        }
        if (selectedVideos.length + fileList?.length >= TOTAL_UPLOAD_ALLOWED) {
            toast.warning(`You can only upload ${TOTAL_UPLOAD_ALLOWED} videos simultaneously. Please un-select and select again.`);
            return;
        }
        setSelectedVideos([...selectedVideos, video]);
    };

    const handleRemove = (video: IInstagramVideo) => {
        setSelectedVideos(selectedVideos.filter(({ id }) => id !== video.id));
    };

    return (
        <div
            className="g-instagram-modal-card-wrapper"
            onScroll={handleScroll}
            data-testid={`${dataTestIdPrefix}-Div`}
        >
            {videos.map((video, index) => {
                const isVideoSelected = selectedVideos.findIndex(({ id }) => id === video.id) !== -1;
                return (
                    <InstagramVideoCard
                        key={video.id + index}
                        video={video}
                        selected={isVideoSelected}
                        active={video.id === active}
                        handleAdd={handleAdd}
                        handleRemove={handleRemove}
                        setActive={setActive}
                    />
                );
            })}
            {isFetchingMore && <div className="g-instagram-modal-footer-card"><Spin>Loading more videos...</Spin></div>}
        </div>
    );
};

export default InstagramVideosSelector;
