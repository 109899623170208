import TikTokIcon from "assets/img/tiktok.jpg";
import InstagramIcon from "assets/img/instagram.jpg";
import { InfoCircleOutlined, CaretDownOutlined } from "@ant-design/icons";
import { toast } from "utils/toast";
import { useContext, useState } from "react";
import { AppContext } from "context/app-context";
import { createUrlWithHostAndPathName } from "utils/common-functions";
import { Button, Dropdown, MenuProps, Tooltip, Typography } from "antd";
import GenerateNewUrlConfirmationModal from "./generate-new-url-confirmation-modal";
import { EDefaultPlan, IShopDetails } from "interfaces";
import { VideosContext } from "context/videos-context";

import "./index.scss";

const { Title } = Typography;

const dataTestIdPrefix = "videos-page";

interface IProps {
    setIsShowTikTokModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsShowInstagramModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getGetUploadUrlDropdownItems = (
    shopDetails: IShopDetails | null,
    copyExternalVideoUploadUrlToClipBoard: (externalVideoUploadString: string) => Promise<void>,
    setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const items: MenuProps["items"] = [
        {
            key: "0",
            label: (
                <div
                    onClick={() => (shopDetails?.externalVideoUploadId ? copyExternalVideoUploadUrlToClipBoard(shopDetails?.externalVideoUploadId!) : false)}
                    data-testdisabled={!shopDetails?.externalVideoUploadId}
                >
                    <div className="g-video-upload-button-content">Copy Current URL</div>
                </div>
            ),
            disabled: !shopDetails?.externalVideoUploadId,
        },
        {
            key: "1",
            label: (
                <div className="g-video-upload-button" onClick={() => setIsConfirmationModalOpen(true)}>
                    <div className="g-video-upload-button-content">Generate New URL</div>
                </div>
            ),
        },
    ];
    return { items };
};

const UploadVideosHeader = ({ setIsShowTikTokModal, setIsShowInstagramModal }: IProps) => {
    const { activeSubscription, setIsShowUpgradePlanBanner } = useContext(AppContext);
    const { videos, isVideosLoading, isRecentlyAddedVideosLoading, setIsShowV3FreePlanUploadLimitModel } = useContext(VideosContext);
    const { shopDetails } = useContext(AppContext);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const isFreePlan = activeSubscription?.activePlan?.meta === EDefaultPlan.NEW_FREE_PLAN;
    const isShowWarning = (videos.length >= 1 && isFreePlan);

    const copyExternalVideoUploadUrlToClipBoard = async (externalVideoUploadString: string) => {
        try {
            let url = new URL(createUrlWithHostAndPathName(window.location.hostname, "/public/upload-and-link-videos"));
            url.searchParams.set("externalUploadId", externalVideoUploadString);
            await navigator.clipboard.writeText(url.href);
            toast.info("External Video Upload URL Copied");
        } catch (error) {
            console.log("🚀 ~ file: columns.tsx ~ copyUrlToClipBoard ~ error", error);
        }
    };

    const handleTiktokImportClick = () => {
        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
        } else {
            setIsShowTikTokModal(true);
        }
    };

    const handleInstagramImportClick = () => {
        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
        } else {
            setIsShowInstagramModal(true);
        }
    };

    const showV3FreePlanUploadLimitModel = () => {
        setIsShowV3FreePlanUploadLimitModel(true);
    }

    return (
        <div className="g-video-upload-header">
            <Title level={3}>Upload New</Title>
            <div className="g-video-upload-header-buttons-container">
                <Dropdown
                    menu={getGetUploadUrlDropdownItems(shopDetails, copyExternalVideoUploadUrlToClipBoard, setIsConfirmationModalOpen)}
                    trigger={["click"]}
                    placement="bottom"
                    autoAdjustOverflow={false}
                    disabled={isVideosLoading || isRecentlyAddedVideosLoading}
                >
                    <Button className="g-video-upload-button g-video-external-upload-button" data-testid={`${dataTestIdPrefix}-get-upload-link`}>
                        <div className="g-video-upload-button-content">
                            Get Upload Link
                            <Tooltip
                                title="Allows you to generate external upload url which you can share with anyone who you'd like to receive content from."
                                className="g-video-upload-button-content-tooltip"
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                            <span className="g-video-upload-button-content-drop-icon">
                                <CaretDownOutlined />
                            </span>
                        </div>
                    </Button>
                </Dropdown>
                <Button className="g-video-upload-button" data-testid={`${dataTestIdPrefix}-instagram-button`} onClick={isShowWarning ? showV3FreePlanUploadLimitModel : handleInstagramImportClick} disabled={isVideosLoading || isRecentlyAddedVideosLoading}>
                    <div className="g-video-instagram-button-content">
                        Instagram Import
                        <img className="g-video-instagram-icon" alt="views" src={InstagramIcon} />
                    </div>
                </Button>
                <Button className="g-video-upload-button" data-testid={`${dataTestIdPrefix}-tiktok-button`} onClick={isShowWarning ? showV3FreePlanUploadLimitModel : handleTiktokImportClick} disabled={isVideosLoading || isRecentlyAddedVideosLoading}>
                    <div className="g-video-tiktok-button-content">
                        Tiktok Import
                        <img className="g-video-tiktok-icon" alt="views" src={TikTokIcon} />
                    </div>
                </Button>
            </div>

            <GenerateNewUrlConfirmationModal
                isConfirmationModalOpen={isConfirmationModalOpen}
                setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                copyExternalVideoUploadUrlToClipBoard={copyExternalVideoUploadUrlToClipBoard}
                dataTestIdPrefix={dataTestIdPrefix}
            />
        </div>
    );
};

export default UploadVideosHeader;
