import { IExternalVideoSubmitPayload } from "app/pages/public/upload-and-link-videos/interface";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IResponse {
  message: string;
}

const apiPostStoreExternalVideoUpload = async (payload: IExternalVideoSubmitPayload): Promise<IResponse> => {
  try {
    const url = `${REACT_APP_API_URL}/external-uploads/store-videos`;
    const response = await axios.post<IResponse>(url, payload);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiPostStoreExternalVideoUpload;