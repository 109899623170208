import { Typography } from "antd";

const { Title } = Typography;

const Header = () => {
  return (
    <Title level={4}>
      Send confirmation code
    </Title>
  );
}

export default Header;