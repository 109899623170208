import { v4 as uuidv4 } from "uuid";
import { Button, Form, FormListFieldData, Tooltip, Typography } from "antd";
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Choice from "./choice-component";
import { EQuestionType, IChoice, IQuestion } from "interfaces/schema";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";

import "./index.scss";

const { Text } = Typography;

interface Props {
  choices: FormListFieldData[];
  questions: IQuestion[];
  currentQuestion: IQuestion;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  move: (from: number, to: number) => void;
}

const Choices = ({ choices, questions, currentQuestion, add, remove, move }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active?.id && over?.id && active.id !== over.id) {
      const oldIndex = choices.findIndex(({ key }) => key === Number(active.id));
      const newIndex = choices.findIndex(({ key }) => key === Number(over.id));
      move(oldIndex, newIndex);
    }
  };

  const handleAddChoice = () => {
    const newChoice: IChoice = {
      id: uuidv4(),
      nextQuestionMeta: "",
      redirectUrl: "",
    };

    if (currentQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
      newChoice.text = "";
    } else {
      newChoice.pictureUrl = "";
    }

    add(newChoice);
  };

  const addChoiceButtonComponent = (
    <Button type="dashed" onClick={() => handleAddChoice()} block icon={<PlusOutlined />} disabled={choices.length >= 10}>
      Add choice
    </Button>
  );

  return (
    <>
      <div className="g-interaction-edit-question-edit-form-field-title-container g-d-flex">
        <Text>Choices</Text>
        <Tooltip title='Select what should happen after a visitor answers the question (only available for "Single Select").' trigger="click">
          <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center g-interaction-edit-question-edit-form-field-icon" />
        </Tooltip>
      </div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={choices.map((choice) => ({ ...choice, id: choice.key.toString() }))} strategy={verticalListSortingStrategy}>
          {choices.map((choice, index) => (
            <Choice
              key={choice.key.toString()}
              id={choice.key.toString()}
              index={index}
              choice={choice}
              questions={questions}
              currentQuestion={currentQuestion}
              disableDelete={choices.length === 1}
              remove={remove}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Form.Item>
        <div className="g-interaction-edit-question-edit-form-field-choice-add-button-container">
          {choices.length >= 10 ? (
            <Tooltip title="Question can only have a maximum of 10 choices">{addChoiceButtonComponent}</Tooltip>
          ) : (
            addChoiceButtonComponent
          )}
        </div>
      </Form.Item>
    </>
  );
};

export default Choices;
