
import { Form, Input } from 'antd';
import { VideosContext } from 'context/videos-context';
import { useContext, useEffect, useState } from 'react';
import { apiUpdateVideo } from 'services/video.services';
import { toast } from 'utils/toast';
import { IVideo } from 'interfaces/schema';

import './index.scss';

const dataTestIdPrefix = "Videos-EditVideoNameInputComponent";

interface IProps {
    editVideoNameInput: IVideo;
    setEditVideoNameInput: React.Dispatch<React.SetStateAction<IVideo | undefined>>;

}

const EditVideoNameInput = ({ editVideoNameInput, setEditVideoNameInput }: IProps) => {
    const [newName, setNewName] = useState(editVideoNameInput.displayName);
    const [form] = Form.useForm();
    const { fetchVideos } = useContext(VideosContext);

    const handleChange = (event: any) => {
        setNewName(event.target.value);
    }

    useEffect(() => {
        if (editVideoNameInput) {
            form?.setFieldValue('name', editVideoNameInput.displayName);
        }
    }, [editVideoNameInput]);

    const onFinish = async () => {
        try {
            const updatePayload = {
                displayName: newName,
            }
            const updateRes = await apiUpdateVideo(updatePayload, editVideoNameInput.meta);
            toast.success(updateRes?.message);
            fetchVideos();
            setEditVideoNameInput(undefined);
        } catch (e: any) {
            console.log("🚀 ~ file: edit-video-name-input/index.tsx ~ onFinish ~ exception", e);
            toast.error(e?.message);
        }
    };

    return (
        <Form form={form} onFinishFailed={() => console.log('onFinishFailed')} onFinish={onFinish}>
            <Form.Item name="name">
                <Input type="text" onChange={handleChange} data-testid={`${dataTestIdPrefix}-EditVideoNameInput`} />
            </Form.Item>
        </Form>
    )
}

export default EditVideoNameInput;