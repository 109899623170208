import { useContext } from "react";
import { Button, Typography, Space, Tooltip } from "antd";
import { AppContext } from 'context/app-context';
import { applyPreviewCornerRadiusOverride } from "utils/customization-settings";
import { EEmbeddedLayout, EOverlayOpenState, EWidgetType, IDeviceSettings } from "interfaces/schema";
import { ReactComponent as OverlayMobileLayout } from "assets/img/overlay-mobile-layout.svg";
import { ReactComponent as EmbeddedMobileLayout } from "assets/img/embedded-mobile-layout.svg";
import { InfoCircleOutlined } from "@ant-design/icons";

import "./index.scss";

const { Text } = Typography;
const DATA_TEST_ID_PREFIX = "Layout-Settings";;

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings) => void;
}

const LayoutSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const { activeSubscription } = useContext(AppContext);

    const handleWidgetTypeButtonClick: (widgetType: EWidgetType) => void = (widgetType: EWidgetType) => {
        const isEmbeddedInFrameMode = widgetType === EWidgetType.EMBEDDED && deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME;

        handleDeviceSettingsUpdate({
            ...deviceSettings,
            widgetType,
            previewCornerRadius: isEmbeddedInFrameMode ? applyPreviewCornerRadiusOverride(deviceSettings?.previewCornerRadius) : deviceSettings?.previewCornerRadius,
        });
    };

    const handleEmbedTypeButtonClick = (embedType: EEmbeddedLayout) => {
        const isHorizontalScrollSelected = embedType === EEmbeddedLayout.HORIZONTAL_SCROLL;
        const isEmbeddedInFrameMode = deviceSettings?.widgetType === EWidgetType.EMBEDDED && deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME;

        handleDeviceSettingsUpdate({
            ...deviceSettings,
            embedType,
            openStateType: isHorizontalScrollSelected && isEmbeddedInFrameMode ? EOverlayOpenState.OVERLAY : deviceSettings?.openStateType,
        });
    };

    return (
        <>
            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Player-Style-Text`}>Player Style</Text>
            <Space direction="vertical" size="middle">
                <Button
                    className={deviceSettings?.widgetType === EWidgetType.OVERLAY ? "g-customization-layout-button-selected" : "g-customization-layout-button"}
                    type="text"
                    onClick={() => handleWidgetTypeButtonClick(EWidgetType.OVERLAY)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Player-Style-Overlay-Button`}
                >
                    <OverlayMobileLayout className="g-customization-layout-button-icon" />
                    <Text className="g-customization-layout-button-text">Overlay</Text>
                </Button>
                <Button
                    className={`
                        ${deviceSettings?.widgetType === EWidgetType.EMBEDDED ? "g-customization-layout-button-selected" : "g-customization-layout-button"}
                        ${activeSubscription?.activePlan?.isAllowEmbedPlayer ? '' : 'disabled'}
                    `}
                    type="text"
                    disabled={!activeSubscription?.activePlan?.isAllowEmbedPlayer}
                    onClick={() => handleWidgetTypeButtonClick(EWidgetType.EMBEDDED)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Player-Style-Embedded-Button`}
                >
                    <EmbeddedMobileLayout className="g-customization-layout-button-icon" />
                    <Text className="g-customization-layout-button-text">Embedded</Text>
                    <span className="g-customization-layout-button-tooltip">Embedded available for growth or professional tier only</span>
                </Button>
            </Space>
            {deviceSettings?.widgetType === EWidgetType.EMBEDDED && (
                <>
                    <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Scroll-Type-Text`}>Scroll Type</Text>
                    <div className="g-customization-toggle-buttons-container">
                        <Button
                            className={deviceSettings?.embedType === EEmbeddedLayout.HORIZONTAL_SCROLL ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                            type="text"
                            onClick={() => handleEmbedTypeButtonClick(EEmbeddedLayout.HORIZONTAL_SCROLL)}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Scroll-Type-Horizontal-Scroll-Button`}
                        >
                            Horizontal
                        </Button>
                        <Button
                            className={deviceSettings?.embedType === EEmbeddedLayout.GRID ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                            type="text"
                            onClick={() => handleEmbedTypeButtonClick(EEmbeddedLayout.GRID)}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Scroll-Type-Grid-Button`}
                        >
                            Grid
                            <Tooltip title="Will only apply if # of videos exceeds the width of container.">
                                <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                            </Tooltip>
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default LayoutSettings;
