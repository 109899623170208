import { Radio, Button, Typography, Tooltip, Slider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { EOverlayOpenState, EOverlayStartingState, IDeviceSettings } from "interfaces/schema";
import { EOverridePreviewState } from "interfaces";
import { convertNumbertoVhString, convertVhStringToNumber } from "utils/type-conversion-functions";
import { Unit } from "constants/strings";
import { PlayerCustomizationSettings } from "constants/numbers";
import { useContext } from "react";
import { AppContext } from "context/app-context";

const { Text } = Typography;
const PLAYER_SIZE_MAX = 100;
const PLAYER_SIZE_DEFAULT = 50;
const DATA_TEST_ID_PREFIX = "OverlayOpenStateSettings";

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const OverlayOpenStateSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const { activeSubscription } = useContext(AppContext);

    const handleOpenStateButtonClick = (dirtyOpenStateType: EOverlayOpenState) => {
        if (deviceSettings?.previewOverlayStartingState === EOverlayStartingState.MUTED_GIF) {
            const overridePreviewState = dirtyOpenStateType === EOverlayOpenState.FULLSCREEN ? EOverridePreviewState.FULLSCREEN : EOverridePreviewState.OVERLAY;
            handleDeviceSettingsUpdate(
                {
                    ...deviceSettings,
                    openStateType: dirtyOpenStateType,
                },
                overridePreviewState
            );
            return;
        }

        handleDeviceSettingsUpdate({
            ...deviceSettings,
            openStateType: dirtyOpenStateType,
        });
    }

    const handleVisibleVideosButtonClick = (dirtyFullScreenIsShowAll: boolean) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            fullScreenIsShowAll: dirtyFullScreenIsShowAll,
        });
    }

    const handleSizeSliderChange = (dirtyOverlayWidgetSize: string) => {
        handleDeviceSettingsUpdate(
            {
                ...deviceSettings,
                overlayWidgetSize: dirtyOverlayWidgetSize,
            },
            EOverridePreviewState.OVERLAY
        );
    }

    const handleDisplayBrandingRadioClick = (dirtyDisplayBranding: boolean) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            displayBranding: dirtyDisplayBranding,
        });
    }

    return (
        <>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-Text`}>Open State</Text>
                <Tooltip title="Defines what the video player should look like when a user clicks on a video. You can interact with the preview panel to preview this.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={
                        deviceSettings?.openStateType === EOverlayOpenState.FULLSCREEN
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleOpenStateButtonClick(EOverlayOpenState.FULLSCREEN)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-FullScreen-Button`}
                >
                    Full Screen
                </Button>
                <Button
                    className={
                        deviceSettings?.openStateType === EOverlayOpenState.OVERLAY ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleOpenStateButtonClick(EOverlayOpenState.OVERLAY)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-Overlay-Button`}
                >
                    Overlay
                </Button>
            </div>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-Text`}>Visible Videos (in Full Screen Mode)</Text>
                <Tooltip title={"“All Videos” means users will be able to see all the videos in your Gander application. “Connected Videos” means users will only be able to see videos connected to a page."}>
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={deviceSettings?.fullScreenIsShowAll ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                    type="text"
                    onClick={() => handleVisibleVideosButtonClick(true)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-All-Videos-Button`}
                >
                    All Videos
                </Button>
                <Button
                    className={!deviceSettings?.fullScreenIsShowAll ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                    type="text"
                    onClick={() => handleVisibleVideosButtonClick(false)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-Connected-Videos-Button`}
                >
                    Connected Videos
                </Button>
            </div>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Size-Text`}>Size</Text>
                <Tooltip title={`Adjust open overlay player size (portrait minimum height & landscape minimum width: ${PlayerCustomizationSettings.OPEN_OVERLAY_PORTRAIT_LANDSCAPE_MIN_HEIGHT_WIDTH}${Unit.PIXEL}).`}>
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-slider-container">
                <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-LeftItem-Text`}>Small</Text>
                <Slider
                    max={PLAYER_SIZE_MAX}
                    value={deviceSettings?.overlayWidgetSize?.endsWith("px") ? PLAYER_SIZE_DEFAULT : convertVhStringToNumber(deviceSettings?.overlayWidgetSize)}
                    onChange={(value) => handleSizeSliderChange(convertNumbertoVhString(value))}
                    className={`${DATA_TEST_ID_PREFIX}-Size-Slider`}
                />
                <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-RightItem-Text`}>Large</Text>
            </div>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Text`}>Display Gander Branding</Text>
                <Tooltip title="Setting only available for growth and professional accounts.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <Radio.Group
                disabled={activeSubscription?.activePlan?.isForceBranding}
                value={deviceSettings?.displayBranding}
                onChange={(event) => handleDisplayBrandingRadioClick(event.target.value)}
            >
                <Radio value={true} data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Show-Radio`}>Show</Radio>
                <Radio value={false} data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Hide-Radio`}>Hide</Radio>
            </Radio.Group>
        </>
    );
};

export default OverlayOpenStateSettings;
