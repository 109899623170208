import { Button, Typography, Tooltip, Slider, Space, Checkbox } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { EOverlayOpenState, EOverlayStartingState, EPlayerShape, IDeviceSettings } from "interfaces/schema";
import { convertNumbertoVhString, convertVhStringToNumber, convertNumbertoPercentageString, convertPercentageStringToNumber } from "../../../../../../utils/type-conversion-functions";
import { EDeviceType, EOverridePreviewState } from "interfaces";
import { previewStateMinHeight } from "utils/customization-settings";
import { Unit } from "constants/strings";

import "./index.scss";

const { Text } = Typography;
const PLAYER_SIZE_MAX = 100;
const PLAYER_SIZE_DEFAULT = 50;
const CORNER_RADIUS_MAX = 50;
const CORNER_RADIUS_DEFAULT = 25;
const OPEN_STATE_TYPE_TO_OVERRIDE_PREVIEW_STATE_MAP = {
    [EOverlayOpenState.FULLSCREEN]: EOverridePreviewState.FULLSCREEN,
    [EOverlayOpenState.OVERLAY]: EOverridePreviewState.OVERLAY,
    [EOverlayOpenState.IN_FRAME]: EOverridePreviewState.OVERLAY,
}
const DATA_TEST_ID_PREFIX = "OverlayPreviewStateSettings";

interface Props {
    device: EDeviceType;
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const OverlayPreviewStateSettings = ({ device, deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const handleUpdateDeviceSettings = (dirtyWidgetSettings: Partial<IDeviceSettings>, overridePreview?: EOverridePreviewState) => {
        handleDeviceSettingsUpdate(
            {
                ...deviceSettings,
                ...dirtyWidgetSettings,
            },
            overridePreview
        );
    };

    const handleCornerRadiusChange = (value: number) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            previewCornerRadius: convertNumbertoPercentageString(value),
            textIsShowViewCount: value > 5 ? false : deviceSettings?.textIsShowViewCount,
        });
    };

    return (
        <>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Starting-State-Text`}>Starting State</Text>
                <Tooltip title="This refers to what the video player will look like when visitors first land on your page.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={
                        deviceSettings?.previewOverlayStartingState === EOverlayStartingState.MUTED_GIF
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleUpdateDeviceSettings({ previewOverlayStartingState: EOverlayStartingState.MUTED_GIF })}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Starting-State-Muted-GIF-Button`}
                >
                    Muted GIF
                </Button>
                <Button
                    className={
                        deviceSettings?.previewOverlayStartingState === EOverlayStartingState.OPEN_STATE
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleUpdateDeviceSettings(
                        {
                            previewOverlayStartingState: EOverlayStartingState.OPEN_STATE
                        },
                        OPEN_STATE_TYPE_TO_OVERRIDE_PREVIEW_STATE_MAP[deviceSettings?.openStateType],
                    )}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Starting-State-Open-State-Button`}
                >
                    Open State
                </Button>
            </div>
            {deviceSettings?.previewOverlayStartingState === EOverlayStartingState.MUTED_GIF && (
                <>
                    <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Text`}>Orientation</Text>
                    <div className="g-customization-toggle-buttons-container">
                        <Button
                            className={
                                deviceSettings?.previewOrientation === EPlayerShape.PORTRAIT_LANDSCAPE
                                    ? "g-customization-toggle-button-selected"
                                    : "g-customization-toggle-button"
                            }
                            type="text"
                            onClick={() => handleUpdateDeviceSettings({ previewOrientation: EPlayerShape.PORTRAIT_LANDSCAPE })}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Portrait-Landscape-Button`}
                        >
                            Portrait/Landscape
                        </Button>
                        <Button
                            className={
                                deviceSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE
                                    ? "g-customization-toggle-button-selected"
                                    : "g-customization-toggle-button"
                            }
                            type="text"
                            onClick={() => handleUpdateDeviceSettings({ previewOrientation: EPlayerShape.SQUARE_CIRCLE, textIsShowViewCount: false })}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Orientation-Square-Circle-Button`}
                        >
                            Square/Circle
                        </Button>
                    </div>
                    <div className="g-customization-field-header g-d-flex g-align-center">
                        <Text data-testid={`${DATA_TEST_ID_PREFIX}-Size-Text`}>Size</Text>
                        <Tooltip title={`Minimum height: ${previewStateMinHeight(device, deviceSettings)}${Unit.PIXEL}`}>
                            <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                        </Tooltip>
                    </div>
                    <div className="g-customization-slider-container">
                        <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-LeftItem-Text`}>Small</Text>
                        <Slider
                            max={PLAYER_SIZE_MAX}
                            value={deviceSettings?.previewPlayerSize?.endsWith("px") ? PLAYER_SIZE_DEFAULT : convertVhStringToNumber(deviceSettings?.previewPlayerSize)}
                            onChange={(value) => handleUpdateDeviceSettings({ previewPlayerSize: convertNumbertoVhString(value) })}
                            className={`${DATA_TEST_ID_PREFIX}-Size-Slider`}
                        />
                        <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-RightItem-Text`}>Large</Text>
                    </div>
                    <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-Text`}>Corner Style</Text>
                    <div className="g-customization-slider-container">
                        <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-LeftItem-Text`}>Square</Text>
                        <Slider
                            max={CORNER_RADIUS_MAX}
                            tooltip={{ formatter: ((value?: number) => value! * 2) }}
                            value={deviceSettings?.previewCornerRadius?.includes("px") ? CORNER_RADIUS_DEFAULT : convertPercentageStringToNumber(deviceSettings?.previewCornerRadius)}
                            onChange={(value) => handleCornerRadiusChange(value)}
                            className={`${DATA_TEST_ID_PREFIX}-Corner-Radius-Slider`}
                        />
                        <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Corner-Radius-RightItem-Text`}>Circular</Text>
                    </div>
                    <Space className="g-customization-checkbox-group" direction="vertical" size="middle">
                        <div className="g-overlay-preview-state-add-border-div">
                            <Checkbox
                                checked={deviceSettings?.previewIsShowBorder}
                                onChange={(e) => handleUpdateDeviceSettings({ previewIsShowBorder: e.target.checked, previewIsBorderPadding: false })}
                                data-testid={`${DATA_TEST_ID_PREFIX}-Add-Border-Checkbox`}
                            >
                                Add Border
                            </Checkbox>
                            <div
                                className={`g-overlay-preview-state-border-input-div ${!deviceSettings?.previewIsShowBorder ? 'disabled' : ''}`}
                                onClick={() => document.getElementById('previewBorderColor')?.click()}
                            >
                                <input
                                    type="color"
                                    name="previewBorderColor"
                                    id="previewBorderColor"
                                    value={deviceSettings?.previewBorderColor}
                                    onInput={(e) => handleUpdateDeviceSettings({ previewBorderColor: e.currentTarget.value })}
                                    disabled={!deviceSettings?.previewIsShowBorder}
                                    data-testid={`${DATA_TEST_ID_PREFIX}-Border-Color-Input`}
                                />
                                <span>{deviceSettings?.previewBorderColor}</span>
                            </div>
                        </div>
                        <Checkbox
                            checked={deviceSettings?.previewIsBorderPadding}
                            disabled={!deviceSettings?.previewIsShowBorder}
                            onChange={(e) => handleUpdateDeviceSettings({ previewIsBorderPadding: e.target.checked })}
                            data-testid={`${DATA_TEST_ID_PREFIX}-Border-Padding-Checkbox`}
                        >
                            Include Border Padding
                        </Checkbox>
                    </Space>
                </>
            )}
        </>
    );
};

export default OverlayPreviewStateSettings;
