import { v4 as uuidv4 } from 'uuid';
import { EChoiceNextQuestionMetaRedirect, EQuestionSelectionType, EQuestionType, IQuestion } from "interfaces/schema";

export const CALL_TO_ACTION_TEMPLATE: Pick<IQuestion, "text" | "selectionType" | "choices"> = {
 text: "",
 choices: [{
  id: uuidv4(),
  buttonColor: "#000000",
  buttonTextColor: "#FFFFFF",
  nextQuestionMeta: EChoiceNextQuestionMetaRedirect,
  redirectUrl: "",
 }],
}

export const ADD_TO_CART_TEMPLATE: Pick<IQuestion, "text" | "selectionType" | "choices"> = {
 text: "",
 choices: [{
  id: uuidv4(),
  buttonColor: "#000000",
  buttonTextColor: "#FFFFFF",
  productUrl: "",
 }],
}

export const SHORT_ANSWER_TEMPLATE: Pick<IQuestion, "text" | "selectionType" | "choices"> = {
  text: "",
  choices: [{
   id: uuidv4(),
   text: "",
   nextQuestionMeta: "",
   redirectUrl: "",
  }],
 }

 export const MULTIPLE_CHOICE_TEMPLATE: Pick<IQuestion, "text" | "selectionType" | "choices"> = {
  selectionType: EQuestionSelectionType.SINGLE_SELECT,
  text: "",
  choices: [{
    id: uuidv4(),
    text: "",
    nextQuestionMeta: "",
    redirectUrl: "",
  }],
 }

 export const PICTURE_CHOICE_TEMPLATE: Pick<IQuestion, "text" | "selectionType" | "choices"> = {
  selectionType: EQuestionSelectionType.SINGLE_SELECT,
  text: "",
  choices: [{
    id: uuidv4(),
    pictureUrl: "",
    nextQuestionMeta: "",
    redirectUrl: "",
  }],
 }

 export const typeToTemplateMap = {
  [EQuestionType.CALL_TO_ACTION]: CALL_TO_ACTION_TEMPLATE,
  [EQuestionType.ADD_TO_CART]: ADD_TO_CART_TEMPLATE,
  [EQuestionType.MULTIPLE_CHOICE]: MULTIPLE_CHOICE_TEMPLATE,
  [EQuestionType.PICTURE_CHOICE]: PICTURE_CHOICE_TEMPLATE,
  [EQuestionType.SHORT_ANSWER]: SHORT_ANSWER_TEMPLATE,
 };