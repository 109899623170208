import { Typography, Checkbox, Space, Slider } from "antd";
import { IDeviceSettings } from "interfaces/schema";
import { EOverridePreviewState } from "interfaces";
import './index.scss';

const { Text } = Typography;

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const dataTestIdPrefix = "Overlay_ClosedStateSettings";

const OverlayClosedStateSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {

    const handleUpdateDeviceSettings = (dirtyWidgetSettings: Partial<IDeviceSettings>) => {
        const payload = { ...deviceSettings, ...dirtyWidgetSettings };
        handleDeviceSettingsUpdate(payload, EOverridePreviewState.CLOSE_STATE);
    };

    return (
        <div className="g-overlay-close-state-container" data-testid={`${dataTestIdPrefix}_Container_Div`}>
            <Text className="g-customization-field-header" data-testid={`${dataTestIdPrefix}_FieldHeader_Text`}>Corner Style</Text>
            <div className="g-customization-slider-container">
                <Text className="g-customization-slider-left-item" data-testid={`${dataTestIdPrefix}_LeftItem_Text`}>Square</Text>
                <Slider
                    max={50}
                    step={0.5}
                    tooltip={{ formatter: ((value?: number) => value! * 2) }}
                    value={Number(deviceSettings?.closeCornerRadius?.replace('%', ''))}
                    onChange={(value) => handleUpdateDeviceSettings({ closeCornerRadius: `${value}%` })}
                    className={`${dataTestIdPrefix}_Slider`}
                />
                <Text className="g-customization-slider-right-item" data-testid={`${dataTestIdPrefix}_RightItem_text`}>Circular</Text>
            </div>
            <Space className="g-customization-checkbox-group" direction="vertical" size="middle">
                <div className="g-overlay-close-state-add-border-div" data-testid={`${dataTestIdPrefix}_AddBorder_Div`}>
                    <Checkbox
                        checked={deviceSettings?.closeIsShowBorder}
                        onChange={(e) => handleUpdateDeviceSettings({ closeIsShowBorder: e.target.checked, closeIsBorderPadding: false })}
                        data-testid={`${dataTestIdPrefix}_AddBorder_Checkbox`}
                    >
                        Add Border
                    </Checkbox>
                    <div
                        className={`g-overlay-close-state-border-input-div ${!deviceSettings?.closeIsShowBorder ? 'disabled' : ''}`}
                        onClick={() => document.getElementById('closeBorderColor')?.click()}
                    >
                        <input
                            type="color"
                            name="closeBorderColor"
                            id="closeBorderColor"
                            value={deviceSettings?.closeBorderColor}
                            onInput={(e) => handleUpdateDeviceSettings({ closeBorderColor: e.currentTarget.value })}
                            disabled={!deviceSettings?.closeIsShowBorder}
                            data-testid={`${dataTestIdPrefix}_CloseBorderColor_InputTypeColor`}
                        />
                        <span data-testid={`${dataTestIdPrefix}_ColorHex_Span`}>{deviceSettings?.closeBorderColor}</span>
                    </div>
                </div>
                <Checkbox
                    disabled={!deviceSettings?.closeIsShowBorder}
                    checked={deviceSettings?.closeIsBorderPadding}
                    onChange={(e) => handleUpdateDeviceSettings({ closeIsBorderPadding: e.target.checked })}
                    data-testid={`${dataTestIdPrefix}_IncludePadding_Checkbox`}
                >
                    Include Border Padding
                </Checkbox>
            </Space>
        </div>
    );
};

export default OverlayClosedStateSettings;
