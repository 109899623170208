import { PlayerCloseIcon, PlayerPlayIcon, PlayVideoIcon } from "components/icons";
import { EPreviewPlayButtonSize, EProgress } from "interfaces";
import { useRef, useState } from "react";
import ReactPlayer from "react-player";
import BlinkingMessage from "./blinking-message";
import { onVideoThumbnailLoadError } from "utils/common-functions";
import { IVideo } from "interfaces/schema";

import "./index.scss";

const dataTestIdPrefix = "Gander-GanderVideoComponent";

interface IProps {
    video: Partial<IVideo>;
    buttonSize: EPreviewPlayButtonSize;
}

const GanderVideo = ({ video, buttonSize }: IProps) => {
    const [isPlayVideoModal, setIsPlayVideoModal] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const wrapperRef = useRef(null);

    const initVideoPlayer = () => {
        setIsPlayVideoModal(true);
        setIsPlaying(true);
    };

    const closeVideoPlayer = () => {
        setIsPlayVideoModal(false);
        setIsPlaying(false);
    };

    const handleWrapperClick = (e: any) => {
        // only outside click, close the player
        if (e?.target === wrapperRef?.current) {
            closeVideoPlayer();
        }
    };

    return (
        <>
            {video?.progress === EProgress.COMPLETE &&
                <>
                    <div className="g-video-preview-container" data-testid={`${dataTestIdPrefix}-PreviewContainer`}>
                        <img className="g-video-preview-thumbnail" src={video?.hdThumbnail} alt={"preview"} onError={(e) => onVideoThumbnailLoadError(e, video)}
                        />
                        <div className="g-video-preview-thumbnail-overlay">
                            <div className={buttonSize} onClick={initVideoPlayer}>
                                <PlayVideoIcon />
                            </div>
                        </div>
                    </div>
                    {isPlayVideoModal && video?.playbackId && (
                        <div className="g-react-video-player-wrapper" onClick={handleWrapperClick} ref={wrapperRef}>
                            <div className="g-react-video-player-container">
                                <div className="g-react-video-player-close-icon" onClick={closeVideoPlayer}>
                                    <PlayerCloseIcon />
                                </div>
                                <div className={"g-react-video-player-play-icon " + (isPlaying && "g-hidden")} onClick={() => setIsPlaying(true)}>
                                    <PlayerPlayIcon />
                                </div>
                                <ReactPlayer
                                    playsinline
                                    playsInline
                                    className="g-react-video-player"
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    onError={(error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
                                        console.log(" error => ", error, "\n", "data => ", data, "\n", "hlsInstance => ", hlsInstance, "\n", "hlsGlobal => ", hlsGlobal);
                                        if (error.name === "AbortError" || error.name === "NotAllowedError") {
                                            setIsPlaying(false);
                                        } else if (error === "hlsError" && data?.response?.code === 404) {
                                            closeVideoPlayer();
                                        }
                                    }}
                                    url={video?.playbackId}
                                    controls={true}
                                    muted={false}
                                    playing={isPlaying}
                                    pip={false}
                                    height={"100%"}
                                    width={"100%"}
                                />
                            </div>
                        </div>
                    )}
                </>
            }
            {(video?.progress === EProgress.PROGRESSING || video?.progress === EProgress.PENDING || video?.progress === EProgress.UPLOADING) &&
                <div className="g-video-not-complete-container" data-testid={`${dataTestIdPrefix}-ProgressMessage`}>
                    <BlinkingMessage text={video?.progress} />
                </div>
            }
            {video?.progress === EProgress.ERROR &&
                <div className="g-video-not-complete-container" data-testid={`${dataTestIdPrefix}-ErrorMessage`}>
                    <p className="g-video-progress-error-text">ERROR</p>
                </div>
            }
        </>
    );
};

export default GanderVideo;
