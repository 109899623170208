import { useNavigate } from "react-router-dom";
import { Dropdown, Button, Modal, Menu } from "antd";
import { toast } from "utils/toast";
import { apiDeleteInteraction } from "services/interactions";
import { apiGetInteractions } from "services/interactions";
import { IInteraction } from "interfaces/schema";
import * as ROUTE_PATHS from "constants/routes";
import { SwitcherFilled, ExclamationCircleOutlined, RightOutlined } from "@ant-design/icons";
import { VideoTableDeleteButtonIcon } from "components/icons";
import { MAX_INTERACTIONS_PER_PAGE } from "constants/interactions";
import { useContext } from "react";
import { InteractionsContext } from "context/interactions-context";
import { useState } from "react";
import { AppContext } from "context/app-context";

import "./index.scss";

const { confirm } = Modal;

const dataTestIdPrefix = "InteractionsActionsColumn";
interface Props {
    interaction: IInteraction;
    setActiveInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
}

const InteractionsActionsColumn = ({
    interaction,
    setActiveInteraction
}: Props) => {
    const {
        setInteractions,
        setLastEvaluatedMeta,
        setIsLoading,
        setAllowedCreateNew,
        setTotalInteractionsCreated,
    } = useContext(InteractionsContext);
    const { activeSubscription, setIsShowUpgradePlanBanner } = useContext(AppContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const navigate = useNavigate();

    const deleteInteraction = async () => {
        try {
            setIsLoading(true);
            await apiDeleteInteraction({ id: interaction.meta });
            const response = await apiGetInteractions({ limit: MAX_INTERACTIONS_PER_PAGE });
            setInteractions(response.interactions);
            setLastEvaluatedMeta(response.lastEvaluatedMeta);
            setAllowedCreateNew(response.allowedCreateNew);
            setTotalInteractionsCreated(response?.interactionsCount ?? 0);
        } catch (e) {
            console.log(e);
            toast.error("Failed to delete interaction");
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewResponsesClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        navigate(`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/${interaction.meta}?tab=results`);
    };

    const handleDeleteClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        if (interaction?.videos?.length > 0) {
            toast.warning('Please disconnect all videos before deleting this interaction.');
            return;
        }
        confirm({
            title: "Delete Interaction",
            icon: <ExclamationCircleOutlined />,
            content: "Do you want to delete this interaction?",
            okText: <span data-testid={`${dataTestIdPrefix}-DeleteConfirmationButton`}>Delete</span>,
            okType: "danger",
            cancelText: <span data-testid={`${dataTestIdPrefix}-DeleteCancelButton`}>Cancel</span>,
            centered: true,
            async onOk() {
                await deleteInteraction();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const handleViewDetailsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        navigate(`${ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH}/${interaction.meta}`);
    };

    const openDetailDrawer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
            return;
        }
        setActiveInteraction(interaction);
        setIsDropdownOpen(false);

    };

    const menu = (row: IInteraction) => (
        <Menu
            items={[
                {
                    key: `interaction-${row.meta}-edit`,
                    label: <div onClick={handleViewDetailsClick}>Edit Interaction</div>,
                },
                {
                    key: `interaction-${row.meta}-video`,
                    label: (
                        <div onClick={(e) => { openDetailDrawer(e) }} data-testid={`${dataTestIdPrefix}-AttachVideoOption`}>
                            Attach Video(s)
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <div className="g-interactions-action-row-container">
            <Button
                size="small"
                icon={<SwitcherFilled />}
                onClick={handleViewResponsesClick}
                data-testid={`${dataTestIdPrefix}-ViewResponsesButton`}
            >
                View Responses
            </Button>
            <Dropdown dropdownRender={() => menu(interaction)} trigger={["click"]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen} >
                <span onClick={(e) => e.stopPropagation()}>
                    <Button
                        data-testid={`${dataTestIdPrefix}-ManageInteractionButton`}
                        size="small"
                    >
                        Manage Interaction
                        <RightOutlined />
                    </Button>
                </span>
            </Dropdown>
            <Button
                size="small"
                icon={<VideoTableDeleteButtonIcon />}
                className="g-interactions-action-row-delete-button"
                ghost
                onClick={handleDeleteClick}
                data-testid={`${dataTestIdPrefix}-DeleteButton`}
            />
        </div>
    );
};

export default InteractionsActionsColumn;

