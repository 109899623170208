import { notification } from "antd";
import { InfoCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const info = (msg: string, duration?: number) => {
  notification.info({
    message: msg,
    placement: "bottom",
    className: "g-toast-info",
    duration,
    icon: <InfoCircleOutlined />,
  });
};

const warning = (msg: string, duration?: number) => {
  notification.warning({
    message: msg,
    placement: "bottom",
    className: "g-toast-warning",
    duration,
    icon: <InfoCircleOutlined />,
  });
};

const error = (msg: string, duration?: number) => {
  notification.error({
    message: msg,
    placement: "bottom",
    className: "g-toast-error",
    icon: <CloseCircleOutlined />,
    duration,
  });
};

const success = (msg: string, duration?: number) => {
  notification.success({
    message: msg,
    placement: "bottom",
    className: "g-toast-success",
    icon: <CheckCircleOutlined />,
    duration,
  });
};

export const toast = { info, error, success, warning };
