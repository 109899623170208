import { useEffect, useState } from 'react';
import { UploadFile, Upload, UploadProps, Form, Modal } from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/lib/upload';
import { toast } from 'utils/toast';
import { apiGetS3PresignedUrl } from 'services/questions';
import { apiUploadFileToS3 } from 'services/aws';
import { IQuestion } from 'interfaces/schema';
import { PlusOutlined } from '@ant-design/icons';

import "./index.scss";

const FILE_SIZE_LIMIT_IN_MB = 5;
const ALLOWED_IMAGE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

interface Props {
  currentQuestion: IQuestion;
  index: number;
  value?: string;
  onChange?: (value: string) => void;
}

const UploadInput = ({ currentQuestion, index, value, onChange }: Props) => {
  const form = Form.useFormInstance();
  const choiceValue = Form.useWatch(["choices", index], form);
  const [url, setUrl] = useState<string>();
  const [file, setFile] = useState<UploadFile | undefined>(value ? {
    uid: '1',
    name: `${choiceValue ? choiceValue.id : "picture"}.png`,
    percent: 100,
    status: 'done',
    url: value,
  } : undefined);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    const fetchS3PresignedUrl = async () => {
      try {
        const choiceMeta = choiceValue.id;
        const response = await apiGetS3PresignedUrl({ questionId: currentQuestion.meta, choiceId: choiceMeta });
        setUrl(response.url);
      } catch (e) {
        console.log(e);
      }
    }

    if (choiceValue) {
      fetchS3PresignedUrl();
    }
  }, [choiceValue]);

  useEffect(() => {
    if (value) {
      setFile({
        uid: '1',
        name: `${choiceValue ? choiceValue.id : "picture"}.png`,
        percent: 100,
        status: 'done',
        url: value,
      });
    } else {
      setFile(undefined);
    }
  }, [value]);

  const handleBeforeUpload = async (file: RcFile) => {
    const isFileTypeAllowed = ALLOWED_IMAGE_TYPES.includes(file?.type);
    const isLessThanSizeLimit = file?.size < (1024 * 1024 * FILE_SIZE_LIMIT_IN_MB);

    if (!isFileTypeAllowed) {
        toast.error('Supported photo formats: JPG, PNG, GIF.');
    }
    if (!isLessThanSizeLimit) {
        toast.error(`Image must be smaller than ${FILE_SIZE_LIMIT_IN_MB} MB.`);
    }

    return (isFileTypeAllowed && isLessThanSizeLimit) || Upload.LIST_IGNORE;
}

  const handleUpload: UploadProps["customRequest"] = async (options) => {
    if (!url) {
      return;
    }

    const { file, onProgress } = options;
    try {
      const config = {
        headers: { "Content-Type": "image/jpg" },
        onUploadProgress: (event: any) => {
          onProgress?.({ percent: (event.loaded / event.total) * 100 });
        }
      };
      await apiUploadFileToS3({ url, file, config });
      const urlObject = new URL(url);
      const newValue = urlObject.hostname + urlObject.pathname;
      onChange?.(`https://${newValue}`);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length === 0) {
      return;
    }

    if (newFileList[0]?.status === "error") {
      toast.error("Sorry, something went wrong. Please try again.");
    } else {
      if (file) {
        setFile({...file, percent: newFileList[0].percent });
      }
      setFile(newFileList[0]);
    }
  };

  const handleRemove: UploadProps['onRemove'] = (file) => {
    setFile(undefined);
    onChange?.("");
  };

  return (
    <div className="g-interaction-edit-question-edit-form-field-upload-input">
      <ImgCrop modalClassName="g-interaction-edit-question-edit-form-field-upload-cropper" showGrid rotationSlider showReset>
        <Upload
          accept="image/*"
          customRequest={handleUpload}
          listType="picture-card"
          fileList={file ? [file] : []}
          maxCount={0}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
          onRemove={handleRemove}
          onPreview={() => setPreviewOpen(true)}
          disabled={url === undefined}
        >
          {file ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </ImgCrop>
      <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img alt="example" style={{ width: '100%' }} src={value} />
      </Modal>
    </div>
  );
}

export default UploadInput;