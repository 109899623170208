import { Button, Checkbox, Drawer, Form, Input, Table, Tooltip, Typography } from 'antd';
import { ArrowWithCircleIconBackground } from 'components/icons';
import { LeftOutlined, DeleteOutlined, RightOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { apiUpdatePage } from 'services/pages.services';
import { toast } from 'utils/toast';
import { EPreviewPlayButtonSize, IPage } from 'interfaces';
import GanderVideo from 'components/gander-video';
import { PagesContext } from 'context/pages-context';
import { IVideo } from 'interfaces/schema';
import { HELP_CENTER_LINK } from 'constants/hyper-links';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Pages-AbTestDrawerComponent";

interface IProps {
    setManageContentABTestDrawer: React.Dispatch<React.SetStateAction<IPage | null | undefined>>,
    manageContentABTestDrawer: IPage,
    onNextAbTestEvent: any;
    onPrevAbTestEvent: any;
}

const ABTestDrawer = ({ setManageContentABTestDrawer, manageContentABTestDrawer, onPrevAbTestEvent, onNextAbTestEvent }: IProps) => {
    const VIDEO_COUNT = 2;
    const [form] = Form.useForm();
    const [videos, setVideos] = useState<Array<IVideo> | undefined>(manageContentABTestDrawer?.videos);

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const { fetchPages } = useContext(PagesContext);

    const [isABTestEnabled] = useState(manageContentABTestDrawer?.abTest?.videos?.length === VIDEO_COUNT);

    const isValidData = (
        videos && (
            videos?.filter((vid: IVideo) => vid?.abTest).length < VIDEO_COUNT ||
            videos?.filter((vid: IVideo) => vid?.abTest).length > VIDEO_COUNT
        )
    );

    useEffect(() => {
        if (manageContentABTestDrawer && manageContentABTestDrawer?.videos) {
            form?.setFieldValue('name', manageContentABTestDrawer?.abTest?.name);
            const vids = manageContentABTestDrawer?.videos
                .map((vid: any) => {
                    vid.abTest = manageContentABTestDrawer?.abTest?.videos?.includes(vid.meta);
                    return vid;
                })
            setVideos(vids);
        }
    }, [manageContentABTestDrawer]);

    const onCheckboxClick = (isChecked: boolean, record: IVideo, index: number) => {
        if (videos) {
            const vids = cloneDeep(videos);
            vids[index].abTest = isChecked;
            setVideos(vids);
        }
    }

    const handleDisableAbTest = async () => {
        setIsDeleteLoading(true);
        try {
            const payload = {
                meta: manageContentABTestDrawer?.meta,
                abTest: null,
                mobileSettings: manageContentABTestDrawer?.mobileSettings,
                desktopSettings: manageContentABTestDrawer?.desktopSettings,
            }
            await apiUpdatePage(payload);
            toast.success("A/B Test setting removed successfully.");
            fetchPages();
            setManageContentABTestDrawer(null);
        } catch (e: any) {
            console.log("🚀 ~ file: ABTestDrawer/index.tsx ~ handleDisableAbTest ~ e", e);
            toast.error(e?.message);
        } finally {
            setIsDeleteLoading(false);
        }
    }

    const onFinish = async (values: { name: string }) => {
        setIsLoading(true);
        try {
            const selectedVideos =
                videos && videos
                    .filter((vid: IVideo) => vid?.abTest)
                    .map((vid: IVideo) => vid.meta);

            const abTest = {
                videos: selectedVideos || [],
                name: values?.name || '',
                createdAt: Date.now(),
            }
            const payload = {
                meta: manageContentABTestDrawer?.meta,
                abTest,
                mobileSettings: manageContentABTestDrawer?.mobileSettings,
                desktopSettings: manageContentABTestDrawer?.desktopSettings,
            }
            await apiUpdatePage(payload);
            toast.success("A/B Test setting saved successfully.");
            fetchPages();
            setManageContentABTestDrawer(null);
        } catch (e: any) {
            console.log("🚀 ~ file: ABTestDrawer/index.tsx ~ onFinish ~ e", e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Drawer
            title={
                <div className="g-pages-ab-test-drawer-header">
                    <div>
                        <Title className="g-ab-test-drawer-title" level={2} data-testid={`${dataTestIdPrefix}-Title`}>{manageContentABTestDrawer?.name} </Title>
                        <span className='g-description-text' data-testid={`${dataTestIdPrefix}-LastModified`}>Last modified {moment(manageContentABTestDrawer?.updatedAt).fromNow()}</span>
                    </div>

                    <div className="g-ab-test-drawer-nav-arrows">
                        <Button className='g-mx-8' size='large' icon={<LeftOutlined />} onClick={onPrevAbTestEvent} disabled={isLoading || isDeleteLoading} data-testid={`${dataTestIdPrefix}-PreviousAbTest`} />
                        <Button className='g-mx-8' size='large' icon={<RightOutlined />} onClick={onNextAbTestEvent} disabled={isLoading || isDeleteLoading} data-testid={`${dataTestIdPrefix}-NextAbTest`} />
                    </div>

                </div>
            }
            className="g-manage-content-ab-test-drawer"
            placement="right"
            onClose={() => setManageContentABTestDrawer(null)}
            open={!!(manageContentABTestDrawer)}
            width={451}
            height={100}
            closable={false}
            footer={
                <div className={`g-d-flex ${isABTestEnabled ? 'g-justify-between' : 'g-justify-end'} g-w-100`}>
                    {isABTestEnabled &&
                        <Tooltip title="Disable A/B Test">
                            <Button
                                danger={true}
                                size='large'
                                onClick={handleDisableAbTest}
                                icon={<DeleteOutlined />}
                                loading={isDeleteLoading}
                                disabled={isLoading || isDeleteLoading}
                            >
                            </Button>
                        </Tooltip>
                    }
                    <Button
                        type="primary"
                        htmlType="submit"
                        size='large'
                        className='g-align-self-end'
                        disabled={isValidData || isLoading || isDeleteLoading}
                        onClick={() => form.submit()}
                        loading={isLoading}
                    >
                        {isABTestEnabled ? 'Update A/B Test' : 'Enable A/B Test'}
                    </Button>
                </div>
            }
        >
            <div className='g-ab-test-drawer-content-container'>
                <Title level={3}>A/B test your creatives by selecting 2 videos below</Title>
                <span className='g-description-text'>
                    Use the check boxes below to test which video creative performs better. Learn more&nbsp;
                    <Button type='link' className='g-description-text g-padding-0' href={HELP_CENTER_LINK} target='_blank'>
                        <u>here</u>
                    </Button>
                </span>
                <ArrowWithCircleIconBackground style={{ transform: manageContentABTestDrawer ? 'rotate(0deg)' : 'rotate(180deg)' }} onClick={() => setManageContentABTestDrawer(null)} />
                <div className='g-ab-test-drawer-form-table-div'>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={() => console.log('onFinishFailed')}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="name"
                            label="Test Name"
                        >
                            <Input placeholder="Test Name" size='large' />
                        </Form.Item>
                    </Form>
                    <Table
                        className='g-table-default-padding'
                        dataSource={videos}
                        pagination={false}
                        rowKey="meta"
                        bordered={false}
                        columns={[
                            {
                                title: 'Videos',
                                dataIndex: 'meta',
                                key: 'meta',
                                render: (value, record) => (
                                    <div className='g-list-with-video-previews'>
                                        <div className='g-list-with-video-preview-avatar'>
                                            <GanderVideo video={record} buttonSize={EPreviewPlayButtonSize.SMALL} />
                                        </div>
                                        <div className='g-list-with-video-preview-title-description'>
                                            <Title ellipsis level={5} title={record?.displayName}>{record?.displayName}</Title>
                                            <span>Last modified {moment(record?.updatedAt).fromNow()}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                title: 'Action',
                                key: 'action',
                                width: '5%',
                                align: 'center',
                                render: (value, record, index) => (
                                    <div>
                                        <Checkbox onChange={(e) => onCheckboxClick(e.target.checked, record, index)} checked={record.abTest}></Checkbox>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </Drawer>
    )
}

export default ABTestDrawer;