import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IResponse {
    connected: boolean;
    message: string;
}

const apiSyncStatus = async (): Promise<IResponse> => {
    try {
        const url = `${REACT_APP_API_URL}/shop/sync-status`;
        const response = await axios.get<IResponse>(url);
        return response.data;
    } catch (e: any) {
        console.log(e);
        throw e;
    }
};

export default apiSyncStatus;
