import { Button, Input, Modal } from "antd"
import { useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "utils/toast";
import './index.scss';

interface IProps {
    setIsConfirmationCodeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    resetAppContextOnFieldChange: () => void;
}

const ConfirmationCodeModalComponent = ({ setIsConfirmationCodeModalVisible, resetAppContextOnFieldChange }: IProps) => {
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isResendLoading, setIsResendLoading] = useState<boolean>(false);

    const handlerResendCode = async (deviceType: string) => {
        try {
            setIsResendLoading(true);
            await Auth.verifyCurrentUserAttribute(deviceType);
            toast.success(`Verification code sent to your ${deviceType}`, 5);
            setIsResendLoading(false);
        } catch (error) {
            toast.error(`Error in verification mail: ${error}`, 5);
        }
    };

    const handlerConfirmCode = async (deviceType: string) => {
        try {
            setIsLoading(true);
            await Auth.verifyCurrentUserAttributeSubmit(deviceType, verificationCode);
            setIsConfirmationCodeModalVisible(false);
            toast.success(`${deviceType.charAt(0).toUpperCase() + deviceType.slice(1)} verified successfully`, 5);
            resetAppContextOnFieldChange();
        } catch (error) {
            console.log("🚀 ~ file: index.tsx ~ handlerConfirmCode ~ error", error);
            toast.error("Invalid verification code", 5);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            className="g-confirmation-code-modal-container"
            destroyOnClose={true}
            title="Enter confirmation code to verify your email"
            open={true}
            onCancel={() => setIsConfirmationCodeModalVisible(false)}
            footer={[
                <Button
                    key="resendBtn"
                    type="default"
                    onClick={() => handlerResendCode("email")}
                    disabled={isLoading}
                    loading={isResendLoading}
                    className="g-confirmation-code-modal-footer-reset-btn"
                >
                    Resend Code
                </Button>,
                <Button
                    key="submitBtn"
                    htmlType="submit"
                    type="primary"
                    onClick={() => handlerConfirmCode("email")}
                    disabled={!verificationCode || verificationCode.length < 6 || isResendLoading}
                    loading={isLoading}
                    className="g-confirmation-code-modal-footer-submit-btn"
                >
                    Submit
                </Button>,
            ]}
        >
            <div className="g-confirmation-code-modal-text">
                <p>
                    A confirmation code has been sent to your email address, please enter the code below to verify your email address.
                </p>
            </div>
            <Input
                pattern="^[0-9]+$"
                minLength={6}
                placeholder="Enter confirmation code"
                onChange={(evt) => setVerificationCode(evt.target.value)}
                className="g-account-input"
                size="large"
                onFocus={(elm) => elm.target.select()}
            />
        </Modal>
    )
}

export default ConfirmationCodeModalComponent;