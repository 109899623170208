import { Button, Dropdown, List, Typography } from "antd";
import moment from "moment";
import GanderVideo from "components/gander-video";
import { EPreviewPlayButtonSize } from "interfaces";
import { ReactComponent as ArrowDownBold } from "assets/img/arrow-down-bold.svg";
import { IVideo } from "interfaces/schema";

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = "InteractionsVideosColumn";

interface Props {
  videos: Partial<IVideo>[];
}

const InteractionsVideosColumn = ({ videos }: Props) => {

  const menu = (
    <div onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}>
      <List
        className='g-list-with-video-previews g-list-with-video-previews-fixed-height'
        itemLayout="horizontal"
        dataSource={videos}
        renderItem={(video: Partial<IVideo>) =>
          <List.Item data-testid={`${dataTestIdPrefix}-Dropdown-ListItem`}>
            <List.Item.Meta
              avatar={
                <div className='g-list-with-video-preview-avatar'>
                  <GanderVideo video={video} buttonSize={EPreviewPlayButtonSize.SMALL} />
                </div>
              }
              title={<div><Title ellipsis level={5} title={video?.displayName}>{video?.displayName}</Title></div>}
              description={`Last modified ${moment(video.updatedAt).fromNow()}`}
            />
          </List.Item>
        }
      />
    </div>
  );

  return (
    <Dropdown
      dropdownRender={() => menu}
      placement='bottom'
      trigger={['click']}
    >
      <div
        className='g-interactions-clickable-row g-d-flex g-align-center'
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}
        data-testid={`${dataTestIdPrefix}-Dropdown-Div`}
      >
        <Button type="primary" shape="circle" size='small'>{videos?.length}</Button>
        <Button type='link'>Videos</Button>
        <ArrowDownBold />
      </div>
    </Dropdown>
  )
}

export default InteractionsVideosColumn;