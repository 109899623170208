import { InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Card, Tooltip } from "antd";
import { IMetricCardProps } from "interfaces";

const { Meta } = Card;

export default function MetricCardComponent({ icon, color, title, information, body }: IMetricCardProps) {
  return (
    <>
      <Card
        title={
          <Meta
            avatar={<Avatar icon={icon} style={{ backgroundColor: color }} />}
            title={<p style={{ color: color }}>{title}</p>}
          ></Meta>
        }
        size="default"
        extra={
          <Tooltip title={information}>
            <InfoCircleOutlined />
          </Tooltip>
        }
      >
        {body}
      </Card>
    </>
  );
}
