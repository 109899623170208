import { useContext, useEffect, useState } from 'react';
import { Button, Card, Input, Typography, Form } from 'antd';
import { apiCreatePage } from 'services/pages.services';
import { apiGetPages } from 'services/page.services';
import { getURL } from 'app/pages/videos/utils';
import { toast } from "utils/toast";
import { PAGE_TYPE } from 'constants/store';
import { IPage, ICreatePage } from 'interfaces';
import { AppContext } from 'context/app-context';

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = 'Videos-EditVideoDetailsComponent-AddNewPage';

interface Props {
  newPageUrl: string;
  toggleCreateNewPage: (newPageUrl: string) => void;
  onSelectPage: (newPage: IPage) => Promise<void>;
}

const LinkVideoToNewPageComponent = ({ newPageUrl, toggleCreateNewPage, onSelectPage }: Props) => {
  const { shopDetails } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldValue('url', newPageUrl);
  }, [form])

  const handleCreatePage = async ({ url }: ICreatePage) => {
    try {
      setIsLoading(true);

      const response = getURL(url);
      const path = response.url!.pathname;
      await apiCreatePage({
        name: path,
        url: path,
        type: PAGE_TYPE.Page,
      });
      const { data: allPages } = await apiGetPages({});
      const page = (allPages as IPage[]).find(({ url: pageUrl }) => pageUrl === path);
      onSelectPage(page!);

      toggleCreateNewPage('');
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message, 2);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      data-testid={`${dataTestIdPrefix}-Card`}
      className="g-add-edit-video-details-new-page-card"
      loading={isLoading}
    >
      <div className="g-add-edit-video-details-new-page-container">
        <Title data-testid={`${dataTestIdPrefix}-Title`} level={5}>
          Connect video to a new page
        </Title>
        <Form
          data-testid={`${dataTestIdPrefix}-Form`}
          form={form}
          layout="vertical"
          onFinish={handleCreatePage}
          autoComplete="off"
        >
          <Form.Item
            name="url"
            label="URL"
            rules={[
              { required: true, message: 'This field is required' },
              {
                validator: async (_rule, value: string) => {
                  const response = getURL(value);

                  if (!response.isValid) {
                    throw new Error("It looks like this is not a valid url");
                  }

                  const baseUrl = response.url!.hostname;

                  if (!shopDetails?.domain.endsWith(baseUrl) && !baseUrl.endsWith(shopDetails!.domain)) {
                    throw new Error(`The domain name of the shop does not match that of the page. Please ensure the page name starts with ${shopDetails?.domain!}`);
                  }
                }
              }
            ]}
          >
            <Input
              name="url"
              size="large"
              placeholder="Enter new Page URL"
            />
          </Form.Item>
        </Form>
        <div className="g-add-edit-video-details-new-page-buttons-container">
          <Button
            type="default"
            onClick={() => toggleCreateNewPage('')}
            disabled={isLoading}
            data-testid={`${dataTestIdPrefix}-BackButton`}
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={isLoading}
            data-testid={`${dataTestIdPrefix}-ConfirmButton`}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Card >
  );
}

export default LinkVideoToNewPageComponent;