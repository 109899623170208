import { Button, Dropdown, Input, Tabs, TabsProps, Tooltip, Typography } from "antd";
import { RightOutlined, ThunderboltFilled, CaretDownOutlined } from '@ant-design/icons';
import { searchSvg } from 'components/icons';
import { useContext, useState } from "react";
import { toast } from "utils/toast";
import { REQUEST_CONTENT_GET_UGC_CONTENT_BANNER_LEARN_MORE_LINK } from "constants/hyper-links";
import { AppContext } from "context/app-context";
import { InfoCircleOutlined } from "@ant-design/icons";
import { createUrlWithHostAndPathName } from "utils/common-functions";
import GenerateNewUrlConfirmationModal from "../videos/upload-videos-header-component/generate-new-url-confirmation-modal";
import { getGetUploadUrlDropdownItems } from "../videos/upload-videos-header-component";
import ExternalUploadedContentTable from "./external-uploaded-content-table";
import RequestContentTable from "./request-content-table";

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = "request-content";

const RequestContentPage = () => {
    const { shopDetails } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [currentTabKeyIndexId, setCurrentTabKeyIndexId] = useState(1);
    const [isShowRequestContentTab, setIsShowRequestContentTab] = useState<boolean | undefined>(undefined);

    const copyExternalVideoUploadUrlToClipBoard = async (externalVideoUploadString: string) => {
        try {
            let url = new URL(createUrlWithHostAndPathName(window.location.hostname, "/public/upload-and-link-videos"));
            url.searchParams.set("externalUploadId", externalVideoUploadString);
            await navigator.clipboard.writeText(url.href);
            toast.info("External Video Upload URL Copied");
        } catch (error) {
            console.log("🚀 ~ file: columns.tsx ~ copyUrlToClipBoard ~ error", error);
        }
    };

    const myContentTableItems: TabsProps['items'] = [
        {
            key: '1',
            label: <b data-testid={`${dataTestIdPrefix}-ExternalUploadTabButton`}>External Uploads</b>,
            children: <ExternalUploadedContentTable searchTerm={searchTerm} isActive={currentTabKeyIndexId === 1} />,
        },
        {
            key: '2',
            label: <b data-testid={`${dataTestIdPrefix}-RequestCotnentTabButton`}>From Gander Creators</b>,
            forceRender: true,
            children: <RequestContentTable searchTerm={searchTerm} setIsShowRequestContentTab={setIsShowRequestContentTab} isActive={currentTabKeyIndexId === 2} />,
        }
    ];

    const onContentTabChange = (keyId: string) => {
        setCurrentTabKeyIndexId(Number(keyId));
        setSearchTerm('');
    }

    return (
        <div className="g-request-content-container">
            <div className="g-request-content-card" data-testid={`${dataTestIdPrefix}-TopBanner`}>
                <Title level={3}>Get UGC content for your website & beyond</Title>
                <Title level={5}>User-Generated content establishes trust between customer and brand.</Title>
                <div>
                    <p>Here's how to use Gander to request more videos:</p>
                    <ol>
                        <li>Click Get Upload Link</li>
                        <li>Select Generate or Copy URL</li>
                        <li>Share this URL with anyone who you'd like to receive content from (pro tip: send this to shoppers post-purchase to get more videos!)</li>
                        <li>Receive notification when you get new videos uploaded to your portal!</li>
                        <li>Review, download, and/or add submitted to your site.</li>
                    </ol>
                </div>
                <Button 
                    type="primary" 
                    target='_blank' 
                    href={REQUEST_CONTENT_GET_UGC_CONTENT_BANNER_LEARN_MORE_LINK}
                    data-testid={`${dataTestIdPrefix}-TopBannerLinkButton`}
                >
                    Learn More <RightOutlined />
                </Button>
            </div>
            <div className="g-request-content-title-wrapper">
                <div>
                    <Title level={3}>
                        My Content
                    </Title>
                </div>
                <div className='g-d-flex'>
                    <Input size="large" placeholder="Search by URL" className="g-request-content-search-input" prefix={searchSvg} onChange={(e) => setSearchTerm(e?.target?.value)} value={searchTerm} />
                    <Dropdown menu={getGetUploadUrlDropdownItems(shopDetails, copyExternalVideoUploadUrlToClipBoard, setIsConfirmationModalOpen)} trigger={["click"]} placement="bottom" autoAdjustOverflow={false}>
                        <Button type="primary" className='g-ml-16 g-video-upload-button g-video-external-upload-button' size="large">
                            <div className="g-video-upload-button-content">
                                <ThunderboltFilled className="g-video-upload-button-content-tooltip-icon" />
                                <div className="g-video-upload-button-content-tooltip-text">
                                    Get Upload Link
                                </div>
                                <Tooltip title="Allows you to generate external upload url which you can share with anyone who you'd like to receive content from.">
                                    <InfoCircleOutlined className="g-video-upload-button-content-tooltip-icon" />
                                </Tooltip>
                                <span className="g-video-upload-button-content-drop-icon">
                                    <CaretDownOutlined />
                                </span>
                            </div>
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <div className={`${isShowRequestContentTab === undefined && 'g-hide-request-content-tab'} ${currentTabKeyIndexId === 1 && 'g-external-upload-content-table-container'} ${currentTabKeyIndexId === 2 && 'g-request-content-table-container'}`}>
                <Tabs
                    items={isShowRequestContentTab || isShowRequestContentTab === undefined ? myContentTableItems : [myContentTableItems[0]]}
                    onChange={onContentTabChange}
                    destroyInactiveTabPane={false}
                    defaultActiveKey="1"
                />
            </div>

            <GenerateNewUrlConfirmationModal
                isConfirmationModalOpen={isConfirmationModalOpen}
                setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                copyExternalVideoUploadUrlToClipBoard={copyExternalVideoUploadUrlToClipBoard}
                dataTestIdPrefix={dataTestIdPrefix}
            />
        </div>
    )
}

export default RequestContentPage;