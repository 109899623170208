import { Typography } from "antd";
import ShortAnswerChoiceBreakdown from "./short-answer-choice-breakdown-component";
import { EQuestionType } from "interfaces/schema";
import { IQuestionResponseOverviewChoiceResponse } from "interfaces/snowflake";
import { EInteractionResultsTabs } from "interfaces";

import "./index.scss";

const dataTestIdPrefix = "ChoiceBreakdown";

const { Text } = Typography;

interface Props {
    choiceResponse: IQuestionResponseOverviewChoiceResponse;
    totalViewedCount: number;
    totalSubmittedCount: number;
    type: EQuestionType;
    setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const ChoiceBreakdown = ({
    choiceResponse: { choice_text: text, choice_picture_url: pictureUrl, submitted_count: submittedCount },
    totalViewedCount,
    totalSubmittedCount,
    type,
    setActiveTab,
}: Props) => {
    if (type === EQuestionType.SHORT_ANSWER) {
        return <ShortAnswerChoiceBreakdown setActiveTab={setActiveTab} />;
    }

    const denominator = type === EQuestionType.CALL_TO_ACTION ? totalViewedCount : totalSubmittedCount;
    const submissionPercentage = denominator === 0 ? 0 : (submittedCount / denominator) * 100;

    return (
        <>
            <div className="g-interaction-edit-summary-card-main-card-choice-header">
                {type === EQuestionType.PICTURE_CHOICE ? (
                    <div className="g-interaction-edit-summary-card-main-card-picture-choice-image-container">
                        <img
                            className="g-interaction-edit-summary-card-main-card-picture-choice-image"
                            alt="id"
                            src={pictureUrl}
                            data-testid={`${dataTestIdPrefix}-Image`}
                        />
                    </div>
                ) : (
                    <Text className="g-interaction-edit-summary-card-main-card-choice-title" data-testid={`${dataTestIdPrefix}-Text`}>
                        {text}
                    </Text>
                )}
                <div className="g-interaction-edit-summary-card-main-card-choice-sub-title-container">
                    <Text className="g-interaction-edit-summary-card-main-card-choice-sub-title-count" data-testid={`${dataTestIdPrefix}-Subtitle-Count`}>
                        {submittedCount} responses
                    </Text>
                    <Text
                        className="g-interaction-edit-summary-card-main-card-choice-sub-title-percentage"
                        data-testid={`${dataTestIdPrefix}-Subtitle-Percentage`}
                    >
                        {Math.round(submissionPercentage)}%
                    </Text>
                </div>
            </div>
            <div className="g-interaction-edit-summary-card-main-card-choice-body">
                <div className="g-interaction-edit-synnary-card-main-card-choice-views" />
                <div
                    className="g-interaction-edit-synnary-card-main-card-choice-submitted"
                    style={{ width: `${submissionPercentage}%` }}
                    data-testid={`${dataTestIdPrefix}-Submitted-Bar`}
                />
            </div>
        </>
    );
};

export default ChoiceBreakdown;
