import { TMetricGroup } from "interfaces";
import { sumBy } from "lodash";

export interface IPercentageValue {
  device: string;
  value: number;
}

export const DEFAULT_LEGEND_VALUE: IPercentageValue[] = [
  { "device": "Desktop", "value": 0 },
  { "device": "Mobile", "value": 0 }
];

interface IDeviceSum {
  ID: string;
  VALUE: number;
}

export const calculateDeviceLegendData = (deviceBreakdownValues: TMetricGroup): IPercentageValue[] => {
  if (deviceBreakdownValues?.length) {
    const allDeviceSum = sumBy(deviceBreakdownValues, 'VALUE');

    const sumData: IDeviceSum[] = Object.values([...deviceBreakdownValues].reduce((acc: any, { ID, VALUE }) => {
      acc[ID] = { ID, VALUE: (acc[ID] ? acc[ID].VALUE : 0) + VALUE };
      return acc;
    }, {}));

    const percentageResult = sumData.map((k: IDeviceSum) => {
      return { device: k?.ID, value: Number((k?.VALUE / allDeviceSum * 100)?.toFixed(0)) };
    });
    return percentageResult;
  }
  return DEFAULT_LEGEND_VALUE;
};