import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const { REACT_APP_API_URL } = process.env;

interface Payload {
  questionId: string;
  choiceId: string;
}

interface Response {
  url: string;
  message: string;
}

const apiGetS3PresignedUrl = async ({ questionId, choiceId }: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/questions/presigned-url?questionId=${questionId}&choiceId=${choiceId}&pictureId=${uuidv4()}`;
    const response = await axios.get<Response>(url);
    return response.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiGetS3PresignedUrl;