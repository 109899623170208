import { Dispatch, memo, SetStateAction, useState } from "react";
import ReactPlayer from "react-player";
import { Input, Spin } from "antd";
import { IInstagramVideo } from "interfaces/instagram";

import "./index.scss";

const dataTestIdPrefix = "InstagramVideoCard";

interface IProps {
    video: IInstagramVideo;
    selected: boolean;
    active: boolean;
    handleAdd: (video: IInstagramVideo) => void;
    handleRemove: (video: IInstagramVideo) => void;
    setActive: Dispatch<SetStateAction<string>>;
}

const InstagramVideoCard = ({ video, selected, active, handleAdd, handleRemove, setActive }: IProps) => {
    const [ready, setReady] = useState<boolean>(false);
    return (
        <div
            key={video.id}
            className={`g-instagram-modal-card ${selected ? "g-instagram-modal-card-selected" : ""}`}
            onClick={selected ? () => handleRemove(video) : () => handleAdd(video)}
            data-testid={`${dataTestIdPrefix}-Div`}
        >
            {!ready && (
                <div className="g-instagram-modal-card-video-loader">
                    <Spin />
                </div>
            )}
            <div
                className="g-instagram-modal-card-video-wrapper"
                onMouseEnter={() => setActive(video.id)}
                data-testid={`${dataTestIdPrefix}-Video-Wrapper`}
            >
                <ReactPlayer
                    width={159}
                    height={282}
                    loop
                    playing={active}
                    muted
                    url={video.videoUrl}
                    onReady={() => setReady(true)}
                />
            </div>
            {ready && (
                <div className="g-instagram-modal-card-select-checkbox-wrapper">
                    <Input
                        className="g-instagram-modal-card-select-checkbox"
                        type="checkbox"
                        checked={selected}
                        data-testid={`${dataTestIdPrefix}-Input`}
                    />
                </div>
            )}
            {ready && (
                <div
                    className="g-instagram-modal-card-caption-wrapper"
                    data-testid={`${dataTestIdPrefix}-Caption`}>{video.title}
                </div>
            )}
        </div>
    );
};

export default memo(InstagramVideoCard);
