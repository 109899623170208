import { Typography } from "antd";
import PreviewInteraction from "./preview-interaction-component";
import { IQuestion } from "interfaces/schema";
import PreviewImage from 'assets/img/preview.jpg';

import "./index.scss";

const { Title } = Typography;

interface Props {
  selectedQuestion: IQuestion | undefined;
}

const Preview = ({ selectedQuestion }: Props) => {
  return (
    <div className="g-interation-edit-preview-panel-container">
      <Title className="g-interation-edit-your-questions-panel-title" level={4}>Preview</Title>
      <div className="g-interation-edit-preview-panel-body-container">
        <div className="g-interation-edit-preview-panel-body-grid">
          <img className="g-interation-edit-preview-panel-thumbnail-image" src={PreviewImage} alt="gander" />
          <div className="g-interation-edit-preview-panel-thumbnail-container">
            <PreviewInteraction question={selectedQuestion} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preview;