import { Button, Tooltip, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { IInteraction } from "interfaces/schema";

const { Title } = Typography;

const dataTestIdPrefix = "Interactions-DetailsDrawer";

interface IProps {
  activeInteraction: IInteraction | undefined;
  isLoading: boolean;
  isDeleteLoading?: boolean;
  isSearchVideoLoading: boolean;
  onNextViewDetailsEvent: () => void;
  onPrevViewDetailsEvent: () => void;
}

const DetailsDrawerTitle = ({
  activeInteraction,
  isLoading,
  isDeleteLoading,
  isSearchVideoLoading,
  onNextViewDetailsEvent,
  onPrevViewDetailsEvent,
}: IProps) => (
  <div className="g-d-flex g-justify-between">
    <div className="g-interaction-details-drawer-title-container">
      <div className="g-d-flex g-align-center">
        <Tooltip title={activeInteraction?.name}>
          <Title level={2} className="g-interaction-details-drawer-title" data-testid={`${dataTestIdPrefix}-NameLabel`}>
            {activeInteraction?.name}
          </Title>
        </Tooltip>
      </div>
      <span className="g-description-text" data-testid={`${dataTestIdPrefix}-LastModified`}>
        Last modified {moment(activeInteraction?.updatedAt).fromNow()}
      </span>
    </div>

    <div>
      <Button
        className="g-mx-8"
        size="large"
        icon={<LeftOutlined />}
        onClick={onPrevViewDetailsEvent}
        disabled={isLoading || isDeleteLoading || isSearchVideoLoading}
        data-testid={`${dataTestIdPrefix}-PreviousInteractionDetail`}
      />
      <Button
        className="g-mx-8"
        size="large"
        icon={<RightOutlined />}
        onClick={onNextViewDetailsEvent}
        disabled={isLoading || isDeleteLoading || isSearchVideoLoading}
        data-testid={`${dataTestIdPrefix}-NextInteractionDetail`}
      />
    </div>
  </div>
);

export default DetailsDrawerTitle;
