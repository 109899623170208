import { Dispatch, SetStateAction, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Button, Divider, Typography } from "antd";
import InstagramVideosSelector from "./instagram-videos-selector-component";
import { IInstagramVideo } from "interfaces/instagram";
import { apiUploadVideos } from "services/instagram";
import { toast } from "utils/toast";
import { VideosContext } from "context/videos-context";

import "./index.scss";

const { Text } = Typography;

const dataTestIdPrefix = "instagram-modal";

interface IProps {
    username: string | undefined;
    userId: string | undefined;
    cursor: string | undefined;
    videos: IInstagramVideo[];
    selectedVideos: IInstagramVideo[];
    setCursor: Dispatch<SetStateAction<string | undefined>>;
    setVideos: Dispatch<SetStateAction<IInstagramVideo[]>>;
    setSelectedVideos: Dispatch<SetStateAction<IInstagramVideo[]>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setIsShowInstagramModal: Dispatch<SetStateAction<boolean>>;
}

const InstagramModalSecondStepContent = ({
    username,
    userId,
    cursor,
    videos,
    selectedVideos,
    setCursor,
    setVideos,
    setSelectedVideos,
    setIsLoading,
    setIsShowInstagramModal,
}: IProps) => {
    const { fetchVideos, fetchRecentlyAddedVideos } = useContext(VideosContext);

    const importInstagramVideos = async () => {
        try {
            setIsLoading(true);
            await apiUploadVideos({ videoData: selectedVideos.map((selectedVideo, index) => {
                const meta = uuidv4();
                return {
                    meta,
                    name: `${username}${index}.mp4`,
                    originalMediaUrl: `${meta}.mp4`,
                    ...selectedVideo,
                };
            }) });
            fetchVideos();
            fetchRecentlyAddedVideos();
            setIsShowInstagramModal(false);
        } catch (e) {
            toast.error("Sorry, something went wrong. Please try again.");
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="g-instagram-modal-step-two-body">
            <Text className="g-instagram-modal-disclaimer">
                <strong>Disclaimer</strong>: We do not endorse, promote, or encourage the downloading of copyrighted materials or videos without permission from
                the rightful owner. The use of our website and services to download videos from social media platforms is intended for personal use only, and
                you should only download videos that you have the right to download, such as your own videos or videos that have been made public with
                permission from the rightful owner.
                <br />
                <br />
                We do not take responsibility for any damages or loss incurred by the use of our website or the services provided. By using our website and
                services, you agree to comply with all applicable laws and regulations related to the use of our website and video downloading.
            </Text>
            <InstagramVideosSelector
                userId={userId}
                cursor={cursor}
                videos={videos}
                selectedVideos={selectedVideos}
                setCursor={setCursor}
                setVideos={setVideos}
                setSelectedVideos={setSelectedVideos}
            />
            <Divider />
            <Button className="g-instagram-modal-import-button" data-testid={`${dataTestIdPrefix}-import-button`} type="primary" onClick={() => importInstagramVideos()}>
                {selectedVideos.length === 0 ? "Import Selected Videos" : `Import (${selectedVideos.length}) Selected Videos`}
            </Button>
        </div>
    );
};

export default InstagramModalSecondStepContent;
