import { Button, Modal, Typography } from "antd";
import { AppContext } from "context/app-context";
import { EDefaultPlan, ISubscriptionPlan } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { apiGetStripeCustomer, updateSubscription } from "services/stripe";
import { toast } from "utils/toast";

import "./index.scss";

const { Text } = Typography;

const dataTestIdPrefix = "Settings-Billing-Subscription-Component";

interface IProps {
    currentPlan: ISubscriptionPlan | undefined;
    selectedPlan: ISubscriptionPlan;
    closeModal: () => void;
    redirectToCustomerPortal: () => void;
    refetchPlans: () => Promise<void>;
}

const StripeSubscriptionUpdateConfirmationModal = ({ currentPlan, selectedPlan, closeModal, redirectToCustomerPortal, refetchPlans }: IProps) => {
    const { activeSubscription, setActiveSubscription, shopDetails } = useContext(AppContext);
    const [isRequirePaymentMethod, setIsRequirePaymentMethod] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkIfCustomerHasDefaultPaymentMethod = async () => {
            try {
                setIsLoading(true);
                if (!shopDetails?.stripeCustomerId) {
                    return;
                }
                const response = await apiGetStripeCustomer({ customerId: shopDetails?.stripeCustomerId });
                if (!response.customer?.invoice_settings?.default_payment_method) {
                    setIsRequirePaymentMethod(true);
                }
            } catch (e) {
                console.log(e);
                toast.error("Something went wrong, please try again.");
            } finally {
                setIsLoading(false);
            }
        };

        const usedTrialDays = shopDetails?.usedTrialDays || 0;
        if (usedTrialDays >= selectedPlan.trialDays && selectedPlan.meta !== EDefaultPlan.NEW_FREE_PLAN) {
            checkIfCustomerHasDefaultPaymentMethod();
        }
    }, []);

    const confirmStripeSubscriptionUpdate = async () => {
        try {
            setIsLoading(true);
            const response = await updateSubscription({
                subscriptionId: activeSubscription!.id,
                oldSubscriptionItemId: activeSubscription!.items!.data[0].id,
                newStripeGanderAccessPriceId: selectedPlan?.stripeGanderAccessPriceId,
                newPlanMeta: selectedPlan?.meta,
            });
            setActiveSubscription({
                ...response.subscription,
                activePlan: selectedPlan,
            });
            refetchPlans();
            toast.success("Subscription successfully changed");
            closeModal();
        } catch (e: any) {
            console.log(e);
            toast.error("Failed to change subscription");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            data-testid={`${dataTestIdPrefix}-StripeSubscriptionUpdateConfirmationModal`}
            className="g-stripe-subscription-update-confirmation-modal"
            centered={true}
            width={600}
            open={true}
            onCancel={() => closeModal()}
            closable={false}
            maskClosable={false}
            okText="Confirm"
            okType="default"
            okButtonProps={{ type: "primary", disabled: isRequirePaymentMethod }}
            confirmLoading={isLoading}
            cancelButtonProps={{ disabled: isLoading }}
            onOk={confirmStripeSubscriptionUpdate}
        >
            <b data-testid={`${dataTestIdPrefix}-StripeSubscriptionUpdateConfirmationModalText`}>
                {`Are you sure you want change your plan from "${currentPlan?.displayName}${currentPlan?.billingCycle ? ' (' + currentPlan?.billingCycle + ')' : ''}" to "${selectedPlan.displayName}${selectedPlan?.billingCycle ? ' (' + selectedPlan?.billingCycle + ')' : ''}"?`}
            </b>

            {isRequirePaymentMethod && (
                <Text>
                    In order to continue, please click&nbsp;
                    <Button type="link" className="g-padding-0" onClick={() => redirectToCustomerPortal()} data-testid={`${dataTestIdPrefix}-editAccountLink`}>
                        <u>here</u>
                    </Button>
                    &nbsp;to add a payment method.
                </Text>
            )}
        </Modal>
    );
};

export default StripeSubscriptionUpdateConfirmationModal;
