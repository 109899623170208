import { BLOCKED_EMAIL_DOMAINS } from "./constants";

export const isPublicEmailDomain = (emailDomain: string) => {
    for (const publicEmailDomain of BLOCKED_EMAIL_DOMAINS) {
        if (emailDomain.includes(publicEmailDomain)) {
            return true;
        }
    }

    return false;
};