import { EVideoSource } from "interfaces/schema";

const MB = 1000000; //  1000000 = 1mb
export const MAX_FILE_SIZE_ALLOWED_TO_UPLOAD = MB * 300;
export const VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST = {
    [EVideoSource.INSTAGRAM_IMPORT]: { tag: "rgba(255, 160, 67, 0.1)", text: "#FFA043" },
    [EVideoSource.TIKTOK_IMPORT]: { tag: "rgba(255, 160, 67, 0.1)", text: "#FFA043" },
    [EVideoSource.UPLOADED_CONTENT]: { tag: "rgba(132, 129, 138, 0.1)", text: "#2E2C34" },
    [EVideoSource.REQUESTED_CONTENT]: { tag: "rgba(22, 118, 243, 0.1)", text: "#1676F3" },
    [EVideoSource.EXTERNAL_UPLOAD]: { tag: "rgba(79, 191, 103, 0.1)", text: "#4FBF67" },
};