import uploadVideoS3 from "context/uploadVideoS3";
import { IUploadFile } from "interfaces";
import { EVideoSource } from "interfaces/schema";
import { toast } from "utils/toast";
import { IShopDetailsPayload } from "./interface";

export const isValidUrl = (urlString: string, shopDomain: string) => {
  try {
    const urlStringUpdated = urlString.includes("https://") ? urlString : `https://${urlString}`;
    const inputElement = document.createElement("input");
    inputElement.type = "url";
    inputElement.value = urlStringUpdated;

    const urlDomainHost = new URL(urlStringUpdated)?.hostname;

    if (!inputElement.checkValidity() || urlDomainHost !== shopDomain) {
      return false;
    }
    return true;
  } catch (e) {
    console.log('error : ', e);
    return false;
  }
};

export const isValidFullName = (fullName: string): boolean => {
  const regName = /^[A-Za-z\s]{1,}[.]{0,1}[A-Za-z\s]{0,}$/;
  const splittedFullName = fullName.split(" ");
  if (splittedFullName?.length > 1 && regName.test(fullName)) {
    return true;
  }
  return false;
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const uploadVideo = (
  file: IUploadFile,
  fileRecord: IUploadFile,
  filesListRef: React.MutableRefObject<IUploadFile[]>,
  setFileList: React.Dispatch<React.SetStateAction<IUploadFile[]>>,
  shopDetail: IShopDetailsPayload | undefined
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fileObj = {
        uid: file?.uid,
        lastModified: file?.lastModified,
        lastModifiedDate: file?.lastModifiedDate,
        originFileObj: file,
        name: file?.name,
        size: file?.size,
        type: file?.type,
        percent: 0,
        status: "uploading",
        productUrl: "",
        productUrlValidationError: "",
        hdThumbnail: fileRecord?.hdThumbnail,
      };

      const uploadRes: { status: string; message: string; name?: string } = await uploadVideoS3(
        fileObj,
        fileRecord?.meta!,
        file,
        filesListRef,
        setFileList,
        shopDetail?.shopUUID!,
        EVideoSource.EXTERNAL_UPLOAD
      );

      if (uploadRes?.status === "success") {
        toast.success(uploadRes?.message);
        return resolve(true);
      } else if (uploadRes?.status === "error") {
        toast.error(`${uploadRes?.name} file upload failed.`);
        return resolve(false);
      }
    } catch (error) {
      toast.error(`file upload failed.`);
      console.log("Error uploading file:--------- ", error);
      return resolve(false);
    }
  });
};

export const getIsVideosProductUrlValid = (fileListObj: IUploadFile[]) => {
  let isValidUrls = true;
  fileListObj?.forEach((fileObj: IUploadFile) => {
    if (fileObj?.productUrlValidationError) {
      isValidUrls = false;
    }
  });
  return isValidUrls;
}

export const previewFileThumbnail = (file: File) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};