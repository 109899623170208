import Header from './header';
import SignInFormFields from './sign-in-form-fields';
import SignInFooter from './sign-in-footer';
import SignUpFormFields from './sign-up-form-fields';
import ConfirmSignUpHeader from './confirm-sign-up-header';
import ResetPasswordHeader from './reset-password-header';
import ConfirmResetPasswordHeader from './confirm-reset-password-header';
import ConfirmResetPasswordFooter from './confirm-reset-password-footer';
import NewPasswordFormFields from '../../../../../components/auth/new-password-form-fields';

const components = {
    Header: Header,
    SignIn: {
        FormFields: SignInFormFields,
        Footer: SignInFooter,
    },
    SignUp: {
        FormFields: SignUpFormFields,
    },
    ConfirmSignUp: {
        Header: ConfirmSignUpHeader,
    },
    ResetPassword: {
        Header: ResetPasswordHeader,
    },
    ConfirmResetPassword: {
        Header: ConfirmResetPasswordHeader,
        Footer: ConfirmResetPasswordFooter,
    },
    ForceNewPassword: {
        FormFields: NewPasswordFormFields,
    }
};

export default components;