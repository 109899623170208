import { Button } from "antd";
import { EInteractionResultsTabs } from "interfaces";

import "./index.scss";

const dataTestIdPrefix = "ShortAnswerChoiceBreakdown";

interface Props {
  setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const ShortAnswerChoiceBreakdown = ({ setActiveTab }: Props) => {
  return (
    <div className="g-interaction-edit-summary-card-main-card-short-answer-choice-button-container">
      <Button
        type="primary"
        onClick={() => setActiveTab(EInteractionResultsTabs.RESPONSES)}
        data-testid={`${dataTestIdPrefix}-Button`}
      >
        View Response Details
      </Button>
    </div>
  );
};

export default ShortAnswerChoiceBreakdown;