import Stripe from "stripe";
import { IDeviceSettings, IVideo } from "./schema";

export enum EPreviewPlayButtonSize {
  TINY = "g-play-button-tiny",
  SMALL = "g-play-button-small",
  MEDIUM = "g-play-button-medium",
  LARGE = "g-play-button-large",
}

export enum EProgress {
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  PROGRESSING = "PROGRESSING",
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
}

export enum EManageContentTab {
  VIDEOS = "Videos",
  PAGES = "Pages",
}

export enum EPlanType {
  FREE = "free",
  BASIC = "basic",
  GROWTH = "growth",
}

export enum EDefaultPlan {
  CONTENT_ONLY_PLAN = "content-only-plan",
  BASIC_PLAN = "basic-plan",
  GROWTH_PLAN = "growth-plan",
  NEW_FREE_PLAN = "new-free-plan",
  BASIC_15_MONTHLY_PLAN = "basic-15-monthly-plan",
  BASIC_120_ANNUALLY_PLAN = "basic-120-annually-plan",
  GROWTH_99_MONTHLY_PLAN = "growth-99-monthly-plan",
  GROWTH_1080_ANNUALLY_PLAN = "growth-1080-annually-plan",
  PROFESSIONAL_PLAN = "new-professional-plan",
}
export interface IProduct {
  id: string;
  name?: string;
  thumbnail?: string;
  productUrl?: string;
  variantId?: string;
  isDeleted?: boolean;
}

// Pages Related interfaces starts
// in future we will move this to separate file if its become too lengthy

export interface IPage {
  id: string;
  meta: string;
  createdAt: number;
  updatedAt: number;
  url: string;
  name: string;
  abTest?: { name: string; videos: Array<string>, createdAt: number } | null;
  videos?: IVideo[];
  desktopSettings: IDeviceSettings;
  mobileSettings: IDeviceSettings;
}

export interface ICreatePage {
  name: string;
  url: string;
  type: string;
}
export interface IUpdatePage {
  meta: string;
  name?: string;
  url?: string;
  type?: string;
  abTest?: { name: string; videos: Array<string> } | null;
  videoOrdering?: string[];
  desktopSettings: IDeviceSettings;
  mobileSettings: IDeviceSettings;
}

export interface IGetPageApiPayload {
  filterName?: string;
  requestLastEvaluatedKey?: ILoadMorePageLastEvaluatedKey;
}

export interface ILoadMorePageLastEvaluatedKey {
  id: string;
  createdAt: number;
  meta: string;
}
// Pages Related interfaces ends

export interface IUser {
  name: string;
  email: string;
}

export interface ILoadMoreVideoLastEvaluatedKey {
  id: string;
  createdAt: number;
  meta: string;
}

export interface IGetVideoApiPayload {
  filterName?: string;
  requestLastEvaluatedKey?: ILoadMoreVideoLastEvaluatedKey;
  isExternalOnly?: boolean;
}

export interface IMetricPayload {
  startDate: string;
  endDate: string;
  device?: string;
  interactionId?: string;
}

export interface IMetricValue {
  ID: string;
  VALUE: number;
}

export interface IMetricGroup {
  ID: string;
  TIMESTAMP: Date;
  VALUE: number;
  NUMBER_OF_ENGAGED_VISITOR?: number;
}

export type TMetric = { [id: string]: number };

export type TMetricValue = IMetricValue[];

export type TMetricGroup = IMetricGroup[];

export enum EMetricTimePeriod {
  YEAR = "YEAR",
  MONTH = "MONTH",
  WEEK = "WEEK",
  DAY = "DAY",
}

export enum EMetricTimeString {
  LAST_DAY = "Last Day",
  LAST_7_DAYS = "Last 7 Days",
  LAST_28_DAYS = "Last 28 Days",
  LAST_3_MONTHS = "Last 3 Months",
  LAST_6_MONTHS = "Last 6 Months",
  LAST_12_MONTHS = "Last 12 Months",
  ALL_TIME = "All Time",
  CUSTOM_TIME = "Custom",
}


export enum EMetricValues {
  ALL_QUERIES_NON_GROUPED = "all-queries-non-grouped",
  VIDEO_ANALYTICS = "group/video-analytics",
  PERFORMANCE = "performance",
  ON_SITE_ENGAGEMENT_GROUP = "group/on-site-engagement",
  DEVICE_BREAKDOWN_GROUP = "group/device-breakdown",
  ALL_QUERIES_NON_GROUPED_NON_SHOPIFY = "non-shopify/all-queries-non-grouped",
  INTERACTION_RESPONSE_OVERVIEW = "interaction-response-overview",
  INTERACTION_QUESTION_ANALYSIS_OVERVIEW = "interaction-question-analysis-overview",
  QUESTION_RESPONSES_OVERVIEW = "question-responses-overview",
  INTERACTION_RESPONSES = "interaction-responses",
  INTERACTION_REPORT = "interaction-report",
  TOP_VIDEOS_SHOPIFY = "top-videos",
  TOP_VIDEOS_NON_SHOPIFY = "non-shopify/top-videos",
  AB_TESTING_RESULTS = "ab-testing",
  AB_TESTING_METRICS = "ab-testing-metrics",
  CONVERSION_RATE_BY_VISITOR_TYPE_SHOPIFY = "conversion-rate-by-visitor-type",
  CONVERSION_RATE_BY_VISITOR_TYPE_NON_SHOPIFY = "non-shopify/conversion-rate-by-visitor-type",
  CONVERSION_RATE_BY_DEVICE_SHOPIFY = "conversion-rate-by-device",
  CONVERSION_RATE_BY_DEVICE_NON_SHOPIFY = "non-shopify/conversion-rate-by-device",
  AVERAGE_ORDER_VALUE_BY_DEVICE = "aov-by-device",
  AVERAGE_ORDER_VALUE_BY_DEVICE_REPORT = "aov-by-device-report",
  AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE = "aov-by-visitor-type",
  AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE_REPORT = "aov-by-visitor-type-report",
  ENGAGEMENT_RATE_BY_DEVICE = "engagement-rate-by-device",
  ENGAGEMENT_RATE_BY_VISITOR_TYPE_NON_SHOPIFY = "non-shopify/engagement-rate-by-visitor-type",
  ENGAGEMENT_RATE_BY_DEVICE_NON_SHOPIFY = "non-shopify/engagement-rate-by-device",
  ENGAGEMENT_RATE_BY_VISITOR_TYPE = "engagement-rate-by-visitor-type"
}

export const DATE_FORMAT = "YYYY-MM-DD";

export interface IFirstRowComponentProps {
  dataTestIdPrefix: string;
}

export interface ISecondRowComponentProps {
  currentSelectKey?: EMetricTimeString;
  videoAnalyticsValues?: TMetricValue | undefined;
  videoAnalyticsValuesPrev?: TMetricValue | undefined;
  videoAnalyticsLoading?: boolean;
  videoAnalyticsValuesPrevLoading?: boolean;
  loading?: boolean;
  performance: TMetric | undefined;
}

export interface IThirdRowComponentProps {
  engagementLoading: boolean;
  deviceBreakdownLoading: boolean;
  engagementValues: TMetricGroup | undefined;
  engagementValuesPrev: TMetricGroup | undefined;
  deviceBreakdownValues: TMetricGroup | undefined;
  metricValues: TMetric | undefined;
  currentSelectKey: EMetricTimeString;
}

export interface IFourthRowComponentProps {
  videoAnalyticsValuesPrevLoading: boolean;
  currentSelectKey?: EMetricTimeString;
  metricValues?: TMetric | undefined;
  metricValuesPrev?: TMetric | undefined;
  conversionRateByVisitorType?: IConversionRateByVisitorTypeTableDetails[] | undefined;
  conversionRateByVisitorTypePrev?: IConversionRateByVisitorTypeTableDetails[] | undefined;
  conversionRateByDevice?: IConversionRateByDeviceTableDetails[] | undefined;
  conversionRateByDevicePrev?: IConversionRateByDeviceTableDetails[] | undefined;
  conversionRateByDeviceLoading?: boolean;
  currentConversionRateByDeviceType?: EConversionRateType | undefined;
  setCurrentConversionRateByDeviceType?: React.Dispatch<React.SetStateAction<EConversionRateType | undefined>>;
  conversionRateByVisitorTypeLoading?: boolean;
  currentConversionRateByVisitorType?: EConversionRateType | undefined;
  setCurrentConversionRateByVisitorType?: React.Dispatch<React.SetStateAction<EConversionRateType | undefined>>;
  shopDetails?: IShopDetails | null;
  startDate?: moment.Moment | undefined;
  endDate?: moment.Moment | undefined;
  controller?: AbortController;
  loading?: boolean;
}

export interface IFifthRowComponentProps {
  isOnShopify?: boolean | undefined;
  topVideosValues?: ITopVideosDetails[] | undefined;
  topVideosValuesLoading?: boolean;
  abTesting?: IAbTestingDetails[] | undefined;
  abTestingLoading?: boolean;
}

export interface IMetricComponent {
  title: string;
  icon: JSX.Element;
  color: string;
  prefix?: string;
  suffix?: string;
  information?: string;
}

export interface IMetricComponentProps {
  loading?: boolean;
  activeSubscription?: IActiveSubscription;
  metricValue?: IMetricValue | number;
  metricValuePrev?: IMetricValue | number;
  currentSelectKey?: any;
}

export interface IMetricCVRByDeviceTableComponentProps extends IMetricComponentProps {
  metricValues: TMetric | undefined;
  metricValuesPrev: TMetric | undefined;
  conversionRateByDevice: IConversionRateByDeviceTableDetails[] | undefined;
  conversionRateByDevicePrev: IConversionRateByDeviceTableDetails[] | undefined;
  conversionRateByDeviceLoading: boolean;
  currentConversionRateByDeviceType: EConversionRateType | undefined;
  setCurrentConversionRateByDeviceType: React.Dispatch<React.SetStateAction<EConversionRateType>>;
  shopDetails: IShopDetails | null;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  controller: AbortController;
}

export interface IMetricCVRByVisitorTypeTableComponentProps extends IMetricComponentProps {
  metricValues: TMetric | undefined;
  metricValuesPrev: TMetric | undefined;
  conversionRateByVisitorType: IConversionRateByVisitorTypeTableDetails[] | undefined;
  conversionRateByVisitorTypePrev: IConversionRateByVisitorTypeTableDetails[] | undefined;
  conversionRateByVisitorTypeLoading: boolean;
  currentConversionRateByVisitorType: EConversionRateType | undefined;
  setCurrentConversionRateByVisitorType: React.Dispatch<React.SetStateAction<EConversionRateType>>;
  shopDetails: IShopDetails | null;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  controller: AbortController;
};

export interface IMetricVideoTableComponentProps extends IMetricComponentProps {
  metricValues: ITopVideosDetails[] | undefined;
  isOnShopify?: boolean;
};

export interface IMetricAbTestingTableComponentProps extends IMetricComponentProps {
  metricValues: IAbTestingDetails[] | undefined;
  isOnShopify?: boolean;
}

type TComponentProps = string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null;

export interface IMetricVizComponentProps {
  icon?: TComponentProps;
  color?: any;
  title?: TComponentProps;
  body?: TComponentProps;
  loading?: boolean;
  information?: string;
  cssClass?: string;
}

export interface IMetricCardProps {
  title?: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null;
  description?: TComponentProps;
  icon?: any;
  color?: string;
  body?: any;
  information?: string;
}

export interface IMetricCardTableProps extends IMetricCardProps {
  loading: boolean | undefined;
  cssClass?: string;
}

export interface IMetricStatisticProps {
  prefix?: string;
  metricValue?: number;
  metricValuePrev?: number;
  metricChangeTimePeriod?: EMetricTimeString;
  suffix?: string;
}

export interface IMetricStatisticBlockedProps extends IMetricStatisticProps {
  placeholder: string;
}

export interface ITablesExist {
  eventImpressionTableNameExists: boolean | null;
  eventPurchasesTableNameExists: boolean | null;
  eventVideosTableNameExists: boolean | null;
}

export interface IGetPageApiPayload {
  filterName?: string;
  requestLastEvaluatedKey?: ILoadMoreVideoLastEvaluatedKey;
}

export interface IUpdateVideoApiPayload {
  products?: IProduct[];
  displayName?: string;
  GSI1PK?: string;
  GSI1SK?: string;
}

export interface IWidgetSettings {
  desktopSettings: IDeviceSettings;
  mobileSettings: IDeviceSettings;
}

export enum EWidgetPosition {
  topLeft = "left-top", // LEFT_TOP
  topCenter = "centr-top", // CENTER_TOP
  topRight = "right-top", // RIGHT_TOP
  centerLeft = "left-center", // LEFT_CENTER
  center = "centr", // CENTER
  centerRight = "right-center", // RIGHT_CENTER
  bottomLeft = "left-bottom", // LEFT_BOTTOM
  bottomCenter = "centr-bottom", // CENTER_BOTTOM
  bottomRight = "right-bottom", // RIGHT_BOTTOM
}

export interface IGetContentRequestApiPayload {
  filterName?: string;
  requestLastEvaluatedKey?: ILoadMorePageLastEvaluatedKey;
}

export enum IContentRequestStatus {
  FULFILLMENT = "fulfillment", // in transit // Awaiting fulfillment
  IN_PROGRESS = "in_progress", // Content Creation in Progress
  PENDING = "pending", // content request just created
  DELIVERED = "delivered", // already accepted by admin and ready-for-review by Merchant
  COMPLETED = "completed", // accept by Merchant
  DECLINED = "declined", // reject by Merchant
  REVIEW_CONTENT = "review_content", // In Admin Review
  ADMIN_REJECT = "admin_reject", // rejected by admin // In Improvement
}

export interface IContentRequest {
  id: string;
  meta: string;
  url: string;
  status: IContentRequestStatus;
  totalVariants: number; // number of variants per content request
  video: string | IVideo;
  createdAt: number;
  updatedAt: number;
  deleted?: boolean;
  processed?: boolean;
  processedAt?: number;
  rejectionTime?: number;
  rejectionMessage?: string;
}

export interface ILastEvaluatedKey {
  id: string;
  createdAt: number;
  meta: string;
}

export interface ISettingScreenIntegration {
  title: string;
  extraDetails: string;
  logo: string;
  logoWidth: string;
  logoHeight: string;
  connectLink: string;
  platformCompability: "shopify" | "non-shopify" | "all";
}

export interface ISoMeLicenseDetail {
  onlineStorePreviewUrl: string;
  licensePrice: string;
  licenseDuration: string;
}

export const getShopName = (domain: string): string => {
  // The domain format is TABLE_<domain>_EVENT_IMPRESSION
  // <domain> = domain URL replacing -, . with underscore (_)
  return domain.replace(/-/g, "_").replace(/\./g, "_");
};

export const getEventImpressionTableName = (domain: string): string => {
  return `table_${getShopName(domain)}_event_impression`;
};

export const getEventPurchasesTableName = (domain: string): string => {
  return `table_${getShopName(domain)}_event_purchases`;
};

export const getEventVideosTableName = (domain: string): string => {
  return `table_${getShopName(domain)}_event_videos`;
};

export interface ISubscriptionPlan {
  planName: string,
  displayName: string,
  videoEmbeddingAdditionalPerVisitCharge: string,
  ugcContentPerVideoPrice: string,
  ugcContentSoMeLicensePrice: string,
  ugcContentSoMeLicenseDurationInMonths: string,
  choosePlanLink: string,
  // optionals
  isMostPopular?: boolean,
  isCurrentActivePlan?: boolean,
  isContactSalesPlan?: boolean,
  isDefaultPlan: boolean,
  pricePerMonth?: string,
  // gating v3
  price?: number,
  billingCycle?: EBillingCycle,
  //
  videoEmbeddingIncludedVisit: number,
  // new updated from BE
  id: string;
  isTestPlan: boolean;
  meta: string;
  platform: string;
  trialDays: number; // discuss for new account setup page (it says 14 days trial)
  usageCappedAmount: number;
  usageTerms: string;
  // Found requirement of adding new fields for account setup page
  analyticsText: string;
  isDedicatedCustomerSuccessManager: boolean;
  isMobileDesktopResponsive: boolean;
  isCustomizableDesign: boolean;
  isHTMLPlayerProvided: boolean;
  isForceBranding: boolean;
  stripeContentRequestPriceId: string;
  stripeGanderAccessPriceId: string;
  stripeVideoLicensePriceId: string;
  maxInteractions?: string | number;
  isInteractiveVideo?: boolean;
  isAllowOverlayPlayer?: boolean;
  isAllowEmbedPlayer?: boolean;
  isPrioritySupportProvided?: boolean;
  isPageABTest?: boolean;
  isGlobalABTest?: boolean;
  planVersion?: number;
  supportText?: string;
  isTiktokImport?: boolean;
  isInstagramImport?: boolean;
}

export enum EBillingCycle {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually'
}

export enum EVideoAddEditDrawerTabs {
  LINK_VIDEO_TO_PAGE = 'Link Video to Page',
  ATTACH_PRODUCTS = 'Attach Products',
  ADD_INTERACTION = 'Add Interaction'
}

export enum ESettingTabs {
  BILLING = 'billing',
  INTEGRATION = "integration",
  ACCOUNT = "account",
}

export enum ECustomizePageTabs {
  PREVIEW_STATE = 'preview state',
  OPEN_STATE = "open state"
}

export interface ISelectedPlanIdMeta {
  planId: string;
  planMeta: string;
}

export interface IActiveSubscription {
  id: string;
  meta: string;
  name: string;
  status: string;
  activePlan?: ISubscriptionPlan;

  // Stripe
  items?: Stripe.ApiList<Stripe.SubscriptionItem>;
  metadata?: Stripe.Metadata;
}

export interface IShopDetails {
  shopifyUrl: string;
  meta: string;
  createdAt: number;
  email: string;
  platform: string;
  updatedAt: number;
  sendEmail: boolean;
  id: string;
  trialStart: number;
  domain: string;
  stripeCustomerId?: string;
  externalVideoUploadId?: string;
  isHideOnboardingPopup?: boolean;
  isHideEmbeddedTip?: boolean;
  usedTrialDays?: number;
}

export interface ITopVideosDetails {
  meta: string;
  name: string;
  avatarUrl: string;
  ganderInfluencedSales: number;
  engagementRate: number;
}

export interface IAbTestingExpandedDetails {
  meta: string;
  name: string;
  avatarUrl: string;
  CONVERSION_RATE: number | undefined;
  ENGAGEMENT_RATE: number | undefined;
  AVG_VIEW_TIME: number | undefined;
}

export interface IConversionRateByDevice {
  PRODUCT_NAME: string;
  EVENT_DEVICE: string;
  CONVERSION_RATE: string;
}

export interface IConversionRateByDeviceTableDetails {
  product_name: string;
  mobile: number | null;
  desktop: number | null;
}

export interface IConversionRateByVisitorType {
  DATA_PRODUCT: string;
  VISITOR_TYPE: string;
  VALUE: string;
}

export interface IConversionRateByVisitorTypeTableDetails {
  PRODUCT_NAME: string;
  CONVERSION_NEW: number | null;
  CONVERSION_REPEAT: number | null;
}

export interface IAbTestingDetails {
  key: React.Key;
  meta: string;
  name: string;
  date: string;
  status: string;
  details: IAbTestingExpandedDetails[];
}

export interface IAbTestingResults {
  "ID": string;
  "Meta": string;
  "META": string;
  "Name": string;
  "Status": boolean
}

export interface IAbTestingMetrics {
  "ID": string;
  "avatarUrl": string;
  "META": string;
  "Meta": string;
  "PAGE_META": string;
  CONVERSION_RATE?: number;
  ENGAGEMENT_RATE?: number;
  AVG_VIEW_TIME?: number;
}

export enum EConversionRateType {
  CVR = "Conversion Rate",
  AOV = "Average Order Value",
  ER = "Engagement Rate"
}

export enum EInteractionEditTabs {
  EDIT_QUESTIONS = 'edit-questions',
  RESULTS = "results",
}

export enum EInteractionResultsTabs {
  INSIGHTS = 'insights',
  SUMMARY = "summary",
  RESPONSES = "responses",
}

export enum EInteractionInsightsFilter {
  WEEK = "week",
  MONTH = "month",
}

export interface IUploadFile {
  uid: string;
  lastModified?: string;
  lastModifiedDate?: string;
  originFileObj: any;
  name: string;
  size: string | number;
  type: string;
  percent: number;
  status: string;
  // Additional params for public file upload flow
  productUrlValidationError?: string;
  productUrl?: string;
  hdThumbnail?: string;
  meta?: string;
}

export enum EOverridePreviewState {
  CLOSE_STATE = "close-state",
  PREVIEW_OVERLAY = "preview-overlay",
  OVERLAY = "overlay",
  FULLSCREEN = "fullscreen",
  IN_FRAME = "in-frame",
}

export enum EDeviceType {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export interface IShopifyGIDdecode {
  type: string;
  id: string;
  params?: string;
  raw?: string;
}