import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IPayload {
  domain: string;
}

interface IResponse {
  message: string;
}

const apiCheckDomainExists = async (payload: IPayload): Promise<IResponse> => {
  try {
    const url = `${REACT_APP_API_URL}/public/check-domain-exists`;
    const response = await axios.post<IResponse>(url, payload);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiCheckDomainExists;