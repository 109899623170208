import { useContext } from "react";
import { Button } from "antd";
import { toast } from "utils/toast";
import { apiCreateQuestion } from "services/questions";
import { apiUpdateInteraction } from "services/interactions";
import { EQuestionType, IInteraction, IQuestion } from "interfaces/schema";
import { PictureFilled, CheckSquareFilled, ProfileFilled, RightSquareFilled, ShoppingCartOutlined } from "@ant-design/icons";
import { AppContext } from "context/app-context";
import { PLATFORM } from "constants/store";

import "./index.scss";

const dataTestIdPrefix = "YourQuestionPanel-AddQuestionComponent-QuestionSelectPopover";

interface Props {
    interaction: IInteraction;
    setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
    setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionSelectPopover = ({ interaction, setInteraction, setSelectedQuestion, setIsOpen, setIsLoading }: Props) => {
    const { shopDetails } = useContext(AppContext);
    const isShopify =
        shopDetails?.["platform"].toLowerCase() === PLATFORM?.Shopify.toLowerCase() ||
        shopDetails?.["platform"].toLowerCase() === PLATFORM?.ShopifyV2.toLowerCase();

    const handleCreateNewQuestion = async (type: EQuestionType) => {
        try {
            setIsOpen(false);
            setIsLoading(true);
            const createQuestionResponse = await apiCreateQuestion({ interactionId: interaction.meta, type });
            const updateInteractionResponse = await apiUpdateInteraction({
                id: interaction.meta,
                questionOrder: [...interaction.questionOrder, createQuestionResponse.question.meta],
            });
            setInteraction({
                ...interaction,
                ...updateInteractionResponse.interaction,
                questions: [...interaction.questions, createQuestionResponse.question],
            });
            setSelectedQuestion(createQuestionResponse.question);
        } catch (e) {
            console.log(e);
            toast.error("Sorry, something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="g-interaction-edit-add-question-select-container" data-testid={`${dataTestIdPrefix}-WrapperDiv`}>
            <Button
                className="g-interaction-edit-add-question-select-button"
                type="text"
                onClick={() => handleCreateNewQuestion(EQuestionType.MULTIPLE_CHOICE)}
                data-testid={`${dataTestIdPrefix}-MultipleChoiceButton`}
            >
                <CheckSquareFilled style={{ color: "#9747FF" }} /> Multiple Choice
            </Button>
            <Button
                className="g-interaction-edit-add-question-select-button"
                type="text"
                onClick={() => handleCreateNewQuestion(EQuestionType.PICTURE_CHOICE)}
                data-testid={`${dataTestIdPrefix}-PictureChoiceButton`}
            >
                <PictureFilled style={{ color: "#FF6424" }} /> Picture Choice
            </Button>
            <Button
                className="g-interaction-edit-add-question-select-button"
                type="text"
                onClick={() => handleCreateNewQuestion(EQuestionType.SHORT_ANSWER)}
                data-testid={`${dataTestIdPrefix}-ShortAnswerButton`}
            >
                <ProfileFilled style={{ color: "#1676F3" }} /> Short Answer
            </Button>
            <Button
                className="g-interaction-edit-add-question-select-button"
                type="text"
                onClick={() => handleCreateNewQuestion(EQuestionType.CALL_TO_ACTION)}
                data-testid={`${dataTestIdPrefix}-CallToActionButton`}
            >
                <RightSquareFilled style={{ color: "#4FBF67" }} /> Call to Action
            </Button>
            {isShopify && (
                <Button
                    className="g-interaction-edit-add-question-select-button"
                    type="text"
                    onClick={() => handleCreateNewQuestion(EQuestionType.ADD_TO_CART)}
                    data-testid={`${dataTestIdPrefix}-AddToCartBtn`}
                >
                    <ShoppingCartOutlined style={{ color: "orange" }} /> Add to Cart
                </Button>
            )}
        </div>
    );
};

export default QuestionSelectPopover;
