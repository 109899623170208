import axios from "axios";
import { IShopDetails } from "interfaces";

const { REACT_APP_API_URL } = process.env;

interface IResponse {
    data: IShopDetails;
    message: string;
}

interface IUpdateShop {
    isHideOnboardingPopup?: boolean;
    isHideEmbeddedTip?: boolean;
}

const apiUpdateShop = async (payload: IUpdateShop): Promise<IResponse> => {
    try {
        const url = `${REACT_APP_API_URL}/shop`;
        const response = await axios.put(url, payload);

        return response.data;
    } catch (e: any) {
        console.log(e);
        throw e?.response?.data ? e?.response?.data : e;
    }
};

export default apiUpdateShop;
