import { Button, List, Tooltip, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { LinkOutlined } from '@ant-design/icons';
import { AppContext } from 'context/app-context';
import { useContext } from 'react';
import { IPage } from 'interfaces';
import { createUrlWithHostAndPathName } from 'utils/common-functions';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Videos-LinkedPagesListItemComponent";

interface IProps {
    row: IPage;
    onRemoveVideo?: (pageRecord: IPage) => void;
    isOpenLink?: boolean;
}

const LinkedPagesListItemComponent = ({ row, onRemoveVideo, isOpenLink = true }: IProps) => {
    const { shopDetails } = useContext(AppContext);
    const testIdValue = isOpenLink ? 'LinkedPagesCardContainer' : 'SearchPagesCardContainer'
    const url = createUrlWithHostAndPathName(shopDetails?.domain!, row?.url);
    return (
        <List.Item className='g-sortable-connected-video-component' data-testid={`${dataTestIdPrefix}-${testIdValue}`}>
            <List.Item.Meta
                title={
                    <div className='g-d-flex g-align-center g-justify-between' data-testid={`${dataTestIdPrefix}-LinkedPagesCard`}>
                        <div className='g-sortable-title-wrapper g-manage-content-edit-video-list-linked-pages-container' data-testid={`${dataTestIdPrefix}-LinkedPagesCardTitleContainer`}>
                            <Title ellipsis level={5} data-testid={`${dataTestIdPrefix}-LinkedPagesCardTitle`}>
                                <Tooltip title={row?.name} placement="topLeft">
                                    {row?.name}
                                </Tooltip>
                            </Title>
                            <Button
                                title={url}
                                type='link'
                                href={isOpenLink ? url : undefined}
                                target="_blank"
                                icon={<LinkOutlined />}
                                data-testid={`${dataTestIdPrefix}-LinkedPagesCardButton`}
                            >
                                <span className="g-video-list-page-link-text">{url}</span>
                            </Button>
                        </div>
                        {!!onRemoveVideo &&
                            <div className='g-sortable-connected-video-button' data-testid={`${dataTestIdPrefix}-LinkedPagesCardDeleteButton`}>
                                <Button
                                    type='text'
                                    htmlType='button'
                                    danger={true}
                                    size='large'
                                    onClick={(e) => onRemoveVideo(row)}
                                    icon={<DeleteOutlined />}
                                >
                                </Button>
                            </div>
                        }
                    </div>
                }
            />
        </List.Item>
    );
}

export default LinkedPagesListItemComponent;