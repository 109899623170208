import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const ganderAccessPostCheckout = async (sessionId: string): Promise<void> => {
  try {
    const url = `${REACT_APP_API_URL}/stripe/post-checkout`;
    await axios.put(url, { sessionId });
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default ganderAccessPostCheckout;