import { useContext, useEffect, useState } from "react";
import { EOverridePreviewState, IWidgetSettings } from "interfaces";
import { toast } from "utils/toast";
import { apiGetWidgetSettings } from "services/widget-customization.service";
import ApplyChangesModal from "./apply-changes-modal";
import EmbeddedTipModal from "../common/embedded-tip-modal-component";
import WidgetPreview from "../common/widget-customization-page-components/widget-preview";
import { ECustomizationSettingTabs } from "./types";
import { EWidgetType, IDeviceSettings } from "interfaces/schema";
import CustomizationSettingsTab from "./customization-settings-tab-component";
import { mapDesktopToMobileSettings, mapMobileToDesktopSettings } from "utils/customization-settings";
import { AppContext } from "context/app-context";

import "./index.scss";

const CustomizationPage = () => {
    const { shopDetails } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState<ECustomizationSettingTabs>(ECustomizationSettingTabs.DESKTOP);
    const [widgetSettings, setWidgetSettings] = useState<IWidgetSettings>();
    const [originalWidgetSettings, setOriginalWidgetSettings] = useState<IWidgetSettings>();
    const [overridePreviewState, setOverridePreviewState] = useState<EOverridePreviewState | undefined>();
    const [showApplyAllChangesModal, setShowApplyAllChangesModal] = useState(false);
    const [showEmbeddedTipModal, setShowEmbeddedTipModal] = useState(false);

    useEffect(() => {
        setWidgetSettings(originalWidgetSettings);
    }, [originalWidgetSettings]);

    useEffect(() => {
        const loadWidgetCustomizationData = async () => {
            try {
                const widgetSettingsResponse = await apiGetWidgetSettings();
                if (widgetSettingsResponse?.data) {
                    const { id, meta, dataType, createdAt, updatedAt, ...widgetSettings } = widgetSettingsResponse.data;
                    setOriginalWidgetSettings(widgetSettings);
                }
            } catch (e: any) {
                console.log(e);
                toast.error(e?.message);
            } finally {
                setIsLoading(false);
            }
        };

        loadWidgetCustomizationData();
    }, []);

    const handleDesktopSettingsUpdate = (dirtyDesktopSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => {
        const isSwitchFromOverlayToEmbedded =
            widgetSettings?.desktopSettings?.widgetType === EWidgetType.OVERLAY && dirtyDesktopSettings?.widgetType === EWidgetType.EMBEDDED;
        if (isSwitchFromOverlayToEmbedded && !shopDetails?.isHideEmbeddedTip) {
            setShowEmbeddedTipModal(true);
        }
        setWidgetSettings({
            desktopSettings: dirtyDesktopSettings,
            mobileSettings: mapDesktopToMobileSettings(dirtyDesktopSettings),
        });
        setOverridePreviewState(overridePreview);
    };

    const handleMobileSettingsUpdate = (dirtyMobileSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => {
        if (!widgetSettings?.desktopSettings || Object.keys(widgetSettings.desktopSettings).length === 0) {
            toast.error("Something went wrong with loading settings, please reload and try again.");
            return;
        }

        const isSwitchFromOverlayToEmbedded =
            widgetSettings?.mobileSettings?.widgetType === EWidgetType.OVERLAY && dirtyMobileSettings?.widgetType === EWidgetType.EMBEDDED;
        if (isSwitchFromOverlayToEmbedded && !shopDetails?.isHideEmbeddedTip) {
            setShowEmbeddedTipModal(true);
        }
        setWidgetSettings({
            desktopSettings: mapMobileToDesktopSettings(widgetSettings.desktopSettings, dirtyMobileSettings),
            mobileSettings: dirtyMobileSettings,
        });
        setOverridePreviewState(overridePreview);
    };

    const resetUpdatedWidgetSettings = async () => {
        setWidgetSettings(originalWidgetSettings);
    };

    const saveWidgetSettingChanges = () => {
        setShowApplyAllChangesModal(true);
    };

    const handleTabChange = (selectedTab: ECustomizationSettingTabs) => {
        setCurrentTab(selectedTab);
        setOverridePreviewState(undefined);
    };

    return (
        <div className="g-customization-page-container">
            <CustomizationSettingsTab
                isLoading={isLoading}
                currentTab={currentTab}
                desktopSettings={widgetSettings?.desktopSettings}
                mobileSettings={widgetSettings?.mobileSettings}
                handleTabChange={handleTabChange}
                handleDesktopSettingsUpdate={handleDesktopSettingsUpdate}
                handleMobileSettingsUpdate={handleMobileSettingsUpdate}
            />
            <WidgetPreview
                isLoading={isLoading}
                widgetSettings={widgetSettings}
                overridePreviewState={overridePreviewState}
                currentTab={currentTab}
                resetUpdatedWidgetSettings={resetUpdatedWidgetSettings}
                saveWidgetSettingChanges={saveWidgetSettingChanges}
                setCurrentTab={setCurrentTab}
            />
            {showApplyAllChangesModal && widgetSettings && (
                <ApplyChangesModal
                    widgetSettings={widgetSettings}
                    setShowApplyAllChangesModal={setShowApplyAllChangesModal}
                    setIsLoading={setIsLoading}
                    setOriginalWidgetSettings={setOriginalWidgetSettings}
                />
            )}
            {showEmbeddedTipModal && <EmbeddedTipModal handleClose={() => setShowEmbeddedTipModal(false)} />}
        </div>
    );
};

export default CustomizationPage;
