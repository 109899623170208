import { Button, Input, Typography } from "antd";
import { Dispatch, SetStateAction, useCallback } from "react";

import "./index.scss";

const { Text } = Typography;

const dataTestIdPrefix = "instagram-modal";

interface IProps {
    username: string | undefined;
    cursor: string | undefined;
    setUsername: Dispatch<SetStateAction<string | undefined>>;
    handleNext: (username?: string, cursor?: string) => Promise<void>;
}

const InstagramModalFirstStepContent = ({ username, cursor, setUsername, handleNext }: IProps) => {

    const handleKeyUp = useCallback(
        (onKeyUp: React.KeyboardEvent<any>) => {
            if (onKeyUp?.key === "Enter") {
                handleNext(username, cursor);
            }
        },
        [username, cursor]
    );

    return (
        <div className="g-instagram-modal-step-one-body" onKeyUp={handleKeyUp}>
            <Input
                className="g-instagram-modal-input"
                data-testid={`${dataTestIdPrefix}-UsernameInput`}
                placeholder="Enter Instagram Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <Text className="g-instagram-modal-input-tip">ex. instagram</Text>
            <Button
                className="g-instagram-modal-next-button"
                data-testid={`${dataTestIdPrefix}-NextButton`}
                type="primary"
                onClick={() => handleNext(username, cursor)}
            >
                Next
            </Button>
        </div>
    );
};

export default InstagramModalFirstStepContent;
