import { GenerateConversionRateByDeviceColumns, GenerateConversionRateByVisitorTypeColumns } from "constants/dashboard";
import { PLATFORM } from "constants/store";
import { EConversionRateType, EMetricValues, IConversionRateByDeviceTableDetails, EMetricTimeString, TMetric, IConversionRateByVisitorTypeTableDetails } from "interfaces";
import { DashboardContext } from "context/dashboard-context";
import { useContext } from "react";
import { AppContext } from "context/app-context";

export enum EPropType {
  VISITOR = "visitors",
  DEVICE = "device"
}

interface IGenerateProps {
  currentCvr: EConversionRateType | undefined;
  cvr: IConversionRateByDeviceTableDetails[] | IConversionRateByVisitorTypeTableDetails[] | undefined;
  cvrPrev: IConversionRateByDeviceTableDetails[] | IConversionRateByVisitorTypeTableDetails[] | undefined;
  currentSelectKey: EMetricTimeString;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  controller: AbortController;
  metricValues: TMetric | undefined;
  metricValuesPrev: TMetric | undefined;
  propType: EPropType;
};

interface IDropdownItems {
  propType: EPropType;
}

export const generateProps = ({ currentCvr, cvr, cvrPrev, currentSelectKey, startDate, endDate, controller, metricValues, metricValuesPrev, propType }: IGenerateProps) => {
  let titleProps: any = {
    prefix: "",
    postfix: "%",
    precision: 2,
    value: cvr,
    valuePrev: cvrPrev,
    currentSelectKey: currentSelectKey,
    keys: propType === EPropType.VISITOR ? ['PRODUCT_NAME', 'CONVERSION_NEW', 'CONVERSION_REPEAT'] : ['product_name', 'desktop', 'mobile'],
    startDate: startDate,
    endDate: endDate,
    controller: controller,
    type: propType === EPropType.VISITOR ? EMetricValues.AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE : EMetricValues.AVERAGE_ORDER_VALUE_BY_DEVICE,
  }

  let bodyProps: any = {
    columns: undefined,
    dataSource: cvr
  }

  switch (currentCvr) {
    case EConversionRateType.AOV:
      titleProps['topValue'] = metricValues ? metricValues["Average Order Value"] : undefined;
      titleProps['topValuePrev'] = metricValuesPrev ? metricValuesPrev["Average Order Value"] : undefined;
      bodyProps['columns'] = propType === EPropType.VISITOR ? GenerateConversionRateByVisitorTypeColumns(1, "$", "") : GenerateConversionRateByDeviceColumns(1, "$", "");
      break;
    case EConversionRateType.CVR:
      titleProps['topValue'] = metricValues ? metricValues["Video Conversions"] : undefined;
      titleProps['topValuePrev'] = metricValuesPrev ? metricValuesPrev["Video Conversions"] : undefined;
      bodyProps['columns'] = propType === EPropType.VISITOR ? GenerateConversionRateByVisitorTypeColumns(100, "", "%") : GenerateConversionRateByDeviceColumns(100, "", "%");
      break;
    case EConversionRateType.ER:
      titleProps['topValue'] = metricValues ? metricValues["On Site Engagement"] : undefined;
      titleProps['topValuePrev'] = metricValuesPrev ? metricValuesPrev["On Site Engagement"] : undefined;
      bodyProps['columns'] = propType === EPropType.VISITOR ? GenerateConversionRateByVisitorTypeColumns(100, "", "%") : GenerateConversionRateByDeviceColumns(100, "", "%");
      break;
    default:
      break;
  }

  return {
    title: titleProps,
    body: bodyProps
  }
};

export const useDropdownItems = ({ propType }: IDropdownItems) => {
  const {
    currentConversionRateByDeviceType,
    setCurrentConversionRateByDeviceType,
    currentConversionRateByVisitorType,
    setCurrentConversionRateByVisitorType
  } = useContext(DashboardContext);
  const { shopDetails } = useContext(AppContext);

  let dropdownItems = [
    {
      label: <div onClick={(_e) => {
        propType === EPropType.VISITOR ? setCurrentConversionRateByVisitorType(EConversionRateType.CVR) : setCurrentConversionRateByDeviceType(EConversionRateType.CVR);
      }}>{EConversionRateType.CVR}</div>,
      key: EConversionRateType.CVR,
    },
    {
      label: <div onClick={(_e) => {
        propType === EPropType.VISITOR ? setCurrentConversionRateByVisitorType(EConversionRateType.ER) : setCurrentConversionRateByDeviceType(EConversionRateType.ER);
      }}>{EConversionRateType.ER}</div>,
      key: EConversionRateType.ER,
    }
  ]

  if (shopDetails?.platform?.toLowerCase() === PLATFORM.Shopify.toLowerCase() || shopDetails?.platform?.toLowerCase() === PLATFORM.ShopifyV2.toLowerCase()) {
    dropdownItems.push({
      label: <div onClick={(_e) => {
        propType === EPropType.VISITOR ? setCurrentConversionRateByVisitorType(EConversionRateType.AOV) : setCurrentConversionRateByDeviceType(EConversionRateType.AOV);
      }}>{EConversionRateType.AOV}</div>,
      key: EConversionRateType.AOV,
    });
  }

  dropdownItems = propType === EPropType.VISITOR ? dropdownItems.filter((value) => value.key !== currentConversionRateByVisitorType) : dropdownItems.filter((value) => value.key !== currentConversionRateByDeviceType);
  return dropdownItems;
};