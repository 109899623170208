import { Typography } from "antd";
import { IInteraction, IVideo } from "interfaces/schema";
import InteractionsNameColumn from "./interactions-name-column-component";
import InteractionsVideosColumn from "./interactions-videos-column-component";
import InteractionsActionsColumn from "./interactions-actions-column-component";
import { SetStateAction } from "react";

import "./index.scss";

const { Text } = Typography;

const InteractionsTableColumns = (setActiveInteraction: {
    (value: SetStateAction<IInteraction | undefined>): void;
}) => {
    return [
        {
            title: <Text className="g-interactions-column-header">Name</Text>,
            key: "name",
            render: (interaction: IInteraction) => <InteractionsNameColumn interaction={interaction} />,
        },
        {
            title: <Text className="g-interactions-column-header">Linked Videos</Text>,
            dataIndex: "videos",
            key: "videos",
            render: (videos: Partial<IVideo>[]) => <InteractionsVideosColumn videos={videos} />,
        },
        {
            title: <Text className="g-interactions-column-header">Actions</Text>,
            key: "actions",
            render: (interaction: IInteraction) => <InteractionsActionsColumn interaction={interaction} setActiveInteraction={setActiveInteraction} />,
        },
    ];
};

export default InteractionsTableColumns;
