import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import WidgetPreview from "app/pages/common/widget-customization-page-components/widget-preview";
import CustomizationSettingsTab from "app/pages/customization/customization-settings-tab-component";
import EmbeddedTipModal from "app/pages/common/embedded-tip-modal-component";
import { toast } from "utils/toast";
import { apiUpdatePage } from "services/pages.services";
import { mapDesktopToMobileSettings, mapMobileToDesktopSettings } from "utils/customization-settings";
import { PagesContext } from "context/pages-context";
import { AppContext } from "context/app-context";
import { EOverridePreviewState, IPage, IUpdatePage } from "interfaces";
import * as ROUTE_PATHS from "constants/routes";
import { ECustomizationSettingTabs } from "app/pages/customization/types";
import { EWidgetType, IDeviceSettings } from "interfaces/schema";
import { ChevronRightIcon, PagesContentGrayIcon } from "components/icons";

import "./index.scss";

const { Text } = Typography;
const dataTestIdPrefix = "Pages-CustomizePageComponent";

interface IProps {
    viewCustomizePageComponent: IPage;
    setViewCustomizePageComponent: React.Dispatch<React.SetStateAction<IPage | undefined>>;
}
const CustomizePageComponent = ({ viewCustomizePageComponent, setViewCustomizePageComponent }: IProps) => {
    const { fetchPages } = useContext(PagesContext);
    const { shopDetails } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState<ECustomizationSettingTabs>(ECustomizationSettingTabs.DESKTOP);
    const [desktopSettings, setDesktopSettings] = useState<IDeviceSettings>(viewCustomizePageComponent.desktopSettings);
    const [mobileSettings, setMobileSettings] = useState<IDeviceSettings>(viewCustomizePageComponent.desktopSettings);
    const [overridePreviewState, setOverridePreviewState] = useState<EOverridePreviewState | undefined>();
    const [showEmbeddedTipModal, setShowEmbeddedTipModal] = useState(false);

    const handleDesktopSettingsUpdate = (dirtyDesktopSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => {
        const isSwitchFromOverlayToEmbedded = desktopSettings?.widgetType === EWidgetType.OVERLAY && dirtyDesktopSettings?.widgetType === EWidgetType.EMBEDDED;
        if (isSwitchFromOverlayToEmbedded && !shopDetails?.isHideEmbeddedTip) {
            setShowEmbeddedTipModal(true);
        }
        setDesktopSettings(dirtyDesktopSettings);
        setMobileSettings(mapDesktopToMobileSettings(dirtyDesktopSettings));
        setOverridePreviewState(overridePreview);
    };

    const handleMobileSettingsUpdate = (dirtyMobileSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => {
        const isSwitchFromOverlayToEmbedded = mobileSettings?.widgetType === EWidgetType.OVERLAY && dirtyMobileSettings?.widgetType === EWidgetType.EMBEDDED;
        if (isSwitchFromOverlayToEmbedded && !shopDetails?.isHideEmbeddedTip) {
            setShowEmbeddedTipModal(true);
        }
        setDesktopSettings(mapMobileToDesktopSettings(desktopSettings, dirtyMobileSettings));
        setMobileSettings(dirtyMobileSettings);
        setOverridePreviewState(overridePreview);
    };

    const postUpdatedPageSettings = async () => {
        try {
            setIsLoading(true);
            const payload: IUpdatePage = {
                meta: viewCustomizePageComponent.meta,
                desktopSettings,
                mobileSettings,
            };
            const pageSettingsResponse = await apiUpdatePage(payload);
            fetchPages();
            toast.success(pageSettingsResponse?.message);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const resetSettings = async () => {
        setDesktopSettings(viewCustomizePageComponent.desktopSettings);
        setMobileSettings(viewCustomizePageComponent.mobileSettings);
    };

    const routeToPagesTable = () => {
        setViewCustomizePageComponent(undefined);
    };

    const handleTabChange = (selectedTab: ECustomizationSettingTabs) => {
        setCurrentTab(selectedTab);
        setOverridePreviewState(undefined);
    };

    return (
        <div className="g-customize-page-container">
            <div className="g-customize-page-path">
                <Link onClick={routeToPagesTable} to={ROUTE_PATHS.PRIVATE_ROUTES.PAGES.PATH} className="g-customize-page-path-root">
                    <PagesContentGrayIcon />
                    <Text className="g-customize-page-path-root-name">{ROUTE_PATHS.PRIVATE_ROUTES.PAGES.TITLE}</Text>
                </Link>
                <ChevronRightIcon />
                <Text className="g-customize-page-path-page-name" data-testid={`${dataTestIdPrefix}-PageName`}>
                    {" "}
                    {viewCustomizePageComponent.name}
                </Text>
            </div>

            <div className="g-customize-page-settings-container">
                <CustomizationSettingsTab
                    isLoading={isLoading}
                    currentTab={currentTab}
                    desktopSettings={desktopSettings}
                    mobileSettings={mobileSettings}
                    handleTabChange={handleTabChange}
                    handleDesktopSettingsUpdate={handleDesktopSettingsUpdate}
                    handleMobileSettingsUpdate={handleMobileSettingsUpdate}
                />
                <WidgetPreview
                    isLoading={isLoading}
                    widgetSettings={{ desktopSettings, mobileSettings }}
                    overridePreviewState={overridePreviewState}
                    currentTab={currentTab}
                    saveWidgetSettingChanges={postUpdatedPageSettings}
                    resetUpdatedWidgetSettings={resetSettings}
                    setCurrentTab={setCurrentTab}
                />
            </div>
            {showEmbeddedTipModal && <EmbeddedTipModal handleClose={() => setShowEmbeddedTipModal(false)} />}
        </div>
    );
};

export default CustomizePageComponent;
