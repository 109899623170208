import { IActiveSubscription, IShopDetails } from "interfaces";
import { createContext, Dispatch, SetStateAction, useState } from "react";

export interface IAppContext {
    activeSubscription: IActiveSubscription | null;
    collapsed: boolean;
    currentUserInfo: any;
    isAppLoading: boolean;
    isOnboardingModal: boolean;
    isShowUpgradePlanBanner: boolean;
    isShowV3FreePlanConnectLimitModel: boolean;
    shopDetails: IShopDetails | null;
    setActiveSubscription: Dispatch<SetStateAction<IActiveSubscription | any>>;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    setCurrentUserInfo: Dispatch<SetStateAction<any>>;
    setIsAppLoading: Dispatch<SetStateAction<boolean>>;
    setIsOnboardingModal: Dispatch<SetStateAction<boolean>>;
    setIsShowUpgradePlanBanner: Dispatch<SetStateAction<boolean>>;
    setIsShowV3FreePlanConnectLimitModel: Dispatch<SetStateAction<boolean>>;
    setShopDetails: Dispatch<SetStateAction<IActiveSubscription | any>>;
}

const defaultContext = {
    activeSubscription: null,
    collapsed: false,
    currentUserInfo: {},
    isAppLoading: false,
    isOnboardingModal: false,
    isShowUpgradePlanBanner: false,
    isShowV3FreePlanConnectLimitModel: false,
    shopDetails: null,
    setActiveSubscription: () => { },
    setCollapsed: () => ({}),
    setCurrentUserInfo: () => ({}),
    setIsAppLoading: () => ({}),
    setIsOnboardingModal: () => { },
    setIsShowUpgradePlanBanner: () => { },
    setIsShowV3FreePlanConnectLimitModel: () => { },
    setShopDetails: () => { },
};

export const AppContext = createContext<IAppContext>(defaultContext);

export const AppContextProvider: React.FC<{ initialValues?: Partial<IAppContext>; children: React.ReactNode }> = ({ initialValues, children }) => {
    const [isAppLoading, setIsAppLoading] = useState(initialValues?.isAppLoading !== undefined ? initialValues.isAppLoading : true);
    const [currentUserInfo, setCurrentUserInfo] = useState(initialValues?.currentUserInfo);
    const [collapsed, setCollapsed] = useState(initialValues?.collapsed || false);
    const [activeSubscription, setActiveSubscription] = useState(initialValues?.activeSubscription ? initialValues.activeSubscription : null);
    const [shopDetails, setShopDetails] = useState(initialValues?.shopDetails ? initialValues.shopDetails : null);
    const [isShowUpgradePlanBanner, setIsShowUpgradePlanBanner] = useState(initialValues?.isShowUpgradePlanBanner || false);
    const [isShowV3FreePlanConnectLimitModel, setIsShowV3FreePlanConnectLimitModel] = useState(initialValues?.isShowUpgradePlanBanner || false);
    const [isOnboardingModal, setIsOnboardingModal] = useState(initialValues?.isOnboardingModal || false);

    return (
        <AppContext.Provider
            value={{
                activeSubscription,
                collapsed,
                currentUserInfo,
                isAppLoading,
                isOnboardingModal,
                isShowUpgradePlanBanner,
                isShowV3FreePlanConnectLimitModel,
                shopDetails,
                setActiveSubscription,
                setCollapsed,
                setCurrentUserInfo,
                setIsAppLoading,
                setIsOnboardingModal,
                setIsShowUpgradePlanBanner,
                setIsShowV3FreePlanConnectLimitModel,
                setShopDetails,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
