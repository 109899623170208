import { Button, Input, Typography } from "antd";
import { Dispatch, SetStateAction, useCallback } from "react";

import "./index.scss";

const { Text } = Typography;
const dataTestIdPrefix = "tiktok-modal";

interface IProps {
    username: string | undefined;
    cursor: string | undefined;
    setUsername: Dispatch<SetStateAction<string | undefined>>;
    handleNext: (username?: string, cursor?: string) => Promise<void>;
}

const TiktokModalFirstStepContent = ({ username, cursor, setUsername, handleNext }: IProps) => {
    const handleKeyUp = useCallback(
        (onKeyUp: React.KeyboardEvent<any>) => {
            if (onKeyUp?.key === "Enter") {
                handleNext(username, cursor);
            }
        },
        [username, cursor]
    );
    return (
        <div className="g-tiktok-modal-step-one-body" onKeyUp={handleKeyUp}>
            <Input className="g-tiktok-modal-input" data-testid={`${dataTestIdPrefix}-username-input`} placeholder="Enter Tiktok Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <Text className="g-tiktok-modal-input-tip">ex. earth, @earth</Text>
            <Button className="g-tiktok-modal-next-button" data-testid={`${dataTestIdPrefix}-next-button`} type="primary" onClick={() => handleNext(username, cursor)}>
                Next
            </Button>
        </div>
    );
};

export default TiktokModalFirstStepContent;
