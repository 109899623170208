import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IComponentResponse {
    isUpdated: string;
    message: string;
}

interface IResponse {
    cognito: IComponentResponse;
    shop: IComponentResponse;
    stripe: IComponentResponse;
}

interface IUpdateShop {
    updatePayload: {
        email?: string;
        firstname?: string;
        lastname?: string;
        name?: string;
        role?: string;
        phoneNumber?: string;
        [x: string]: any;
    };
}

const apiUpdateUserProfile = async (payload: IUpdateShop): Promise<IResponse> => {
    try {
        const url = `${REACT_APP_API_URL}/shop/user-profile`;
        const response = await axios.put(url, payload);

        return response?.data;
    } catch (e: any) {
        console.log(e);
        throw e?.response?.data ? e?.response?.data : e;
    }
};

export default apiUpdateUserProfile;
