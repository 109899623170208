import Stripe from "stripe";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IResponse {
  customerPortalSession: Stripe.BillingPortal.Session;
  message: string;
}

const apiCreateCustomerPortalSession = async (customerId: string, urlPath: string): Promise<IResponse> => {
  try {
    const url = `${REACT_APP_API_URL}/stripe/customer-portal-session`;
    const response = await axios.post(url, { customerId, urlPath });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiCreateCustomerPortalSession;