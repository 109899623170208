import { Divider, Typography } from "antd";
import moment from "moment";
import { IInteraction } from "interfaces/schema";
import { ExclamationCircleFilled } from "@ant-design/icons";

import "./index.scss";

const dataTestIdPrefix = "InteractionViewInsightsViewHeader";

const { Text } = Typography;

interface Props {
    interaction: IInteraction;
}

const Header = ({ interaction }: Props) => {
    return (
        <div className="g-interaction-edit-insights-card-header">
            <div className="g-interaction-edit-insights-card-title-container">
                <div className="g-interaction-edit-insights-card-sub-title">
                    <Text data-testid={`${dataTestIdPrefix}-Questions-Text`}>{interaction.questions.length} Questions</Text>
                    <Divider type="vertical" />
                    <Text data-testid={`${dataTestIdPrefix}-CreatedAt-Text`}>
                        Created on {moment(interaction.createdAt).format("D MMMM YYYY [at] h:mm:ss A")}
                    </Text>
                </div>
                <Text className="g-interaction-edit-insights-card-title-footer-text" data-testid={`${dataTestIdPrefix}-Results-Text`}>
                    <ExclamationCircleFilled style={{ color: "#F5AB33" }} />
                    &nbsp; Results are updated every 30 minutes
                </Text>
            </div>
        </div>
    );
};

export default Header;
