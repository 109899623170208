import { Button, List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { EPreviewPlayButtonSize } from 'interfaces';
import GanderVideo from 'components/gander-video';
import { IVideo } from 'interfaces/schema';

import './index.scss';

const { Title } = Typography;
const dataTestIdPrefix = "Pages-LinkedVideosListItemComponent";

interface IProps {
    row: IVideo;
    isLoading: boolean;
    onRemoveVideo: (row: IVideo) => void;
}
const VideoListItemComponent = ({ row, isLoading, onRemoveVideo }: IProps) => {

    return (
        <List.Item className='g-sortable-connected-video-component' data-testid={`${dataTestIdPrefix}-LinkedVideosCardContainer`}>
            <List.Item.Meta
                avatar={
                    <div className='g-d-flex g-align-center'>
                        <div className='g-list-with-video-preview-avatar'>
                            <GanderVideo video={row} buttonSize={EPreviewPlayButtonSize.SMALL} />
                        </div>
                    </div>
                }
                title={
                    <div className='g-d-flex g-align-center g-justify-between' data-testid={`${dataTestIdPrefix}-LinkedVideosCard`}>
                        <div className='g-sortable-title-wrapper'>
                            <Title ellipsis level={5} title={row?.displayName}>{row?.displayName}</Title>
                            <span className='g-description-text'>Last modified {moment(row?.updatedAt).fromNow()}</span>
                        </div>
                        <div className='g-sortable-connected-video-button'>
                            <Button type='text' htmlType='button' danger={true} size='large' onClick={(e) => onRemoveVideo(row)} icon={<DeleteOutlined />} disabled={isLoading} data-testid={`${dataTestIdPrefix}-LinkedVideosCardDeleteButton`}></Button>
                        </div>
                    </div>
                }
            />
        </List.Item>
    );
}

export default VideoListItemComponent;