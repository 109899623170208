import { Button, useAuthenticator, View } from "@aws-amplify/ui-react";

import "./index.scss";

const Footer = () => {
  const { toResetPassword } = useAuthenticator(context => [context.toResetPassword]);

  return (
    <>
      <View className='g-sign-in-footer-view' textAlign="center">
        <Button
          fontWeight="normal"
          onClick={toResetPassword}
          size="small"
          variation="link"
        >
          Forgot password?
        </Button>
      </View>
    </>
  );
}

export default Footer;