import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import "./index.scss";
import App from "./App";
import { AppContextProvider } from "./context/app-context";
import { Authenticator } from "@aws-amplify/ui-react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter } from "react-router-dom";
import { UploadContextProvider } from "context/upload-context";


const { REACT_APP_AWS_COGNITO_REGION, REACT_APP_AWS_COGNITO_USER_POOL_ID, REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID, REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID } = process.env;

Amplify.configure({
  Auth: {
    region: REACT_APP_AWS_COGNITO_REGION,
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Authenticator.Provider>
    <AppContextProvider>
      <LDProvider clientSideID={REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!} deferInitialization={false}>
        <UploadContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UploadContextProvider>
      </LDProvider>
    </AppContextProvider>
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
