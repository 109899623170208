import { useState } from "react";
import { Button, Popover, Typography } from "antd";
import QuestionSelectPopover from "./question-select-popover-component";
import { IInteraction, IQuestion } from "interfaces/schema";
import { PlusOutlined } from "@ant-design/icons";

import "./index.scss";

const dataTestIdPrefix = "YourQuestionPanel-AddQuestionComponent";

const { Text } = Typography;

interface Props {
    interaction: IInteraction;
    isLoading: boolean;
    setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
    setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddQuestionButton = ({ interaction, isLoading, setInteraction, setSelectedQuestion, setIsLoading }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenPopover = (newIsOpen: boolean) => {
        setIsOpen(newIsOpen);
    };

    return (
        <div className="g-interation-edit-add-question-container" data-testid={`${dataTestIdPrefix}-WrapperDiv`}>
            {isLoading || interaction?.questions?.length >= 10 ? (
                <Button
                    className="g-interation-edit-add-question-button-disabled"
                    type="primary"
                    disabled={true}
                    data-testid={`${dataTestIdPrefix}-AddNewQuestionBtn`}
                >
                    <Text className="g-interation-edit-add-question-text">
                        <PlusOutlined /> Add New Question
                    </Text>
                </Button>
            ) : (
                <Popover
                    open={isOpen}
                    content={
                        <QuestionSelectPopover
                            interaction={interaction}
                            setInteraction={setInteraction}
                            setSelectedQuestion={setSelectedQuestion}
                            setIsOpen={setIsOpen}
                            setIsLoading={setIsLoading}
                        />
                    }
                    placement="right"
                    title={<div className="g-interaction-edit-add-question-select-title">Question types</div>}
                    trigger="click"
                    onOpenChange={handleOpenPopover}
                >
                    <Button
                        className="g-interation-edit-add-question-button"
                        type="text"
                        onClick={() => handleOpenPopover(true)}
                        data-testid={`${dataTestIdPrefix}-AddNewQuestionBtn`}
                    >
                        <Text className="g-interation-edit-add-question-text">
                            <PlusOutlined /> Add New Question
                        </Text>
                    </Button>
                </Popover>
            )}
        </div>
    );
};

export default AddQuestionButton;
