import { Button, Form, FormListFieldData, Input, Select, Tooltip } from "antd";
import { useSortable } from "@dnd-kit/sortable";
import UploadInput from "./upload-input-component";
import { CSS } from "@dnd-kit/utilities";
import { getSubArrayBeforeValue } from "utils/common-functions";
import { EChoiceNextQuestionMetaRedirect, EQuestionSelectionType, EQuestionType, IQuestion } from "interfaces/schema";
import Icon from "@ant-design/icons";
import { DeleteOutlined, ArrowRightOutlined, LinkOutlined } from "@ant-design/icons";
import { ReactComponent as DraggableEllipsis } from "assets/img/draggable.svg";

import "./index.scss";

const typeToCSSMap: { [key: string]: string } = {
  [EQuestionType.MULTIPLE_CHOICE]: "g-interaction-edit-question-edit-form-field-multple-choice-grid",
  [EQuestionType.PICTURE_CHOICE]: "g-interaction-edit-question-edit-form-field-picture-choice-grid",
};

interface Props {
  key: string;
  id: string;
  index: number;
  choice: FormListFieldData;
  questions: IQuestion[];
  currentQuestion: IQuestion;
  disableDelete: boolean;
  remove: (index: number | number[]) => void;
}

const Choice = ({ index, choice, questions, currentQuestion, disableDelete, remove }: Props) => {
  const form = Form.useFormInstance();
  const choiceValue = Form.useWatch(["choices", index], form);
  const typeValue = Form.useWatch("type", form);
  const selectionTypeValue = Form.useWatch("selectionType", form);
  const questionMetasBeforeCurrentQuestion = getSubArrayBeforeValue(
    questions.map(({ meta }) => meta),
    currentQuestion.meta
  );
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: String(choice.key) });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (!choice) {
    return null;
  }

  return (
    <div className={`g-interaction-edit-question-edit-form-field-choice-container ${typeToCSSMap[typeValue]}`} ref={setNodeRef} style={style}>
      <Icon className="g-interaction-edit-question-edit-form-field-choice-icon" component={DraggableEllipsis} {...attributes} {...listeners} />
      {typeValue === EQuestionType.PICTURE_CHOICE ? (
        <Form.Item
          {...choice}
          key={`${index}-pictureUrl`}
          className="g-interaction-edit-question-edit-form-field-choice"
          name={[choice.name, "pictureUrl"]}
          shouldUpdate
        >
          <UploadInput currentQuestion={currentQuestion} index={index} />
        </Form.Item>
      ) : (
        <Tooltip title={choiceValue?.text} trigger={["hover"]}>
          <Form.Item
            {...choice}
            key={`${index}-text`}
            className="g-interaction-edit-question-edit-form-field-choice"
            name={[choice.name, "text"]}
            shouldUpdate
            rules={[
              () => ({
                validator(_, value) {
                  if (value.length <= 30) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Max 30 characters long."));
                },
              }),
            ]}
          >
            <Input className="g-interaction-edit-question-edit-form-field-choice-input" />
          </Form.Item>
        </Tooltip>
      )}
      <div className="g-interaction-edit-question-edit-form-field-choices-select-container">
        <Form.Item
          {...choice}
          key={`${index}-nextQuestionMeta`}
          className="g-interaction-edit-question-edit-form-field-choice"
          name={[choice.name, "nextQuestionMeta"]}
          rules={[{ required: false, message: "Field is required" }]}
          shouldUpdate
        >
          <Select
            size="large"
            options={[
              {
                label: (
                  <span>
                    <ArrowRightOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                    &nbsp;&nbsp;N/A; next question
                  </span>
                ),
                value: "",
              },
              ...questions.map(({ meta, text }, index) => ({
                label: (
                  <span>
                    <ArrowRightOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                    &nbsp;&nbsp;{`${index + 1}. ${text}`}
                  </span>
                ),
                value: meta,
                disabled: meta === currentQuestion.meta || questionMetasBeforeCurrentQuestion.includes(meta),
              })),
              {
                label: (
                  <span>
                    <LinkOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                    &nbsp;&nbsp;Link
                  </span>
                ),
                value: EChoiceNextQuestionMetaRedirect,
              },
            ]}
            disabled={selectionTypeValue === EQuestionSelectionType.MULTIPLE_SELECT}
          />
        </Form.Item>
        {choiceValue?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect && (
          <Tooltip title={choiceValue?.redirectUrl} trigger={["hover"]}>
            <Form.Item
              {...choice}
              className="g-m-0"
              key="redirectUrl"
              name={[choice.name, "redirectUrl"]}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input
                className="g-interaction-edit-question-edit-form-field-choice-input"
                placeholder="Provide redirect URL here"
                disabled={selectionTypeValue === EQuestionSelectionType.MULTIPLE_SELECT}
              />
            </Form.Item>
          </Tooltip>
        )}
      </div>
      <div className="g-interaction-edit-question-edit-form-field-choice-delete-container">
        <Button type="text" onClick={() => remove(choice.name)} disabled={disableDelete}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Choice;
