import { useEffect, useState } from "react";
import { Button, Col, Input, Row, Form } from "antd";
import { toast } from "utils/toast";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/core";
import { apiUpdateUserProfile } from "services/shop";
import "react-phone-number-input/style.css";
import ConfirmationCodeModalComponent from "./confirmation-code-modal-component";

interface IUserProp {
    email?: string;
    firstname?: string;
    lastname?: string;
    name?: string;
    role?: string;
    phoneNumber?: string;
    [x: string]: any;
}

interface IProp {
    userInfo: any;
    resetAppContextOnFieldChange: () => void;
}

const dataTestIdPrefix = "AccountComponent";

const REQUIRED_RULE = { required: true, message: "This field is required" };
const NAME_PATTERN_RULE = { pattern: new RegExp(/^[a-zA-Z]+$/), message: "Please input alphabet characters only!" };
const SMALL_INPUT_MAX_LENGTH_RULE = { max: 50, message: "Input limited to maximum 50 characters!" };
const BIG_INPUT_MAX_LENGTH_RULE = { max: 256, message: "Input limited to maximum 256 characters!" };
const EMAIL_PATTERN_RULE = { pattern: new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/), message: "Please enter a valid email address" };

const AccountSettingsComponent = ({ userInfo, resetAppContextOnFieldChange }: IProp) => {
    const [isUpdateContentLoading, setUpdateContentLoading] = useState<boolean>(false);
    const [form] = Form.useForm<Pick<IUserProp, "firstname" | "lastname" | "role" | "email">>();
    const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
    const [isConfirmationCodeModalVisible, setIsConfirmationCodeModalVisible] = useState<boolean>(false);
    const [isEmailVerified, setEmailVerified] = useState<boolean>(true);

    useEffect(() => {
        console.log(userInfo);
        if (userInfo) {
            setInitialValues();
        }
    }, [userInfo]);

    const setInitialValues = async () => {
        setEmailVerified(Boolean(userInfo["email_verified"]));
        form.setFieldValue("firstname", userInfo["custom:firstName"]);
        form.setFieldValue("lastname", userInfo["custom:lastName"]);
        form.setFieldValue("role", userInfo["custom:userRole"] || "");
        form.setFieldValue("email", userInfo["email"]);
        setPhoneNumber(userInfo["phone_number"]);
        form.validateFields(["firstname", "lastname", "role", "email"]);
    };

    const handleSave = async (payload: IUserProp) => {
        try {
            const originalValuesObj: IUserProp = {
                firstname: userInfo['custom:firstName'],
                lastname: userInfo['custom:lastName'],
                role: userInfo['custom:userRole'],
                email: userInfo['email'],
                phoneNumber: userInfo['phone_number'],
            };

            payload.phoneNumber = phoneNumber;
            const diffStrArray: any = Object.keys(originalValuesObj).filter((v) => originalValuesObj[v] !== payload[v]);
            if (diffStrArray.length === 0) {
                toast.info(`No changes detected`, 5);
                return;
            }

            if (diffStrArray.indexOf('email') === -1) {
                delete payload.email;
            }

            const name = `${payload.firstname} ${payload.lastname}`;
            payload.name = name;
            setUpdateContentLoading(true);
            await apiUpdateUserProfile({ updatePayload: payload });
            if (payload.email && userInfo) {
                setIsConfirmationCodeModalVisible(true);
            }
            toast.success(`User information updated successfully`, 5);
        } catch (error: any) {
            console.log("🚀 ~ file: index.tsx ~ handleSave ~ error", error);
            toast.error(error?.message || error, 10);
        } finally {
            resetAppContextOnFieldChange();
            setUpdateContentLoading(false);
        }
    };

    return (
        <div className="g-account-settings">
            <Form form={form} layout="vertical" onFinish={handleSave} autoComplete="off">
                <Row className="g-account-settings-row" gutter={{ xs: 8, sm: 8, md: 10, lg: 10 }}>
                    <Col span={8} className="g-account-settings-col">
                        <Form.Item name="firstname" label="First Name" rules={[REQUIRED_RULE, SMALL_INPUT_MAX_LENGTH_RULE, NAME_PATTERN_RULE]}>
                            <Input
                                type="text"
                                name="firstname"
                                placeholder="First Name"
                                className="g-account-input"
                                size="large"
                                data-testid={`${dataTestIdPrefix}-FirstNameInput`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="g-account-settings-col">
                        <Form.Item name="lastname" label="Last Name" rules={[REQUIRED_RULE, SMALL_INPUT_MAX_LENGTH_RULE, NAME_PATTERN_RULE]}>
                            <Input
                                type="text"
                                name="lastname"
                                placeholder="Last Name"
                                className="g-account-input"
                                size="large"
                                data-testid={`${dataTestIdPrefix}-LastNameInput`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="g-account-settings-col">
                        <Form.Item name="role" label="Role" rules={[BIG_INPUT_MAX_LENGTH_RULE]}>
                            <Input
                                type="text"
                                name="role"
                                placeholder="Role"
                                className="g-account-input"
                                size="large"
                                data-testid={`${dataTestIdPrefix}-RoleInput`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="g-account-settings-col">
                        <Form.Item name="email" label="Email" rules={[REQUIRED_RULE, EMAIL_PATTERN_RULE, BIG_INPUT_MAX_LENGTH_RULE]}>
                            <Input
                                type="text"
                                name="email"
                                placeholder="Email"
                                className="g-account-input"
                                size="large"
                                data-testid={`${dataTestIdPrefix}-EmailInput`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="g-account-settings-col">
                        <Form.Item label="Phone Number">
                            <PhoneInput
                                placeholder="Enter Phone Number"
                                defaultCountry={"US"}
                                value={phoneNumber}
                                onChange={(value: E164Number) => setPhoneNumber(value)}
                                className="g-account-setting-phone-number-input"
                                limitMaxLength={true}
                                error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : "Please input Valid Phone number!") : undefined}
                                data-testid={`${dataTestIdPrefix}-PhoneInput`}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="g-account-settings-col">
                        {!isEmailVerified && (
                            <div className="g-account-setting-email-verification">
                                <div className="g-account-setting-email-verification-text">
                                    Your email is not verified. Please verify your email by clicking&nbsp;&nbsp;
                                    <span className="g-account-setting-email-verification-text-span-link" onClick={() => setIsConfirmationCodeModalVisible(true)}>
                                        here
                                    </span>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
                <div className="g-account-setting-container-for-buttons">
                    <Button type="default" className="g-account-remove-button" size="large" onClick={setInitialValues} disabled={isUpdateContentLoading}>
                        Discard
                    </Button>
                    <Button type="primary" className="g-account-save-button" size="large" onClick={() => form.submit()} disabled={isUpdateContentLoading} loading={isUpdateContentLoading}>
                        Save Changes
                    </Button>
                </div>
            </Form>
            {isConfirmationCodeModalVisible && (
                <ConfirmationCodeModalComponent resetAppContextOnFieldChange={resetAppContextOnFieldChange} setIsConfirmationCodeModalVisible={setIsConfirmationCodeModalVisible} />
            )}
        </div>
    );
};

export default AccountSettingsComponent;
