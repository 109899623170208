import axios from "axios";
import { IInteraction } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  id: string;
}

interface Response {
  interaction: IInteraction;
  message: string;
}

const apiDeleteInteraction = async ({ id }: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/interactions?id=${id}`;
    const response = await axios.delete<Response>(url);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiDeleteInteraction;