import { IAbTestingDetails, IConversionRateByDeviceTableDetails, IConversionRateByVisitorTypeTableDetails, ITopVideosDetails, TMetricGroup } from "interfaces";

export const tableRowDummyData = {
  eventImpressionTableNameExists: true,
  eventPurchasesTableNameExists: true,
  eventVideosTableNameExists: true,
};

export const onSiteEngagementCurrentDummyData: TMetricGroup = [
  {
    ID: "On Site Engagement (Group)",
    TIMESTAMP: new Date("2023-01-26"),
    NUMBER_OF_ENGAGED_VISITOR: 459,
    VALUE: 13.1481,
  },
  {
    ID: "On Site Engagement (Group)",
    TIMESTAMP: new Date("2023-01-27"),
    NUMBER_OF_ENGAGED_VISITOR: 67,
    VALUE: 10.9836,
  },
];
export const onSiteEngagementPrevDummyData: TMetricGroup = [
  {
    ID: "On Site Engagement (Group)",
    TIMESTAMP: new Date("2023-01-25"),
    NUMBER_OF_ENGAGED_VISITOR: 311,
    VALUE: 13.7855,
  },
  {
    ID: "On Site Engagement (Group)",
    TIMESTAMP: new Date("2023-01-26"),
    NUMBER_OF_ENGAGED_VISITOR: 459,
    VALUE: 13.1481,
  },
];

export const deviceBreakdownCurrentDummyData: TMetricGroup = [
  {
    ID: "Desktop",
    TIMESTAMP: new Date("2023-01-26"),
    VALUE: 530,
  },
  {
    ID: "Mobile",
    TIMESTAMP: new Date("2023-01-26"),
    VALUE: 293,
  },
  {
    ID: "Mobile",
    TIMESTAMP: new Date("2023-01-27"),
    VALUE: 83,
  },
  {
    ID: "Desktop",
    TIMESTAMP: new Date("2023-01-27"),
    VALUE: 62,
  },
];

export const deviceBreakdownTotalDummyData = [
  {
    ID: "Mobile",
    VALUE: 365,
  },
  {
    ID: "Desktop",
    VALUE: 585,
  },
];

export const allQueriesNonGroupedCurrentDay = [
  {
    ID: "Gander Influenced Sale",
    VALUE: 224.8,
  },
  {
    ID: "Average Order Value",
    VALUE: 14.05,
  },
  {
    ID: "On Site Engagement",
    VALUE: 12.8261,
  },
  {
    ID: "Video Conversions",
    VALUE: 1.2464,
  },
  {
    ID: "Performance",
    VALUE: 2.318022633,
  },
];

export const allQueriesNonGroupedPreviousDay = [
  {
    ID: "Gander Influenced Sale",
    VALUE: 502.58,
  },
  {
    ID: "Average Order Value",
    VALUE: 14.781764706,
  },
  {
    ID: "On Site Engagement",
    VALUE: 13.3983,
  },
  {
    ID: "Video Conversions",
    VALUE: 2.0661,
  },
  {
    ID: "Performance",
    VALUE: 2.363297278,
  },
];

export const groupVideoAnalyticsGroupCurrentDay = [
  {
    ID: "Watched",
    TIMESTAMP: "2023-01-26",
    VALUE: 180,
  },
  {
    ID: "Purchased",
    TIMESTAMP: "2023-01-26",
    VALUE: 1,
  },
];

export const groupVideoAnalyticsGroupPreviousDay = [
  {
    ID: "Watched",
    TIMESTAMP: "2023-01-25",
    VALUE: 153,
  },
  {
    ID: "Purchased",
    TIMESTAMP: "2023-01-25",
    VALUE: 8,
  },
  {
    ID: "Watched",
    TIMESTAMP: "2023-01-26",
    VALUE: 180,
  },
  {
    ID: "Purchased",
    TIMESTAMP: "2023-01-26",
    VALUE: 1,
  },
];

export const videoAnalyticsDummyData = [
  {
    ID: "Watched",
    TIMESTAMP: "2023-01-26",
    VALUE: 180,
  },
    {
    ID: "Purchased",
    TIMESTAMP: "2023-01-26",
    VALUE: 1,
  },
];

export const viewWatchedDummyData = [
  {
    ID: "Watched",
    TIMESTAMP: "2023-01-26",
    VALUE: 180,
  },
];

export const videoPurchasedDummyData = [
  {
    ID: "Purchased",
    TIMESTAMP: "2023-01-26",
    VALUE: 1,
  },
];

export const topVideosDummyData: ITopVideosDetails[] = [
  {
    meta: "meta-01",
    name: "video-01",
    avatarUrl: "https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png",
    engagementRate: 100,
    ganderInfluencedSales: 100
  }
];


const conversionRateDummyDataRow: IConversionRateByDeviceTableDetails = {
  product_name: "",
  mobile: 0,
  desktop: 0
}

export const conversionRateByDeviceDummyData: IConversionRateByDeviceTableDetails[] = [
  conversionRateDummyDataRow,
  conversionRateDummyDataRow
];

const conversionRateDummyDataRowVisitor: IConversionRateByVisitorTypeTableDetails = {
  PRODUCT_NAME: "",
  CONVERSION_NEW: 0,
  CONVERSION_REPEAT: 0
}

export const conversionRateByVisitorTypeDummyData: IConversionRateByVisitorTypeTableDetails[] = [
  conversionRateDummyDataRowVisitor,
  conversionRateDummyDataRowVisitor,
];

export const abTestingDummyData: IAbTestingDetails[] = [
  {
    key: 'test',
    name: 'Test A/B Testing',
    date: '3 March 2023',
    meta: 'meta-1',
    status: 'running',
    details: [
      {
        avatarUrl: 'https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png',
        AVG_VIEW_TIME: 4,
        CONVERSION_RATE: 32,
        ENGAGEMENT_RATE: 32,
        name: 'test-video',
        meta: 'test-meta'
      },
      {
        avatarUrl: 'https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png',
        AVG_VIEW_TIME: 4,
        CONVERSION_RATE: 32,
        ENGAGEMENT_RATE: 32,
        name: 'test-video',
        meta: 'test-meta'
      }
      ]
  },
  {
    name: 'Test A/B Testing',
    key: 'test-2',
    date: '3 March 2023',
    meta: 'meta-2',
    status: 'complete',
    details: [
      {
        avatarUrl: 'https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png',
        AVG_VIEW_TIME: 4,
        CONVERSION_RATE: 32,
        ENGAGEMENT_RATE: 32,
        name: 'test-video',
        meta: 'test-meta'
      },
      {
        avatarUrl: 'https://uxwing.com/wp-content/themes/uxwing/download/emoji-emoticon/smiley-icon.png',
        AVG_VIEW_TIME: 4,
        CONVERSION_RATE: 32,
        ENGAGEMENT_RATE: 32,
        name: 'test-video',
        meta: 'test-meta'
      }
      ]
  }
];