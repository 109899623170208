import PreviewShortAnswerInteraction from "./preview-short-answer-interaction-component";
import PreviewCallToActionInteraction from "./preview-call-to-action-interaction-component";
import PreviewMultipleChoiceInteraction from "./preview-multiple-choice-interaction-component";
import PreviewPictureChoiceInteraction from "./preview-picture-choice-interaction-component";
import { EQuestionType, IQuestion } from "interfaces/schema";
import PreviewAddToCartInteraction from "./preview-add-to-cart-interaction-component";

import "./index.scss";

const dataTestIdPrefix = "Videos-PreviewInteractionComponent";
interface Props {
  question: IQuestion | undefined;
  size?: "default" | "large";
}

const PreviewInteraction = ({ question, size }: Props) => {
  let questionComponent;

  switch (question?.type) {
    case (EQuestionType.SHORT_ANSWER):
      questionComponent = <PreviewShortAnswerInteraction question={question} size={size} />;
      break;
    case (EQuestionType.CALL_TO_ACTION):
      questionComponent = <PreviewCallToActionInteraction question={question} size={size} />;
      break;
    case (EQuestionType.ADD_TO_CART):
      questionComponent = <PreviewAddToCartInteraction question={question} />;
      break;
    case (EQuestionType.MULTIPLE_CHOICE):
      questionComponent = <PreviewMultipleChoiceInteraction question={question} size={size} />;
      break;
    case (EQuestionType.PICTURE_CHOICE):
      questionComponent = <PreviewPictureChoiceInteraction question={question} size={size} />;
      break;
    default:
      questionComponent = null;
  }

  return (
    <div className="g-interation-edit-preview-panel-thumbnail-interaction-container" data-testid={`${dataTestIdPrefix}-PreviewInteraction`}>
      {questionComponent}
    </div>
  );
}

export default PreviewInteraction;