import { Input } from "antd";
import { HEX2RGB } from "utils/common-functions";

import "./index.scss";

interface IRGBAColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

interface Props {
    isEyeDropperAllowed: boolean;
    color: IRGBAColor;
    hexColor: string;
    setHexColor: React.Dispatch<React.SetStateAction<string>>;
    updateRGBAPickerValue: (RGBA: IRGBAColor) => void;
}

const HexColorInput = ({ isEyeDropperAllowed, color, hexColor, setHexColor, updateRGBAPickerValue }: Props) => {
    const updateHexColorValue = (value: string) => {
        if (value?.length <= 6) {
            setHexColor(value.toUpperCase());
            if (value?.length === 6) {
                const resRgb = HEX2RGB("#" + value);
                if (resRgb !== null) {
                    updateRGBAPickerValue({
                        r: resRgb?.r,
                        g: resRgb?.g,
                        b: resRgb?.b,
                        a: color?.a,
                    });
                }
            }
        }
    };

    const updateRGBColorValue = (colorType: string, value: string) => {
        var pattern = /^\d+\.?\d*$/;
        if (pattern.test(value) || value === "") {
            switch (colorType) {
                case "R":
                    updateRGBAPickerValue({ r: Number(value), g: color?.g, b: color?.b, a: color?.a });
                    break;
                case "G":
                    updateRGBAPickerValue({ r: color?.r, g: Number(value), b: color?.b, a: color?.a });
                    break;
                case "B":
                    updateRGBAPickerValue({ r: color?.r, g: color?.g, b: Number(value), a: color?.a });
                    break;
            }
        }
    };

    return (
        <div
            className={
                isEyeDropperAllowed ? "g-customization-color-input-container g-customization-color-reduce-margin" : "g-customization-color-input-container"
            }
        >
            <div>
                <div className="g-customization-color-input-title">Hex</div>
                <Input
                    className="g-customization-color-input g-customization-color-hex-input"
                    type="text"
                    value={hexColor}
                    onChange={(e) => updateHexColorValue(e?.target?.value)}
                    maxLength={6}
                />
            </div>
            <div>
                <div className="g-customization-color-input-title">R</div>
                <Input
                    className="g-customization-color-input"
                    min={0}
                    max={255}
                    maxLength={3}
                    value={color?.r}
                    onChange={(e) => updateRGBColorValue("R", e?.target?.value)}
                />
            </div>
            <div>
                <div className="g-customization-color-input-title">G</div>
                <Input
                    className="g-customization-color-input"
                    min={0}
                    max={255}
                    maxLength={3}
                    value={color?.g}
                    onChange={(e) => updateRGBColorValue("G", e?.target?.value)}
                />
            </div>
            <div>
                <div className="g-customization-color-input-title">B</div>
                <Input
                    className="g-customization-color-input"
                    min={0}
                    max={255}
                    maxLength={3}
                    value={color?.b}
                    onChange={(e) => updateRGBColorValue("B", e?.target?.value)}
                />
            </div>
        </div>
    );
};

export default HexColorInput;
