import { Button, Typography } from 'antd';
import { SettingsNeedHelpArrowIcon } from 'components/icons';

import "./index.scss";

const dataTestIdPrefix = "NeedHelpCardComponent";

const { Title, Text } = Typography;

const NeedHelpCardComponent = () => {
    return (
        <div className="g-need-help-card">
            <Title className='g-need-help-card-title' level={3} data-testid={`${dataTestIdPrefix}-NeedHelpTitle`}>
                Need help?
            </Title>
            <Text className='g-need-help-card-description'>
                Check out our help center for guidance on how to set everything up.
            </Text>
            <br />
            <Button
                data-testid={`${dataTestIdPrefix}-GoToHelpCenter`}
                className='g-need-help-card-button'
                type="primary"
                ghost
                href="https://www.gogander.io/faq"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div>Go to help center</div>
                <SettingsNeedHelpArrowIcon className='g-mt-4 g-mx-8' />
            </Button>
        </div>
    )
}

export default NeedHelpCardComponent;