import { SETTINGS_PAGE_SUBSCRIPTION_CUSTOM_PLAN_CONTACT_US_LINK } from "constants/hyper-links";
import { EBillingCycle, EDefaultPlan, IActiveSubscription, IShopDetails, ISubscriptionPlan } from "interfaces";
import { PLATFORM } from "constants/store";
import { apiGetSubscriptionPlans } from "services/subscription.services";

export const getPlanPriceTextGatingV3 = (subscriptionPlan: ISubscriptionPlan) => {
    if (subscriptionPlan?.isContactSalesPlan) {
        return "Custom";
    } else if (subscriptionPlan?.billingCycle === EBillingCycle.MONTHLY && subscriptionPlan?.price) {
        return `$${subscriptionPlan?.price}/mo`;
    } else if (subscriptionPlan?.billingCycle === EBillingCycle.ANNUALLY && subscriptionPlan?.price) {
        return `$${(Math.round((subscriptionPlan?.price / 12) *100)/100)}/mo`;
    } else {
        return `$${subscriptionPlan?.price}/mo`;
    }
};

export const getPlanVideoEmbeddingTextV1V2 = (subscriptionPlan: ISubscriptionPlan) => {
    let videoEmbeddingText = "n/a";

    if (subscriptionPlan?.videoEmbeddingIncludedVisit !== undefined && subscriptionPlan?.videoEmbeddingAdditionalPerVisitCharge) {
        videoEmbeddingText = `includes ${subscriptionPlan?.videoEmbeddingIncludedVisit?.toLocaleString("en-US")} impressions + $${
            subscriptionPlan?.videoEmbeddingAdditionalPerVisitCharge
        } per impression`;
    }

    if (!!subscriptionPlan?.isContactSalesPlan) {
        videoEmbeddingText = "Includes custom number of visits + discounted cost per impression";
    }
    return videoEmbeddingText;
};

export const getPlanVideoEmbeddingTextV3 = (subscriptionPlan: ISubscriptionPlan) => {
    let videoEmbeddingText = "n/a";

    if (subscriptionPlan?.videoEmbeddingIncludedVisit !== undefined && subscriptionPlan?.videoEmbeddingAdditionalPerVisitCharge) {
        videoEmbeddingText = `includes ${subscriptionPlan?.videoEmbeddingIncludedVisit?.toLocaleString("en-US")} views`;
    } else if (subscriptionPlan.meta === EDefaultPlan.NEW_FREE_PLAN) {
        videoEmbeddingText = "includes 5,000 views";
    }

    if ((subscriptionPlan?.videoEmbeddingIncludedVisit === 0 && Number(subscriptionPlan?.videoEmbeddingAdditionalPerVisitCharge) === 0) || !!subscriptionPlan?.isContactSalesPlan) {
        videoEmbeddingText = "includes unlimited views";
    }
    return videoEmbeddingText;
};

export const getPlanActionButtonHrefLink = (subscriptionPlan: ISubscriptionPlan) => {
    let actionButtonHrefLink: string | undefined;

    if (subscriptionPlan?.isContactSalesPlan) {
        actionButtonHrefLink = SETTINGS_PAGE_SUBSCRIPTION_CUSTOM_PLAN_CONTACT_US_LINK;
    }

    if (subscriptionPlan?.isCurrentActivePlan) {
        actionButtonHrefLink = undefined;
    }

    return actionButtonHrefLink;
};

export const getPlanActionButtonText = (subscriptionPlan: ISubscriptionPlan, isCurrentlyActivePlan: boolean | undefined) => {
    let actionButtonText = "Select Plan";

    if (subscriptionPlan?.isContactSalesPlan) {
        actionButtonText = "Contact Sales";
    }

    if (isCurrentlyActivePlan) {
        actionButtonText = "Current Plan";
    }

    return actionButtonText;
};

export const getShopUrl = (shop: IShopDetails) => {
    if (shop.platform === "shopify") {
        return shop.shopifyUrl;
    }
    return shop.domain;
};

// This function will help us managing v3 v2 plans easily
export const getPlansAndParameters = async (shopDetails: IShopDetails, activeSubscription: IActiveSubscription) => {
    let response = await apiGetSubscriptionPlans();

    // listing plans based on versions and filters
    const plansV3 = response.plans.filter((plan) => plan.planVersion === 3);

    const oldPlans = response.plans.filter((plan) => {
        // if user has content only plan already active
        const isContentOnlyPlan = activeSubscription?.activePlan?.meta === EDefaultPlan.CONTENT_ONLY_PLAN;
        if (plan?.meta === EDefaultPlan.CONTENT_ONLY_PLAN) {
            return isContentOnlyPlan;
        }
        return !plan.planVersion;
    });
    // Checking for acurrenlty active plan is v3
    const isActivePlanVersionV3 = activeSubscription?.activePlan?.planVersion === 3;
    // if platfom is shopify or not
    const isPlatformShopify = shopDetails?.platform.toLowerCase() === PLATFORM.Shopify.toLowerCase();
    // based on that we can decide if its v3 flow or v1v2 flow
    const isV3PlansFlow = (!activeSubscription || isActivePlanVersionV3);
    let plans = isV3PlansFlow ? plansV3 : oldPlans;
    const customPlans = plans.filter((p) => !p.isDefaultPlan);
    if (customPlans.length > 0) {
        // Note: if no active plan then showing new plans only (ignoring v2 cusstom plans as well)
        const currentDefaultActivePlan = plans.filter((p) => activeSubscription?.activePlan?.meta === p?.meta && activeSubscription.activePlan.isDefaultPlan);
        plans = [...currentDefaultActivePlan, ...customPlans];
    }

    // if already billing cycle plan selected then we should show it otherwise montlhy or annual
    let defaultBillingCycle = activeSubscription?.activePlan?.billingCycle || (isPlatformShopify ? EBillingCycle.MONTHLY : EBillingCycle.ANNUALLY);
    // To avoid showing blank plan list
    const annualPlans = plans.filter((plan) => plan.billingCycle === EBillingCycle.ANNUALLY);
    const monthlyPlans = plans.filter((plan) => plan.billingCycle === EBillingCycle.MONTHLY);
    if (defaultBillingCycle === EBillingCycle.ANNUALLY && annualPlans.length === 0 && monthlyPlans.length > 0) {
        defaultBillingCycle = EBillingCycle.MONTHLY;
    }

    // Showing  billingCycle content only if non shopify
    const isShowBillingCycleContent = !isPlatformShopify && isV3PlansFlow;

    // console.log(`🚀 getPlansAndParameters ~ { plans, defaultBillingCycle, isShowBillingCycleContent, isV3PlansFlow, annualPlans, monthlyPlans }`, {
    //     plans,
    //     defaultBillingCycle,
    //     isShowBillingCycleContent,
    //     isV3PlansFlow,
    //     monthlyPlans,
    //     annualPlans,
    // });
    return { plans, defaultBillingCycle, isShowBillingCycleContent, isV3PlansFlow };
};

export const getPlanPriceText = (subscriptionPlan: ISubscriptionPlan) => {
    if (subscriptionPlan?.isContactSalesPlan) {
        return "Contact Us";
    } else {
        return `$${subscriptionPlan?.pricePerMonth}/mo`;
    }
};