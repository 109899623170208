import axios from "axios";
import { IInteraction } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  limit?: number;
  filterName?: string;
  lastEvaluatedMeta?: string;
}

interface Response {
  interactions: IInteraction[];
  lastEvaluatedMeta: string | undefined;
  message: string;
  allowedCreateNew: boolean;
  interactionsCount: number;
}

const apiGetInteractions = async (payload: Payload): Promise<Response> => {
  try {
    const response = await axios.post<Response>(`${REACT_APP_API_URL}/interactions/search`, payload);
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiGetInteractions;