import axios, { AxiosError } from "axios";
import { IInstagramVideo } from "interfaces/instagram";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  username?: string;
  userId?: string;
  cursor?: string;
}

interface Response {
  message: string;
  userId?: string;
  videos?: IInstagramVideo[];
  cursor?: string;
}

const apiGetUserVideos = async ({ username, userId, cursor }: Payload): Promise<Response> => {
  try {
    if (!username && !userId) {
      throw new Error("username or user id required");
    }

    const url = `${REACT_APP_API_URL}/instagram/videos`;
    const response = await axios.get<Response>(url, { params: {
      username,
      userId,
      cursor,
    }});
    return response.data;
  } catch (e) {
    console.log(e);
    if (e instanceof AxiosError) {
      if (e?.response?.status === 400) {
        return {
          message: e.response.data?.message,
        }
      }
    }
    throw e;
  }
};

export default apiGetUserVideos;