import { useContext, useEffect, useRef, useState } from "react";
import { Button, Input, Typography } from "antd";
import { IInteraction } from "interfaces/schema";
import { LoadingOutlined } from "@ant-design/icons";
import { EditPageUrlIcon } from "components/icons";
import { apiUpdateInteraction } from "services/interactions";
import { toast } from "utils/toast";
import { InteractionsContext } from "context/interactions-context";

import './index.scss';

const { Title } = Typography;

const dataTestIdPrefix = "InteractionsNameColumn";

interface Props {
  interaction: IInteraction;
}

const InteractionsNameColumn = ({ interaction }: Props) => {
  const { interactions, setInteractions } = useContext(InteractionsContext);
  const [input, setInput] = useState(interaction.name);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setInput(interaction.name);
        setIsEdit(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleEditOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setIsEdit(true);
  };

  const handleEditSubmit = async (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (input.length === 0) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await apiUpdateInteraction({ id: interaction.meta, name: input, isNameUpdatedByUser: true });
      const tempInteractions = [...interactions];
      const updatedInteractionIndex = interactions.indexOf(interaction);
      tempInteractions[updatedInteractionIndex] = {
        ...tempInteractions[updatedInteractionIndex],
        ...response.interaction,
      }
      setInteractions(tempInteractions);
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong with updating interaction. Please try again.");
    } finally {
      setIsLoading(false);
      setIsEdit(false);
    }
  }

  return (
    <div className="g-interactions-name-row-container" ref={wrapperRef}>
      {isEdit ? (
        <>
          <Input
            type="text"
            value={input}
            status={input.length === 0 ? "error" : undefined}
            placeholder="Name cannot be empty"
            disabled={isLoading}
            onPressEnter={handleEditSubmit}
            onChange={(event) => setInput(event.target.value)}
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}
            data-testid={`${dataTestIdPrefix}-Input`}
          />
          <Button
            className="g-interactions-name-edit-submit-button"
            type="default"
            size='small'
            onClick={handleEditSubmit}
            data-testid={`${dataTestIdPrefix}-SaveButton`}
          >
            {isLoading ? <LoadingOutlined/> : "Save"}
          </Button>
        </>
      ) : (
        <>
          <span onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}>
            <Title className="g-interaction-name-title" level={5} data-testid={`${dataTestIdPrefix}-Title`} title={interaction.name}>{interaction.name}</Title>
          </span>
          <Button
            type="text"
            size='small'
            icon={<EditPageUrlIcon/>}
            onClick={handleEditOpen}
            data-testid={`${dataTestIdPrefix}-EditButton`}
          />
        </>
      )}
    </div>
  );
}

export default InteractionsNameColumn;