import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Button, Col, Row, Typography, Upload, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import ImgCrop from "antd-img-crop";
import { toast } from "utils/toast";

const { Title } = Typography;

const ALLOWED_TYPES = ["image/jpeg", "image/png"];
const FILE_SIZE_LIMIT_IN_MB = 1024 * 1024 * 2;
const dataTestIdPrefix = "ProfilePictureComponent";

interface IProp {
    userInfo: any;
    updateCognitoUserAttributes: (userAttributes: any) => Promise<IResponse>;
}

interface IResponse {
    res: any;
    error: any;
}

const ProfilePictureComponent = ({ userInfo, updateCognitoUserAttributes }: IProp) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRemoveImageLoader, setIsRemoveImageLoader] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>("");
    const [s3UserProfilePictureUrlLocation, setS3UserProfilePictureUrlLocation] = useState<string>("");

    useEffect(() => {
        if (userInfo) {
            const profilePictureUrl = userInfo["custom:profilePictureUrl"] || "";
            const shopUUID = userInfo["custom:shopUUID"] || "";
            const userUUID = userInfo["sub"] || "";
            const timestamp = new Date().getTime();
            const baseS3GetImageLocation = `profile-pictures/${shopUUID}/${userUUID}-${timestamp}`;
            setS3UserProfilePictureUrlLocation(baseS3GetImageLocation);
            setImageUrl(profilePictureUrl);
        }
    }, [userInfo]);

    const onRemoveImage = async () => {
        setIsRemoveImageLoader(true);
        try {
            const filePath = imageUrl.split("public/")[1];
            await Storage.remove(filePath);
            const res = await updateCognitoUserAttributes({ "custom:profilePictureUrl": "" });
            if (res.error) {
                throw new Error(String(res.error));
            }
            setImageUrl("");
            toast.success(`Image removed successfully`, 5);
        } catch (error) {
            toast.error(`Sorry, something went wrong with removing image. Please try again later.`, 10);
            console.log("🚀 ~ file: index.tsx ~ onRemoveImage ~ error", error);
        } finally {
            setIsRemoveImageLoader(false);
        }
    };

    const beforeUpload = async (file: RcFile) => {
        const isAllowedFileType = ALLOWED_TYPES.includes(file.type);
        if (!isAllowedFileType) {
            toast.error("You can only upload JPG/PNG file!", 10);
        }
        const isLessThan2M = file.size <= FILE_SIZE_LIMIT_IN_MB;
        if (!isLessThan2M) {
            toast.error("Image must smaller than 2MB!", 10);
        }
        if (isAllowedFileType && isLessThan2M) {
            await imageUploadEvent(file);
        }
        return false;
    };

    const imageUploadEvent = async (file: RcFile) => {
        setIsLoading(true);
        try {
            const fileExtension = file.name.split(".").pop();
            const filePath = imageUrl.split("public/")[1];
            const { key } = await Storage.put(`${s3UserProfilePictureUrlLocation}.${fileExtension}`, file, {
                contentType: file.type,
                completeCallback: (event) => {
                    console.log(`Successfully uploaded ${event.key}`);
                },
                progressCallback: (progress) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
                errorCallback: (err) => {
                    console.error("Unexpected error while uploading", err);
                },
            });
            const signedUrl = await Storage.get(key);
            const imageS3Url = signedUrl.split("?")[0];
            const res = await updateCognitoUserAttributes({ "custom:profilePictureUrl": imageS3Url });
            if (res.error) {
                throw new Error(String(res.error));
            }
            setImageUrl(imageS3Url);
            if (filePath) {
                await Storage.remove(filePath);
            }
            toast.success("Image uploaded successfully", 5);
        } catch (error) {
            toast.error("Error uploading image", 10);
            console.log("🚀 ~ file: index.tsx ~ imageUploadEvent ~ error", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="g-account-profile">
            <Row align="top">
                <Col>
                    <Avatar
                        data-testid={`${dataTestIdPrefix}-Avatar`}
                        size={104}
                        style={{ backgroundColor: "#1890ff" }}
                        icon={<UserOutlined />}
                        src={imageUrl}
                    ></Avatar>
                </Col>
                <Col className="g-account-profile-layout">
                    <Title level={5} className="g-account-title-5">
                        Profile Picture
                    </Title>
                    <div className="g-account-subtitle">
                        We recommend an image of at least 400x400.
                    </div>
                    <ImgCrop rotationSlider>
                        <Upload
                            name="upload-button"
                            className="g-account-profile-upload-component"
                            showUploadList={false}
                            beforeUpload={(evt) => beforeUpload(evt)}
                        >
                            <Button
                                type="primary"
                                className="g-account-save-button"
                                size="large"
                                disabled={isLoading || isRemoveImageLoader}
                                loading={isLoading}
                                data-testid={`${dataTestIdPrefix}-UploadButton`}
                            >
                                Upload Image
                            </Button>
                        </Upload>
                    </ImgCrop>
                    <Button
                        type="default"
                        className="g-account-remove-button"
                        color="red"
                        size="large"
                        disabled={isRemoveImageLoader || isLoading || !imageUrl}
                        loading={isRemoveImageLoader}
                        onClick={onRemoveImage}
                        data-testid={`${dataTestIdPrefix}-RemoveButton`}
                    >
                        Remove
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ProfilePictureComponent;
