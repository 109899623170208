import { Button, Modal, Popover, Skeleton, Space, Tag, Tooltip, Typography } from "antd";
import GanderVideo from "components/gander-video";
import { EPreviewPlayButtonSize, EProgress } from "interfaces";
import { EVideoSource, IVideo } from "interfaces/schema";
import moment from "moment";
import { formatBytes, formatDuration } from "utils/common-functions";
import { RightOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { AppContext } from "context/app-context";
import { PLATFORM } from "constants/store";
import { VideoTableDeleteButtonIcon } from "components/icons";
import { toast } from "utils/toast";
import { apiCreateCustomerPortalSession } from "services/stripe";
import { PRIVATE_ROUTES } from "constants/routes";
import { VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST } from "config";

const { Title, Text } = Typography;
const { confirm } = Modal;

const dataTestIdPrefix = "RequestCotnentPage-ExternalUploadedContentColumnsComponent";

const ExternalUploadedContentColumns = (setSelectedReviewVideo: React.Dispatch<React.SetStateAction<IVideo | undefined>>, deleteVideo: (videoObj: IVideo) => Promise<void>,) => {
    const { shopDetails, setIsAppLoading } = useContext(AppContext);

    const handleDeleteVideo = (row: IVideo) => {
        if (!!row.purchaseId && !!row.purchasedAt) {
            toast.warning("Please cancel the SoMe license subscription first to delete the video");
            return;
        }
        if (row?.pages?.length > 0) {
            toast.warning("Please unlink all pages to delete video");
            return;
        }
        confirm({
            title: "Delete Video",
            icon: <ExclamationCircleOutlined />,
            className: "g-manage-content-video-delete-confirmation-modal",
            content: `Do you want to delete this video?`,
            okText: <div>Delete</div>,
            okType: "danger",
            cancelText: "Cancel",
            centered: true,
            async onOk() {
                await deleteVideo(row);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const getSourceTag = (video: IVideo) => {
        if (video?.progress === EProgress.PROGRESSING || video?.progress === EProgress.UPLOADING || video?.progress === EProgress.PENDING) {
            return <Skeleton.Button className="g-list-source-tag-loading" active size="small" />;
        }

        const isPurchasedRequestedContent = video?.source === EVideoSource.REQUESTED_CONTENT && video?.purchasedAt && video?.purchaseId;
        const isExternalUploadApproveFlow = video.source === EVideoSource.EXTERNAL_UPLOAD && !video?.externalUploadDetails?.approved;
        if (isExternalUploadApproveFlow) {
            return (
                <Space size="middle" className="g-manage-content-spacer-action-items">
                    <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => setSelectedReviewVideo(video)}
                        disabled={![EProgress.COMPLETE].includes(video.progress)}
                    >
                        Review Video
                        <RightOutlined />
                    </Button>
                </Space>
            );
        } else if (isPurchasedRequestedContent && shopDetails?.platform !== PLATFORM.Shopify.toLowerCase()) {
            return video?.originalMediaUrl ? (
                <Popover
                    content={
                        <div className="g-list-sources-popover-content">
                            <div className="g-list-sources-popover-content-title">Manage social media license subscription</div>
                            <div>
                                <Button
                                    type="link"
                                    className="g-padding-0 "
                                    onClick={() => openStripeCustomerPortal()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }
                    trigger="hover"
                >
                    <Tag color={"orange"}>
                        {`Expires ${moment(Number(video?.expiresAt)).format("LL")}`}
                    </Tag>
                </Popover>
            ) : (
                <Tag color={"red"}>
                    {"Expired"}
                </Tag>
            );
        } else if (isPurchasedRequestedContent && shopDetails?.platform === PLATFORM.Shopify.toLowerCase()) {
            return (
                <Tag color={video?.originalMediaUrl ? "orange" : "red"} >
                    {video?.originalMediaUrl ? `Expires ${moment(Number(video?.expiresAt)).format("LL")}` : "Expired"}
                </Tag>
            );
        }

        return (
            <Tag color={VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST[video?.source]?.tag} >
                <Text style={{ color: VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST[video?.source]?.text }}>{video?.source}</Text>
            </Tag>
        );
    };

    const openStripeCustomerPortal = async () => {
        try {
            setIsAppLoading(true);
            const responseData = await apiCreateCustomerPortalSession(shopDetails!.stripeCustomerId!, PRIVATE_ROUTES?.VIDEOS?.PATH);
            window.open(responseData?.customerPortalSession.url, "_self");
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong fetching portal URL!");
            setIsAppLoading(false);
        }
    };

    return [
        {
            title: "VIDEO",
            dataIndex: "displayName",
            key: "displayName",
            render: (displayName: string, video: IVideo) => (
                <div className="g-list-video-container">
                    <div className="g-list-video-player">
                        <GanderVideo video={video} buttonSize={EPreviewPlayButtonSize.TINY} />
                    </div>
                    <div>
                        <Title className="g-list-video-table-title" level={5} data-testid={`${dataTestIdPrefix}-VideoTitle`}>
                            <Tooltip title={displayName} placement="topLeft">
                                {displayName}
                            </Tooltip>
                        </Title>
                        <Space size={1} className="g-manage-content-spacer-action-items">
                            {video?.duration && Number(video?.duration) > 0 && (
                                <div className="g-list-video-details">
                                    {formatDuration(Number(video?.duration)) &&
                                        <div>{formatDuration(Number(video?.duration))}</div>
                                    }
                                    <div className="g-list-video-details-dot">&nbsp;</div>
                                </div>
                            )}
                            {video?.size &&
                                <div className="g-list-video-details">{formatBytes(video?.size) && <div>{formatBytes(video?.size)}</div>}</div>
                            }
                        </Space>
                    </div>
                </div>
            ),
        },
        {
            title: "DATE UPLOADED",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: number) => (
                <div className="g-list-date-uploaded-container">
                    <div className="g-list-date-uploaded-date">{moment(createdAt).format("DD MMM YYYY")}</div>
                    <div className="g-list-date-uploaded-time">{moment(createdAt).format("hh:mm A")}</div>
                </div>
            ),
        },
        {
            title: "SOURCE",
            key: "source",
            render: (row: IVideo, video: IVideo) => (
                <div className="g-list-sources-container" >
                    {getSourceTag(video)}
                </div>
            ),
        },
        {
            title: "ACTIONS",
            key: "action",
            render: (row: IVideo, video: IVideo) => (
                <Space size="middle" className="g-manage-content-spacer-action-items">
                    <a rel="noreferrer" href={`${video?.originalMediaUrl}`} target="_blank" >
                        <Button size="small" type="primary" disabled={row.progress === EProgress.UPLOADING} ghost>
                            <DownloadOutlined />
                        </Button>
                    </a>
                    <Button
                        size="small"
                        ghost
                        className="g-manage-content-spacer-action-items-delete-button"
                        disabled={row.progress !== EProgress.COMPLETE && row.progress !== EProgress.ERROR}
                        onClick={() => handleDeleteVideo(row)}
                        data-testid={`${dataTestIdPrefix}-VideoDeleteButton`}
                    >
                        <VideoTableDeleteButtonIcon />
                    </Button>
                </Space>
            ),
        },
    ];
}

export default ExternalUploadedContentColumns;