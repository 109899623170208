import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import Header from "./header-component";
import SummaryCards from "./summary-cards-component";
import QuestionAnalysis from "./question-analysis-component";
import { IInteraction } from "interfaces/schema";
import { IInsightsPageMetrics } from "..";
import * as ROUTE_PATHS from 'constants/routes';

import "./index.scss";

interface Props {
  interaction: IInteraction;
  insightsPageMetrics: IInsightsPageMetrics | undefined;
}

const InsightsView = ({ interaction, insightsPageMetrics }: Props) => {
  const navigate = useNavigate();

  if (insightsPageMetrics === undefined) {
    return (
      <div className="g-h-100 g-d-flex g-justify-center g-align-center">
        Sorry, something went wrong. Click&nbsp;
        <Button type="link" className="g-padding-0" onClick={() => navigate(ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH)}>
          <u>here</u>
        </Button>
        &nbsp;to go back.
      </div>
    );
  }

  return (
    <div className="g-interaction-edit-insights-card-container">
      <Header interaction={interaction} />
      <SummaryCards data={insightsPageMetrics.interactionResponseOverview} />
      <QuestionAnalysis data={insightsPageMetrics.questionAnalysisOverview}  />
    </div>
  );
};

export default InsightsView;