import { Authenticator, } from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import { formFields } from "./form-fields";
import components from "./components";
import { getURL } from "app/pages/videos/utils";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast as utilToast } from "utils/toast";
import apiCheckDomainExists from "services/public/check-domain-exists";
import { isPublicEmailDomain } from "./components/sign-up-form-fields/util";

import "./index.scss";

interface Props {
    state?: 'signIn' | 'signUp' | 'resetPassword';
}

const LoginPage = ({ state }: Props) => {
    const [searchParams] = useSearchParams();
    // We can't change initial state afterwards se setting it initially
    const [initialState] = useState(state || searchParams.get('initialState') as 'signIn' | 'signUp' | 'resetPassword');

    useEffect(() => {
        const msg = searchParams.get('message');
        if (msg) {
            utilToast.info(msg);
        }
    }, [searchParams]);

    const getPrefillEmailValue = () => {
        const d = searchParams.get('data') || '{}';
        const data = JSON.parse(decodeURIComponent(d));
        return data?.email || '';
    }

    const services = {
        async handleSignUp(formData: any) {
            let { username, password, attributes } = formData;

            const hostName = getURL(attributes['website']).url?.hostname;
            attributes['website'] = hostName;
            attributes['name'] = `${hostName} Admin`;
            attributes["custom:firstName"] = hostName;
            attributes["custom:lastName"] = "Admin";
            attributes['custom:shopUUID'] = uuidv4();
            attributes['custom:signupMethod'] = "Gander App";

            const splitEmail = username.split("@");
            if (splitEmail.length === 2 && isPublicEmailDomain(splitEmail[1])) {
                throw new Error("Please use a work email or custom domain.");
            }

            if (hostName) {
                await apiCheckDomainExists({ domain: hostName });
            }

            if (searchParams.get('initialState') === 'signUp') {
                const d = searchParams.get('data');
                const dataToPrefill = JSON.parse(decodeURIComponent(d!));
                if (dataToPrefill.shopifyUrl) {
                    attributes['custom:shopifyUrl'] = dataToPrefill?.shopifyUrl;
                }
                if (dataToPrefill.accessToken) {
                    attributes['custom:shopifyAccessToken'] = dataToPrefill?.accessToken;
                }
            }
            return await Auth.signUp({
                username,
                password,
                attributes,
            });
        },
        async handleSignIn(params: { username: string, password: string }) {
            return await Auth.signIn({
                username: params.username.trim(),
                password: params.password,
            })
        }
    };

    I18n.putVocabulariesForLanguage('en', {
        'Sign In': 'Login', // Tab header
        'Sign in': 'Login', // Button label
        'Signing in': 'Login',
        'Back to Sign In': 'Back to Login',
        'Create Account': 'Sign up'
    });

    const formFieldsObj = { ...formFields(getPrefillEmailValue()) }

    return (
        <div className="g-login-layout-wrapper">
            <Authenticator formFields={formFieldsObj} components={components} services={services} initialState={initialState} />
        </div>
    );
};

export default LoginPage;
