import { Typography } from "antd";
import { IChoiceResponse } from "interfaces/snowflake";

const { Text } = Typography;

const dataTestIdPrefix = "ShortAnswerResponseCell";

interface Props {
  choiceResponses: IChoiceResponse[];
}

const ShortAnswerResponseCell = ({ choiceResponses }: Props) => {
  return (
    <Text data-testid={`${dataTestIdPrefix}-Text`}>
      {choiceResponses[0].choice_user_input}
    </Text>
  );
};

export default ShortAnswerResponseCell;