import { Modal } from 'antd';
import { Link } from 'react-router-dom';

import './index.scss';

const { REACT_APP_SHOPIFY_API_KEY, REACT_APP_API_URL } = process.env;
const scopes = [
    "read_products",
    "write_products",
    "read_product_listings",
    "read_orders",
    "write_orders",
    "read_themes",
    "write_themes",
    "read_script_tags",
    "write_script_tags",
    "write_discounts",
    "read_draft_orders",
    "write_draft_orders",
    "read_customers",
    "write_customers",
];

const dataTestIdPrefix = "install-app-modal";

interface IProps {
    shopDetailsDomain: string;
}

const InstallShopifyAppModal = ({ shopDetailsDomain }: IProps) => {
    const url = shopDetailsDomain.replace('www.', '').replace('https://', '').replace('http://', '').replace('/', '');

    const getInstallAppUrl = () => {
        const key = REACT_APP_SHOPIFY_API_KEY;
        const redirect_uri = `${REACT_APP_API_URL}/public/shopify/callback/`;
        const installUrl = `https://${url}/admin/oauth/authorize?client_id=${key}&scope=${scopes.join(",")}&redirect_uri=${redirect_uri}`;
        return installUrl;
    }

    return (
        <Modal className='g-install-app-modal' centered={true} open={true} closable={false} okText="" cancelText="">
            <div data-testid={`${dataTestIdPrefix}-modal-body`}>
                <h3>Welcome To Gander</h3>
                Please click <Link to={getInstallAppUrl()} data-testid={`${dataTestIdPrefix}-modal-link`}>here</Link> to connect your Shopify account and finish installing Gander.
            </div>
        </Modal>
    )
}

export default InstallShopifyAppModal;