import { Typography } from "antd";
import { EQuestionType } from "interfaces/schema";

import "./index.scss";

const dataTestIdPrefix = "QuestionTypeLabel";

const { Text } = Typography;

export const questionTypeToTextMap = {
    [EQuestionType.CALL_TO_ACTION]: "Call to Action",
    [EQuestionType.ADD_TO_CART]: "Add to Cart",
    [EQuestionType.MULTIPLE_CHOICE]: "Multiple Choice",
    [EQuestionType.PICTURE_CHOICE]: "Picture Choice",
    [EQuestionType.SHORT_ANSWER]: "Short Answer Question",
};

const questionTypeToCSSMap = {
    [EQuestionType.CALL_TO_ACTION]: "g-interaction-edit-question-card-type-cta-color",
    [EQuestionType.ADD_TO_CART]: "g-interaction-edit-question-card-type-add-to-cart-color",
    [EQuestionType.MULTIPLE_CHOICE]: "g-interaction-edit-question-card-type-mc-color",
    [EQuestionType.PICTURE_CHOICE]: "g-interaction-edit-question-card-type-pc-color",
    [EQuestionType.SHORT_ANSWER]: "g-interaction-edit-question-card-type-saq-color",
};

interface Props {
    questionType: EQuestionType;
}

const QuestionTypeLabel = ({ questionType }: Props) => {
    return (
        <div className="g-interaction-edit-question-card-type-container">
            <Text className={`g-interaction-edit-question-card-type-text ${questionTypeToCSSMap[questionType]}`} data-testid={`${dataTestIdPrefix}-Text`}>
                {questionTypeToTextMap[questionType]}
            </Text>
        </div>
    );
};

export default QuestionTypeLabel;
