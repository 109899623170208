import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Divider, Modal, Spin, Typography } from "antd";
import InstagramModalFirstStepContent from "./instagram-modal-first-step-content-component";
import InstagramModalSecondStepContent from "./instagram-modal-second-step-content-component";
import { toast } from "utils/toast";
import { IInstagramVideo } from "interfaces/instagram";
import InstagramNoBackground from "assets/img/instagram-no-background.jpg";
import { apiGetUserVideos } from "services/instagram";

import "./index.scss";

const { Title } = Typography;
const { confirm } = Modal;
const dataTestIdPrefix = "instagram-modal";

const STEPS = [
    { id: "search-username-videos", title: "Connect Your Instagram Account" },
    { id: "select-videos-to-import", title: "Select Videos You Want to Import" },
];

interface IProps {
    setIsShowInstagramModal: Dispatch<SetStateAction<boolean>>;
}

const InstagramModal = ({ setIsShowInstagramModal }: IProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState<string>();
    const [userId, setUserId] = useState<string>();
    const [videos, setVideos] = useState<IInstagramVideo[]>([]);
    const [selectedVideos, setSelectedVideos] = useState<IInstagramVideo[]>([]);
    const [cursor, setCursor] = useState<string>();

    const fetchInstagramVideos = useCallback(
        async (username?: string, cursor?: string) => {
            try {
                if (username === undefined || username.length === 0) {
                    toast.warning("Username required");
                    return;
                }
                setIsLoading(true);
                const response = await apiGetUserVideos({ username, cursor });
                if (response.videos && response.videos.length > 0) {
                    setVideos(response.videos);
                    setCursor(response.cursor);
                    setUserId(response.userId);
                    setCurrentStep(1);
                } else {
                    toast.error(`Sorry, something went wrong: ${response.message}`);
                }
            } catch (e) {
                toast.error("Sorry, something went wrong. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        },
        [cursor]
    );

    const handleCancel = () => {
        if (currentStep === 1) {
            confirm({
                title: "Are you sure you want to leave?",
                content: "All progress will be lost.",
                okText: <span>Confirm</span>,
                okType: "default",
                cancelText: "Cancel",
                centered: true,
                async onOk() {
                    setIsShowInstagramModal(false);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
            return;
        }

        setIsShowInstagramModal(false);
    };

    return (
        <Modal open centered width={730} footer={null} onCancel={handleCancel}>
            {isLoading ? (
                <div className="g-instagram-modal-spinner">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="g-instagram-modal-content" data-testid={`${dataTestIdPrefix}-body`}>
                    {currentStep === 0 && <img className="g-instagram-modal-title-icon" alt="views" src={InstagramNoBackground} />}
                    <Title level={3}>{STEPS[currentStep].title}</Title>
                    <Divider style={{ margin: "10px 0 24px" }} />
                    {currentStep === 0 ? (
                        <InstagramModalFirstStepContent username={username} cursor={cursor} setUsername={setUsername} handleNext={fetchInstagramVideos} />
                    ) : (
                        <InstagramModalSecondStepContent
                            username={username}
                            userId={userId}
                            cursor={cursor}
                            videos={videos}
                            selectedVideos={selectedVideos}
                            setCursor={setCursor}
                            setVideos={setVideos}
                            setSelectedVideos={setSelectedVideos}
                            setIsLoading={setIsLoading}
                            setIsShowInstagramModal={setIsShowInstagramModal}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export default InstagramModal;
