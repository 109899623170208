enum SOURCE {
  ShopifyAppStore = "Shopify App Store",
  Newsletter = "Newsletter",
  SearchEngine = "Search Engine",
  SocialAd = "Social Ad",
  GanderSocialMedia = "Gander's Social Media",
  GanderBlog = "Gander's Blog",
  Agency = "Agency",
  Email = "Email",
  Other = "Other",
}

enum PLATFORM {
  Shopify = "Shopify",
  ShopifyV2 = "shopify-v2",
  Other = "Other",
}

enum PAGE_TYPE {
  Collection = "Collection",
  Page = "Page",
  Product = "Product",
}

enum CUSTOMIZATION_TABS {
  PREVIEW_STATE = "Preview State",
  OPEN_STATE = "Open State",
}

export { SOURCE, PLATFORM, PAGE_TYPE, CUSTOMIZATION_TABS }
