import { Row, Col, Typography, Statistic, Spin, Card, Tooltip } from "antd"
import { useContext, useEffect, useState } from "react";
import { ROW_GUTTER } from "../../../../constants/dashboard";
import { DATE_FORMAT, EMetricTimeString, TMetric, TMetricValue } from "../../../../interfaces";
import { DashboardContext } from "context/dashboard-context";
import { apiGetVideoAnalyticsGroup, apiGetPerformance } from "services/dashboard.service";
import { shiftStartEndDateBackForComparison } from "utils/metrics";
import { groupVideoAnalyticsGroupCurrentDay } from "../dummy-data";
import { AppContext } from "context/app-context";
import { getPerformanceChartConfig, getVideoAnalyticsChartConfig } from "./config";
import { Gauge } from "@ant-design/plots";
import { getPercentageChange, getStatisticColorIcon } from "./utils";
import { Area, Column } from "@ant-design/charts";

import { InfoCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

export const SecondRowComponent = () => {
  const { currentSelectKey, startDate, endDate, controller } = useContext(DashboardContext);
  const { isShowUpgradePlanBanner } = useContext(AppContext);

  const [percentageChange, setPercentageChange] = useState<number | undefined>(0);
  const [videoAnalyticsValues, setVideoAnalyticsValues] = useState<TMetricValue>();
  const [loading, setLoading] = useState<boolean>(false);

  const [performance, setPerformance] = useState<TMetric | undefined>();

  useEffect(() => {
    isShowUpgradePlanBanner ? setDefaultParams() : setParams();
  }, [startDate, endDate, isShowUpgradePlanBanner]);

  const setDefaultParams = () => {
    setVideoAnalyticsValues(groupVideoAnalyticsGroupCurrentDay);
  };

  const setParams = async () => {
    setVideoAnalyticsValues(undefined);

    const dateObj = {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    };

    try {
      setLoading(true);
      const [videoAnalyticsValues, videoAnalyticsValuesPrev, performance] = await Promise.all([
        apiGetVideoAnalyticsGroup(dateObj, controller),
        apiGetVideoAnalyticsGroup(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
        apiGetPerformance(dateObj, controller),
      ]);
      setVideoAnalyticsValues(videoAnalyticsValues?.data);
      setPerformance(performance?.data[0]);
      setPercentageChange(getPercentageChange(videoAnalyticsValues?.data, videoAnalyticsValuesPrev?.data));
    } catch (e) {
      setVideoAnalyticsValues(undefined);
      setPerformance(undefined);
    } finally {
      setLoading(false);
    }
  }

  const performanceChartConfig = getPerformanceChartConfig(performance?.VALUE);
  const videoAnalyticsConfig = getVideoAnalyticsChartConfig(videoAnalyticsValues);
  const videoAnalyticsColorIcon = getStatisticColorIcon(percentageChange);

  const configs = {
    videoAnalytics: {
      title: (
        <div className="g-video-analytics-wrapper">
          <div className="g-video-analytics-title-container">
            <Title level={3}>Video Analytics</Title>
          </div>
          {loading ? <></> : <div className="g-video-analytics-info-container" >
            <Statistic {...{
              value: (percentageChange ?? 0).toFixed(2),
              precision: 2,
              valueStyle: { color: videoAnalyticsColorIcon.color, fontSize: "14px" },
              prefix: videoAnalyticsColorIcon.icon,
              suffix: "%"
            }} />
            <Text style={{ fontSize: "14px", color: "#84818A" }}>in {currentSelectKey?.toLocaleLowerCase()}</Text>
          </div>}
        </div >),
      cssClass: "g-video-analytics-card",
      body: (
        <Spin spinning={loading}>
          {(currentSelectKey === EMetricTimeString.LAST_DAY) ? <Column {...videoAnalyticsConfig} loading={loading} /> : <Area {...videoAnalyticsConfig} loading={loading} />}
        </Spin >
      ),
      information: ""
    },
    performance: {
      title: (<div className="g-video-analytics-title-container">
        <Title level={3}>Performance</Title>
      </div>),
      body: <div className="g-dashboard-performance-gauge">
        <Spin spinning={loading}>
          <Gauge {...performanceChartConfig} />
        </Spin >
      </div >,
      information: "The performance is the average amount of view time per shopper."
    }
  };

  return (
    <Row gutter={ROW_GUTTER}>
      <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 18 }} xxl={{ span: 18 }}>
        <Card
          title={configs.videoAnalytics.title}
          size="default"
          className="g-viz-component"
          extra={
            <Tooltip title={configs.videoAnalytics.information} placement="bottomRight" showArrow={false}>
              <InfoCircleOutlined />
            </Tooltip>
          }
        >
          <div>{configs.videoAnalytics.body}</div>
        </Card>
      </Col>
      <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
        <Card
          title={configs.performance.title}
          size="default"
          className="g-viz-component"
          extra={
            <Tooltip title={configs.performance.information} placement="bottomRight" showArrow={false}>
              <InfoCircleOutlined />
            </Tooltip>
          }
        >
          <div>{configs.performance.body}</div>
        </Card>
      </Col>
    </Row>
  );
}