import { Button, Tag, List, Dropdown, Space, Typography, Modal, Tooltip, Popover, Skeleton } from "antd";
import { RightOutlined, ExclamationCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as ArrowDownBold } from "assets/img/arrow-down-bold.svg";
import { LinkOutlined } from "@ant-design/icons";
import { EPreviewPlayButtonSize, EProgress, IPage } from "interfaces";
import { EVideoSource, IVideo } from "interfaces/schema";
import { EditPageUrlIcon, VideoTableDeleteButtonIcon } from "components/icons";
import { createUrlWithHostAndPathName, formatBytes, formatDuration } from "utils/common-functions";
import GanderVideo from "components/gander-video";
import { toast } from "utils/toast";
import moment from "moment";
import EditVideoNameInput from "./edit-video-name-input";
import { apiCreateCustomerPortalSession } from "services/stripe";
import { useContext } from "react";
import { AppContext } from "context/app-context";
import { PRIVATE_ROUTES } from "constants/routes";
import { PLATFORM } from "constants/store";
import { VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST } from "config";

const { Title, Text } = Typography;
const { confirm } = Modal;

const dataTestIdPrefix = "Videos-VideosTableColumns";

export const GetColumns = (
    openViewVideoDetailDrawer: (vid: IVideo | undefined) => void,
    setEditVideoNameInput: React.Dispatch<React.SetStateAction<IVideo | undefined>>,
    deleteVideo: (videoObj: IVideo) => Promise<void>,
    website: string,
    openPurchaseSoMeLicenseModal: (crMeta: string, videoMeta: string) => void,
    editVideoNameInput: IVideo | undefined,
    handleEditVideoName: { (videoObj: IVideo): void; (arg0: any): void },
    setSelectedReviewVideo: React.Dispatch<React.SetStateAction<IVideo | undefined>>
) => {
    const { setIsAppLoading, shopDetails } = useContext(AppContext);
    const handleDeleteVideo = (row: IVideo) => {
        if (!!row.purchaseId && !!row.purchasedAt) {
            toast.warning("Please cancel the SoMe license subscription first to delete the video");
            return;
        }
        if (row?.pages?.length > 0) {
            toast.warning("Please unlink all pages to delete video");
            return;
        }
        confirm({
            title: "Delete Video",
            icon: <ExclamationCircleOutlined />,
            className: "g-manage-content-video-delete-confirmation-modal",
            content: `Do you want to delete this video?`,
            okText: <div data-testid={`${dataTestIdPrefix}-DeleteConfirmationDeleteButton`}>Delete</div>,
            okType: "danger",
            cancelText: "Cancel",
            centered: true,
            async onOk() {
                await deleteVideo(row);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const openStripeCustomerPortal = async () => {
        try {
            setIsAppLoading(true);
            const responseData = await apiCreateCustomerPortalSession(shopDetails!.stripeCustomerId!, PRIVATE_ROUTES?.VIDEOS?.PATH);
            window.open(responseData?.customerPortalSession.url, "_self");
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong fetching portal URL!");
            setIsAppLoading(false);
        }
    };

    const getSourceTag = (video: IVideo) => {
        if (video?.progress === EProgress.PROGRESSING || video?.progress === EProgress.UPLOADING || video?.progress === EProgress.PENDING) {
            return <Skeleton.Button className="g-list-source-tag-loading" active size="small" />;
        }

        const isPurchasedRequestedContent = video?.source === EVideoSource.REQUESTED_CONTENT && video?.purchasedAt && video?.purchaseId;
        const isExternalUploadApproveFlow = video.source === EVideoSource.EXTERNAL_UPLOAD && !video?.externalUploadDetails?.approved;
        if (isExternalUploadApproveFlow) {
            return (
                <Space size="middle" className="g-manage-content-spacer-action-items" data-testid={`${dataTestIdPrefix}-LinkedPagesSelectPage`}>
                    <Button
                        data-testid={`${dataTestIdPrefix}-SourceExternalVideoReview`}
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => setSelectedReviewVideo(video)}
                        disabled={![EProgress.COMPLETE].includes(video.progress)}
                    >
                        Review Video
                        <RightOutlined />
                    </Button>
                </Space>
            );
        } else if (isPurchasedRequestedContent && shopDetails?.platform !== PLATFORM.Shopify.toLowerCase()) {
            return video?.originalMediaUrl ? (
                <Popover
                    content={
                        <div className="g-list-sources-popover-content">
                            <div className="g-list-sources-popover-content-title">Manage social media license subscription</div>
                            <div>
                                <Button
                                    type="link"
                                    className="g-padding-0 "
                                    onClick={() => openStripeCustomerPortal()}
                                    data-testid={`${dataTestIdPrefix}-editAccountLink`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }
                    trigger="hover"
                >
                    <Tag color={"orange"} data-testid={`${dataTestIdPrefix}-SourceTag`}>
                        {`Expires ${moment(Number(video?.expiresAt)).format("LL")}`}
                    </Tag>
                </Popover>
            ) : (
                <Tag color={"red"} data-testid={`${dataTestIdPrefix}-SourceTag`}>
                    {"Expired"}
                </Tag>
            );
        } else if (isPurchasedRequestedContent && shopDetails?.platform === PLATFORM.Shopify.toLowerCase()) {
            return (
                <Tag color={video?.originalMediaUrl ? "orange" : "red"} data-testid={`${dataTestIdPrefix}-SourceTag`}>
                    {video?.originalMediaUrl ? `Expires ${moment(Number(video?.expiresAt)).format("LL")}` : "Expired"}
                </Tag>
            );
        }

        return (
            <Tag color={VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST[video?.source]?.tag} data-testid={`${dataTestIdPrefix}-SourceTag`}>
                <Text style={{ color: VIDEO_RECORD_TYPE_SOURCE_COLOR_LIST[video?.source]?.text }}>{video?.source}</Text>
            </Tag>
        );
    };

    return [
        {
            title: "VIDEO",
            dataIndex: "displayName",
            key: "displayName",
            render: (displayName: string, video: IVideo) => (
                <div className="g-list-video-container" data-testid={`${dataTestIdPrefix}-VideosColumn`}>
                    <div className="g-list-video-player">
                        <GanderVideo video={video} buttonSize={EPreviewPlayButtonSize.TINY} />
                    </div>

                    <div>
                        <div className="g-edit-video-table-title">
                            {/* Edit Page Name Input Form */}
                            {editVideoNameInput && video === editVideoNameInput && (
                                <EditVideoNameInput editVideoNameInput={editVideoNameInput} setEditVideoNameInput={setEditVideoNameInput} />
                            )}
                            {/* Edit Page Name Input Form */}
                        </div>
                        {video !== editVideoNameInput && (
                            <Title className="g-list-video-table-title" level={5} data-testid={`${dataTestIdPrefix}-VideoName`}>
                                <Tooltip title={displayName} placement="topLeft">
                                    {displayName}
                                </Tooltip>
                            </Title>
                        )}
                        <Space size={1} className="g-manage-content-spacer-action-items">
                            {video?.duration && Number(video?.duration) > 0 && (
                                <div className="g-list-video-details">
                                    {formatDuration(Number(video?.duration)) &&
                                        <div>{formatDuration(Number(video?.duration))}</div>
                                    }
                                    <div className="g-list-video-details-dot">&nbsp;</div>
                                </div>
                            )}
                            {video?.size &&
                                <div className="g-list-video-details">{formatBytes(video?.size) && <div>{formatBytes(video?.size)}</div>}</div>
                            }
                        </Space>
                    </div>

                    {
                        <Button
                            className="g-edit-video-table-title-button"
                            size="small"
                            icon={<EditPageUrlIcon />}
                            onClick={() => handleEditVideoName(video)}
                            data-testid={`${dataTestIdPrefix}-EditVideoNameButton`}
                        ></Button>
                    }
                </div>
            ),
        },
        {
            title: "LINKED PAGES",
            dataIndex: "pages",
            key: "pages",
            render: (pages: any, row: IVideo) => {
                const menu = (
                    <List
                        data-testid={`${dataTestIdPrefix}-LinkedPagesDropdownList`}
                        className="g-list-with-video-previews g-list-with-video-previews-fixed-height"
                        itemLayout="horizontal"
                        dataSource={pages}
                        renderItem={(page: IPage) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <div className="g-manage-content-video-list-linked-pages-container">
                                            <Title ellipsis title={page?.name} level={5}>
                                                {page?.name}
                                            </Title>
                                            <Button
                                                title={website + page?.url}
                                                type="link"
                                                href={createUrlWithHostAndPathName(website, page?.url)}
                                                target="_blank"
                                                icon={<LinkOutlined />}
                                            >
                                                <span className="g-video-list-page-link-text">{website + page?.url}</span>
                                            </Button>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                );
                return (
                    <div>
                        {pages && pages.length ? (
                            <Dropdown dropdownRender={() => menu} placement="bottom" trigger={["click"]}>
                                <div className="g-dropdown-with-text" data-testid={`${dataTestIdPrefix}-LinkedPagesDropdown`}>
                                    <Button className="g-dropdown-circle-btn" type="primary" shape="circle" size="small">
                                        {pages.length}
                                    </Button>
                                    <Button type="link">Pages</Button>
                                    <ArrowDownBold />
                                </div>
                            </Dropdown>
                        ) : (
                            <Space size="middle" className="g-manage-content-spacer-action-items" data-testid={`${dataTestIdPrefix}-LinkedPagesSelectPage`}>
                                <Button
                                    size="small"
                                    type="primary"
                                    ghost
                                    onClick={() => openViewVideoDetailDrawer(row)}
                                    disabled={![EProgress.COMPLETE, EProgress.UPLOADING, EProgress.PROGRESSING].includes(row.progress) || (row?.externalUploadDetails && row?.externalUploadDetails?.approved === false)}
                                >
                                    Select Page
                                    <RightOutlined />
                                </Button>
                            </Space>
                        )}
                    </div>
                );
            },
        },
        {
            title: "DATE UPLOADED",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: any) => (
                <div className="g-list-date-uploaded-container" data-testid={`${dataTestIdPrefix}-DateUploadedColumn`}>
                    <div className="g-list-date-uploaded-date">{moment(createdAt).format("DD MMM YYYY")}</div>
                    <div className="g-list-date-uploaded-time">{moment(createdAt).format("hh:mm A")}</div>
                </div>
            ),
        },
        {
            title: "SOURCE",
            key: "source",
            render: (row: IVideo, video: IVideo) => (
                <div className="g-list-sources-container" data-testid={`${dataTestIdPrefix}-Sources`}>
                    {getSourceTag(video)}
                </div>
            ),
        },
        {
            title: "ACTIONS",
            key: "action",
            render: (row: IVideo, video: IVideo) => (
                <Space size="middle" className="g-manage-content-spacer-action-items" data-testid={`${dataTestIdPrefix}-Actions`}>
                    {((!row?.externalUploadDetails) || (row?.externalUploadDetails && row?.externalUploadDetails?.approved === true)) &&
                        <Button
                            size="small"
                            disabled={![EProgress.COMPLETE, EProgress.UPLOADING, EProgress.PROGRESSING].includes(row.progress)}
                            onClick={() => openViewVideoDetailDrawer(row)}
                            data-testid={`${dataTestIdPrefix}-OpenVideoAddEditDrawerButton`}
                        >
                            Manage Video
                            <RightOutlined />
                        </Button>
                    }
                    {!!video?.originalMediaUrl ? (
                        null
                        // TODO: temporary not allowing anyone to download the video, until we identify secure way
                        // <a rel="noreferrer" href={`${video?.originalMediaUrl}`} target="_blank" data-testid={`${dataTestIdPrefix}-DownloadVideoLink`}>
                        //     <Button size="small" type="primary" disabled={row.progress === EProgress.UPLOADING} ghost>
                        //         <DownloadOutlined />
                        //     </Button>
                        // </a>
                    ) : (
                        <Button
                            size="small"
                            type="primary"
                            ghost
                            onClick={() => openPurchaseSoMeLicenseModal(video?.crMeta, video?.meta)}
                            data-testid={`${dataTestIdPrefix}-DownloadVideoModal`}
                        >
                            <DownloadOutlined />
                        </Button>
                    )}
                    <Button
                        size="small"
                        ghost
                        className="g-manage-content-spacer-action-items-delete-button"
                        disabled={row.progress !== EProgress.COMPLETE && row.progress !== EProgress.ERROR}
                        onClick={() => handleDeleteVideo(row)}
                        data-testid={`${dataTestIdPrefix}-DeleteActions`}
                    >
                        <VideoTableDeleteButtonIcon />
                    </Button>
                </Space>
            ),
        },
    ];
};
