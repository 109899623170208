import { Storage } from "aws-amplify";
import { IUploadFile } from "interfaces";

const uploadVideoS3 = async (
  fileObj: IUploadFile,
  meta: string,
  file: IUploadFile,
  filesListRef: { current: IUploadFile[] },
  setFileList: React.Dispatch<React.SetStateAction<IUploadFile[]>>,
  shopuuid: string,
  source: string,
) => {
  const pRes = await new Promise<{ status: string; message: string; name?: string; }>((resolve, reject) => {
    const fileExtension = fileObj?.name?.split(".").pop();
    const key = `${meta}.${fileExtension}`;

    Storage.put(key, file, {
      metadata: { shopuuid, source },
      contentType: file.type,
      customPrefix: {
        public: "",
      },
      progressCallback: (event: { loaded: number; total: number }) => {
        const percent = Math.ceil((event?.loaded / event?.total) * 100);
        const fList = filesListRef.current
          .map((f: IUploadFile) => {
            if (f.uid === fileObj?.uid) {
              f.percent = percent;
            }
            return f;
          })
          .filter((fi: IUploadFile) => fi.percent !== 100);

        filesListRef.current = fList;
        setFileList(filesListRef.current);
        if (percent === 100) {
          resolve({
            status: "success",
            message: `${fileObj?.name} file upload successfully.`,
          });
        }
      },
      completeCallback: (event: {}) => {
        console.log("complete event", event);
        resolve({
          status: "success",
          message: `${fileObj?.name} file upload successfully.`,
        });
      },
      errorCallback: (err: {}) => {
        reject({
          status: "error",
          message: `${fileObj?.name} file upload failed.`,
          name: fileObj?.name
        });
      },
    });
  });

  return pRes;
};

export default uploadVideoS3;
