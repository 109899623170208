import { Avatar, Button, Table, TableColumnsType, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IAbTestingDetails, IAbTestingExpandedDetails, IConversionRateByDeviceTableDetails, IConversionRateByVisitorTypeTableDetails, ITopVideosDetails } from 'interfaces';
import { ThunderboltFilled, CheckCircleFilled } from '@ant-design/icons'
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';

const { Title } = Typography;
const Precision = 2;

export const TopVideosColumns: ColumnsType<ITopVideosDetails> = [
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'videoUrl',
    render: (_, record) => {
      return <>
        <div style={{ padding: "10px" }}>
          <Avatar shape="square" size={64} src={record.avatarUrl} />
        </div>
      </>
    }
  },
  {
    title: 'Gander Influenced Sales',
    key: 'ganderInfluencedSales',
    render: (_, record) => {
      if (record?.ganderInfluencedSales) {
        return <>${(record?.ganderInfluencedSales).toFixed(Precision)}</>
      } else {
        return <>N/A</>
      }
    }
  },
  {
    title: 'Engagement Rate',
    key: 'engagementRate',
    render: (_, record) => {
      if (record?.engagementRate) {
        return <>{(record?.engagementRate * 100).toFixed(Precision)}%</>
      } else {
        return <>N/A</>
      }
    }
  },
];

export const TopVideosColumnsNonShopify: ColumnsType<ITopVideosDetails> = [
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'videoUrl',
    render: (_, record) => {
      return <><div style={{ padding: "10px" }}><Avatar shape="square" size={64} src={record.avatarUrl} /></div></>
    }
  },
  {
    title: 'Engagement Rate',
    key: 'engagementRate',
    render: (_, record) => {
      if (record?.engagementRate) {
        return <>{(record?.engagementRate * 100).toFixed(Precision)}%</>
      } else {
        return <>N/A</>
      }
    }
  },
];

export const AbTestingColumns: ColumnsType<IAbTestingDetails> = [
  {
    title: 'Name of Page',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      return (<><Title level={5} style={{ marginBottom: "0px" }}>{record.name}</Title>{record.date}</>);
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => {
      if (record.status) {
        return (<><Button style={{ backgroundColor: "#EAF4FE", color: "#3C8EF9", fontWeight: 'bold' }} shape="default" icon={<ThunderboltFilled />} size={'small'}>Running</Button></>);
      } else {
        return (<><Button style={{ backgroundColor: "#E2F0E7", color: "#408651", fontWeight: 'bold' }} shape="default" icon={<CheckCircleFilled />} size={'small'}>Completed</Button></>);

      }
    }
  }
]

// Function signature: function(record, index, indent, expanded): ReactNode
export const expandedRowRender = (record: any, _index: any, index: any, _expanded: any) => {
  const columns: TableColumnsType<IAbTestingExpandedDetails> = [
    {
      title: 'Video',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (_: any, record: { avatarUrl: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => {
        return (<><div style={{ paddingTop: "5px", paddingBottom: "5px" }}><Avatar shape="square" size={40} src={record.avatarUrl} /></div></>)
      }
    },
    {
      title: 'Engagement', dataIndex: 'engagementRate', key: 'engagementRate', render: (_: any, record) => {
        return (<div style={{ fontWeight: "bold" }}>{((record.ENGAGEMENT_RATE as number) * 100).toFixed(Precision)}%</div>)
      }
    },
    {
      title: 'Conversion', dataIndex: 'conversionRate', key: 'conversionRate', render: (_: any, record) => {
        return (<div style={{ fontWeight: "bold" }}>{((record.CONVERSION_RATE as number) * 100).toFixed(Precision)}%</div>)
      }
    },
    {
      title: 'Avg View Time', dataIndex: 'avgViewTime', key: 'avgViewTime', render: (_: any, record) => {

            return (record?.AVG_VIEW_TIME)
              ? (<div style={{ fontWeight: "bold" }}>{record.AVG_VIEW_TIME?.toFixed(Precision)}s</div>)
              : <div style={{ fontWeight: "bold" }}>-</div>

      }
    }
  ];


  return (
    <>
      <Table
        columns={columns}
        dataSource={record.details}
        pagination={false}
      />
    </>
  );
}

export const GenerateConversionRateByDeviceColumns = (multiplier: number, prefix: string, postfix: string) => {
  const ConversionRateByDeviceColumns: ColumnsType<IConversionRateByDeviceTableDetails> = [
    {
      title: 'Product',
      dataIndex: 'product',
      render: (_, record) => {
        return <>{record.product_name}</>
      }
    },
    {
      title: 'Desktop',
      key: 'desktop',
      render: (_, record) => {
        if (record.desktop) {
          return <>{prefix}{(record.desktop * multiplier).toFixed(Precision)}{postfix}</>
        } else {
          return <>-</>;
        }
      }
    },
    {
      title: 'Mobile',
      key: 'mobile',
      render: (_, record) => {
        if (record.mobile) {
          return <>{prefix}{(record.mobile * multiplier).toFixed(Precision)}{postfix}</>
        } else {
          return <>-</>;
        }
      }
    }
  ];

  return ConversionRateByDeviceColumns;
}

export const GenerateConversionRateByVisitorTypeColumns = (multiplier: number, prefix: string, postfix: string) => {
  const ConversionRateByVisitorTypeColumns: ColumnsType<IConversionRateByVisitorTypeTableDetails> = [
    {
      title: "Product",
      dataIndex: 'product',
      render: (_, record) => {
        return <>{record.PRODUCT_NAME}</>
      }
    },
    {
      title: "New",
      key: "new",
      render: (_, record) => {
        if (record.CONVERSION_NEW) {
          return <>{prefix}{(record.CONVERSION_NEW * multiplier).toFixed(Precision)}{postfix}</>;
        } else {
          return <>-</>;
        }

      }
    },
    {
      title: "Repeat",
      key: "repeat",
      render: (_, record) => {
        if (record.CONVERSION_REPEAT) {
          return <>{prefix}{(record.CONVERSION_REPEAT * multiplier).toFixed(Precision)}{postfix}</>;
        } else {
          return <>-</>;
        }
      }
    }
  ];

  return ConversionRateByVisitorTypeColumns

}

export const ROW_GUTTER = { xs: 8, sm: 16, md: 24, lg: 32 };