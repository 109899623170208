import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const UploadNewContentButton = (props: { onClick: any }) => {
  let { onClick } = props;
  return (
    <Button icon={<PlusCircleOutlined />} onClick={onClick} size="large" key="2" type="primary">
      Upload New Content
    </Button>
  );
};

export default UploadNewContentButton;
