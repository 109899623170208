import { useNavigate } from "react-router-dom";
import { Typography, Button } from "antd";
import * as ROUTE_PATHS from "constants/routes";
import { RightOutlined } from "@ant-design/icons";
import { InteractionsContentIcon } from "components/icons";

import "./index.scss";

const dataTestIdPrefix = "InteractionBreadcrumbs";

const { Text } = Typography;

interface Props {
    name: string;
}

const Breadcrumbs = ({ name }: Props) => {
    const navigate = useNavigate();

    const handleBreadcrumbsParentClick = () => {
        navigate(ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH);
    };

    return (
        <div className="g-interaction-breadcrumbs-container">
            <InteractionsContentIcon />
            <Button
                className="g-interaction-breadcrumbs-parent-button"
                type="link"
                onClick={() => handleBreadcrumbsParentClick()}
                data-testid={`${dataTestIdPrefix}-Button`}
            >
                Interactions
            </Button>
            <RightOutlined className="g-interaction-breadcrumbs-arrow" />
            <Text className="g-interaction-breadcrumbs-name" title={name} data-testid={`${dataTestIdPrefix}-Text`}>
                {name}
            </Text>
        </div>
    );
};

export default Breadcrumbs;
