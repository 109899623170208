import { Avatar, Drawer, List, Select, Spin, Typography } from "antd";
import { ArrowWithCircleIconBackground } from "components/icons";
import { EVideoSource, IInteraction, IVideo } from "interfaces/schema";
import { useContext, useEffect, useState } from "react";
import DetailsDrawerTitle from "./details-drawer-title";
import moment from "moment";
import { apiGetVideos, apiUpdateVideo } from "services/video.services";
import { InteractionsContext } from "context/interactions-context";
import { toast } from "utils/toast";
import { sortBy } from "lodash";
import VideoListItemComponent from "../../../../common/video-list-item-component";

import "./index.scss";

const { Title, Text } = Typography;
const { Option } = Select;

const dataTestIdPrefix = "Interactions-DetailsDrawer";

interface IProps {
    activeInteraction: IInteraction | undefined;
    interactions: IInteraction[];
    setActiveInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
}

const DetailsDrawer = ({ activeInteraction, interactions, setActiveInteraction, children }: React.PropsWithChildren<IProps>) => {
    const { fetchInteractions } = useContext(InteractionsContext);
    const [isSearchVideoLoading, setIsSearchVideoLoading] = useState(false);
    const [videos, setVideos] = useState<IVideo[]>([]);
    const [searchedVideos, setSearchedVideos] = useState<IInteraction[]>([]);
    const [selectedSearchedVideo, setSelectedSearchedVideo] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const linkedVideoMetas = activeInteraction?.videos?.map((video: any) => video?.meta);

    useEffect(() => {
        if (activeInteraction?.videos) {
            let vids = activeInteraction.videos;

            vids.forEach((vid: any) => {
                if (vid) {
                    vid.id = vid?.meta;
                }
            });
            vids = sortBy(vids, "id");
            setVideos(vids);
        }
        fetchInteractions();
        setSearchedVideos([]);
        setIsSearchVideoLoading(true);
        fetchAndUpdateSearchValues();
    }, [activeInteraction]);

    useEffect(() => {
        if (!!selectedSearchedVideo && selectedSearchedVideo?.length > 1) {
            setIsSearchVideoLoading(true);
            const debounceInput = setTimeout(() => {
                fetchAndUpdateSearchValues(selectedSearchedVideo);
            }, 1000);
            return () => {
                clearTimeout(debounceInput);
                setIsSearchVideoLoading(false);
            };
        }
    }, [selectedSearchedVideo]);

    const fetchAndUpdateSearchValues = async (filterName?: string) => {
        try {
            const searchVideoRes = await apiGetVideos({ filterName });
            if (searchVideoRes?.data?.length) {
                const searchVideoWithoutExternalUploaded = searchVideoRes?.data.filter((video: IVideo) => {
                    if (video?.externalUploadDetails?.approved === false && video?.source === EVideoSource.EXTERNAL_UPLOAD) {
                        return false;
                    }
                    return true;
                });

                if (linkedVideoMetas?.length) {
                    // filter videos to exclude already linked videos (to avoid linking again)
                    const filteredPagesExcludingLinked = searchVideoWithoutExternalUploaded?.filter((video: IVideo) => {
                        return !linkedVideoMetas.includes(video?.meta);
                    });
                    setSearchedVideos(filteredPagesExcludingLinked);
                    return;
                }
                setSearchedVideos(searchVideoWithoutExternalUploaded);
                return;
            }
            setSearchedVideos([]);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsSearchVideoLoading(false);
        }
    };

    const onNextViewDetailsEvent = () => {
        const currentIndex = interactions.findIndex((obj: { meta: string }) => obj?.meta === (activeInteraction as IInteraction)?.meta);
        if (interactions[currentIndex + 1]) {
            setActiveInteraction(interactions[currentIndex + 1]);
        } else {
            setActiveInteraction(interactions[0]);
        }
    };

    const onPrevViewDetailsEvent = () => {
        const currentIndex = interactions.findIndex((obj: { meta: string }) => obj?.meta === (activeInteraction as IInteraction)?.meta);
        if (interactions[currentIndex - 1]) {
            setActiveInteraction(interactions[currentIndex - 1]);
        } else {
            setActiveInteraction(interactions[interactions.length - 1]);
        }
    };

    const onRemoveVideo = async (record: IVideo) => {
        try {
            setIsLoading(true);
            await apiUpdateVideo({ GSI1PK: "INTERACTION#" }, record.meta);
            const updatedVideos = activeInteraction?.videos.filter((video) => video.meta !== record.meta) || [];
            if (activeInteraction?.id) {
                setActiveInteraction({
                    ...activeInteraction,
                    videos: updatedVideos,
                });
                setVideos(updatedVideos);
            }
        } catch (e: any) {
            console.log(e);
            toast.error("Sorry, something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleVideoSelect = async (videoMeta: string) => {
        if (videoMeta) {
            try {
                setIsLoading(true);
                await apiUpdateVideo(
                    {
                        GSI1PK: `INTERACTION#${activeInteraction?.meta}`,
                        GSI1SK: `VIDEO#${videoMeta}`,
                    },
                    videoMeta
                );

                toast.success("Video Linked to page successfully");
                const videoToUpdateInState: any = searchedVideos.find((video) => video?.meta === videoMeta);
                if (activeInteraction && videoToUpdateInState) {
                    const updateInteractionObj: IInteraction = {
                        ...activeInteraction,
                        videos: [...activeInteraction.videos, videoToUpdateInState],
                    };

                    setActiveInteraction(updateInteractionObj);
                } else {
                    console.error("Active interaction is undefined");
                }
                fetchInteractions();
            } catch (e: any) {
                console.log(e);
                toast.error(e?.message);
            } finally {
                setSelectedSearchedVideo(null);
                setSearchedVideos([]);
                setIsLoading(false);
            }
        } else {
            setSelectedSearchedVideo(null);
        }
    };

    const handleVideoSearch = async (newValue: string) => {
        if (newValue) {
            setSelectedSearchedVideo(newValue);
        } else {
            setSelectedSearchedVideo(null);
            setSearchedVideos([]);
        }
    };
    return (
        <Drawer
            title={
                <DetailsDrawerTitle
                    activeInteraction={activeInteraction}
                    isLoading={isLoading}
                    isSearchVideoLoading={isSearchVideoLoading}
                    onNextViewDetailsEvent={onNextViewDetailsEvent}
                    onPrevViewDetailsEvent={onPrevViewDetailsEvent}
                />
            }
            className="g-interaction-details-drawer"
            placement="right"
            onClose={() => setActiveInteraction(undefined)}
            open={!!activeInteraction}
            width={449}
            footerStyle={{ padding: 0 }}
            height={100}
            closable={false}
            bodyStyle={{ maxWidth: 449, overflow: "hidden" }}
            data-testid={`${dataTestIdPrefix}-Drawer`}
        >
            <ArrowWithCircleIconBackground
                style={{ transform: activeInteraction ? "rotate(0deg)" : "rotate(180deg)" }}
                onClick={() => setActiveInteraction(undefined)}
            />
            <div className="g-interaction-details-drawer-content-container g-d-flex" data-testid={`${dataTestIdPrefix}-DrawerBody`}>
                {activeInteraction?.meta && (
                    <div className="g-interaction-details-connected-video-section">
                        <div className="g-d-flex g-justify-between g-align-center">
                            <Title level={3}>Connect Videos</Title>
                            {isSearchVideoLoading ? <Spin className="g-mx-8" size="small" /> : null}
                        </div>

                        <Select
                            className="g-add-edit-video-details-form-select-page g-w-100 g-mb-16 g-custom-search-select"
                            size="large"
                            showSearch
                            autoClearSearchValue
                            value={"Select video to connect"}
                            placeholder="Select video to connect"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleVideoSearch}
                            onChange={handleVideoSelect}
                            notFoundContent={null}
                            data-testid={`${dataTestIdPrefix}-SearchVideos`}
                        >
                            {searchedVideos?.map((video: any) => (
                                <Option key={video?.meta} data-testid={`${dataTestIdPrefix}-SearchVideosCardContainer`}>
                                    <div className="g-list-with-video-previews g-list-with-video-previews-fixed-height g-d-flex g-w-100">
                                        <div className="g-list-with-video-preview-avatar">
                                            <Avatar shape="square" size={52} src={video?.thumbnail} />
                                        </div>
                                        <div className="g-list-with-video-preview-title-description">
                                            <Title ellipsis level={5} title={video?.displayName}>
                                                {video?.displayName}
                                            </Title>
                                            <span>Last modified {moment(video?.updatedAt).fromNow()}</span>
                                        </div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                        <div className="g-mb-16">
                            <Text type="secondary">
                                {" "}
                                Note: A video can only have one interaction. Connecting a new interaction will replace any existing interaction.{" "}
                            </Text>
                        </div>
                        <div className="g-list-header">
                            <span>Connected Videos</span>
                            <span>Action</span>
                        </div>
                        {videos?.length > 0 ? (
                            <div className="g-interaction-details-list">
                                <List
                                    data-testid={`${dataTestIdPrefix}-LinkedVideosCardContainer`}
                                    className="g-list-with-video-previews g-w-100"
                                    itemLayout="horizontal"
                                    dataSource={videos}
                                    renderItem={(video: any) => (
                                        <VideoListItemComponent key={video?.meta} row={video} onRemoveVideo={onRemoveVideo} isLoading={isLoading} data-testid={`${dataTestIdPrefix}-RemoveVideoButton`}/>
                                    )}
                                />
                            </div>
                        ) : (
                            <div className="g-interaction-details-no-video-list">
                                <Title level={5}>No Videos Linked</Title>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default DetailsDrawer;
