import axios from "axios";
import { ITiktokVideo } from "interfaces/tiktok";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  username: string;
  cursor?: string;
}

interface Response {
  videos: ITiktokVideo[];
  cursor: string;
  message: string;
}

const apiGetUserVideos = async ({ username, cursor }: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/tiktok/videos`;
    const response = await axios.get<Response>(url, { params: {
      username,
      cursor,
    }});
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiGetUserVideos;