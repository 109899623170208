import axios from "axios";
import Stripe from "stripe";

const { REACT_APP_API_URL } = process.env;

interface Payload {
    customerId: string;
}

interface Response {
    customer: Stripe.Customer;
    message: string;
}

const apiGetStripeCustomer = async ({ customerId }: Payload): Promise<Response> => {
    try {
        const url = `${REACT_APP_API_URL}/stripe/customers/${customerId}`;
        const response = await axios.get<Response>(url);
        return response.data;
    } catch (e: any) {
        console.log(e);
        throw e?.response?.data ? e?.response?.data : e;
    }
};

export default apiGetStripeCustomer;
