import UpgradeYourAccountModal from "app/pages/common/upgrade-your-account-modal-component";
import { EProgress, ILoadMoreVideoLastEvaluatedKey } from "interfaces";
import { IVideo } from "interfaces/schema";
import { uniqBy } from "lodash";
import { createContext, useEffect, useState } from "react";
import { apiGetRecentlyAddedVideos, apiGetVideoByMeta, apiGetVideos } from "services/video.services";
import { updateVideoArrayByMeta } from "utils/common-functions";
import { toast } from "utils/toast";

export interface IVideosContext {
    isVideosLoading: boolean,
    setIsVideosLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isRecentlyAddedVideosLoading: boolean,
    videos: IVideo[],
    setVideos: React.Dispatch<React.SetStateAction<IVideo[]>>,
    recentlyAddedVideos: IVideo[],
    setRecentlyAddedVideos: React.Dispatch<React.SetStateAction<IVideo[]>>,
    videoSearchKeyword: string,
    setVideoSearchKeyword: React.Dispatch<React.SetStateAction<string>>,
    fetchVideos: (isLoadMore?: boolean) => void,
    fetchRecentlyAddedVideos: (isShowLoader?: boolean) => void,
    videoRequestLastEvaluatedKey: ILoadMoreVideoLastEvaluatedKey | undefined,
    isShowV3FreePlanUploadLimitModel: boolean,
    setIsShowV3FreePlanUploadLimitModel: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultContext = {
    isVideosLoading: false,
    setIsVideosLoading: () => ({}),
    isRecentlyAddedVideosLoading: false,
    videos: [],
    setVideos: () => ({}),
    recentlyAddedVideos: [],
    setRecentlyAddedVideos: () => ({}),
    videoSearchKeyword: '',
    setVideoSearchKeyword: () => ({}),
    fetchVideos: () => ({}),
    fetchRecentlyAddedVideos: () => ({}),
    videoRequestLastEvaluatedKey: undefined,
    isShowV3FreePlanUploadLimitModel: false,
    setIsShowV3FreePlanUploadLimitModel: () => ({}),
};

export const VideosContext = createContext<IVideosContext>(defaultContext);
let videoSearchTimeout: any;
let refetchInterval: NodeJS.Timer;

export const VideosContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isVideosLoading, setIsVideosLoading] = useState(false);
    const [isRecentlyAddedVideosLoading, setIsRecentlyAddedVideosLoading] = useState(false);
    const [isShowV3FreePlanUploadLimitModel, setIsShowV3FreePlanUploadLimitModel] = useState(false);
    const [recentlyAddedVideos, setRecentlyAddedVideos] = useState<IVideo[]>([]);

    const [videoSearchKeyword, setVideoSearchKeyword] = useState('');
    const [videos, setVideos] = useState<IVideo[]>([]);
    const [videoRequestLastEvaluatedKey, setVideoRequestLastEvaluatedKey] = useState<ILoadMoreVideoLastEvaluatedKey | undefined>(undefined);

    useEffect(() => {
        fetchRecentlyAddedVideos();
    }, []);

    useEffect(() => {
        videoSearchTimeout = setTimeout(() => {
            fetchVideos();
        }, 1000);
        return () => clearTimeout(videoSearchTimeout);
    }, [videoSearchKeyword]);

    useEffect(() => {
        if ((videos && videos?.length) || (recentlyAddedVideos && recentlyAddedVideos?.length)) {
            checkRefetchAndUpdateOnProgressingVideos();
        }

        return () => {
            clearInterval(refetchInterval);
        }
    }, [videos, recentlyAddedVideos]);

    const checkRefetchAndUpdateOnProgressingVideos = () => {
        let videoRefetchController = new AbortController();
        let loading = false;

        videoRefetchController.abort();
        clearInterval(refetchInterval);
        videoRefetchController = new AbortController();

        refetchInterval = setInterval(async () => {
            if (loading) {
                console.log('waiting for previous api calls to complete');
                return;
            }
            loading = true;

            // videos refetch if anyone of them is in progress
            const progressingVideos = videos?.filter((v) => {
                return v?.progress === EProgress.PROGRESSING || v?.progress === EProgress.PENDING || v?.progress === EProgress.UPLOADING;
            });

            // recently added videos refetch if anyone of them is in progress
            const progressingRecentlyAddedVideos = recentlyAddedVideos?.filter((v) => {
                return v?.progress === EProgress.PROGRESSING || v?.progress === EProgress.PENDING || v?.progress === EProgress.UPLOADING;
            });

            // creating unique array from inprogress video and inprogress recently added videos
            const uniqueInProgressVideos = uniqBy([...progressingVideos, ...progressingRecentlyAddedVideos], 'meta');

            if (uniqueInProgressVideos?.length > 0) {

                // calling all api for get video detail and adding all its promise to the array
                const inprogressVideoPromises = uniqueInProgressVideos?.map((v) => {
                    return apiGetVideoByMeta(v?.meta, videoRefetchController);
                });

                // waiting for all the promises to get settled
                const inprogressVideoPromisesResponse = await Promise.allSettled(inprogressVideoPromises);

                // loop through all promises and check for status fulfilled & completed video and update state if found
                inprogressVideoPromisesResponse?.forEach((videoRes) => {
                    if (videoRes?.status === "fulfilled" && videoRes?.value?.progress && (videoRes?.value?.progress === EProgress?.COMPLETE || videoRes?.value?.progress === EProgress?.PROGRESSING)) {
                        setVideos(updateVideoArrayByMeta(videos, videoRes?.value));
                        setRecentlyAddedVideos(updateVideoArrayByMeta(recentlyAddedVideos, videoRes?.value));
                    }
                });

                loading = false;
            } else {
                clearInterval(refetchInterval);
            }
        }, 10000);
    }

    const fetchVideos = async (isLoadMore?: boolean) => {
        setIsVideosLoading(true);

        // api call logic and set current videos and new videos object
        try {
            const reqPayload: { filterName: string, requestLastEvaluatedKey?: ILoadMoreVideoLastEvaluatedKey } = {
                filterName: videoSearchKeyword ? videoSearchKeyword : ''
            }

            // if isLoadMore is false that means this function is called with intension of refetch so we need to get new fresh data instead of doing load more
            if (isLoadMore) {
                // if load more is true then only adding loadMore key
                reqPayload.requestLastEvaluatedKey = videoRequestLastEvaluatedKey;
            }
            const videosRes = await apiGetVideos(reqPayload);
            setVideoRequestLastEvaluatedKey(videosRes?.LastEvaluatedKey);
            if (isLoadMore) {
                setVideos([...videos, ...videosRes?.data]);
            } else {
                setVideos(videosRes?.data);
            }
        } catch (e: any) {
            toast.error(e?.message);
        } finally {
            setIsVideosLoading(false);
        }
    }

    const fetchRecentlyAddedVideos = async (isShowLoader = true) => {
        setIsRecentlyAddedVideosLoading(isShowLoader);

        // api call logic and set recently added videos object
        try {
            const videosRes = await apiGetRecentlyAddedVideos(5);
            setRecentlyAddedVideos(videosRes.slice(0, 5));
        } catch (e: any) {
            setRecentlyAddedVideos([]);
            toast.error(e?.message);
        } finally {
            setIsRecentlyAddedVideosLoading(false);
        }
    }

    return (
        <VideosContext.Provider value={{
            isVideosLoading, setIsVideosLoading, isRecentlyAddedVideosLoading,
            videos, recentlyAddedVideos, fetchVideos, fetchRecentlyAddedVideos, videoSearchKeyword, setVideoSearchKeyword,
            videoRequestLastEvaluatedKey, setVideos, setRecentlyAddedVideos,
            isShowV3FreePlanUploadLimitModel, setIsShowV3FreePlanUploadLimitModel
        }}>
            {children}
            {/* V3 Free Plan Upload Limit */}
            {isShowV3FreePlanUploadLimitModel &&
                <UpgradeYourAccountModal
                    setIsShowUpgradePopup={setIsShowV3FreePlanUploadLimitModel}
                    modalTitle={"Upgrade Your Account!"}
                    modalDescription={
                        `You've hit the upload limit!
                     Your current Free plan allows you to upload one video.
                     If you'd like to change your plan, you can do so on the settings page or by clicking 'Upgrade Plan' below.`
                    }
                />
            }
        </VideosContext.Provider>
    );
};