import { PageHeader, Typography } from "antd";
import { Icon } from "@aws-amplify/ui-react";
import { AmplifyUser } from "@aws-amplify/ui";
import { EMetricTimeString, DATE_FORMAT } from "interfaces";
import { NavigateFunction } from "react-router-dom";
import * as ROUTE_PATHS from "constants/routes";
import { ConfirmDateFunction } from "./confirm-date-component";
import SelectComponent from "./select-component";
import UploadNewContentButton from "../upload-new-content-component";
import { useContext, useRef, useState } from "react";
import { DashboardContext } from "context/dashboard-context";
import { findStartDateFromString } from "utils/metrics";
import moment from "moment";

const { Title, Text } = Typography;

interface IPageHeaderProps {
  user: AmplifyUser;
  navigate: NavigateFunction;
}

// TODO: update file naming to follow our pattern, folder naming as well
export const DashboardHeaderComponent = ({ user, navigate, /*startDate, setStartDate, endDate, setEndDate, currentSelectKey, setCurrentSelectKey*/ }: IPageHeaderProps) => {
  const { startDate, setStartDate, endDate, setEndDate, currentSelectKey, setCurrentSelectKey } = useContext(DashboardContext);

  const [date] = useState(moment().format("dddd, DD MMMM YYYY"));
  const tempStartDate = useRef<moment.Moment>(moment());
  const tempEndDate = useRef<moment.Moment>(moment());

  const handleCustomDateClick = () => {
    ConfirmDateFunction({ setStartDate, setEndDate, setCurrentSelectKey, tempStartDate, tempEndDate });
  }

  const handleChange = (value: string) => {
    if ((value as EMetricTimeString) === EMetricTimeString.CUSTOM_TIME) {
      return;
    }

    setCurrentSelectKey(value as EMetricTimeString);
    setStartDate(findStartDateFromString(moment(), value as EMetricTimeString));
    setEndDate(moment());
  }

  const handleUpdateNewContentBtnClick = () => {
    setCurrentSelectKey(EMetricTimeString.CUSTOM_TIME);
    navigate(ROUTE_PATHS.PRIVATE_ROUTES.VIDEOS.PATH);
  }

  return (
    <PageHeader
      title={
        <div>
          <Title level={3}>Welcome back, {user?.attributes?.name}</Title>{" "}
          <Title level={5}>
            {date}. <Text type="secondary">Here is what's happening with your store.</Text>
          </Title>
        </div>
      }
      extra={[
        <Icon key={1} />,
        <div key={2} className="g-header-extra">
          <SelectComponent currentSelectKey={currentSelectKey} handleChange={handleChange} onClick={handleCustomDateClick} />
          <UploadNewContentButton onClick={handleUpdateNewContentBtnClick} />
          <br />
          {currentSelectKey === EMetricTimeString.CUSTOM_TIME && (
            <Text type="secondary">
              From {startDate?.format(DATE_FORMAT)} to {endDate?.format(DATE_FORMAT)}
            </Text>
          )}
        </div>,
      ]}
    ></PageHeader>
  )
}

