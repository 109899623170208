import { DatePicker, Modal } from "antd";
import { EMetricTimeString } from "interfaces";
import { CalendarOutlined } from "@ant-design/icons";
import './index.scss';
import moment from "moment";

const { RangePicker } = DatePicker;

const { confirm } = Modal;
type RangeValue = [moment.Moment | null, moment.Moment | null] | null;

interface IConfirmDateProps {
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setCurrentSelectKey: React.Dispatch<React.SetStateAction<EMetricTimeString>>;
  tempStartDate: React.MutableRefObject<moment.Moment>;
  tempEndDate: React.MutableRefObject<moment.Moment>;
}

export const ConfirmDateFunction = ({ setStartDate, setEndDate, setCurrentSelectKey, tempStartDate, tempEndDate }: IConfirmDateProps) => {
  return confirm({
    title: "Custom time",
    width: '450px',
    wrapClassName: 'g-dashboard-confirm-date-modal',
    content: (
      <RangePicker
        showTime
        onOk={(val: RangeValue) => {
          if (val !== null && val[0] !== null && val[1] !== null) {
            tempStartDate.current = val[0];
            tempEndDate.current = val[1];
          }
        }}
      />
    ),
    icon: <CalendarOutlined />,
    onOk() {
      setStartDate(tempStartDate.current);
      setEndDate(tempEndDate.current);
      setCurrentSelectKey(EMetricTimeString.CUSTOM_TIME);
    },
    onCancel() {
      return;
    },
    afterClose() {
      tempStartDate.current = moment();
      tempEndDate.current = moment();
    },
  });
};