export const BLOCKED_EMAIL_DOMAINS = [
  "aol",
  "gmail",
  "gmx",
  "hotmail",
  "icloud",
  "outlook",
  "proton",
  "yahoo",
  "yandex",
  "zoho",
  "videowise",
  "gotolstoy",
  "firework", 
];