import VideoProcessingLoader from 'components/video-processing-loader';
import { EProgress, IProduct } from 'interfaces';
import { onVideoThumbnailLoadError } from 'utils/common-functions';
import PreviewProductsComponent from './preview-products-component';
import PreviewInteraction from 'app/pages/interactions/interaction-view-component/preview-component/preview-interaction-component';
import { IInteraction, IVideo } from 'interfaces/schema';

import "./index.scss";

interface IProps {
    viewVideoDetailsDrawer: IVideo;
    dataTestIdPrefix: string;
    products?: IProduct[];
    selectedInteraction?: IInteraction;
}

const VideoDetailDrawerPreviewContainer = ({ viewVideoDetailsDrawer, dataTestIdPrefix, products, selectedInteraction }: IProps) => {
    return (
        <div className="g-add-edit-video-details-drawer-preview" data-testid={`${dataTestIdPrefix}-Preview`}>
            <div className="g-product-preview-container">
                {viewVideoDetailsDrawer?.progress !== EProgress.COMPLETE ?
                    <VideoProcessingLoader />
                    :
                    <img
                        data-testid={`${dataTestIdPrefix}-PreviewHdThumbnail`}
                        className="g-video-preview-thumbnail"
                        src={viewVideoDetailsDrawer?.hdThumbnail}
                        alt={"preview"}
                        onError={(e) => onVideoThumbnailLoadError(e, viewVideoDetailsDrawer)}
                    />
                }
                {(products && selectedInteraction) && <>
                    <div className="g-product-preview-overlay">
                        {viewVideoDetailsDrawer.products.length > 0 &&
                            <PreviewProductsComponent products={products} dataTestId={`${dataTestIdPrefix}-PreviewProductComponent`} />
                        }
                    </div>
                    <div className="g-interaction-preview-overlay">
                        <PreviewInteraction
                            question={selectedInteraction?.questions.find(({ meta }: any) => meta === selectedInteraction?.questionOrder?.[0])}
                            size="large"
                        />
                    </div>
                </>}
                {products && <>
                    <div className="g-product-preview-overlay" data-testid={`${dataTestIdPrefix}-PreviewImage`}>
                        <PreviewProductsComponent products={products} dataTestId={`${dataTestIdPrefix}-PreviewProductComponent`} />
                    </div>
                </>}
            </div>
        </div>
    )
}

export default VideoDetailDrawerPreviewContainer