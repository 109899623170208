import { Button, Spin } from "antd";
import { PreviewDesktopButtonIcon, PreviewMobileButtonIcon, PreviewTabletButtonIcon } from "components/icons";
import { EOverridePreviewState, IWidgetSettings } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "context/app-context";
import { ReactComponent as PreviewBrowserControl } from "assets/img/preview-browser-controls.svg";
import { ReactComponent as PreviewURLControl } from "assets/img/preview-url-controls.svg";
import { ReactComponent as PreviewMobileHeader } from "assets/img/preview-mobile-header.svg";
import { ECustomizationSettingTabs } from "app/pages/customization/types";
import useDebounce from "hooks/useDebounce";

import "./index.scss";

const { REACT_APP_PREVIEW_WIDGET_URL } = process.env;
const dataTestIdPrefix = "PageCustomization-WidgetPreviewComponent";

interface IProps {
    isLoading: boolean;
    widgetSettings: IWidgetSettings | undefined;
    overridePreviewState: EOverridePreviewState | undefined;
    currentTab: ECustomizationSettingTabs;
    resetUpdatedWidgetSettings: () => void;
    saveWidgetSettingChanges: () => void;
    setCurrentTab: React.Dispatch<React.SetStateAction<ECustomizationSettingTabs>>
}

const WidgetPreview = ({ isLoading, widgetSettings, overridePreviewState, currentTab, saveWidgetSettingChanges, resetUpdatedWidgetSettings, setCurrentTab }: IProps) => {
    const { shopDetails } = useContext(AppContext);
    const [currentPreviewState, setCurrentPreviewState] = useState<"desktop" | "mobile" | "tablet">("desktop");
    const debouncedWidgetSettings = useDebounce<IWidgetSettings | undefined>(widgetSettings, 500);
    const [iFrameUrl, setIFrameUrl] = useState("");

    useEffect(() => {
        setCurrentPreviewState(currentTab);
        if (debouncedWidgetSettings) {
            setIFrameUrl(
                `${REACT_APP_PREVIEW_WIDGET_URL}?isPreviewMode=true&shopUrl=${shopDetails?.domain}&settings=${encodeURIComponent(
                    JSON.stringify(currentTab === ECustomizationSettingTabs.DESKTOP ? debouncedWidgetSettings.desktopSettings : debouncedWidgetSettings.mobileSettings)
                )}&overridePreviewState=${overridePreviewState}`
            );
        }
    }, [debouncedWidgetSettings, currentPreviewState, currentTab]);

    const handlePreviewButtonClick = (state: "desktop" | "mobile" | "tablet") => {
        setCurrentTab(state === "mobile" ? ECustomizationSettingTabs.MOBILE : ECustomizationSettingTabs.DESKTOP);
        setCurrentPreviewState(state);
    };

    return (
        <>
            <div className="g-widget-preview-container">
                {isLoading ? (
                    <div className="g-preview-state-loader" data-testid={`${dataTestIdPrefix}-LoaderContainer`}>
                        <Spin />
                    </div>
                ) : (
                    <div className="g-widget-preview-card-container g-container-border">{setupPreviewContainer(currentPreviewState, iFrameUrl)}</div>
                )}
                <div className="g-widget-preview-footer-page-settings-container">
                    <div className="g-widget-preview-footer-page-settings-left">
                        <Button
                            className="g-widget-preview-footer-screen-type-button"
                            onClick={() => handlePreviewButtonClick("desktop")}
                            type={`${currentPreviewState === "desktop" ? "primary" : "default"}`}
                            ghost={currentPreviewState === "desktop"}
                            data-testid={`${dataTestIdPrefix}-DesktopViewButton`}
                        >
                            <PreviewDesktopButtonIcon />
                        </Button>
                        <Button
                            className="g-widget-preview-footer-screen-type-button"
                            onClick={() => handlePreviewButtonClick("mobile")}
                            type={`${currentPreviewState === "mobile" ? "primary" : "default"}`}
                            ghost={currentPreviewState === "mobile"}
                            data-testid={`${dataTestIdPrefix}-MobileViewButton`}
                        >
                            <PreviewMobileButtonIcon />
                        </Button>
                        <Button
                            className="g-widget-preview-footer-screen-type-button"
                            onClick={() => handlePreviewButtonClick("tablet")}
                            type={`${currentPreviewState === "tablet" ? "primary" : "default"}`}
                            ghost={currentPreviewState === "tablet"}
                            data-testid={`${dataTestIdPrefix}-TabletViewButton`}
                        >
                            <PreviewTabletButtonIcon />
                        </Button>
                    </div>
                    <div className="g-widget-preview-footer-page-settings-center">
                        <Button
                            className="g-widget-preview-footer-button"
                            onClick={resetUpdatedWidgetSettings}
                            disabled={isLoading}
                            loading={isLoading}
                            data-testid={`${dataTestIdPrefix}-DiscardButton`}
                        >
                            Discard
                        </Button>
                        <Button
                            className="g-widget-preview-footer-button"
                            onClick={saveWidgetSettingChanges}
                            type={"primary"}
                            disabled={isLoading || !debouncedWidgetSettings}
                            loading={isLoading}
                            data-testid={`${dataTestIdPrefix}-SaveChangesButton`}
                        >
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const setupPreviewContainer = (currentPreviewState: string, iFrameUrl: string): JSX.Element => {
    if (currentPreviewState === "desktop") {
        return (
            <div className="g-widget-preview-view-container g-widget-preview-desktop-ratio">
                <PreviewBrowserControl />
                <PreviewURLControl />
                <iframe
                    title="g-preview-iframe"
                    className={"g-widget-preview-iframe"}
                    src={iFrameUrl}
                    key={iFrameUrl}
                    data-testid={`${dataTestIdPrefix}-PreviewModeIFrame`}
                ></iframe>
            </div>
        );
    } else if (currentPreviewState === "mobile") {
        return (
            <div className="g-widget-preview-view-container g-widget-preview-mobile-ratio">
                <PreviewMobileHeader className={"g-widget-preview-mobile-header"} />
                <iframe
                    title="g-preview-iframe"
                    className={"g-widget-preview-iframe"}
                    src={iFrameUrl}
                    key={iFrameUrl}
                    data-testid={`${dataTestIdPrefix}-PreviewModeIFrame`}
                ></iframe>
            </div>
        );
    } else {
        return (
            <div className="g-widget-preview-view-container g-widget-preview-tablet-ratio">
                <PreviewBrowserControl />
                <PreviewURLControl />
                <iframe
                    title="g-preview-iframe"
                    className={"g-widget-preview-iframe"}
                    src={iFrameUrl}
                    key={iFrameUrl}
                    data-testid={`${dataTestIdPrefix}-PreviewModeIFrame`}
                ></iframe>
            </div>
        );
    }
};

export default WidgetPreview;
