import { Modal } from "antd";
import { IWidgetSettings } from "interfaces";
import { apiUpdateGlobalWidgetSettings } from "services/widget-customization.service";
import { toast } from "utils/toast";

import "./index.scss";

interface IProps {
    widgetSettings: IWidgetSettings;
    setShowApplyAllChangesModal: (show: boolean) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setOriginalWidgetSettings: React.Dispatch<React.SetStateAction<IWidgetSettings | undefined>>;
}

const ApplyChangesModal = ({
    widgetSettings,
    setShowApplyAllChangesModal,
    setIsLoading,
    setOriginalWidgetSettings,
}: IProps) => {
    const postUpdatedWidgetSettings = async () => {
        try {
            setShowApplyAllChangesModal(false);
            setIsLoading(true);
            const widgetSettingsResponse = await apiUpdateGlobalWidgetSettings(widgetSettings);
            const { id, meta, dataType, createdAt, updatedAt, ...updatedWidgetSettings } = widgetSettingsResponse?.data;
            setOriginalWidgetSettings(updatedWidgetSettings);
            toast.success(widgetSettingsResponse?.message);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            className="g-apply-global-customization-modal"
            centered={true}
            open={true}
            onCancel={() => setShowApplyAllChangesModal(false)}
            closable={true}
            okText={<div data-testid="ApplyToAllSaveChangesButton">Apply to All</div>}
            cancelText="Go Back"
            onOk={postUpdatedWidgetSettings}
        >
            <div className="g-modal-title">Apply Changes to all Pages?</div>
            <div className="g-modal-description">
                These changes will be made globally across all pages on site. Saving these changes will override any changes you have on individual pages and
                colors set on interactions.
                <br></br> Would you like to continue?
            </div>
        </Modal>
    );
};

export default ApplyChangesModal;
