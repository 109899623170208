import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { Spin } from "antd";
import TiktokVideoCard from "./tiktok-video-card-component/TiktokVideoCardComponent";
import { apiGetUserVideos } from "services/tiktok";
import { toast } from "utils/toast";
import { ITiktokVideo } from "interfaces/tiktok";
import { UploadContext } from "context/upload-context";
import { EDefaultPlan } from "interfaces";
import { AppContext } from "context/app-context";

import "./index.scss";

const THRESHOLD = 200;
const TOTAL_UPLOAD_ALLOWED = 5;
const dataTestIdPrefix = "tiktok-modal";

interface IProps {
    username: string | undefined;
    cursor: string | undefined;
    videos: ITiktokVideo[];
    selectedVideos: ITiktokVideo[];
    setCursor: Dispatch<SetStateAction<string | undefined>>;
    setVideos: Dispatch<SetStateAction<ITiktokVideo[]>>;
    setSelectedVideos: Dispatch<SetStateAction<ITiktokVideo[]>>;
}

const TiktokVideosSelector = ({ username, cursor, videos, selectedVideos, setCursor, setVideos, setSelectedVideos }: IProps) => {
    const { fileList } = useContext(UploadContext);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [active, setActive] = useState<string>(videos[0]?.id);
    const { activeSubscription } = useContext(AppContext);
    const isFreePlan = activeSubscription?.activePlan?.meta === EDefaultPlan.NEW_FREE_PLAN;

    const fetchMore = useCallback(
        async (username: string, cursor: string) => {
            try {
                setIsFetchingMore(true);
                const response = await apiGetUserVideos({ username, cursor });
                setVideos([...videos, ...response.videos]);
                setCursor(response.cursor);
            } catch (e) {
                toast.error("Sorry, something went wrong. Please try again.");
                console.log(e);
            } finally {
                setIsFetchingMore(false);
            }
        },
        [username, cursor]
    );

    const handleScroll = async () => {
        const scrollTop = document.querySelector(".g-tiktok-modal-card-wrapper")?.scrollTop;
        const scrollHeight = document.querySelector(".g-tiktok-modal-card-wrapper")?.scrollHeight;
        const clientHeight = document.querySelector(".g-tiktok-modal-card-wrapper")?.clientHeight;

        if (scrollTop === undefined || scrollHeight === undefined || clientHeight === undefined) {
            return;
        }
        if (scrollTop + clientHeight < scrollHeight - THRESHOLD || isFetchingMore) {
            return;
        }
        if (username === undefined || cursor === undefined || cursor === "") {
            return;
        }
        await fetchMore(username, cursor);
    };

    const handleAdd = (video: ITiktokVideo) => {
        if(isFreePlan && selectedVideos.length + fileList?.length > 0) {
            setSelectedVideos([video]);
            return;
        }
        if (selectedVideos.length + fileList?.length >= TOTAL_UPLOAD_ALLOWED) {
            toast.warning(`You can only upload ${TOTAL_UPLOAD_ALLOWED} videos simultaneously. Please un-select and select again.`);
            return;
        }
        setSelectedVideos([...selectedVideos, video]);
    };

    const handleRemove = (video: ITiktokVideo) => {
        setSelectedVideos(selectedVideos.filter(({ id }) => id !== video.id));
    };

    return (
        <div className="g-tiktok-modal-card-wrapper" data-testid={`${dataTestIdPrefix}-modal-card-wrapper`} onScroll={handleScroll}>
            {videos.map((video, index) => {
                const isVideoSelected = selectedVideos.findIndex(({ id }) => id === video.id) !== -1;
                return (
                    <TiktokVideoCard
                        key={video.id + index}
                        video={video}
                        selected={isVideoSelected}
                        active={video.id === active}
                        handleAdd={handleAdd}
                        handleRemove={handleRemove}
                        setActive={setActive}
                    />
                );
            })}
            {isFetchingMore && <div className="g-tiktok-modal-footer-card"><Spin>Loading more videos...</Spin></div>}
        </div>
    );
};

export default TiktokVideosSelector;
