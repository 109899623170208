import { PlayerCustomizationSettings } from "constants/numbers";
import { Unit } from "constants/strings";
import { EDeviceType } from "interfaces";
import { EEmbeddedLayout, EEmbeddedPlayerStyle, EWidgetType, IDeviceSettings } from "interfaces/schema";
import { convertNumbertoPercentageString, removeSubstring } from "./type-conversion-functions";

export const mapDesktopToMobileSettings = (desktopSettings: IDeviceSettings): IDeviceSettings => {
  return {
    ...desktopSettings,
    previewEmbedGifStyle: EEmbeddedPlayerStyle.SIMULTANEOUS,
  }
}

export const mapMobileToDesktopSettings = (desktopSettings: IDeviceSettings, mobileSettings: IDeviceSettings): IDeviceSettings => {
  return {
    ...desktopSettings,
    textColor: mobileSettings.textColor,
    buttonColor: mobileSettings.buttonColor,
  }
}

export const applyPreviewCornerRadiusOverride = (rawPreviewCornerRadius: string) => {
  const previewCornerRadius = Number(removeSubstring(rawPreviewCornerRadius, Unit.PERCENTAGE));
  return convertNumbertoPercentageString(Math.min(previewCornerRadius, PlayerCustomizationSettings.EMBEDDED_INFRAME_CORNER_RADIUS_MAX));
};

export const previewStateMinHeight = (device: EDeviceType, deviceSettings: IDeviceSettings): number => {
  const { widgetType, embedType } = deviceSettings;

  if (widgetType === EWidgetType.EMBEDDED) {
    if (embedType === EEmbeddedLayout.HORIZONTAL_SCROLL) {
      return PlayerCustomizationSettings.EMBEDDED_HORIZONTAL_SCROLL_PREVIEW_MIN_HEIGHT;
    } else {
      return PlayerCustomizationSettings.EMBEDDED_GRID_PREVIEW_MIN_HEIGHT;
    }
  } else {
    if (device === EDeviceType.DESKTOP) {
      return PlayerCustomizationSettings.PREVIEW_OVERLAY_PORTRAIT_MIN_HEIGHT;
    } else {
      return PlayerCustomizationSettings.MOBILE_PREVIEW_OVERLAY_PORTRAIT_MIN_HEIGHT;
    }
  }
};