import axios from "axios";
import { EMetricValues, IMetricPayload } from "interfaces";
import { ISnowflakeResponseData } from "interfaces/snowflake";
const { REACT_APP_API_URL } = process.env;

const METRICS_API_URL = `${REACT_APP_API_URL}/metrics`;

const metricFactory = async <T>(metricUrl: string, payload: IMetricPayload, abortController: AbortController): Promise<T> => {
  try {
    const url = `${METRICS_API_URL}/${metricUrl}`;
    const response = await axios.post(url, payload, { signal: abortController.signal });
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data?.message;
  }
};

// First Row
export const apiGetAllQueriesNonGrouped = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.ALL_QUERIES_NON_GROUPED, payload, abortController);
export const apiGetAllQueriesNonGroupedNonShopify = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.ALL_QUERIES_NON_GROUPED_NON_SHOPIFY, payload, abortController);

// Second Row
export const apiGetVideoAnalyticsGroup = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.VIDEO_ANALYTICS, payload, abortController);
export const apiGetPerformance = (payload: IMetricPayload, abortController: AbortController) => metricFactory<ISnowflakeResponseData<any>>(EMetricValues.PERFORMANCE, payload, abortController);

// Third Row
export const apiGetDeviceBreakdownGroup = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.DEVICE_BREAKDOWN_GROUP, payload, abortController);
export const apiGetOnSiteEngagementGroupShopify = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.ON_SITE_ENGAGEMENT_GROUP, payload, abortController);

// Fourth Row
export const apiGetConversionRateByDeviceShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.CONVERSION_RATE_BY_DEVICE_SHOPIFY, payload, abortController);
export const apiGetConversionRateByDeviceNonShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.CONVERSION_RATE_BY_DEVICE_NON_SHOPIFY, payload, abortController);
export const apiGetConversionRateByVisitorTypeShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.CONVERSION_RATE_BY_VISITOR_TYPE_SHOPIFY, payload, abortController);
export const apiGetConversionRateByVisitorTypeNonShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.CONVERSION_RATE_BY_VISITOR_TYPE_NON_SHOPIFY, payload, abortController);

  export const apiGetAverageOrderValueByDevice = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.AVERAGE_ORDER_VALUE_BY_DEVICE, payload, abortController);
export const apiGetAverageOrderValueByVisitorType = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE, payload, abortController);

export const apiGetEngagementRateByVisitorTypeShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.ENGAGEMENT_RATE_BY_VISITOR_TYPE, payload, abortController);
export const apiGetEngagementRateByVisitorTypeNonShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.ENGAGEMENT_RATE_BY_VISITOR_TYPE_NON_SHOPIFY, payload, abortController);
export const apiGetEngagementRateByDeviceShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.ENGAGEMENT_RATE_BY_DEVICE, payload, abortController);
export const apiGetEngagementRateByDeviceNonShopify = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.ENGAGEMENT_RATE_BY_DEVICE_NON_SHOPIFY, payload, abortController);

export const apiGetAverageOrderValueByDeviceReport = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.AVERAGE_ORDER_VALUE_BY_DEVICE_REPORT, payload, abortController);
export const apiGetAverageOrderValueByVisitorTypeReport = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.AVERAGE_ORDER_VALUE_BY_VISITOR_TYPE_REPORT, payload, abortController);
  
// Fifth Row
export const apiGetTopVideosShopify = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.TOP_VIDEOS_SHOPIFY, payload, abortController);
export const apiGetTopVideosNonShopify = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.TOP_VIDEOS_NON_SHOPIFY, payload, abortController);
export const apiGetAbTestResults = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.AB_TESTING_RESULTS, payload, abortController);
export const apiGetAbTestMetrics = (payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<any>>(EMetricValues.AB_TESTING_METRICS, payload, abortController);

// Interactions
export const apiGetInteractionResponseOverview = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.INTERACTION_RESPONSE_OVERVIEW, payload, abortController);
export const apiGetInteractionQuestionAnalysisOverview = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.INTERACTION_QUESTION_ANALYSIS_OVERVIEW, payload, abortController);
export const apiGetQuestionResponsesOverview = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.QUESTION_RESPONSES_OVERVIEW, payload, abortController);
export const apiGetInteractionResponses = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.INTERACTION_RESPONSES, payload, abortController);
export const apiGenerateOfflineInteractionReport = <T>(payload: IMetricPayload, abortController: AbortController) =>
  metricFactory<ISnowflakeResponseData<T>>(EMetricValues.INTERACTION_REPORT, payload, abortController);

