import { Typography } from "antd";

const { Title } = Typography;

const Header = () => {
  return (
    <Title level={4}>
      Password reset code sent.
    </Title>
  );
}

export default Header;