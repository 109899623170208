import { EQuestionSelectionType, IQuestion } from "interfaces/schema";
import { ArrowRightOutlined } from "@ant-design/icons";

import "./index.scss";

const CSS_PREFIX = 'g-preview-picture-choice-interaction';

const sizeToQuestionTextCSS = {
  default: `${CSS_PREFIX}-question-text-default`,
  large: `${CSS_PREFIX}-question-text-large`,
}

const sizeToResponseContainerCSS = {
  default: `${CSS_PREFIX}-response-container-default`,
  large: `${CSS_PREFIX}-response-container-large`,
}

const sizeToResponseChoicesCSS = {
  default: `${CSS_PREFIX}-response-choices-default`,
  large: `${CSS_PREFIX}-response-choices-large`,
}

const sizeToImageContainerCSS = {
  default: `${CSS_PREFIX}-image-container-default`,
  large: `${CSS_PREFIX}-image-container-large`,
}

const sizeToNextButtonCSS = {
  default: `${CSS_PREFIX}-next-button-default`,
  large: `${CSS_PREFIX}-next-button-large`,
}

interface Props {
  question: IQuestion;
  size?: "default" | "large";
}

const PreviewPictureChoiceInteraction = ({ question, size = "default" }: Props) => {
  return (
    <div className="g-interation-edit-preview-panel-picture-choice-question-container">
      <div className="g-interation-edit-preview-panel-picture-choice-question-text-container">
        <div className={`g-interation-edit-preview-panel-picture-choice-question-text ${sizeToQuestionTextCSS[size]}`}>
          {question.text ? question.text : 'Your question here.'}
        </div>
      </div>
      <div className={`g-interation-edit-preview-panel-picture-choice-question-response-container ${sizeToResponseContainerCSS[size]}`}>
        <div className={`g-interation-edit-preview-panel-picture-choice-question-response-choices ${sizeToResponseChoicesCSS[size]}`}>
          {question.choices.map(({ id, pictureUrl }) => {
            if (!pictureUrl) {
              return null;
            }

            return (
              <div key={id} className="g-interation-edit-preview-panel-picture-choice-question-response-choice">
                <div className={`g-interation-edit-preview-panel-picture-choice-question-image-container ${sizeToImageContainerCSS[size]}`}>
                  <img className="g-interation-edit-preview-panel-picture-choice-question-image" alt="id" src={pictureUrl} />
                </div>
              </div>
            );
          })}
        </div>
        {question.selectionType === EQuestionSelectionType.MULTIPLE_SELECT && (
          <div className="g-interation-edit-preview-panel--picture-choice-question-next-button-container">
            <div className={`g-interation-edit-preview-panel--picture-choice-question-next-button ${sizeToNextButtonCSS[size]}`}>
              <ArrowRightOutlined />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewPictureChoiceInteraction;