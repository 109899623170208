import { Image, useTheme, View } from "@aws-amplify/ui-react";

import ganderLogo from "assets/img/gander-logo-tag-line-black.png";
import "./index.scss";

const Header = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="Gander logo"
        src={ganderLogo}
        width="50%"
      />
      <div className="g-login-header-title">
        Welcome to Gander! Please enter your details.
      </div>
    </View>
  );
}

export default Header;