import { Button } from "antd";
import { EInteractionResultsTabs } from "interfaces";

import "./index.scss";

const dataTestIdPrefix = "InteractionViewResultsViewHeader";

interface Props {
  activeTab: EInteractionResultsTabs;
  isCardLoading: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const Header = ({ activeTab, isCardLoading, setActiveTab }: Props) => {
  return (
    <div className="g-interaction-edit-results-card-title-container">
      <div className="g-interaction-edit-results-card-toggle-buttons-container">
        <Button
          className={activeTab === EInteractionResultsTabs.INSIGHTS ? "g-interaction-edit-results-card-toggle-button-active" : "g-interaction-edit-results-card-toggle-button"}
          type="text"
          disabled={isCardLoading}
          onClick={() => setActiveTab(EInteractionResultsTabs.INSIGHTS)}
          data-testid={`${dataTestIdPrefix}-InsightsButton`}
        >
          INSIGHTS
        </Button>
        <Button 
          className={activeTab === EInteractionResultsTabs.SUMMARY ? "g-interaction-edit-results-card-toggle-button-active" : "g-interaction-edit-results-card-toggle-button"}
          type="text"
          disabled={isCardLoading}
          onClick={() => setActiveTab(EInteractionResultsTabs.SUMMARY)}
          data-testid={`${dataTestIdPrefix}-SummaryButton`}
        >
          SUMMARY
        </Button>
        <Button 
          className={activeTab === EInteractionResultsTabs.RESPONSES ? "g-interaction-edit-results-card-toggle-button-active" : "g-interaction-edit-results-card-toggle-button"}
          type="text"
          disabled={isCardLoading}
          onClick={() => setActiveTab(EInteractionResultsTabs.RESPONSES)}
          data-testid={`${dataTestIdPrefix}-ResponsesButton`}
        >
          RESPONSES
        </Button>
      </div>
    </div>
  );
};

export default Header;
