import { useContext, useEffect, useState } from 'react';
import { Drawer, Form, Tabs } from 'antd';
import { ArrowWithCircleIconBackground } from 'components/icons';
import { AppContext } from 'context/app-context';
import { EVideoAddEditDrawerTabs, IPage, IProduct } from 'interfaces';
import { apiUnlinkAllPagesFromVideo, apiUpdateVideo } from 'services/video.services';
import { apiUnlinkVideoPage } from 'services/page.services';
import { shopifyGIDdecode, updateVideoArrayByMeta } from 'utils/common-functions';
import { toast } from 'utils/toast';
import { VideosContext } from 'context/videos-context';
import LinkVideoToPageTab from './link-video-to-page-tab-component';
import AttachProductsTab from './attach-products-tab-component';
import AddInteractionTab from './add-interaction-tab-component';
import { apiBatchGetProducts } from 'services/shopify.service';
import { PLATFORM } from 'constants/store';
import { IVideo } from 'interfaces/schema';
import DrawerTitleComponent from './drawer-title-component';

import './index.scss';

const startingTab = EVideoAddEditDrawerTabs?.LINK_VIDEO_TO_PAGE;

interface IProps {
    viewVideoDetailsDrawer: IVideo;
    openViewVideoDetailDrawer: (vid: IVideo | undefined) => void;
    onNextViewDetailsEvent: () => void;
    onPrevViewDetailsEvent: () => void;
    products: IProduct[];
    setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
}

const AddEditPageDetailsComponent = ({ viewVideoDetailsDrawer, openViewVideoDetailDrawer, onNextViewDetailsEvent, onPrevViewDetailsEvent, products, setProducts }: IProps) => {
    const { shopDetails } = useContext(AppContext);
    const { videos, recentlyAddedVideos, setRecentlyAddedVideos, setVideos, fetchVideos } = useContext(VideosContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDisplayName, setCurrentDisplayName] = useState<string>(viewVideoDetailsDrawer.displayName);
    const [viewEditDisplayNameInput, setViewEditDisplayNameInput] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchProducts();
        setViewEditDisplayNameInput(false);
        form?.setFieldValue("name", viewVideoDetailsDrawer.displayName);
    }, [viewVideoDetailsDrawer]);

    const fetchProducts = async () => {
        try {
            setIsLoading(true);
            const productIds = viewVideoDetailsDrawer?.products?.map((product) => product?.variantId || product?.id);
            // Avoiding api call incase of products is null / undefined
            if (!productIds || productIds.length === 0) {
                setProducts([]);
                return;
            }
            const productsResponse = await apiBatchGetProducts(productIds);
            const productsData = productsResponse?.data?.nodes?.map((node: any) => {
                const { id, title, onlineStorePreviewUrl, onlineStoreUrl, featuredImage, variantId } = node;
                const obj = {
                    id: id,
                    name: title,
                    thumbnail: featuredImage?.url,
                    productUrl: onlineStoreUrl ? onlineStoreUrl : onlineStorePreviewUrl,
                    variantId,
                };

                if (variantId) {
                    obj.productUrl += '?variant=' + shopifyGIDdecode(variantId)?.id
                }
                return obj;
            });
            if (viewVideoDetailsDrawer.products.length > productsData.length) {
                viewVideoDetailsDrawer.products.forEach((product) => {
                    const obj = productsData.find((pro: IProduct) => {
                        if (product.variantId) {
                            if (product.variantId === pro.variantId) {
                                return pro;
                            }
                        } else if (pro.id === product.id) {
                            return pro;
                        }
                        return null;
                    });
                    if (!obj) {
                        product.isDeleted = true;
                        productsData.push(product);
                    }
                });
            }
            setProducts(productsData);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
            setProducts([]);
        } finally {
            setIsLoading(false);
        }
    };


    const editVideoDisplayName = () => {
        setViewEditDisplayNameInput(true);
    }
    // single page remove api call here
    const onRemoveSinglePage = async (pageRecord: IPage) => {
        try {
            setIsLoading(true);
            const singleDeleteRes = await apiUnlinkVideoPage(pageRecord?.meta + "_" + viewVideoDetailsDrawer?.meta);
            toast.success(singleDeleteRes?.message);
            // after api call succeed
            const updatedPagesArray = viewVideoDetailsDrawer?.pages?.filter((page: IPage) => page?.meta !== pageRecord?.meta);
            const updateVideoObj = { ...viewVideoDetailsDrawer, pages: updatedPagesArray };
            openViewVideoDetailDrawer(updateVideoObj);
            setRecentlyAddedVideos(updateVideoArrayByMeta(recentlyAddedVideos, updateVideoObj));
            setVideos(updateVideoArrayByMeta(videos, updateVideoObj));
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }

    // all pages remove api call here
    const onRemoveAllPage = async () => {
        try {
            setIsLoading(true);
            const removeAllPagesRes = await apiUnlinkAllPagesFromVideo(viewVideoDetailsDrawer?.meta);
            toast.success(removeAllPagesRes?.message);

            // after api call succeed
            const updateVideoObj = { ...viewVideoDetailsDrawer, pages: [] };
            openViewVideoDetailDrawer(updateVideoObj);
            setRecentlyAddedVideos(updateVideoArrayByMeta(recentlyAddedVideos, updateVideoObj));
            setVideos(updateVideoArrayByMeta(videos, updateVideoObj));
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsLoading(false);
        }
    }

    const onFinish = async () => {
        viewVideoDetailsDrawer.displayName = currentDisplayName;
        try {
            const updatePayload = {
                displayName: currentDisplayName,
            }
            const updateRes = await apiUpdateVideo(updatePayload, viewVideoDetailsDrawer.meta);
            toast.success(updateRes?.message);
            fetchVideos();
            setViewEditDisplayNameInput(false);
        } catch (e: any) {
            console.log("🚀 ~ file: add-edit-page-details-component/index.tsx ~ onFinish ~ exception", e);
            toast.error(e?.message);
        }
    };

    let items: {
        label: string;
        key: EVideoAddEditDrawerTabs;
        children: JSX.Element;
    }[];

    if (shopDetails && (shopDetails["platform"].toLowerCase() === PLATFORM?.Shopify.toLowerCase() || shopDetails["platform"].toLowerCase() === PLATFORM?.ShopifyV2.toLowerCase())) {
        items = [
            {
                label: `Link Video to Page`,
                key: EVideoAddEditDrawerTabs.LINK_VIDEO_TO_PAGE,
                children: <> <LinkVideoToPageTab onRemoveAllPage={onRemoveAllPage} onRemoveSinglePage={onRemoveSinglePage} isLoading={isLoading} viewVideoDetailsDrawer={viewVideoDetailsDrawer} setIsLoading={setIsLoading} openViewVideoDetailsDrawer={openViewVideoDetailDrawer} /> </>
            },
            {
                label: `Attach Products`,
                key: EVideoAddEditDrawerTabs.ATTACH_PRODUCTS,
                children: <>  <AttachProductsTab isLoading={isLoading} viewVideoDetailsDrawer={viewVideoDetailsDrawer} setIsLoading={setIsLoading} openViewVideoDetailsDrawer={openViewVideoDetailDrawer} products={products} setProducts={setProducts} /> </>
            },
            {
                label: `Add Interaction`,
                key: EVideoAddEditDrawerTabs.ADD_INTERACTION,
                children: <AddInteractionTab viewVideoDetailsDrawer={viewVideoDetailsDrawer} products={products} />

            }
        ];
    } else {
        items = [
            {
                label: `Link Video to Page`,
                key: EVideoAddEditDrawerTabs.LINK_VIDEO_TO_PAGE,
                children: <> <LinkVideoToPageTab onRemoveAllPage={onRemoveAllPage} onRemoveSinglePage={onRemoveSinglePage} isLoading={isLoading} viewVideoDetailsDrawer={viewVideoDetailsDrawer} setIsLoading={setIsLoading} openViewVideoDetailsDrawer={openViewVideoDetailDrawer} /> </>
            },
            {
                label: `Add Interaction`,
                key: EVideoAddEditDrawerTabs.ADD_INTERACTION,
                children: <AddInteractionTab viewVideoDetailsDrawer={viewVideoDetailsDrawer} products={products} />

            }
        ];
    }

    return (
        <Drawer
            title={
                <DrawerTitleComponent
                    form={form}
                    viewVideoDetailsDrawer={viewVideoDetailsDrawer}
                    editVideoDisplayName={editVideoDisplayName}
                    viewEditDisplayNameInput={viewEditDisplayNameInput}
                    onFinish={onFinish}
                    isLoading={isLoading}
                    setCurrentDisplayName={setCurrentDisplayName}
                    onPrevViewDetailsEvent={onPrevViewDetailsEvent}
                    onNextViewDetailsEvent={onNextViewDetailsEvent}
                />
            }
            className="g-manage-content-add-edit-video-details-drawer"
            placement="right"
            onClose={() => openViewVideoDetailDrawer(undefined)}
            open={!!(viewVideoDetailsDrawer)}
            width={900}
            footerStyle={{ padding: 0 }}
            height={100}
            closable={false}
            bodyStyle={{ maxWidth: 900, overflow: 'hidden' }}
        >
            <ArrowWithCircleIconBackground
                style={{ transform: viewVideoDetailsDrawer ? 'rotate(0deg)' : 'rotate(180deg)', zIndex: 1001 }}
                onClick={() => isLoading ? false : openViewVideoDetailDrawer(undefined)}
            />
            <div className='g-video-details-container'>
                <Tabs
                    className='g-video-details-tabs-container'
                    defaultActiveKey={startingTab}
                    items={items}
                />
            </div>
        </Drawer>
    )
}

export default AddEditPageDetailsComponent;