import { useState } from "react";
import { Typography } from "antd";
import QuestionCards from "./question-cards-component";
import AddQuestionButton from "./add-question-button-component";
import { IInteraction, IQuestion } from "interfaces/schema";
import { getOrderedQuestions } from "utils/common-functions";

import "./index.scss";

const { Text, Title } = Typography;

interface Props {
  interaction: IInteraction;
  selectedQuestion: IQuestion | undefined;
  setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
}

const YourQuestionsPanel = ({ interaction, selectedQuestion, setInteraction, setSelectedQuestion }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Title className="g-interation-edit-your-questions-panel-title" level={4}>
        Your Questions
      </Title>
      <AddQuestionButton
        interaction={interaction}
        isLoading={isLoading}
        setInteraction={setInteraction}
        setSelectedQuestion={setSelectedQuestion}
        setIsLoading={setIsLoading}
      />
      <Text className="g-interation-edit-max-questions-text">max: 10 questions</Text>
      <QuestionCards
        orderedQuestions={getOrderedQuestions(interaction.questionOrder, interaction.questions)}
        interaction={interaction}
        selectedQuestion={selectedQuestion}
        isLoading={isLoading}
        setInteraction={setInteraction}
        setSelectedQuestion={setSelectedQuestion}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default YourQuestionsPanel;
