import { Typography } from "antd";
import { IProduct } from "interfaces";
import { MAX_PRODUCTS } from "../../constants";
import LinkVideoToExistingProductComponent from "./link-video-to-existing-product-component";

const { Title, Text } = Typography;
interface Props {
  dataTestId: string;
  onSelectProduct: (product: IProduct) => Promise<void>;
  products: IProduct[];
}

const LinkVideoToProductComponent = ({ dataTestId, onSelectProduct, products }: Props) => {
  return (
    <>
      <div className="g-add-edit-video-details-form-field-title">
        <Title level={3}>
          Search Product Name or URL
        </Title>
      </div>
      <div className="g-video-details-form-field-help-text" data-testid={`${dataTestId}-MaxProductText`}>
        <Text>max {MAX_PRODUCTS} products</Text>
      </div>
      <LinkVideoToExistingProductComponent
        onSelectProduct={onSelectProduct}
        products={products}
      />
    </>
  );
}

export default LinkVideoToProductComponent;