import { Form, FormListFieldData, Input, Select, Tooltip, Typography } from "antd";
import { getSubArrayBeforeValue } from "utils/common-functions";
import { EChoiceNextQuestionMetaRedirect, IQuestion } from "interfaces/schema";
import { LinkOutlined, ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface Props {
  choice: FormListFieldData;
  questions: IQuestion[];
  currentQuestion: IQuestion;
}

const ShortAnswerChoice = ({ choice, questions, currentQuestion }: Props) => {
  const form = Form.useFormInstance();
  const choiceValue = Form.useWatch(["choices", 0], form);
  const questionMetasBeforeCurrentQuestion = getSubArrayBeforeValue(
    questions.map(({ meta }) => meta),
    currentQuestion.meta
  );

  if (!choice) {
    return null;
  }

  return (
    <>
      <div className="g-interaction-edit-question-edit-form-field-title-container">
        <Text>Placeholder Text</Text>
      </div>
      <Form.Item {...choice} name={[choice.name, "text"]} rules={[{ required: true, message: "This field is required" }]}>
        <Input className="g-interaction-edit-question-edit-form-field-input"  placeholder="Type your answer here… "/>
      </Form.Item>
      <div className="g-interaction-edit-question-edit-form-field-title-container g-d-flex">
        <Text>Jump To</Text>
        <Tooltip title="Select what should happen after a visitor answers the question." trigger="click">
          <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center g-interaction-edit-question-edit-form-field-icon" />
        </Tooltip>
      </div>
      <Form.Item {...choice} key="nextQuestionMeta" name={[choice.name, "nextQuestionMeta"]} rules={[{ required: false, message: "Field is required" }]}>
        <Select
          size="large"
          options={[
            {
              label: (
                <span>
                  <ArrowRightOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                  &nbsp;&nbsp;N/A; next question
                </span>
              ),
              value: "",
            },
            ...questions.map(({ meta, text }, index) => ({
              label: (
                <span>
                  <ArrowRightOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                  &nbsp;&nbsp;{`${index + 1}. ${text}`}
                </span>
              ),
              value: meta,
              disabled: meta === currentQuestion.meta || questionMetasBeforeCurrentQuestion.includes(meta),
            })),
            {
              label: (
                <span>
                  <LinkOutlined className="g-interaction-edit-question-edit-form-field-icon" />
                  &nbsp;&nbsp;Link
                </span>
              ),
              value: EChoiceNextQuestionMetaRedirect,
              disabled: false,
            },
          ]}
        />
      </Form.Item>
      {choiceValue?.nextQuestionMeta === EChoiceNextQuestionMetaRedirect && (
        <>
          <div className="g-interaction-edit-question-edit-form-field-title-container">
            <Text>Direct to the following URL</Text>
          </div>
          <Form.Item {...choice} key="redirectUrl" name={[choice.name, "redirectUrl"]} rules={[{ required: true, message: "This field is required" }]}>
            <Input className="g-interaction-edit-question-edit-form-field-input" placeholder="Enter a URL" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default ShortAnswerChoice;
