import axios from "axios";
import { IVideo } from "interfaces/schema";
import { ITiktokVideo } from "interfaces/tiktok";

const { REACT_APP_API_URL } = process.env;

interface IVideoData extends ITiktokVideo, Pick<IVideo, "meta" | "name" | "originalMediaUrl"> {}

interface Payload {
    videoData: IVideoData[];
}

interface Response {
    keys: string[];
    message: string;
}

const apiUploadVideos = async (payload: Payload): Promise<Response> => {
    try {
        const url = `${REACT_APP_API_URL}/tiktok/videos`;
        const response = await axios.post<Response>(url, payload);
        return response.data;
    } catch (e: any) {
        console.log(e);
        throw e?.response?.data ? e?.response?.data : e;
    }
};

export default apiUploadVideos;
