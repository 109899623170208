import Stripe from "stripe";
import axios from "axios";
import { ProductType } from "interfaces/stripe";

const { REACT_APP_API_URL } = process.env;

interface IResponse {
  checkoutSession: Stripe.Checkout.Session;
  message: string;
}

const apiCreateGanderAccessCheckoutSession = async (
  meta: string,
  trialDays: number,
  stripeGanderAccessPriceId: string
): Promise<IResponse> => {
  try {
    const url = `${REACT_APP_API_URL}/stripe/checkout-session`;
    const response = await axios.post(url, {
      type: ProductType.GANDER_ACCESS,
      meta,
      trialDays,
      ganderAccessPriceId: stripeGanderAccessPriceId,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiCreateGanderAccessCheckoutSession;