import { useContext, useEffect } from "react";
import { Space } from "antd";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context/app-context";

// dummy data
import UpgradeYourAccountModal from "../common/upgrade-your-account-modal-component";
import { DashboardHeaderComponent } from "./dashboard-header-components";

import { FirstRowComponent } from "./first-row-component";
import { SecondRowComponent } from "./second-row-component";
import { ThirdRowComponent } from "./third-row-component";
import { FourthRowComponent } from "./fourth-row-component";
import { FifthRowComponent } from "./fifth-row-component";
import { DashboardContextProvider } from "context/dashboard-context";
import { EDefaultPlan, EPlanType } from "interfaces";


import "./index.scss";

const dataTestIdPrefix = "DashboardPage";

const DashboardPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { activeSubscription, isShowUpgradePlanBanner, setIsShowUpgradePlanBanner } = useContext(AppContext);

  const navigate = useNavigate();
  const isFreePlan =
    activeSubscription?.activePlan?.meta === EDefaultPlan.NEW_FREE_PLAN ||
    activeSubscription?.activePlan?.planName?.toLowerCase() === EPlanType.FREE;

  useEffect(() => {
    if (isFreePlan) {
      setIsShowUpgradePlanBanner(true);
    }
  }, []);


  return (
    <DashboardContextProvider>
      <div className="g-dashboard-component">
        <Space direction="vertical" size="large">
          <DashboardHeaderComponent user={user} navigate={navigate} />
          <FirstRowComponent dataTestIdPrefix={dataTestIdPrefix} />
          <SecondRowComponent />
          <ThirdRowComponent />
          <FourthRowComponent />
          <FifthRowComponent />
        </Space>
        {isShowUpgradePlanBanner &&
          <UpgradeYourAccountModal
            modalTitle={"Upgrade Your Account!"}
            modalDescription={`You are currently on a ${activeSubscription?.activePlan?.displayName} plan which does not give you access to data & analytics. If you'd like to change your plan, you can do so on the settings page or by clicking upgrade plan below.`}
          />
        }
      </div>
    </DashboardContextProvider>
  );
};

export default DashboardPage;
