import * as ROUTE_PATHS from 'constants/routes';
import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';

const PublicRoute: any = ({ children }: { children: any }) => {
    const { route } = useAuthenticator((context) => [context.route]);
    const { pathname } = useLocation();

    if (route === 'authenticated' && ROUTE_PATHS.isAuthRoutes(pathname)) {
        return <Navigate to={ROUTE_PATHS.PRIVATE_ROUTES.ROOT.PATH} />;
    }
    return children;
};

export default PublicRoute;
