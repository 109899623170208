import axios from "axios";
import { ICreatePage, IGetVideoApiPayload, IUpdatePage } from "interfaces";

const { REACT_APP_API_URL } = process.env;
const PAGE_API_URL = `${REACT_APP_API_URL}/pages`;

export const apiCreatePage = async (videoPayload: ICreatePage): Promise<any> => {
  try {
    const url = `${PAGE_API_URL}/create`;
    const response = await axios.post(url, videoPayload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiUpdatePage = async (videoPayload: IUpdatePage): Promise<any> => {
  try {
    const url = `${PAGE_API_URL}/update`;
    const response = await axios.put(url, videoPayload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiDeletePage = async (videoMeta: string): Promise<any> => {
  try {
    const url = `${PAGE_API_URL}/delete?id=${videoMeta}`;
    const response = await axios.delete(url);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export const apiGetPages = async (payload : IGetVideoApiPayload): Promise<any> => {
  try {
    const url = `${PAGE_API_URL}/get-pages`;
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data ? e?.response?.data : e;
  }
};
