import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';


export const useNavigationBlocker = (isShowPrompt: boolean, handleNavigateAway: { (navigateAway: any): void; (arg0: () => void): void; }) => {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (isShowPrompt) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
        return () => { window.onbeforeunload = null };
    }, [isShowPrompt]);

    useEffect(() => {
        if (!isShowPrompt) {
            return
        };

        const push = navigator.push;
        navigator.push = (...args: Parameters<typeof push>) => {
            const confirm = () => push(...args);
            handleNavigateAway(confirm);
        };
        return () => {
            navigator.push = push;
        };
    }, [isShowPrompt, navigator]);
};