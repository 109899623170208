import moment from "moment";
import { IShopifyGIDdecode } from "interfaces";
import { IQuestion, IVideo } from "interfaces/schema";

export const formatBytes = (bytes: number) => {
  const decimals = 2;
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const updateVideoArrayByMeta = (valueArray: IVideo[], vid: IVideo) => {
  const newVideos = valueArray.map((v: IVideo) => {
    if (v?.meta === vid?.meta) {
      return { ...v, ...vid };
    }
    return v;
  });
  return newVideos;
};
export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}


const componentToHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const RGB2HEX = (red: number, green: number, blue: number, isIncludeHash: boolean = true) => {
  if (isIncludeHash) {
    return "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue);
  }
  return componentToHex(red) + componentToHex(green) + componentToHex(blue);
};

export const HEX2RGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

export const createUrlWithHostAndPathName = (url: string, pathName : string) => {
  if (url && (url?.includes('http://') || url?.includes('https://'))) {
    return url + pathName;
  }
  const urlRes = new URL('http://' + url + pathName);
  return urlRes?.href;
}

export const getOrderedQuestions = (questionOrder: string[], questions: IQuestion[]): IQuestion[] => {
  const orderedQuestions = questionOrder.reduce((resultSoFar: IQuestion[], questionMeta) => {
    const question = questions.find(({ meta }) => questionMeta === meta);
    if (question === undefined) {
      return resultSoFar;
    }

    question.id = question.meta;
    return [...resultSoFar, question];
  }, []);

  return orderedQuestions;
};

export const getSubArrayBeforeValue = (array: string[], value: string) => {
  const currentQuestionMetaIndex = array.findIndex((meta) => meta === value);
  if (currentQuestionMetaIndex === -1) {
    return array;
  }

  array.splice(currentQuestionMetaIndex);
  return array;
};

export const onVideoThumbnailLoadError = (e: any, videoObj: Partial<IVideo>) => {
    const newSrc = videoObj?.thumbnail;
    if (e.target.currentSrc !== newSrc) {
        e.target.src = newSrc; //replacement image with low quality thumbnail
        return;
    }
    e.target.src = "https://d1m0ae25uhy92x.cloudfront.net/gwr_assets/gander-round-logo-black.png"; //replacement image imported above if none of thumbnail loads successfully
    e.target.style = "padding: 17px;object-fit: contain;object-position: center;"; // inline styles in html format
};
export const formatDuration = (duration: number) => {
    const durationFormat = duration > 3600 ? "H:mm:ss" : "mm:ss";
    return moment.unix(Math.ceil(duration)).utc().format(durationFormat);
};


export const shopifyGIDdecode = (str: string): IShopifyGIDdecode => {
    const raw = str?.split("shopify/")[1];
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [type, id] = raw?.split("/");

    const params = (id.split("?").slice(1)[0] || "").split("&").reduce((p: any, q: string) => {
      const [key, value] = q.split("=");
      p[key] = value;
      return p;
    }, {});
    const returnData = {
      type,
      id: id?.split("?")[0],
      params,
      raw: str,
    } as IShopifyGIDdecode;
    return returnData;
  };

export const getURLQueryParamsAsObject = (search: string) => {
  try {
    const obj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    return obj;
  } catch (error) {
    console.log("🚀 ~ file: common-functions.ts ~ getURLQueryParamsAsObject ~ error", error);
    return;
  }
}