import axios, { AxiosRequestConfig } from "axios";
import { RcFile } from "antd/lib/upload";

interface Payload {
  url: string;
  file: string | Blob | RcFile;
  config: AxiosRequestConfig<RcFile>;
}

const apiUploadFileToS3 = async ({ url, file, config }: Payload): Promise<string> => {
  try {
    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    const response = await instance.put(url, file, config);
    return response.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiUploadFileToS3;