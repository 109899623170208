import { IChoiceResponse } from "interfaces/snowflake";

import "./index.scss";

const dataTestIdPrefix = "PictureChoiceResponseCell";

interface Props {
    choiceResponses: IChoiceResponse[];
}

const PictureChoiceResponseCell = ({ choiceResponses }: Props) => {
    return (
        <div className="g-interaction-edit-responses-card-table-cell-images-container">
            {choiceResponses.map(({ id, choice_picture_url: choicePictureUrl }) => (
                <div key={id} className="g-interaction-edit-responses-card-table-cell-image-container">
                    <img
                        className="g-interaction-edit-responses-card-table-cell-image"
                        alt="id"
                        src={choicePictureUrl}
                        data-testid={`${dataTestIdPrefix}-Image`}
                    />
                </div>
            ))}
        </div>
    );
};

export default PictureChoiceResponseCell;
