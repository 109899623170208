import { Button, Modal } from "antd";
import { toast } from "utils/toast";
import { apiDeleteQuestion } from "services/questions";
import { apiUpdateInteraction } from "services/interactions";
import { IInteraction, IQuestion } from "interfaces/schema";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const dataTestIdPrefix = "QuestionDeleteButton";

const { confirm } = Modal;

interface Props {
    question: IQuestion;
    interaction: IInteraction;
    setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
    setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionDeleteButton = ({ question, interaction, setSelectedQuestion, setInteraction, setIsLoading }: Props) => {
    const handleDeleteQuestion = async () => {
        try {
            setIsLoading(true);
            await apiDeleteQuestion({ id: question.meta });
            const tempQuestionOrder = interaction.questionOrder;
            const deletedQuestionOrderIndex = tempQuestionOrder.indexOf(question.meta);
            tempQuestionOrder.splice(deletedQuestionOrderIndex, 1);
            const updateInteractionResponse = await apiUpdateInteraction({ id: interaction.meta, questionOrder: tempQuestionOrder });
            const tempQuestions = interaction.questions;
            const deletedQuestionIndex = tempQuestions.indexOf(question);
            tempQuestions.splice(deletedQuestionIndex, 1);
            setSelectedQuestion(tempQuestions.find(({ meta }) => meta === tempQuestionOrder[deletedQuestionOrderIndex - 1]));
            setInteraction({
                ...interaction,
                ...updateInteractionResponse.interaction,
                questions: tempQuestions,
            });
        } catch (e) {
            console.log(e);
            toast.error("Sorry, something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = async () => {
        confirm({
            title: "Delete Question",
            icon: <ExclamationCircleOutlined />,
            content: "Do you want to delete this question?",
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            centered: true,
            async onOk() {
                await handleDeleteQuestion();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    return (
        <Button
            type="text"
            htmlType="button"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick()}
            data-testid={`${dataTestIdPrefix}-Button`}
        />
    );
};

export default QuestionDeleteButton;
