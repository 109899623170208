export const formFields = (defaultEmail: string | null) => {
    return {
        signIn: {
            username: {
                label: "Work Email",
                labelHidden: false,
                placeholder: "hi@example.com",
                isRequired: true,
                defaultValue: defaultEmail,
            },
            password: {
                label: "Password",
                labelHidden: false,
                placeholder: "*****",
                isRequired: true,
            },
        },
        resetPassword: {
            username: {
                labelHidden: false,
                label: "Enter your email",
                placeholder: "hi@example.com",
                isRequired: true,
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: true,
                label: "Confirmation code",
                placeholder: "Enter your Confirmation Code",
            },
            password: {
                labelHidden: false,
                label: "Enter your new password",
                placeholder: "*****",
            },
            confirm_password: {
                labelHidden: false,
                placeholder: "*****",
            },
        },
    };
};
