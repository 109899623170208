import { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { toast } from "utils/toast";
import connectToConvert from "assets/img/connect-to-convert-eye.png";
import { apiGetSoMeLicenseDetails, apiPostSoMeLicensePurchase } from "services/so-me-purchase.service";
import { ISoMeLicenseDetail } from "interfaces";
import { MANAGE_CONTENT_SOME_LICENSE_PURCHASE_CONFIRMATION_MODAL_LEARN_MORE_LINK } from "constants/hyper-links";

import "./index.scss";
const dataTestIdPrefix = "Videos-SoMeLicensePurchaseModal";

interface IProps {
    crMeta: string;
    videoMeta: string;
    closeModal: (isSucceed?: boolean) => void;
}

const PurchaseSoMeLicenseModal = ({ crMeta, videoMeta, closeModal }: IProps) => {
    const [isSoMeDetailLoading, setIsSoMeDetailLoading] = useState(false);
    const [isSoMePurchaseLoading, setIsSoMePurchaseLoading] = useState(false);
    const [soMePurchaseDetail, setSoMePurchaseDetail] = useState<ISoMeLicenseDetail>();

    useEffect(() => {
        loadSoMePurchaseModalDetail(crMeta);
    }, [crMeta]);

    const loadSoMePurchaseModalDetail = async (crMeta: string) => {
        try {
            setIsSoMeDetailLoading(true);
            const responseData = await apiGetSoMeLicenseDetails(crMeta);
            setSoMePurchaseDetail(responseData);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
            closeModal();
        } finally {
            setIsSoMeDetailLoading(false);
        }
    };

    const confirmSoMeLicensePurchase = async () => {
        try {
            setIsSoMePurchaseLoading(true);
            const responseData = await apiPostSoMeLicensePurchase(videoMeta);
            toast.success(responseData?.message);
            closeModal(true);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsSoMePurchaseLoading(false);
        }
    }

    return (
        <Modal
            data-testid={`${dataTestIdPrefix}-Modal`}
            className="g-some-license-purchase-confirmation-modal"
            centered={true}
            width={814}
            open={true}
            onCancel={() => closeModal()}
            closable={false}
            maskClosable={false}
            okText="Confirm"
            okType="default"
            okButtonProps={{ type: "primary" }}
            confirmLoading={(isSoMeDetailLoading || isSoMePurchaseLoading)}
            cancelButtonProps={{ disabled: (isSoMeDetailLoading || isSoMePurchaseLoading) }}
            onOk={confirmSoMeLicensePurchase}
        >
            <div className="g-some-license-purchase-confirmation-body">
                <div className="g-some-license-purchase-confirmation-top">
                    <div className="g-some-license-purchase-confirmation-top-title-container">
                        <div className="g-some-license-purchase-confirmation-top-title" data-testid={`${dataTestIdPrefix}-ModalTitle`}>Buy Usage License</div>
                        <div className="g-some-license-purchase-confirmation-top-detail">
                            Looking to use your UGC videos beyond your online store? You can use this to purchase a 3 month usage license for your brand. Learn more &nbsp;
                            <a data-testid={`${dataTestIdPrefix}-HereNotionLink`}
                                style={{ textDecoration: "underline", color: "inherit" }}
                                href={MANAGE_CONTENT_SOME_LICENSE_PURCHASE_CONFIRMATION_MODAL_LEARN_MORE_LINK}
                                type="link"
                                target="none"
                            >here</a>.
                        </div>
                    </div>
                    <div className="g-some-license-purchase-confirmation-top-image-container">
                        <img src={connectToConvert} alt={"connect-to-convert"} />
                    </div>
                    <div className="g-some-license-purchase-confirmation-top-close-container">
                        <div onClick={() => (isSoMeDetailLoading || isSoMePurchaseLoading) ? false : closeModal()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                    fill="#84818A"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                {isSoMeDetailLoading ? (
                    <div
                        style={{ height: "170px" }}
                        className="g-fullscreen-loader g-d-flex g-justify-center g-align-center"
                        data-testid={`${dataTestIdPrefix}-ModalLoader`}
                    >
                        <Spin></Spin>
                    </div>
                ) : (
                    <div className="g-some-license-purchase-confirmation-main">
                        <div className="g-some-license-purchase-confirmation-main-detail">
                            <div className="g-some-license-purchase-confirmation-main-detail-primary">Product URL</div>
                            <div className="g-some-license-purchase-confirmation-main-detail-secondary">
                                <a
                                    style={{ color: "inherit" }}
                                    href={soMePurchaseDetail?.onlineStorePreviewUrl}
                                    type="link"
                                    target="none"
                                    data-testid={`${dataTestIdPrefix}-ProductUrlLink`}
                                >
                                    {soMePurchaseDetail?.onlineStorePreviewUrl}
                                </a>
                            </div>
                        </div>
                        <div className="g-d-flex g-justify-between" style={{ marginTop: "26px" }}>
                            <div className="g-some-license-purchase-confirmation-main-detail">
                                <div className="g-some-license-purchase-confirmation-main-detail-primary">License Cost</div>
                                <div
                                    className="g-some-license-purchase-confirmation-main-detail-secondary"
                                    data-testid={`${dataTestIdPrefix}-LicensePrice`}
                                >
                                    ${soMePurchaseDetail?.licensePrice}
                                </div>
                            </div>
                            <div className="g-some-license-purchase-confirmation-main-detail">
                                <div className="g-some-license-purchase-confirmation-main-detail-primary">Usage Term</div>
                                <div
                                    className="g-some-license-purchase-confirmation-main-detail-secondary"
                                    data-testid={`${dataTestIdPrefix}-LicenseMonth`}
                                >
                                    {soMePurchaseDetail?.licenseDuration ? `${soMePurchaseDetail?.licenseDuration} Months` : '3 Months'}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PurchaseSoMeLicenseModal;
