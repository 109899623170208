import { Row, Col, Skeleton, Dropdown, Typography, Table, Card } from "antd";
import { ROW_GUTTER } from "constants/dashboard";
import { DATE_FORMAT, EConversionRateType, EDefaultPlan, EPlanType, IConversionRateByDeviceTableDetails, IConversionRateByVisitorTypeTableDetails } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "context/dashboard-context";
import { AppContext } from "context/app-context";
import { apiGetConversionRateByDeviceShopify, apiGetConversionRateByVisitorTypeShopify, apiGetConversionRateByDeviceNonShopify, apiGetConversionRateByVisitorTypeNonShopify, apiGetEngagementRateByDeviceNonShopify, apiGetEngagementRateByDeviceShopify, apiGetAverageOrderValueByDevice, apiGetAverageOrderValueByVisitorType, apiGetEngagementRateByVisitorTypeShopify, apiGetEngagementRateByVisitorTypeNonShopify } from "services/dashboard.service";
import { shiftStartEndDateBackForComparison, aggregateConversionRateByDevice, aggregateConversionRateByVisitorType } from "utils/metrics";
import { conversionRateByDeviceDummyData, conversionRateByVisitorTypeDummyData } from "../dummy-data";
import MetricCardTableBlockedUpgradeCard from "../metric-table-component/MetricCardTableBlockedUpgradeCard";
import { ReactComponent as ArrowDownBold } from "../../../../assets/img/arrow-down-outline.svg";
import { MetricCardTableMetricsWithDownloadButtonComponent } from "../metric-table-component/MetricCardTableMetricsWithDownloadButtonComponent";
import { MetricCardTableMetricsComponent } from "../metric-table-component/MetricCardTableMetricsComponent";
import { EPropType, useDropdownItems, generateProps } from "./utils";

const { Title, Text } = Typography;

interface IByDevice {
  title: any;
  body: any;
}

interface IByVisitor {
  title: any;
  body: any;
}

interface IConfig {
  byDevice: IByDevice,
  byVisitor: IByVisitor
};

export const FourthRowComponent = () => {
  const {
    currentSelectKey,
    startDate,
    endDate,
    controller,
    metricValues,
    metricValuesPrev,
    currentConversionRateByDeviceType,
    currentConversionRateByVisitorType
  } = useContext(DashboardContext);
  const { shopDetails, isShowUpgradePlanBanner, activeSubscription } = useContext(AppContext);

  const [loadingDevice, setLoadingDevice] = useState(false);
  const [loadingVisitorType, setLoadingVisitorType] = useState(false);

  const [conversionRateByDevice, setConversionRateByDevice] = useState<IConversionRateByDeviceTableDetails[]>();
  const [conversionRateByDevicePrev, setConversionRateByDevicePrev] = useState<IConversionRateByDeviceTableDetails[]>();

  const [conversionRateByVisitorType, setConversionRateByVisitorType] = useState<IConversionRateByVisitorTypeTableDetails[]>();
  const [conversionRateByVisitorTypePrev, setConversionRateByVisitorTypePrev] = useState<IConversionRateByVisitorTypeTableDetails[]>();

  const [config, setConfig] = useState<IConfig>({ byDevice: { title: undefined, body: undefined }, byVisitor: { title: undefined, body: undefined } });

  const dropdownItemsDevice = useDropdownItems({ propType: EPropType.DEVICE });
  const dropdownItemsVisitor = useDropdownItems({ propType: EPropType.VISITOR });

  const isBasicPlan =
    activeSubscription?.activePlan?.planName?.toLowerCase() === EPlanType.BASIC ||
    activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_15_MONTHLY_PLAN ||
    activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_120_ANNUALLY_PLAN;


  useEffect(() => {
    isShowUpgradePlanBanner ? setDefaultParams() : setParams();
    setConfig({
      ...config,
      byDevice: generateProps({
        currentCvr: currentConversionRateByDeviceType,
        cvr: conversionRateByDevice,
        cvrPrev: conversionRateByDevicePrev,
        currentSelectKey,
        startDate,
        endDate,
        controller,
        metricValues,
        metricValuesPrev,
        propType: EPropType.DEVICE
      }),
      byVisitor: generateProps({
        currentCvr: currentConversionRateByVisitorType,
        cvr: conversionRateByVisitorType,
        cvrPrev: conversionRateByVisitorTypePrev,
        currentSelectKey,
        startDate,
        endDate,
        controller,
        metricValues,
        metricValuesPrev,
        propType: EPropType.VISITOR
      }),
    });
  }, [startDate, endDate, isShowUpgradePlanBanner]);

  useEffect(() => {
    setDeviceParams();
  }, [currentConversionRateByDeviceType]);

  useEffect(() => {
    setConfig({
      ...config,
      byDevice: generateProps({
        currentCvr: currentConversionRateByDeviceType,
        cvr: conversionRateByDevice,
        cvrPrev: conversionRateByDevicePrev,
        currentSelectKey,
        startDate,
        endDate,
        controller,
        metricValues,
        metricValuesPrev,
        propType: EPropType.DEVICE
      })
    });
  }, [conversionRateByDevice]);

  useEffect(() => {
    setVisitorTypeParams();
  }, [currentConversionRateByVisitorType]);

  useEffect(() => {
    setConfig({
      ...config,
      byVisitor: generateProps({
        currentCvr: currentConversionRateByVisitorType,
        cvr: conversionRateByVisitorType,
        cvrPrev: conversionRateByVisitorTypePrev,
        currentSelectKey,
        startDate,
        endDate,
        controller,
        metricValues,
        metricValuesPrev,
        propType: EPropType.VISITOR
      })
    });
  }, [conversionRateByVisitorType]);

  const dateObj = {
    startDate: startDate.format(DATE_FORMAT),
    endDate: endDate.format(DATE_FORMAT),
  };

  const gridLayout = {
    xs: { span: 24 },
    sm: { span: 24 },
    xl: { span: 12 },
    xxl: { span: 12 }
  };

  const setDefaultParams = () => {
    setConversionRateByDevice(conversionRateByDeviceDummyData);
    setConversionRateByVisitorType(conversionRateByVisitorTypeDummyData);
    setConversionRateByDevicePrev(conversionRateByDeviceDummyData)
    setConversionRateByVisitorTypePrev(conversionRateByVisitorTypeDummyData);
  };

  const setParams = async () => {
    await setDeviceParams();
    await setVisitorTypeParams();
  }

  const setDeviceParams = async () => {
    try {
      setLoadingDevice(true);
      switch (currentConversionRateByDeviceType) {
        case EConversionRateType.AOV:
          const [aovByDevice, aovByDevicePrev] = await Promise.all([
            apiGetAverageOrderValueByDevice(dateObj, controller),
            apiGetAverageOrderValueByDevice(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ])
          setConversionRateByDevice(aggregateConversionRateByDevice(aovByDevice?.data));
          setConversionRateByDevicePrev(aggregateConversionRateByDevice(aovByDevicePrev?.data));
          break;
        case EConversionRateType.CVR:
          const [cvrByDevice, cvrByDevicePrev] = (shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2') ? await Promise.all([
            apiGetConversionRateByDeviceShopify(dateObj, controller),
            apiGetConversionRateByDeviceShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]) : await Promise.all([
            apiGetConversionRateByDeviceNonShopify(dateObj, controller),
            apiGetConversionRateByDeviceNonShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]);
          setConversionRateByDevice(aggregateConversionRateByDevice(cvrByDevice?.data));
          setConversionRateByDevicePrev(aggregateConversionRateByDevice(cvrByDevicePrev?.data));
          break;
        case EConversionRateType.ER:
          const [erByDevice, erByDevicePrev] = (shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2') ? await Promise.all([
            apiGetEngagementRateByDeviceShopify(dateObj, controller),
            apiGetEngagementRateByDeviceShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]) : await Promise.all([
            apiGetEngagementRateByDeviceNonShopify(dateObj, controller),
            apiGetEngagementRateByDeviceNonShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]);
          setConversionRateByDevice(aggregateConversionRateByDevice(erByDevice?.data));
          setConversionRateByDevicePrev(aggregateConversionRateByDevice(erByDevicePrev?.data));
          break;
      }
    } catch (e) {
      setConversionRateByDevice(undefined);
      setConversionRateByVisitorType(undefined);
      setConversionRateByDevicePrev(undefined);
      setConversionRateByVisitorTypePrev(undefined);
    } finally {
      setLoadingDevice(false);
    }
  }

  const setVisitorTypeParams = async () => {
    try {
      setLoadingVisitorType(true);
      switch (currentConversionRateByVisitorType) {
        case EConversionRateType.AOV:
          const [aovByVisitorType, aovByVisitorTypePrev] = await Promise.all([
            apiGetAverageOrderValueByVisitorType(dateObj, controller),
            apiGetAverageOrderValueByVisitorType(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ])
          setConversionRateByVisitorType(aggregateConversionRateByVisitorType(aovByVisitorType?.data));
          setConversionRateByVisitorTypePrev(aggregateConversionRateByVisitorType(aovByVisitorTypePrev?.data));
          break;
        case EConversionRateType.CVR:
          const [cvrByVisitorType, cvrByVisitorTypePrev] = (shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2') ? await Promise.all([
            apiGetConversionRateByVisitorTypeShopify(dateObj, controller),
            apiGetConversionRateByVisitorTypeShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]) : await Promise.all([
            apiGetConversionRateByVisitorTypeNonShopify(dateObj, controller),
            apiGetConversionRateByVisitorTypeNonShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]);
          setConversionRateByVisitorType(aggregateConversionRateByVisitorType(cvrByVisitorType?.data));
          setConversionRateByVisitorTypePrev(aggregateConversionRateByVisitorType(cvrByVisitorTypePrev?.data));
          break;
        case EConversionRateType.ER:
          const [erByVisitorType, erByVisitorTypePrev] = (shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2') ? await Promise.all([
            apiGetEngagementRateByVisitorTypeShopify(dateObj, controller),
            apiGetEngagementRateByVisitorTypeShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]) : await Promise.all([
            apiGetEngagementRateByVisitorTypeNonShopify(dateObj, controller),
            apiGetEngagementRateByVisitorTypeNonShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller),
          ]);
          setConversionRateByVisitorType(aggregateConversionRateByVisitorType(erByVisitorType?.data));
          setConversionRateByVisitorTypePrev(aggregateConversionRateByVisitorType(erByVisitorTypePrev?.data));
          break;
      }
    } catch (e) {
      setConversionRateByDevice(undefined);
      setConversionRateByVisitorType(undefined);
      setConversionRateByDevicePrev(undefined);
      setConversionRateByVisitorTypePrev(undefined);
    } finally {
      setLoadingVisitorType(false);
    }
  }

  if (isShowUpgradePlanBanner || isBasicPlan) {
    return (
      <Row gutter={ROW_GUTTER}>
        <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>
          <Card title={<Title level={3}><h1 className={"g-metric-card-title"}>Conversion Rate by Visitor</h1></Title>} size="default">
            {loadingDevice ? <Skeleton active={true} paragraph={{ rows: 2 }} /> : <MetricCardTableBlockedUpgradeCard property={<>Upgrade to see <br />conversion rate by product</>} />}
          </Card>
        </Col>
        <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>
          <Card title={<Title level={3}><h1 className={"g-metric-card-title"}>Conversion Rate by Device</h1></Title>} size="default">
            {loadingVisitorType ? <Skeleton active={true} paragraph={{ rows: 2 }} /> : <MetricCardTableBlockedUpgradeCard property={<>Upgrade to see <br />conversion rate by product</>} />}
          </Card>
        </Col>
      </Row>);
  }

  if (!metricValues || !currentSelectKey) {
    return (
      <Row gutter={ROW_GUTTER}>
        <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>

          <Card
            title={
              <div className="g-metrics-table-analytics-wrapper">
                <div className="g-metrics-table-analytics-info-container">
                  <Title level={3} style={{ "marginBottom": "0px" }}>{currentConversionRateByVisitorType}</Title>
                  <Text style={{ "fontSize": "16px" }}>{currentConversionRateByVisitorType === EConversionRateType.ER ? "by No. of User" : "by Visitor Type"}</Text>
                </div>
              </div>
            } size="default">
            {loadingDevice ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<b>No data</b>)}
          </Card>
        </Col>
        <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>
          <Card
            title={
              <div className="g-metrics-table-analytics-wrapper">
                <div className="g-metrics-table-analytics-info-container">
                  <Title level={3} style={{ "marginBottom": "0px" }}>{currentConversionRateByDeviceType}</Title>
                  <Text style={{ "fontSize": "16px" }}>by Device</Text>
                </div>
              </div>
            } size="default">
            {loadingVisitorType ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<b>No data</b>)}
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={ROW_GUTTER}>
      <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>
        <Card
          title={
            <div className="g-metrics-table-analytics-wrapper">
              <div className="g-metrics-table-analytics-info-container">
                <Dropdown menu={{ items: dropdownItemsDevice }} trigger={['click']}>
                  <Title level={3}>{currentConversionRateByDeviceType} <ArrowDownBold style={{ marginBottom: "2px" }} /></Title>
                </Dropdown>
                <Text style={{ "fontSize": "14px" }}>by Device</Text>
              </div>
              <div className="g-metrics-table-analytics-info-container">
                {currentConversionRateByDeviceType === EConversionRateType.AOV ? <MetricCardTableMetricsWithDownloadButtonComponent {...config.byDevice.title} /> : <MetricCardTableMetricsComponent {...config.byDevice.title} />}
              </div>
            </div>
          } size="default" className="g-metrics-table-analytics-card">
          {loadingDevice && config?.byDevice ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<Table {...config?.byDevice.body} />)}
        </Card>
      </Col>
      <Col className="gutter-row g-dashboard-state-container" {...gridLayout}>
        <Card
          title={
            <div className="g-metrics-table-analytics-wrapper">
              <div className="g-metrics-table-analytics-info-container">
                <Dropdown menu={{ items: dropdownItemsVisitor }} trigger={['click']}>
                  <Title level={3}>{currentConversionRateByVisitorType} <ArrowDownBold style={{ marginBottom: "2px" }} /></Title>
                </Dropdown>
                <Text style={{ "fontSize": "14px" }}>{currentConversionRateByVisitorType === EConversionRateType.ER ? "by No. of User" : "by Visitor Type"}</Text>
              </div>
              <div className="g-metrics-table-analytics-info-container">
                {currentConversionRateByVisitorType === EConversionRateType.AOV ? <MetricCardTableMetricsWithDownloadButtonComponent {...config.byVisitor.title} /> : <MetricCardTableMetricsComponent {...config.byVisitor.title} />}
              </div>
            </div>
          } size="default" className={"g-metrics-table-analytics-card"}>
          {loadingVisitorType ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<Table {...config.byVisitor.body} />)}
        </Card>
      </Col>
    </Row>
  );
}