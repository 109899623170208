import { Button, Table } from 'antd'
import requestContentColumns from './request-content-columns'
import { toast } from 'utils/toast';
import { apiGetContentRequest } from 'services/request-content.services';
import { IContentRequest, IGetContentRequestApiPayload, ILastEvaluatedKey } from 'interfaces';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

let timeout: NodeJS.Timer;

const dataTestIdPrefix = "request-content-table";

interface IProps {
  searchTerm: string;
  isActive: boolean;
  setIsShowRequestContentTab: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const RequestContentTable = ({ searchTerm, isActive, setIsShowRequestContentTab }: IProps) => {
  const [isContentRequestLoading, setIsContentRequestLoading] = useState(false);
  const [contentRequestData, setContentRequestData] = useState<IContentRequest[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<ILastEvaluatedKey | undefined>();

  useEffect(() => {
    if (searchTerm) {
      if (!isActive) {
        return;
      }
      timeout = setTimeout(() => {
        handleLoadMoreAndSearch();
      }, 1000);
    } else {
      fetchContentRequests(undefined, false)
    }
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  const handleLoadMoreAndSearch = (isLoadMore?: boolean) => {
    const reqPayload: IGetContentRequestApiPayload = {
      requestLastEvaluatedKey: lastEvaluatedKey
    }
    if (searchTerm) {
      reqPayload.filterName = searchTerm;
    }
    fetchContentRequests(reqPayload, isLoadMore);
  }

  const fetchContentRequests = async (reqPayload: IGetContentRequestApiPayload | undefined, isLoadMore: boolean = false) => {
    setIsContentRequestLoading(true);
    try {
      const res = await apiGetContentRequest(reqPayload);
      if (!searchTerm && !(res?.data?.length > 0)) {
        setIsShowRequestContentTab(false);
      } else {
        setIsShowRequestContentTab(true);
      }
      setLastEvaluatedKey(res?.LastEvaluatedKey);
      setContentRequestData(isLoadMore ? [...contentRequestData, ...res?.data] : res?.data);
    }
    catch (e: any) {
      console.log("🚀 ~ file: request-content.tsx ~ fetchContentRequest ~ catch e", e);
      setContentRequestData([]);
      toast.error(e?.message);
    } finally {
      setIsContentRequestLoading(false);
    }
  }

  return (<>
    <Table
      columns={requestContentColumns(dataTestIdPrefix)}
      dataSource={contentRequestData}
      pagination={false}
      rowKey="meta"
      bordered={false}
      loading={isContentRequestLoading}
    />
    <br />
    {lastEvaluatedKey &&
      <Button
        block
        icon={<PlusOutlined />}
        onClick={() => handleLoadMoreAndSearch(true)}
        disabled={isContentRequestLoading}
        data-testid={`${dataTestIdPrefix}-RequestContentTableLoadMore`}
      >
        Load More
      </Button>
    }
  </>
  )
}

export default RequestContentTable;