import { Radio, Button, Typography, Tooltip, Slider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { EEmbeddedLayout, EOverlayOpenState, EPlayerShape, EWidgetType, IDeviceSettings } from "interfaces/schema";
import { EOverridePreviewState } from "interfaces";
import { convertNumbertoVhString, convertVhStringToNumber } from "utils/type-conversion-functions";
import { applyPreviewCornerRadiusOverride } from "utils/customization-settings";
import { useContext } from "react";
import { AppContext } from "context/app-context";

const { Text } = Typography;
const PLAYER_SIZE_MAX = 100;
const PLAYER_SIZE_DEFAULT = 50;
const OPEN_STATE_TYPE_TO_OVERRIDE_PREVIEW_STATE_MAP = {
    [EOverlayOpenState.FULLSCREEN]: EOverridePreviewState.FULLSCREEN,
    [EOverlayOpenState.OVERLAY]: EOverridePreviewState.OVERLAY,
    [EOverlayOpenState.IN_FRAME]: EOverridePreviewState.IN_FRAME,
};
const DATA_TEST_ID_PREFIX = "EmbeddedOpenStateSettings";

interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const EmbeddedOpenStateSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const { activeSubscription } = useContext(AppContext);
    const handleOverlayOpenStateButtonClick = (dirtyOpenStateType: EOverlayOpenState) => {
        const isInFrameMode = dirtyOpenStateType === EOverlayOpenState.IN_FRAME;

        handleDeviceSettingsUpdate(
            {
                ...deviceSettings,
                openStateType: dirtyOpenStateType,
                previewCornerRadius: isInFrameMode
                    ? applyPreviewCornerRadiusOverride(deviceSettings?.previewCornerRadius)
                    : deviceSettings?.previewCornerRadius,
                previewOrientation:
                    isInFrameMode && deviceSettings?.previewOrientation === EPlayerShape.SQUARE_CIRCLE
                        ? EPlayerShape.PORTRAIT_LANDSCAPE
                        : deviceSettings?.previewOrientation,
            },
            OPEN_STATE_TYPE_TO_OVERRIDE_PREVIEW_STATE_MAP[dirtyOpenStateType]
        );
    };

    const handleFullScreenVideosButtonClick = (dirtyFullScreenIsShowAll: boolean) => {
        handleDeviceSettingsUpdate({
            ...deviceSettings,
            fullScreenIsShowAll: dirtyFullScreenIsShowAll,
        });
    };

    const handleSizeSliderChange = (dirtyOverlayWidgetSize: string) => {
        handleDeviceSettingsUpdate(
            {
                ...deviceSettings,
                overlayWidgetSize: dirtyOverlayWidgetSize,
            },
            EOverridePreviewState.OVERLAY
        );
    };

    const handleDisplayBrandingRadioButtonClick = (dirtyDisplayBranding: boolean) => {
        handleDeviceSettingsUpdate(
            {
                ...deviceSettings,
                displayBranding: dirtyDisplayBranding,
            },
            OPEN_STATE_TYPE_TO_OVERRIDE_PREVIEW_STATE_MAP[deviceSettings?.openStateType]
        );
    };

    return (
        <>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-Text`}>Open State</Text>
                <Tooltip title="Defines what the video player should look like when a user clicks on a video. You can interact with the preview panel to preview this.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={
                        deviceSettings?.openStateType === EOverlayOpenState.FULLSCREEN
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleOverlayOpenStateButtonClick(EOverlayOpenState.FULLSCREEN)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-Full-Screen-Button`}
                >
                    Full Screen
                </Button>
                <Button
                    className={
                        deviceSettings?.openStateType === EOverlayOpenState.OVERLAY ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"
                    }
                    type="text"
                    onClick={() => handleOverlayOpenStateButtonClick(EOverlayOpenState.OVERLAY)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-Overlay-Button`}
                >
                    Overlay
                </Button>
                <Button
                    className={
                        deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME
                            ? "g-customization-toggle-button-selected"
                            : "g-customization-toggle-button"
                    }
                    type="text"
                    disabled={deviceSettings?.embedType === EEmbeddedLayout.HORIZONTAL_SCROLL}
                    onClick={() => handleOverlayOpenStateButtonClick(EOverlayOpenState.IN_FRAME)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Open-State-In-Frame-Button`}
                >
                    In Frame
                </Button>
            </div>
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-Text`}>Visible Videos (in Full Screen Mode)</Text>
                <Tooltip title="Selecting “All Videos” means users will be able to see all the videos in your Gander application. Selecting “Connected Videos” means users will only be able to see videos connected to a page.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <div className="g-customization-toggle-buttons-container">
                <Button
                    className={deviceSettings?.fullScreenIsShowAll ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                    type="text"
                    onClick={() => handleFullScreenVideosButtonClick(true)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-All-Videos-Button`}
                >
                    All Videos
                </Button>
                <Button
                    className={!deviceSettings?.fullScreenIsShowAll ? "g-customization-toggle-button-selected" : "g-customization-toggle-button"}
                    type="text"
                    onClick={() => handleFullScreenVideosButtonClick(false)}
                    data-testid={`${DATA_TEST_ID_PREFIX}-Visible-Videos-Connected-Videos-Button`}
                >
                    Connected Videos
                </Button>
            </div>
            {!(deviceSettings?.widgetType === EWidgetType.EMBEDDED && deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME) && (
                <>
                    <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Size-Text`}>
                        Size
                    </Text>
                    <div className="g-customization-slider-container">
                        <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-LeftItem-Text`}>
                            Small
                        </Text>
                        <Slider
                            max={PLAYER_SIZE_MAX}
                            value={
                                deviceSettings?.overlayWidgetSize?.endsWith("px")
                                    ? PLAYER_SIZE_DEFAULT
                                    : convertVhStringToNumber(deviceSettings?.overlayWidgetSize)
                            }
                            onChange={(value) => handleSizeSliderChange(convertNumbertoVhString(value))}
                            className={`${DATA_TEST_ID_PREFIX}-Size-Slider`}
                        />
                        <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Size-RightItem-Text`}>
                            Large
                        </Text>
                    </div>
                </>
            )}
            <div className="g-customization-field-header g-d-flex g-align-center">
                <Text data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Text`}>Display Gander Branding</Text>
                <Tooltip title="Setting only available for growth and professional accounts.">
                    <InfoCircleOutlined className="g-mx-8 g-d-flex" />
                </Tooltip>
            </div>
            <Radio.Group
                disabled={activeSubscription?.activePlan?.isForceBranding}
                value={deviceSettings?.displayBranding}
                onChange={(event) => handleDisplayBrandingRadioButtonClick(event.target.value)}
            >
                <Radio value={true} data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Show-Radio`}>
                    Show
                </Radio>
                <Radio value={false} data-testid={`${DATA_TEST_ID_PREFIX}-Display-Branding-Hide-Radio`}>
                    Hide
                </Radio>
            </Radio.Group>
        </>
    );
};

export default EmbeddedOpenStateSettings;
