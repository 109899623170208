import { Spin } from "antd";
import { EBillingCycle, ISubscriptionPlan } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { toast } from "utils/toast";
import SelectPlanTableV1V2 from "components/billing-plan/select-plan-table-v1-v2";
import SelectPlanTableV3 from "components/billing-plan/select-plan-table-v3";
import SelectPlanTableBillingCycleSwitch from "app/pages/common/select-plan-table-billing-cycle-switch";
import { AppContext } from "context/app-context";
import { getPlansAndParameters } from "app/pages/settings/billing-subscription-component/utils";

import "./index.scss";

interface IProps {
    selectedPlan: ISubscriptionPlan | undefined;
    setSelectedPlan: React.Dispatch<React.SetStateAction<ISubscriptionPlan | undefined>>;
    isSubscriptionSelectPlansLoading: boolean;
}

const SelectPlanComponent = ({ selectedPlan, setSelectedPlan, isSubscriptionSelectPlansLoading }: IProps) => {
    const { shopDetails, activeSubscription } = useContext(AppContext);
    const [isSubscriptionPlansLoading, setIsSubscriptionPlansLoading] = useState(false);
    const [billingCycleType, setBillingCycleType] = useState<EBillingCycle>(EBillingCycle.ANNUALLY);
    const [plans, setPlans] = useState<ISubscriptionPlan[] | undefined>();
    const [isShowBillingCycleContent, setIsShowBillingCycleContent] = useState(false);
    const [isShowNewPlansTable, setIsShowNewPlansTable] = useState<boolean>();

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = async () => {
        setIsSubscriptionPlansLoading(true);
        try {
            setIsSubscriptionPlansLoading(true);
            let { plans, defaultBillingCycle, isShowBillingCycleContent, isV3PlansFlow } = await getPlansAndParameters(shopDetails!, activeSubscription!);
            setPlans(plans);
            setBillingCycleType(defaultBillingCycle);
            setIsShowBillingCycleContent(isShowBillingCycleContent);
            setIsShowNewPlansTable(isV3PlansFlow);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsSubscriptionPlansLoading(false);
        }
    };

    const selectPlan = async (plan: ISubscriptionPlan) => {
        if (selectedPlan?.id === plan?.id && selectedPlan?.meta === plan?.meta) {
            setSelectedPlan(undefined);
        } else {
            setSelectedPlan(plan);
        }
    };

    return (
        <>
            {!isSubscriptionPlansLoading && isShowBillingCycleContent &&
                <div className="g-account-setup-billing-cycle-switch">
                    <SelectPlanTableBillingCycleSwitch billingCycleType={billingCycleType} setBillingCycleType={setBillingCycleType} />
                </div>
            }
            <div className="g-select-plan-component">
                {isSubscriptionPlansLoading && (
                    <div className="g-select-plan-loader">
                        <Spin size="large" />
                    </div>
                )}

                {!isSubscriptionPlansLoading && <>
                    {isShowNewPlansTable && (
                        <SelectPlanTableV3
                            subscriptionPlans={plans}
                            selectedPlan={selectedPlan}
                            isSubscriptionSelectPlansLoading={isSubscriptionSelectPlansLoading}
                            selectPlan={selectPlan}
                            isHideStartForFreeButton={false}
                            billingCycleType={billingCycleType}
                        />
                    )}

                    {!isShowNewPlansTable && (
                        <SelectPlanTableV1V2
                            subscriptionPlans={plans}
                            selectedPlan={selectedPlan}
                            isSubscriptionSelectPlansLoading={isSubscriptionSelectPlansLoading}
                            selectPlan={selectPlan}
                            isHideStartForFreeButton={false}
                        />
                    )}

                    {!plans?.length && (
                        <h2>
                            Something went wrong, please contact <a href="mailto:support@gogander.io">support@gogander.io</a>
                        </h2>
                    )}
                </>}
            </div>
        </>
    );
};

export default SelectPlanComponent;
