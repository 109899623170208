import { Slider, Space, Typography } from "antd";
import { CUSTOMIZATION_PAGE_EMBEDDED_HELP_LINK } from "constants/hyper-links";
import { EOverridePreviewState } from "interfaces";
import { EOverlayOpenState, EOverlayStartingState, EWidgetType, IDeviceSettings } from "interfaces/schema";
import { useCallback } from "react";

import "./index.scss";

const { Text, Paragraph, Link } = Typography;
const DATA_TEST_ID_PREFIX = "PositionSettingsComponent";
const POSITION_MAX = 100;
const POSITION_MIN = 0;
const POSITION_STEP = 0.5;
interface Props {
    deviceSettings: IDeviceSettings;
    handleDeviceSettingsUpdate: (dirtyDeviceSettings: IDeviceSettings, overridePreview?: EOverridePreviewState) => void;
}

const PositionSettings = ({ deviceSettings, handleDeviceSettingsUpdate }: Props) => {
    const handleUpdateDeviceSettings = useCallback(
        (dirtyDeviceSettings: Partial<IDeviceSettings>) => {
            const { widgetType, previewOverlayStartingState, openStateType } = deviceSettings;
            const payload = { ...deviceSettings, ...dirtyDeviceSettings };

            handleDeviceSettingsUpdate(
                payload,
                isOpenOverlayOverride(widgetType, previewOverlayStartingState, openStateType) ? EOverridePreviewState.OVERLAY : undefined
            );
        },
        [deviceSettings]
    );

    return (
        <div className="g-position-settings-container">
            {deviceSettings?.widgetType === EWidgetType.EMBEDDED && (
                <>
                    <Paragraph data-testid={`${DATA_TEST_ID_PREFIX}-Embedded-Paragraph`}>
                        To control where your embedded player appears, copy & paste the following snippet wherever you would like your video to appear.
                    </Paragraph>
                    <Paragraph>
                        You can find more detailed instructions{" "}
                        <Link href={CUSTOMIZATION_PAGE_EMBEDDED_HELP_LINK} target="_blank" data-testid={`${DATA_TEST_ID_PREFIX}-Embedded-Help-Link`}>
                            here
                        </Link>
                        .
                    </Paragraph>
                    <div className="g-customization-embedded-snippet-container">
                        <Text
                            copyable
                            data-testid={`${DATA_TEST_ID_PREFIX}-Embedded-Snippet-Text`}
                        >{`<div class="gander-widget" data-product-id="{{product.id}}"></div>`}</Text>
                    </div>
                </>
            )}
            {!(deviceSettings?.widgetType === EWidgetType.EMBEDDED && deviceSettings?.openStateType === EOverlayOpenState.IN_FRAME) && (
                <div className="g-position-settings-content">
                    <div className="g-position-settings-wrapper">
                        <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Horizontal-Slider-Text`}>
                            Horizontal Position
                        </Text>
                        <div className="g-customization-slider-container">
                            <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Horizontal-Slider-LeftItem-Text`}>
                                Left
                            </Text>
                            <Slider
                                max={POSITION_MAX}
                                min={POSITION_MIN}
                                step={POSITION_STEP}
                                value={Number(deviceSettings?.leftPosition?.replace("%", ""))}
                                onChange={(value) => handleUpdateDeviceSettings({ leftPosition: `${value}%` })}
                                className={`${DATA_TEST_ID_PREFIX}-Horizontal-Slider`}
                            />
                            <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Horizontal-Slider-RightItem-Text`}>
                                Right
                            </Text>
                        </div>
                    </div>
                    <Space className="g-customization-checkbox-group" direction="vertical" size="middle">
                        <div className="g-position-settings-wrapper">
                            <Text className="g-customization-field-header" data-testid={`${DATA_TEST_ID_PREFIX}-Vertical-Slider-Text`}>
                                Vertical Position
                            </Text>
                            <div className="g-customization-slider-container">
                                <Text className="g-customization-slider-left-item" data-testid={`${DATA_TEST_ID_PREFIX}-Vertical-Slider-LeftItem-Text`}>
                                    Top
                                </Text>
                                <Slider
                                    max={POSITION_MAX}
                                    min={POSITION_MIN}
                                    step={POSITION_STEP}
                                    value={Number(deviceSettings?.topPosition?.replace("%", ""))}
                                    onChange={(value) => handleUpdateDeviceSettings({ topPosition: `${value}%` })}
                                    className={`${DATA_TEST_ID_PREFIX}-Vertical-Slider`}
                                />
                                <Text className="g-customization-slider-right-item" data-testid={`${DATA_TEST_ID_PREFIX}-Vertical-Slider-RightItem-Text`}>
                                    Bottom
                                </Text>
                            </div>
                        </div>
                    </Space>
                </div>
            )}
        </div>
    );
};

const isOpenOverlayOverride = (widgetType: EWidgetType, previewOverlayStartingState: EOverlayStartingState, openStateType: EOverlayOpenState) => {
    if (
        widgetType === EWidgetType.OVERLAY &&
        previewOverlayStartingState === EOverlayStartingState.OPEN_STATE &&
        openStateType === EOverlayOpenState.FULLSCREEN
    ) {
        return true;
    }

    return widgetType === EWidgetType.EMBEDDED;
};

export default PositionSettings;
