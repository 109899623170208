import { Button } from "antd";
import { ColorEyeDropperIcon } from "components/icons";
import { RGBAFromRGBAString } from "utils/colors";

import "./index.scss";

interface IRGBAColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

interface Props {
    color: IRGBAColor;
    updateRGBAPickerValue: (RGBA: IRGBAColor) => void;
}

const EyeDropperPicker = ({ color, updateRGBAPickerValue }: Props) => {
    const handleEyeDropperClick = () => {
        const eyeDropper = new window.EyeDropper();
        eyeDropper
            .open()
            .then((result: { sRGBHex: string }) => {
                const colorObject = RGBAFromRGBAString(result?.sRGBHex);
                updateRGBAPickerValue({
                    r: colorObject?.red,
                    g: colorObject?.green,
                    b: colorObject?.blue,
                    a: color?.a,
                });
            })
            .catch((e: any) => {
                console.log("ERROR : ", e);
            });
    };

    return (
        <Button className="g-customization-color-eye-dropper" onClick={handleEyeDropperClick}>
            <ColorEyeDropperIcon />
        </Button>
    );
};

export default EyeDropperPicker;