import { useContext, useEffect, useState } from "react";
import { Upload } from "antd";
import { UploadBoxIcon } from "components/icons";
import { RcFile } from "antd/lib/upload";
import { AppContext } from "context/app-context";
import { UploadContext } from "context/upload-context";
import { toast } from "utils/toast";
import { VideosContext } from "context/videos-context";
import { debounce } from "lodash";
import { EVideoSource } from "interfaces/schema";
import { EDefaultPlan } from "interfaces";

import "./index.scss";

const allowedUploadFileTypes = ["video/mp4", "video/mov", "video/quicktime"];
const fileUploadSizeLimitInMB = 300;
const totalUploadAllowed = 5;

const UploadVideoMultipartComponent = () => {
    const { activeSubscription, setIsShowUpgradePlanBanner, shopDetails } = useContext(AppContext);
    const { fetchVideos, fetchRecentlyAddedVideos, videos, isVideosLoading, isRecentlyAddedVideosLoading, setIsShowV3FreePlanUploadLimitModel } = useContext(VideosContext);
    const { fileList, uploadVideo, isNewFileAdded, setIsNewFileAdded, isFileRecordCreating } = useContext(UploadContext);
    const [toastMessage, setToastMessage] = useState<any>(undefined);
    const isFreePlan = activeSubscription?.activePlan?.meta === EDefaultPlan.NEW_FREE_PLAN;

    useEffect(() => {
        if (isNewFileAdded) {
            fetchVideos();
            fetchRecentlyAddedVideos();
            setIsNewFileAdded(false);
        }
    }, [isNewFileAdded]);

    useEffect(() => {
        if (toastMessage?.status === "done") {
            toast.success(toastMessage?.response);
        } else if (toastMessage?.status === "error") {
            toast.error(`${toastMessage?.name} file upload failed.`);
        }
    }, [toastMessage]);

    const showMaxFileUploadCountValidationError = debounce(() => {
        toast.error(`You can only upload ${totalUploadAllowed} videos simultaneously. Please try again.`);
    }, 1000);

    const beforeUpload = async (file: RcFile, FileList: RcFile[]) => {
        if (isFreePlan && videos.length >= 1) {
            setIsShowV3FreePlanUploadLimitModel(true);
            return false;
        }
        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
            return false;
        }

        if (FileList?.length > totalUploadAllowed || fileList?.length >= totalUploadAllowed || fileList?.length + FileList?.length > totalUploadAllowed) {
            showMaxFileUploadCountValidationError();
            return false;
        }

        const isFileTypeAllowed = allowedUploadFileTypes.includes(file?.type);
        if (!isFileTypeAllowed) {
            toast.error(`Video ${file?.name} is invalid, You can only upload MP4/MOV video file!`);
            return false;
        }

        if (file?.size === 0) {
            toast.error(`Video ${file?.name} is invalid, Video has no content with size of 0 KB!`);
            return false;
        }

        const isLessThanSizeLimit = file?.size < 1024 * 1024 * fileUploadSizeLimitInMB;
        if (!isLessThanSizeLimit) {
            toast.error(`Video ${file?.name} is invalid, Video must be smaller than ${fileUploadSizeLimitInMB} MB!`);
            return false;
        }
    };

    return (
        <div>
            <div className="g-upload-video-component">
                <div className="g-upload-video-upload-container" data-testid="Upload-Video-Container">
                    <Upload
                        className="g-upload-video-upload-box"
                        fileList={fileList}
                        customRequest={(e) => uploadVideo(e, shopDetails?.id, EVideoSource.UPLOADED_CONTENT)}
                        beforeUpload={beforeUpload}
                        listType="text"
                        multiple={isFreePlan ? false : true}
                        disabled={isFileRecordCreating || isVideosLoading || isRecentlyAddedVideosLoading}
                        onChange={(updateData) => setToastMessage({ status: updateData?.file?.status, name: updateData?.file?.name, response: updateData?.file?.response })}
                        showUploadList={{ showRemoveIcon: false }}
                    >
                        <div className="g-upload-video-upload-box-content">
                            <div className="g-upload-video-upload-box-content-icon">
                                <UploadBoxIcon />
                            </div>
                            <div className="g-upload-video-upload-box-content-text">
                                <div className="g-upload-video-upload-box-title">
                                    <b>Drag & Drop Files, or</b>
                                    <b className="g-primary-description-text g-mx-4">Browse</b>
                                </div>
                                <div className="g-upload-video-upload-box-description g-description-text">
                                    Supports MP4 & MOV files with max file size 300mb
                                </div>
                            </div>
                        </div>
                    </Upload>
                </div>
            </div>
        </div>
    );
};
export default UploadVideoMultipartComponent;
