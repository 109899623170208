import { useEffect, useState } from "react";
import { Button, Card, Divider, Form, Input, Radio, Select, Tooltip, Typography } from "antd";
import ShortAnswerChoice from "./short-answer-choice-component";
import CallToActionChoice from "./call-to-action-choice-component";
import Choices from "./choices-component";
import { toast } from "utils/toast";
import { apiUpdateQuestion } from "services/questions";
import { getOrderedQuestions } from "utils/common-functions";
import { typeToTemplateMap } from "constants/question-templates";
import { EQuestionSelectionType, EQuestionType, IChoice, IInteraction, IQuestion } from "interfaces/schema";
import { InfoCircleOutlined } from "@ant-design/icons";
import AddToCartChoiceComponent from "./add-to-cart-choice-component";
import { getURL } from "app/pages/pages/utils";
import { apiUpdateInteraction } from "services/interactions";
import { getShopifyProductNamebyProductUrl } from "services/shopify.service";

import "./index.scss";

const { Text, Title } = Typography;

const typeToTextMap = {
  [EQuestionType.CALL_TO_ACTION]: "Call to Action",
  [EQuestionType.ADD_TO_CART]: "Add to Cart",
  [EQuestionType.MULTIPLE_CHOICE]: "Multiple Choice",
  [EQuestionType.PICTURE_CHOICE]: "Picture Choice",
  [EQuestionType.SHORT_ANSWER]: "Short Answer Question",
}

const selectionTypeToTextMap = {
  [EQuestionSelectionType.SINGLE_SELECT]: "Single Select",
  [EQuestionSelectionType.MULTIPLE_SELECT]: "Multiple Select",
}

const resetNextQuestionMetas = (choices: IChoice[]) => {
  return choices.map((choice) => {
    choice.nextQuestionMeta = "";
    return choice;
  });
}

const createTypeOptions = () => {
  return Object.values(EQuestionType).map((value) => ({
    label: (value === EQuestionType.CALL_TO_ACTION || value === EQuestionType.ADD_TO_CART) ?
      `${typeToTextMap[value]} (Tip: use this as your final question)` :
      typeToTextMap[value],
    value,
  }));
}

interface Props {
  interaction: IInteraction;
  question: IQuestion;
  setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
  isValidateForm: boolean;
  setIsValidateForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionEdit = ({ interaction, question, setInteraction, setSelectedQuestion, isValidateForm, setIsValidateForm }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<Pick<IQuestion, "type" | "text" | "selectionType" | "choices">>();
  const typeValue = Form.useWatch("type", form);

  useEffect(() => {
    if (isValidateForm) {
      form.validateFields();
      setIsValidateForm(false);
    }
  }, [isValidateForm]);

  useEffect(() => {
    if (question) {
      form.resetFields();
      form.setFieldValue("type", question.type);
      form.setFieldValue("text", question.text);
      form.setFieldValue("selectionType", question.selectionType);
      form.setFieldValue("choices", question.choices);
    }
  }, [question]);

  useEffect(() => {
    if (typeValue === undefined) {
      return;
    }

    if (question.type !== typeValue) {
      const template = typeToTemplateMap[typeValue];
      form.setFieldValue("text", template.text);
      form.setFieldValue("selectionType", template.selectionType);
      form.setFieldValue("choices", template.choices);
    } else {
      form.setFieldValue("text", question.text);
      form.setFieldValue("selectionType", question.selectionType);
      form.setFieldValue("choices", question.choices);
    }
  }, [typeValue]);

  const handleFinish = async (values: Pick<IQuestion, "type" | "text" | "selectionType" | "choices">) => {
    try {
      setIsLoading(true);
      if (values.selectionType === EQuestionSelectionType.MULTIPLE_SELECT) {
        values.choices = resetNextQuestionMetas(values.choices);
      }

      if (values.type === EQuestionType.ADD_TO_CART) {
        const response = getURL(values?.choices[0]?.productUrl as string);
        let path = response.isValid ? response.url!.origin + response.url!.pathname : values.choices[0]?.productUrl;

        if (response.isValid && response.url?.search?.includes('variant=')) {
          path = path + response.url?.search;
        }
        values.choices[0].productUrl = path;
      }

      const updateQuestionResponse = await apiUpdateQuestion({ id: question.id, ...values });
      const tempQuestions = interaction.questions;
      const updatedQuestionIndex = tempQuestions.findIndex(({ meta }) => meta === updateQuestionResponse.question.meta);
      tempQuestions[updatedQuestionIndex] = updateQuestionResponse.question;
      setSelectedQuestion(updateQuestionResponse.question);
      setInteraction({ ...interaction, questions: tempQuestions });

      if (!interaction.isNameUpdatedByUser && interaction.questionOrder[0] === question.meta) {
        const name = values.type === EQuestionType.ADD_TO_CART ? `Add to Cart - ${await getShopifyProductNamebyProductUrl(values.choices[0].productUrl)}` : values.text;
        const response = await apiUpdateInteraction({ id: interaction.meta, name });
        setInteraction({ ...interaction, ...response.interaction });
      }
      toast.success("Question updated!");
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title className="g-interation-edit-question-edit-title" level={4}>Edit Question</Title>
      <Divider className="g-m-0" />
      <div className="g-interaction-edit-question-edit-form-card-container">
        <Card bordered={false} bodyStyle={{ height: "100%", overflow: "hidden" }}>
          <Form
            form={form}
            disabled={isLoading}
            onFinish={handleFinish}
          >
            <div className="g-interaction-edit-question-edit-form-fields-container">
              <div className="g-interaction-edit-question-edit-form-fields">
                <div className="g-interaction-edit-question-edit-form-field-title-container">
                  <Text>Type</Text>
                </div>
                <Form.Item name="type" rules={[{ required: true, message: 'This field is required' }]}>
                  <Select
                    className="g-interaction-edit-question-edit-form-field-input"
                    options={createTypeOptions()}
                  />
                </Form.Item>
                <div className="g-interaction-edit-question-edit-form-field-title-container">
                  <Text>{typeValue === EQuestionType.ADD_TO_CART ? 'Button Text' : 'Question'}</Text>
                </div>
                <Form.Item name="text" rules={[{ required: true, message: 'This field is required' }]}>
                  <Input className="g-interaction-edit-question-edit-form-field-input" placeholder="Your question here." />
                </Form.Item>
                {(typeValue === EQuestionType.MULTIPLE_CHOICE || typeValue === EQuestionType.PICTURE_CHOICE) && (
                  <>
                    <div className="g-interaction-edit-question-edit-form-field-title-container g-d-flex">
                      <Text>Selection Type</Text>
                      <Tooltip title="Allow customers to select only one answer or multiple answers." trigger="click">
                        <InfoCircleOutlined className="g-mx-8 g-d-flex g-align-center g-interaction-edit-question-edit-form-field-icon" />
                      </Tooltip>
                    </div>
                    <Form.Item name="selectionType" rules={[{ required: true }]}>
                      <Radio.Group className="g-interaction-edit-question-edit-form-field-radio-group">
                        <Radio.Button className="g-interaction-edit-question-edit-form-field-radio-button" value={EQuestionSelectionType.SINGLE_SELECT}>
                          {selectionTypeToTextMap[EQuestionSelectionType.SINGLE_SELECT]}
                        </Radio.Button>
                        <Radio.Button className="g-interaction-edit-question-edit-form-field-radio-button" value={EQuestionSelectionType.MULTIPLE_SELECT}>
                          {selectionTypeToTextMap[EQuestionSelectionType.MULTIPLE_SELECT]}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
                <Divider className="g-interaction-edit-question-edit-form-divider" />
                {!isLoading && (
                  <Form.List name="choices">
                    {(choices, { add, remove, move }) => {
                      switch (typeValue) {
                        case EQuestionType.SHORT_ANSWER:
                          return (
                            <ShortAnswerChoice
                              choice={choices[0]}
                              questions={getOrderedQuestions(interaction.questionOrder, interaction.questions)}
                              currentQuestion={question}
                            />
                          );
                        case EQuestionType.CALL_TO_ACTION:
                          return (
                            <CallToActionChoice
                              choice={choices[0]}
                              questions={getOrderedQuestions(interaction.questionOrder, interaction.questions)}
                              currentQuestion={question}
                            />
                          );
                        case EQuestionType.ADD_TO_CART:
                          return (
                            <AddToCartChoiceComponent choice={choices[0]} />
                          );
                        default:
                          return (
                            <Choices
                              choices={choices}
                              questions={getOrderedQuestions(interaction.questionOrder, interaction.questions)}
                              currentQuestion={question}
                              add={add}
                              remove={remove}
                              move={move}
                            />
                          );
                      }
                    }}
                  </Form.List>
                )}
              </div>
              <div className="g-interaction-edit-question-edit-form-field-submit-button">
                <Form.Item style={{ marginBottom: "0" }}>
                  <Button type="primary" htmlType="submit">
                    Save Changes
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default QuestionEdit;