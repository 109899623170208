import moment from "moment";
import { Typography } from "antd";
import { IGetInteractionResponseOverviewData } from "interfaces/snowflake";
import LightBlueEye from "assets/img/lightblue-eye.jpg";
import PurpleLightning from "assets/img/purple-lightning.jpg";
import GreenCheckmark from "assets/img/green-checkmark.jpg";
import OrangePercentage from "assets/img/orange-percentage.jpg";
import BlueClock from "assets/img/blue-clock.jpg";

import "./index.scss";

const dataTestIdPrefix = "SummaryCards";

const { Text } = Typography;

interface Props {
    data: IGetInteractionResponseOverviewData;
}

const SummaryCards = ({
    data: {
        VIEWED_COUNT: viewedCount,
        STARTED_COUNT: startedCount,
        SUBMITTED_COUNT: submittedCount,
        COMPLETION_RATE: completionRate,
        AVG_TIME_TAKEN: avgTimeTaken,
    },
}: Props) => {
    const cardContent = [
        { icon: LightBlueEye, title: "Views", value: viewedCount !== null ? viewedCount : "-" },
        { icon: PurpleLightning, title: "Starts", value: startedCount !== null ? startedCount : "-" },
        { icon: GreenCheckmark, title: "Submissions", value: submittedCount !== null ? submittedCount : "-" },
        { icon: OrangePercentage, title: "Completion rate", value: completionRate !== null ? `${Number(Number(completionRate * 100).toPrecision(4))}%` : "-" },
        { icon: BlueClock, title: "Time to complete", value: avgTimeTaken !== null ? moment().startOf("day").seconds(avgTimeTaken).format("HH:mm:ss") : "-" },
    ];
    return (
        <div className="g-interaction-edit-insights-card-summaries">
            {cardContent.map(({ icon, title, value }) => (
                <div key={title} className="g-interaction-edit-insights-card-summary-card-container">
                    <div className="g-interaction-edit-insights-card-summary-card">
                        <div className="g-interaction-edit-insights-card-summary-image-container">
                            <img className="g-interaction-edit-insights-card-summary-image" alt="views" src={icon} data-testid={`${dataTestIdPrefix}-Icon`} />
                        </div>
                        <Text className="g-interaction-edit-insights-card-summary-title" data-testid={`${dataTestIdPrefix}-Title`}>
                            {title}
                        </Text>
                        <Text className="g-interaction-edit-insights-card-summary-count" data-testid={`${dataTestIdPrefix}-Count`}>
                            {value}
                        </Text>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SummaryCards;
