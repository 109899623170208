import moment from "moment";
import { Button, Tag } from "antd";
import { LinkOutlined, CheckCircleFilled } from '@ant-design/icons';
import { IContentRequestStatus } from "interfaces";

const requestContentColumns = (dataTestId: string) => {
    return [
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            key: 'name',
            render: (name: string, row: { url: string }) => (
                <div className="g-list-url-name-container">
                    <Button
                        data-testid={`${dataTestId}-ProductUrlButton`}
                        title={row?.url}
                        className="g-d-flex g-align-center"
                        type='link'
                        icon={<LinkOutlined />}
                        target='_blank'
                        href={row?.url}
                    >
                        <span className="g-list-url-name">
                            {row?.url}
                        </span>
                    </Button>
                </div>
            ),
        },
        {
            title: 'DATE REQUESTED',
            dataIndex: 'createdAt',
            key: 'type',
            render: (createdAt: string) => (
                <div className="g-list-date-uploaded-container">
                    <div
                        className="g-list-date-uploaded-date"
                        data-testid={`${dataTestId}-ProductCreatedDate`}
                    >
                        {moment(createdAt).format("DD MMM YYYY")}
                    </div>
                    <div
                        className="g-list-date-uploaded-time"
                        style={{ color: 'gray' }}
                        data-testid={`${dataTestId}-ProductCreatedTime`}
                    >
                        {moment(createdAt).format("h:mm A")}
                    </div>
                </div>
            ),
        },
        {
            title: 'STATUS',
            key: 'status',
            dataIndex: 'status',
            render: (status: string) => {
                return (
                    <span data-testid={`${dataTestId}-ProductStatusTag`}>
                        {status === IContentRequestStatus.FULFILLMENT && <Tag color='orange'>Awaiting fulfillment</Tag>}
                        {status === IContentRequestStatus.IN_PROGRESS && <Tag color='blue'>In Progress</Tag>}
                        {status === IContentRequestStatus.PENDING && <Tag color="orange">Pending</Tag>}
                        {status === IContentRequestStatus.DELIVERED && <Tag color='cyan' icon={<CheckCircleFilled />}>Delivered</Tag>}
                        {status === IContentRequestStatus.COMPLETED && <Tag color='purple'>Completed</Tag>}
                        {status === IContentRequestStatus.DECLINED && <Tag color='red'>Declined</Tag>}
                        {status === IContentRequestStatus.REVIEW_CONTENT && <Tag color='orange'>In Admin Review</Tag>}
                        {status === IContentRequestStatus.ADMIN_REJECT && <Tag color='gold'>In Improvement</Tag>}
                    </span>
                );
            },
        }
    ];
}

export default requestContentColumns;