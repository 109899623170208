import { Button } from "antd";
import Breadcrumbs from "./breadcrumbs-component";
import { IInteraction, IQuestion } from "interfaces/schema";
import { EInteractionEditTabs } from "interfaces";

import "./index.scss";

const dataTestIdPrefix = "InteractionViewHeader";

interface Props {
    interaction: IInteraction;
    invalidQuestion: IQuestion | null;
    activeTab: EInteractionEditTabs;
    isCardLoading: boolean;
    setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
    setActiveTab: React.Dispatch<React.SetStateAction<EInteractionEditTabs>>;
    setIsCardLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ interaction, activeTab, isCardLoading, setInteraction, setActiveTab, setIsCardLoading, invalidQuestion }: Props) => {
    return (
        <div className="g-interaction-edit-card-title-container">
            <Breadcrumbs name={interaction.name} />
            <div className="g-interaction-edit-card-toggle-buttons-container">
                <Button
                    className={
                        activeTab === EInteractionEditTabs.EDIT_QUESTIONS
                            ? "g-interaction-edit-card-toggle-button-active"
                            : "g-interaction-edit-card-toggle-button"
                    }
                    type="text"
                    disabled={isCardLoading}
                    onClick={() => setActiveTab(EInteractionEditTabs.EDIT_QUESTIONS)}
                    data-testid={`${dataTestIdPrefix}-EditQuestions-Button`}
                >
                    Edit Questions
                </Button>
                <Button
                    className={
                        activeTab === EInteractionEditTabs.RESULTS ? "g-interaction-edit-card-toggle-button-active" : "g-interaction-edit-card-toggle-button"
                    }
                    type="text"
                    disabled={isCardLoading || !!invalidQuestion}
                    onClick={() => setActiveTab(EInteractionEditTabs.RESULTS)}
                    data-testid={`${dataTestIdPrefix}-Results-Button`}
                >
                    Results
                </Button>
            </div>
        </div>
    );
};

export default Header;
