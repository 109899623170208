import { Button, Tag, List, Dropdown, Space, Typography, Tooltip, Modal, Menu } from "antd";
import { ExclamationCircleOutlined, InfoCircleOutlined, LinkOutlined, RightOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowDownBold } from "assets/img/arrow-down-bold.svg";
import moment from "moment";
import { EPreviewPlayButtonSize, IPage } from "interfaces";
import GanderVideo from "components/gander-video";
import { createUrlWithHostAndPathName } from "utils/common-functions";
import { EditPageUrlIcon, OpenPageUrlIcon, VideoTableDeleteButtonIcon } from "components/icons";
import { toast } from "utils/toast";
import EditPageNameInput from "./edit-page-name-input";
import { IVideo } from "interfaces/schema";

const { Title } = Typography;

const dataTestIdPrefix = "Pages-PagesTableColumns";

const { confirm } = Modal;

export const GetColumns = (
    setManageContentABTestDrawer: React.Dispatch<React.SetStateAction<IPage>>,
    setEditPageNameInput: React.Dispatch<React.SetStateAction<IPage | undefined>>,
    deletePage: (pageObj: IPage) => Promise<void>,
    openViewPageDetailDrawer: (pg: IPage) => void,
    openCustomizePageComponent: (pg: IPage) => void,
    website: string,
    handleEditPageName: (pageObj: IPage) => void,
    editPageNameInput: IPage | undefined,
    openPageABTestDrawer: (pg: IPage) => void
) => {

    const handleDeletePage = (row: IPage) => {
        confirm({
            title: 'Delete Page',
            className: "g-delete-page-confirmation-modal-container",
            icon: <ExclamationCircleOutlined />,
            content: `Do you want to delete this page?`,
            okText: <div data-testid={`${dataTestIdPrefix}-DeleteConfirmationDeleteButton`}>Delete</div>,
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            async onOk() {
                await deletePage(row);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const copyUrlToClipBoard = (row: IPage) => {
        try {
            let url = new URL(createUrlWithHostAndPathName(website, row?.url));
            url.searchParams.set('isSharedFullScreen', 'true');
            navigator.clipboard.writeText(url.href);
            toast.info('URL Copied');
        } catch (error) {
            console.log("🚀 ~ file: columns.tsx ~ copyUrlToClipBoard ~ error", error);
        }
    }

    const menu = (row: IPage) => (
        <Menu
            items={[
                {
                    key: `page-${row.id}-edit`,
                    label: <div onClick={() => openCustomizePageComponent(row)} data-testid={`${dataTestIdPrefix}-CustomizePageOption`}>Customize Page</div>,
                },
                {
                    key: `page-${row.id}-video`,
                    label: <div onClick={() => openViewPageDetailDrawer(row)} data-testid={`${dataTestIdPrefix}-ConnectVideoOption`}>Connect Videos</div>,
                },
                {
                    key: `page-${row.id}-abtest`,
                    label: <div onClick={() => openPageABTestDrawer(row)} data-testid={`${dataTestIdPrefix}-ConnectVideoOption`}>Set up Creative A/B Test</div>
                }
            ]}
        />
    );


    return [
        {
            title: 'Page',
            key: 'name',
            render: (page: IPage, row: { url: string }) => (
                <Space size="middle" className='g-manage-content-spacer-action-items' data-testid={`${dataTestIdPrefix}-PageColumn`}>

                    <div className="g-edit-page-table-title">

                        {/* Edit Page Name Input Form */}
                        {editPageNameInput && page === editPageNameInput && <EditPageNameInput editPageNameInput={editPageNameInput} setEditPageNameInput={setEditPageNameInput} />}
                        {/* Edit Page Name Input Form */}
                    </div>
                    {page !== editPageNameInput && <Title className="g-list-page-table-title" level={5}>
                        {page.name}
                    </Title>}

                    <Button className='g-manage-content-spacer-action-items-open-page-button' size='small' icon={<OpenPageUrlIcon />} onClick={() => window.open(createUrlWithHostAndPathName(website, row?.url))}></Button>
                    <Button className='g-manage-content-spacer-action-items-edit-page-button' size='small' icon={<EditPageUrlIcon />} onClick={() => handleEditPageName(page)} data-testid={`${dataTestIdPrefix}-EditPageNameButton`}></Button>
                </Space>
            ),
        },
        {
            title: 'Linked Videos',
            dataIndex: 'videos',
            key: 'videos',
            render: (videos: IVideo[]) => {
                const renderMenu = () => {
                    return (
                        <List
                            className='g-list-with-video-previews g-list-with-video-previews-fixed-height'
                            itemLayout="horizontal"
                            dataSource={videos}
                            data-testid={`${dataTestIdPrefix}-LinkedVideosDropdownList`}
                            renderItem={(video: IVideo) =>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <div className='g-list-with-video-preview-avatar'>
                                                <GanderVideo video={video} buttonSize={EPreviewPlayButtonSize.SMALL} />
                                            </div>
                                        }
                                        title={<div><Title ellipsis level={5} title={video?.displayName}>{video?.displayName}</Title></div>}
                                        description={`Last modified ${moment(video?.updatedAt).fromNow()}`}
                                    />
                                </List.Item>
                            }
                        />
                    )
                };
                return (
                    <Dropdown dropdownRender={renderMenu} placement='bottom' trigger={['click']} >
                        <div className='g-dropdown-with-text' data-testid={`${dataTestIdPrefix}-LinkedVideosDropdown`}>
                            <Button className='g-dropdown-circle-btn' type="primary" shape="circle" size='small'>{videos?.length}</Button>
                            <Button type='link'>Videos</Button><ArrowDownBold />
                        </div>
                    </Dropdown>
                )
            }
        },
        {
            title: 'A/B Testing',
            key: 'abTest',
            dataIndex: 'abTest',
            render: (abTest?: { name: string; videos: Array<string> }) => (
                <span>
                    <Tag color={abTest?.videos?.length === 2 ? 'cyan' : 'default'}>
                        {abTest?.videos?.length === 2 ? 'Active' : 'Inactive'}
                    </Tag>
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (row: IPage) => (
                <Space size="middle" className='g-manage-content-spacer-action-items'>
                    {row?.videos && row?.videos?.length > 0 && (
                        <Button size='small' type="primary" ghost onClick={() => copyUrlToClipBoard(row)} icon={<LinkOutlined />}>
                            Share URL
                            <Tooltip
                                title={'Get a shareable URL that directly opens your full screen video shopping mode.'}
                                placement="bottomRight"
                                showArrow={true}
                            >
                                <InfoCircleOutlined style={{ color: 'black' }} />
                            </Tooltip>
                        </Button>
                    )}
                    <Dropdown dropdownRender={() => menu(row)} trigger={["click"]}>
                        <Button size='small' data-testid={`${dataTestIdPrefix}-OpenPageAddEditDrawerButton`}>
                            Manage Page
                            <RightOutlined />
                        </Button>
                    </Dropdown>
                    <Button size='small' icon={<VideoTableDeleteButtonIcon />} className="g-manage-content-spacer-action-items-delete-button" ghost onClick={() => handleDeletePage(row)} data-testid={`${dataTestIdPrefix}-DeleteActions`}> </Button>
                </Space>
            ),
        },
    ];
}