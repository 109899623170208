import { Row, Col } from "antd";
import { PLATFORM } from "../../../../constants/store";
import { DATE_FORMAT, EDefaultPlan, EPlanType, IFirstRowComponentProps, IMetricValue, TMetric } from "../../../../interfaces";
import { ROW_GUTTER } from "constants/dashboard";
import { DashboardContext } from "context/dashboard-context";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "context/app-context";
import { allQueriesNonGroupedCurrentDay, allQueriesNonGroupedPreviousDay } from "../dummy-data";
import { apiGetAllQueriesNonGrouped, apiGetAllQueriesNonGroupedNonShopify } from "services/dashboard.service";
import { shiftStartEndDateBackForComparison } from "utils/metrics";
import { MetricComponent } from "../metric-statistic-component/MetricComponent";
import { CaretRightOutlined, DollarOutlined, PercentageOutlined, LikeOutlined } from "@ant-design/icons";
import MetricStatisticComponentBlocked from "../metric-statistic-component/MetricStatisticComponentBlocked";

export const FirstRowComponent = ({ dataTestIdPrefix }: IFirstRowComponentProps) => {
    const { currentSelectKey, startDate, endDate, controller, metricValues, setMetricValues, metricValuesPrev, setMetricValuesPrev } = useContext(DashboardContext);
    const { activeSubscription, isShowUpgradePlanBanner, shopDetails } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);

    const isShopifyPlatform = shopDetails?.platform === PLATFORM.Shopify.toLowerCase() || shopDetails?.platform === PLATFORM.ShopifyV2;
    const spanLength = isShopifyPlatform ? 6 : 12;

    const isBasicPlan =
        activeSubscription?.activePlan?.planName?.toLowerCase() === EPlanType.BASIC ||
        activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_15_MONTHLY_PLAN ||
        activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_120_ANNUALLY_PLAN;

    useEffect(() => {
        isShowUpgradePlanBanner ? setDefaultParams() : setParams();
    }, [startDate, endDate, isShowUpgradePlanBanner]);

    const setDefaultParams = () => {
        let metricDictionary: TMetric = {};
        (allQueriesNonGroupedCurrentDay as Array<IMetricValue>).forEach((datum: IMetricValue) => {
            metricDictionary[datum?.ID] = datum?.VALUE;
        });
        setMetricValues(metricDictionary);

        metricDictionary = {};
        (allQueriesNonGroupedPreviousDay as Array<IMetricValue>).forEach((datum: IMetricValue) => {
            metricDictionary[datum?.ID] = datum?.VALUE;
        });
        setMetricValuesPrev(metricDictionary);
    };

    const setParams = async () => {
        const dateObj = {
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
        };

        try {
            setLoading(true);
            if (isShopifyPlatform) {
                const [metricValues, metricValuesPrev] = await Promise.all([
                    apiGetAllQueriesNonGrouped(dateObj, controller),
                    apiGetAllQueriesNonGrouped(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller)
                ]);
                setMetricValues(metricValues?.data[0]);
                setMetricValuesPrev(metricValuesPrev?.data[0]);
            } else {
                const [metricValues, metricValuesPrev] = await Promise.all([
                    apiGetAllQueriesNonGroupedNonShopify(dateObj, controller),
                    apiGetAllQueriesNonGroupedNonShopify(shiftStartEndDateBackForComparison(startDate.clone(), endDate.clone(), currentSelectKey), controller)
                ]);
                setMetricValues(metricValues?.data[0]);
                setMetricValuesPrev(metricValuesPrev?.data[0]);
            }
        } catch (e) {
            setMetricValues(undefined);
            setMetricValuesPrev(undefined);
        } finally {
            setLoading(false);
        }
    };
    const configs = {
        videoConversion: {
            title: "Video Conversion",
            icon: <CaretRightOutlined />,
            color: "#FF9F38",
            suffix: "%",
            information: "The % of visitors who converted after seeing a Gander video",
            currentSelectKey: currentSelectKey,
            loading: loading,
            metricValue: metricValues ? metricValues["Video Conversions"] : undefined,
            metricValuePrev: metricValuesPrev ? metricValuesPrev["Video Conversions"] : undefined,
        },
        onSiteEngagement: {
            title: "On Site Engagement",
            icon: < LikeOutlined />,
            color: "#8338EC",
            suffix: "%",
            information: "The % of visitors who engaged with a Gander video",
            currentSelectKey: currentSelectKey,
            loading: loading,
            metricValue: metricValues ? metricValues["On Site Engagement"] : undefined,
            metricValuePrev: metricValuesPrev ? metricValuesPrev["On Site Engagement"] : undefined
        },
        ganderInfluencedSale: {
            title: "Gander Influenced Sales",
            icon: < DollarOutlined />,
            color: "#3C8EF9",
            prefix: "$",
            information: "The total amount of sales driven by customers who saw a video powered by Gander",
            currentSelectKey: currentSelectKey,
            loading: loading,
            metricValue: metricValues ? metricValues["Gander Influenced Sale"] : undefined,
            metricValuePrev: metricValuesPrev ? metricValuesPrev["Gander Influenced Sale"] : undefined,
            placeholder: isBasicPlan ? "xx,xxx" : ""
        },
        avgOrderValue: {
            title: "Avg. Order Value",
            icon: <PercentageOutlined />,
            color: "#4FBF67",
            prefix: "$",
            information: "The average dollar amount spent each time a customer places an order after seeing a Gander video.",
            currentSelectKey: currentSelectKey,
            loading: loading,
            metricValue: metricValues ? metricValues["Average Order Value"] : undefined,
            metricValuePrev: metricValuesPrev ? metricValuesPrev["Average Order Value"] : undefined,
            placeholder: isBasicPlan ? "xxx" : ""
        }
    }

    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col className="gutter-row g-dashboard-state-container small" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} xl={{ span: spanLength }} xxl={{ span: spanLength }} data-testid={`${dataTestIdPrefix}-VideoConversion`}>
                    <MetricComponent {...configs.videoConversion} />
                </Col>
                <Col className="gutter-row g-dashboard-state-container small" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} xl={{ span: spanLength }} xxl={{ span: spanLength }} data-testid={`${dataTestIdPrefix}-OnSiteEngagement`}>
                    <MetricComponent {...configs.onSiteEngagement} />

                </Col>
                {isShopifyPlatform ? (
                    <>
                        <Col className="gutter-row g-dashboard-state-container small" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }} data-testid={`${dataTestIdPrefix}-AvgOrderValue`}>
                            {isBasicPlan ? <MetricStatisticComponentBlocked {...configs.avgOrderValue} /> : <MetricComponent {...configs.avgOrderValue} />}
                        </Col>
                        <Col className="gutter-row g-dashboard-state-container small" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }} data-testid={`${dataTestIdPrefix}-InfluencedSales`}>
                            {isBasicPlan ? <MetricStatisticComponentBlocked {...configs.ganderInfluencedSale} /> : <MetricComponent {...configs.ganderInfluencedSale} />}
                        </Col>
                    </>
                ) : <></>}
            </Row>
        </>);
};