import { useContext, useState } from "react";
import { Button, Modal, Typography } from "antd";
import { toast } from "utils/toast";
import { apiUpdateShop } from "services/shop";
import { AppContext } from "context/app-context";
import { CUSTOMIZATION_PAGE_EMBEDDED_HELP_LINK } from "constants/hyper-links";

import "./index.scss";

const dataTestIdPrefix = "EmbeddedTipModal";

const { Text, Link } = Typography;

interface IProps {
    handleClose: () => void;
}

const EmbeddedTipModal = ({ handleClose }: IProps) => {
    const { setShopDetails } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleCancel = async () => {
        try {
            setIsLoading(true);
            const response = await apiUpdateShop({ isHideEmbeddedTip: true });
            setShopDetails(response.data);
        } catch (e) {
            console.log(e);
            toast.error("Sorry something went wrong, please try again later");
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <Modal wrapClassName="g-embedded-tip-modal" centered={true} width={500} open closable={false} onCancel={() => handleClose()} footer={null}>
            <div className="g-embedded-tip-modal-content">
                <Text className="g-embedded-tip-modal-title" data-testid={`${dataTestIdPrefix}-Title-Text`}>
                    Setting Up Embedded Layout
                </Text>
                <div className="g-embedded-tip-modal-body">
                    <Text className="g-embedded-tip-modal-body-text" data-testid={`${dataTestIdPrefix}-Body-Text`}>
                        Copy & paste the following snippet where you would like your video(s) to appear.
                    </Text>
                    <Text className="g-embedded-tip-modal-link-container" data-testid={`${dataTestIdPrefix}-Link-Container`}>
                        You can find more detailed instructions{" "}
                        <Link href={CUSTOMIZATION_PAGE_EMBEDDED_HELP_LINK} target="_blank" data-testid={`${dataTestIdPrefix}-Help-Link`}>
                            here
                        </Link>
                        .
                    </Text>
                    <div className="g-embedded-tip-modal-snippet-container">
                        <Text
                            copyable
                            data-testid={`${dataTestIdPrefix}-Snippet-Text`}
                        >{`<div class="gander-widget" data-product-id="{{product.id}}"></div>`}</Text>
                    </div>
                </div>
                <div className="g-embedded-tip-modal-footer">
                    <Button size="large" loading={isLoading} onClick={() => handleCancel()} data-testid={`${dataTestIdPrefix}-Cancel-Button`}>
                        Don't show this again
                    </Button>
                    <Button type="primary" size="large" loading={isLoading} onClick={() => handleClose()} data-testid={`${dataTestIdPrefix}-Close-Button`}>
                        I'll do it later
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EmbeddedTipModal;
