import { useContext, useState } from "react";
import { Typography } from "antd";
import { IPage } from "interfaces";
import LinkVideoToExistingPageComponent from "./link-video-to-existing-page-component";
import LinkVideoToNewPageComponent from "./link-video-to-new-page-component";
import { updateVideoArrayByMeta } from 'utils/common-functions';
import { apiLinkVideoAndPage } from "services/page.services";
import { toast } from 'utils/toast';
import { VideosContext } from "context/videos-context";
import { IVideo } from "interfaces/schema";
import { AppContext } from "context/app-context";

const { Title } = Typography;

interface Props {
  viewVideoDetailsDrawer: IVideo;
  openViewVideoDetailDrawer: (vid: IVideo) => void;
  setDetailsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkVideoToPageComponent = ({ viewVideoDetailsDrawer, openViewVideoDetailDrawer, setDetailsLoading }: Props) => {
  const { videos, recentlyAddedVideos, setRecentlyAddedVideos, setVideos } = useContext(VideosContext);
  const { setIsShowV3FreePlanConnectLimitModel } = useContext(AppContext);
  const [isCreateNewPage, setIsCreateNewPage] = useState(false);
  const [newPageUrl, setNewPageUrl] = useState('');

  const toggleCreateNewPage = (newPageUrl: string) => {
    setNewPageUrl(newPageUrl);
    setIsCreateNewPage(!isCreateNewPage);
  };

  const handleSelectPage = async (newPage: IPage) => {
    try {
      setDetailsLoading(true);
      const apiPayload = [{
        "pageMeta": newPage.meta,
        "videoMeta": viewVideoDetailsDrawer?.meta
      }];
      const linkVideoAndPage = await apiLinkVideoAndPage(apiPayload);
      toast.success(linkVideoAndPage?.message);

      // update state and context states here after api call succeed
      const oldPagesObjects = viewVideoDetailsDrawer?.pages ? viewVideoDetailsDrawer?.pages : [];
      const updateVideoObj = { ...viewVideoDetailsDrawer, pages: [...oldPagesObjects, newPage!] };
      openViewVideoDetailDrawer(updateVideoObj);
      setRecentlyAddedVideos(updateVideoArrayByMeta(recentlyAddedVideos, updateVideoObj));
      setVideos(updateVideoArrayByMeta(videos, updateVideoObj));
    } catch (e: any) {
      console.log(e);
      if (e?.data?.type === 'upgradePlanError') {
        setIsShowV3FreePlanConnectLimitModel(true);
      } else {
        toast.error(e?.message);
      }
    } finally {
      setDetailsLoading(false);
    }
  };

  return (
    <>
      <div className="g-add-edit-video-details-form-field-title">
        <Title level={3}>
          Link Video to New Page
        </Title>
      </div>
      <LinkVideoToExistingPageComponent
        videoDetails={viewVideoDetailsDrawer}
        disabled={Boolean(newPageUrl)}
        onSelectPage={handleSelectPage}
        onCreatePage={toggleCreateNewPage}
      />
      {isCreateNewPage && (
        <LinkVideoToNewPageComponent
          newPageUrl={newPageUrl}
          toggleCreateNewPage={toggleCreateNewPage}
          onSelectPage={handleSelectPage}
        />
      )}
    </>
  );
}

export default LinkVideoToPageComponent;