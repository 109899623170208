import { Row, Col, Typography, Table, Button, Card, Skeleton } from "antd";
import { AbTestingColumns, ROW_GUTTER, TopVideosColumns, TopVideosColumnsNonShopify, expandedRowRender } from "constants/dashboard";
import { DATE_FORMAT, EDefaultPlan, EPlanType, IAbTestingDetails, ITopVideosDetails } from "../../../../interfaces";
import { AppContext } from "context/app-context";
import { DashboardContext } from "context/dashboard-context";
import { useState, useContext, useEffect } from "react";
import { apiGetTopVideosShopify, apiGetAbTestResults, apiGetAbTestMetrics, apiGetTopVideosNonShopify } from "services/dashboard.service";
import { createAbTestingTableData, findExpandedRows } from "utils/metrics";
import { topVideosDummyData, abTestingDummyData } from "../dummy-data";
import MetricCardTableBlockedUpgradeCard from "../metric-table-component/MetricCardTableBlockedUpgradeCard";
import { ColumnsType } from "antd/lib/table";

const { Title, Text } = Typography;

export const FifthRowComponent = () => {
  const { isShowUpgradePlanBanner, shopDetails, activeSubscription } = useContext(AppContext);
  const { startDate, endDate, controller } = useContext(DashboardContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [topVideosValues, setTopVideosValues] = useState<ITopVideosDetails[]>();
  const [abTesting, setAbTesting] = useState<IAbTestingDetails[]>();
  const [abTestingExpandedRows, setAbTestingExpandedRows] = useState<{ [key: React.Key]: boolean }>({});
  const isBasicPlan =
    activeSubscription?.activePlan?.planName?.toLowerCase() === EPlanType.BASIC ||
    activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_15_MONTHLY_PLAN ||
    activeSubscription?.activePlan?.meta === EDefaultPlan.BASIC_120_ANNUALLY_PLAN;

  useEffect(() => {
    isShowUpgradePlanBanner ? setDefaultParams() : setParams();
  }, [startDate, endDate, isShowUpgradePlanBanner]);

  const setDefaultParams = () => {
    setTopVideosValues(topVideosDummyData);
    setAbTesting(abTestingDummyData);
  }

  const isOnShopify = shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2';

  const abTestingColumns: ColumnsType<IAbTestingDetails> = [...AbTestingColumns, {
    title: 'Action',
    render: (_, record) => {
      // Always render the same
      if (Object.keys(abTestingExpandedRows).includes(record.key as string) && abTestingExpandedRows[record.key]) {
        return (<><Button style={{ color: "#3C8EF9", fontWeight: "bold" }} onClick={() => {
          let newAbTestingExpandedRows = { ...abTestingExpandedRows };
          newAbTestingExpandedRows[record.key] = false;
          setAbTestingExpandedRows(newAbTestingExpandedRows);
        }}>View Details &nbsp; <small>&#709;</small></Button></>)
      } else {
        return (<><Button style={{ color: "#3C8EF9", fontWeight: "bold" }} onClick={() => {
          let newAbTestingExpandedRows = { ...abTestingExpandedRows };
          newAbTestingExpandedRows[record.key] = true;
          setAbTestingExpandedRows(newAbTestingExpandedRows);
        }}>View Details &nbsp; <small>&#707;</small></Button></>)
      }
    }
  }];

  const setParams = async () => {
    const dateObj = {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    };

    try {
      setLoading(true);
      if (shopDetails?.platform === 'shopify' || shopDetails?.platform === 'shopify-v2') {
        const [topVideosShopify, abTestResults, abTestMetrics] = await Promise.all([
          apiGetTopVideosShopify(dateObj, controller),
          apiGetAbTestResults(dateObj, controller),
          apiGetAbTestMetrics(dateObj, controller)
        ]);

        setTopVideosValues(topVideosShopify?.data);
        setAbTesting(createAbTestingTableData(abTestResults?.data, abTestMetrics?.data));
      } else {
        const [topVideosNonShopify, abTestResults, abTestMetrics] = await Promise.all([
          apiGetTopVideosNonShopify(dateObj, controller),
          apiGetAbTestResults(dateObj, controller),
          apiGetAbTestMetrics(dateObj, controller)
        ])
        setTopVideosValues(topVideosNonShopify?.data);
        setAbTesting(createAbTestingTableData(abTestResults?.data, abTestMetrics?.data));
      }
    } catch (e) {
      setTopVideosValues(undefined);
      setAbTesting(undefined);
    } finally {
      setLoading(false);
    }
  }

  if (isShowUpgradePlanBanner || isBasicPlan) {
    return (
      <Row gutter={ROW_GUTTER}>
        <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
          <Card title={<Title level={3}><h1 className={"g-metric-card-title"}>Top Videos</h1></Title>} size="default">
            {loading ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<MetricCardTableBlockedUpgradeCard property={<>Upgrade to best<br />performing videos</>} />)}
          </Card>
        </Col>
        <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
          <Card title={<Title level={3}><h1 className={"g-metric-card-title"}>Creative A/B Test Results</h1><p>Summary of your creative A/B test results. Questions? Learn More <a href="settings">here</a></p></Title>} size="default">
            {loading ? (<Skeleton active={true} paragraph={{ rows: 2 }} />) : (<MetricCardTableBlockedUpgradeCard property={<>Upgrade to best<br />performing videos</>} />)}
          </Card>
        </Col >
      </Row >
    );
  };

  const topVideosBody = topVideosValues ? <Table columns={isOnShopify ? TopVideosColumns : TopVideosColumnsNonShopify} dataSource={topVideosValues} /> : <p>No data</p>;
  const abTestingBody = abTesting ? <Table columns={abTestingColumns} expandable={{ expandedRowRender, expandedRowKeys: findExpandedRows(abTestingExpandedRows), showExpandColumn: false }} dataSource={abTesting} /> : <><p>No data</p></>

  return (
    <Row gutter={ROW_GUTTER}>
      <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
        <div className=".g-metrics-table-component">
          <Card title={<><Title level={3}>Top Videos</Title><Text style={{ "fontSize": "14px" }}>Below are your top performing videos</Text></>} size="default">
            {loading ? <Skeleton active={true} paragraph={{ rows: 2 }} /> : topVideosBody}
          </Card>
        </div>
      </Col>
      <Col className="gutter-row g-dashboard-state-container" xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
        <Card title={<><Title level={3}>Creative A/B Test Results</Title><Text style={{ "fontSize": "14px" }}>Summary of your creative A/B test results. Questions? Learn More <a href="settings">here</a></Text></>} size="default">
          {loading ? <Skeleton active={true} paragraph={{ rows: 2 }} /> : abTestingBody}
        </Card>
      </Col>
    </Row>
  );
}