
import { Button } from 'antd';
import { ISettingScreenIntegration } from 'interfaces';

import './index.scss';

const dataTestIdPrefix = "Settings-IntegrationCardComponent";

interface IProps {
    integration: ISettingScreenIntegration
}

const IntegrationCardComponent = ({ integration }: IProps) => {
    const { logo, title, logoWidth, logoHeight, extraDetails, connectLink } = integration;
    return (
        <div className='g-integration-card-container'>
            <div className='g-integration-logo-image-container'>
                <img src={logo} alt={title} style={{ width: logoWidth, height: logoHeight }} data-testid={`${dataTestIdPrefix}-CardImage`} />
            </div>
            <div>
                <div data-testid={`${dataTestIdPrefix}-CardTitle`} className='g-integration-card-title'>{title}</div>
                <div data-testid={`${dataTestIdPrefix}-CardExtraDetail`} className='g-integration-card-details'>{extraDetails}</div>
                <Button className='g-integration-card-connect-button' type={'default'} target='_blank' href={connectLink} data-testid={`${dataTestIdPrefix}-ConnectAppButton`}>
                    Connect App
                    <span className='g-integration-card-connect-button-icon'>
                        <svg width="8" height="9" viewBox="0 0 8 9" fill="black" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.60437 5.00011H6.18937L3.74937 7.44011C3.55437 7.63511 3.55437 7.95511 3.74937 8.15011C3.94437 8.34511 4.25937 8.34511 4.45437 8.15011L7.74937 4.85511C7.94437 4.66011 7.94437 4.34511 7.74937 4.15011L4.45937 0.850107C4.26437 0.655107 3.94937 0.655107 3.75437 0.850107C3.55937 1.04511 3.55937 1.36011 3.75437 1.55511L6.18937 4.00011H0.60437C0.32937 4.00011 0.10437 4.22511 0.10437 4.50011C0.10437 4.77511 0.32937 5.00011 0.60437 5.00011Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default IntegrationCardComponent;