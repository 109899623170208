import { Badge, Card, List, Typography } from "antd";
import QuestionTypeLabel from "./question-type-label-component";
import QuestionDeleteButton from "./question-delete-button-component";
import { useSortable } from "@dnd-kit/sortable";
import { IInteraction, IQuestion } from "interfaces/schema";
import { CSS } from "@dnd-kit/utilities";
import Icon from "@ant-design/icons";
import { ReactComponent as DraggableEllipsis } from "assets/img/draggable.svg";

import "./index.scss";

const dataTestIdPrefix = "YourQuestionPanel-QuestionCardComponent";

const { Text } = Typography;

interface Props {
    key: string;
    id: string;
    question: IQuestion;
    interaction: IInteraction;
    index: number;
    selected: boolean;
    setInteraction: React.Dispatch<React.SetStateAction<IInteraction | undefined>>;
    setSelectedQuestion: React.Dispatch<React.SetStateAction<IQuestion | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionCard = ({ question, interaction, index, selected, setInteraction, setSelectedQuestion, setIsLoading }: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.meta });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <List.Item ref={setNodeRef} style={style}>
            <div
                className={`g-interaction-edit-question-card-container ${selected ? "g-interaction-edit-question-card-selected" : ""}`}
                onClick={() => setSelectedQuestion(question)}
                data-testid={`${dataTestIdPrefix}-WrapperDiv`}
            >
                <Card bordered={false}>
                    <div className="g-interaction-edit-question-card-top g-d-flex" data-testid={`${dataTestIdPrefix}-CardTopDiv`}>
                        <div className="g-interaction-edit-question-card-draggable">
                            <Icon component={DraggableEllipsis} {...attributes} {...listeners} />
                        </div>
                        <QuestionTypeLabel questionType={question.type} />
                        <QuestionDeleteButton
                            question={question}
                            interaction={interaction}
                            setSelectedQuestion={setSelectedQuestion}
                            setInteraction={setInteraction}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                    <div className="g-interaction-edit-question-card-bottom g-d-flex" data-testid={`${dataTestIdPrefix}-CardBottomDiv`}>
                        <Badge className="g-interaction-edit-question-card-badge" count={index + 1} style={{ backgroundColor: "#3C8EF9" }} />
                        <Text className="g-interaction-edit-question-card-question-text" data-testid={`${dataTestIdPrefix}-CardBottomText`}>
                            {question.text ? question.text : "Your question here."}
                        </Text>
                    </div>
                </Card>
            </div>
        </List.Item>
    );
};

export default QuestionCard;
