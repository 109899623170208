import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import QuestionChoicesAnalysis from "./question-choices-analysis-component";
import GenerateReport from "./generate-report-component";
import { ISummaryPageMetrics } from "..";
import { IInteraction } from "interfaces/schema";
import { EInteractionResultsTabs } from "interfaces";
import * as ROUTE_PATHS from "constants/routes";

import "./index.scss";

interface Props {
    interaction: IInteraction;
    summaryPageMetrics: ISummaryPageMetrics | undefined;
    setActiveTab: React.Dispatch<React.SetStateAction<EInteractionResultsTabs>>;
}

const SummaryView = ({ interaction, summaryPageMetrics, setActiveTab }: Props) => {
    const navigate = useNavigate();

    if (summaryPageMetrics === undefined) {
        return (
            <div className="g-h-100 g-d-flex g-justify-center g-align-center">
                Sorry, something went wrong. Click&nbsp;
                <Button type="link" className="g-padding-0" onClick={() => navigate(ROUTE_PATHS.PRIVATE_ROUTES.INTERACTIONS.PATH)}>
                    <u>here</u>
                </Button>
                &nbsp;to go back.
            </div>
        );
    }

    return (
        <div className="g-interaction-edit-summary-card-container">
            <QuestionChoicesAnalysis summaryPageMetrics={summaryPageMetrics} setActiveTab={setActiveTab} />
            <GenerateReport interaction={interaction} />
        </div>
    );
};

export default SummaryView;
