import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from 'context/app-context';
import { PRIVATE_ROUTES_FULL_PAGE } from 'constants/routes';
import { isActivePlan } from 'utils/is-active-plan';

const PlansMiddleware: any = ({ children }: { children: any }) => {
    const { activeSubscription, shopDetails } = useContext(AppContext);

    if (isActivePlan(shopDetails, activeSubscription)) {
        return children;
    }

    return <Navigate to={PRIVATE_ROUTES_FULL_PAGE.ACCOUNT_SETUP.PATH} />;
};

export default PlansMiddleware;