import { Button, Modal } from 'antd';
import { AppContext } from 'context/app-context';
import { useContext, useState } from 'react';
import { apiPutUpdateExternalVideoUploadId } from 'services/upload.services';
import { toast } from 'utils/toast';
import { v4 as uuidv4 } from "uuid";

interface IProps {
    isConfirmationModalOpen: boolean;
    setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    copyExternalVideoUploadUrlToClipBoard: (id: string) => void;
    dataTestIdPrefix?: string;
}

const GenerateNewUrlConfirmationModal = ({ isConfirmationModalOpen, setIsConfirmationModalOpen, copyExternalVideoUploadUrlToClipBoard, dataTestIdPrefix = "GenerateNewConfirmationModal" }: IProps) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { shopDetails, setShopDetails } = useContext(AppContext);

    const handleOk = () => {
        setConfirmLoading(true);
        generateExternalVideoUploadId();
    };

    const handleCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const generateExternalVideoUploadId = async () => {
        try {
            const uniqueId = uuidv4();
            const reqPayload = {
                externalVideoUploadId: uniqueId,
            };
            await apiPutUpdateExternalVideoUploadId(reqPayload);
            setShopDetails({ ...shopDetails, externalVideoUploadId: uniqueId });
            copyExternalVideoUploadUrlToClipBoard(uniqueId);
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        } finally {
            setIsConfirmationModalOpen(false);
            setConfirmLoading(false);
        }
    };

    return (
        <Modal
            open={isConfirmationModalOpen}
            footer={null}
            onCancel={() => confirmLoading ? false : handleCancel()}
            closable={!confirmLoading}
            centered={true}
        >
            <div
                className="g-generate-external-upload-confirmation-modal-container"
                data-testid={`${dataTestIdPrefix}-generate-confirmation-modal`}
            >
                <div className="g-generate-external-upload-confirmation-modal-title">Generate New URL</div>
                <div className="g-generate-external-upload-confirmation-modal-text">
                    Warning: Generating a new URL will expire any previously generated URLs. Are you sure you want to proceed?
                </div>
                <div className="g-generate-external-upload-confirmation-modal-button-container">
                    <Button
                        className="g-generate-external-upload-confirmation-modal-button"
                        disabled={confirmLoading}
                        onClick={handleCancel}
                        data-testid={`${dataTestIdPrefix}-generate-confirmation-modal-cancel`}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="g-generate-external-upload-confirmation-modal-button"
                        type="primary"
                        onClick={handleOk}
                        loading={confirmLoading}
                        data-testid={`${dataTestIdPrefix}-generate-confirmation-modal-submit`}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default GenerateNewUrlConfirmationModal;