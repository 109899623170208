import { EProgress, IPage, IProduct } from "interfaces";

// ENTITIES
export interface IChoice {
  id: string;
  text?: string;
  pictureUrl?: string;
  buttonColor?: string;
  buttonTextColor?: string;

  nextQuestionMeta?: string | typeof EChoiceNextQuestionMetaRedirect;
  redirectUrl?: string;
  productUrl?: string;
}

export interface IInteraction {
  id: string;
  meta: string;
  dataType: string;
  name: string;
  questionOrder: string[];
  questions: IQuestion[];
  videos: IVideo[];
  createdAt: number;
  updatedAt: number;
  GSI1PK: string;
  GSI1SK: string;
  isNameUpdatedByUser?: boolean;
}

export interface IQuestion {
  id: string;
  meta: string;
  dataType: string;
  type: EQuestionType;
  selectionType?: EQuestionSelectionType;
  text: string;
  choices: IChoice[];
  createdAt: number;
  updatedAt: number;
  GSI1PK: string;
  GSI1SK: string;
}

// TODO: why we have two interfaces for Video
export interface IVideo {
  id: string;
  meta: string;
  abTest?: boolean;
  crMeta: string;
  displayName: string;
  name: string;
  expiresAt?: string;
  originalMediaUrl?: string;
  pages: IPage[];
  playbackId: string;
  products: IProduct[];
  progress: EProgress;
  purchaseId?: string;
  purchasedAt?: string;
  requested: boolean;
  size?: number;
  thumbnail: string;
  sdThumbnail: string;
  hdThumbnail: string;
  videoCaptions: string;
  videoPreview: string;
  source: EVideoSource;
  GSI1PK?: string;
  GSI1SK?: string;
  createdAt: number;
  updatedAt: number;
  duration?: string;
  externalUploadDetails?: {
    approved: boolean;
    company: string;
    email: string;
    firstName: string;
    lastName: string;
    productUrl: string;
  };
}

export interface IProductsResponse {
  title: string;
  id: string;
  featuredImage: { url: string };
  onlineStoreUrl: string;
  onlineStorePreviewUrl: string;
  variants: {
    nodes: [{
      title: string,
      id: string,
      image: {
        url: string
      }
    }]
  }
}

export interface IDeviceSettings {
  widgetType: EWidgetType;
  displayBranding: boolean;
  buttonColor: string;
  textColor: string;
  topPosition: string;
  leftPosition: string;
  embedType: EEmbeddedLayout;
  embedGridNumberOfVideos: number;
  previewOverlayStartingState: EOverlayStartingState;
  previewEmbedGifStyle: EEmbeddedPlayerStyle;
  previewOrientation: EPlayerShape;
  previewPlayerSize: string;
  previewCornerRadius: string;
  previewIsShowBorder: boolean;
  previewBorderColor: string;
  previewIsBorderPadding: boolean;
  openStateType: EOverlayOpenState;
  overlayWidgetSize: string;
  fullScreenIsShowAll: boolean;
  closeCornerRadius: string;
  closeIsShowBorder: boolean;
  closeBorderColor: string;
  closeIsBorderPadding: boolean;
  previewTextContent: string;
  textFontSize: string;
  textAlignment: EPreviewTextPosition;
  textIsShowDuration: boolean;
  textIsShowViewCount: boolean;
}
// TYPES
export enum EQuestionType {
  SHORT_ANSWER = "short-answer",
  MULTIPLE_CHOICE = "multiple-choice",
  PICTURE_CHOICE = "picture-choice",
  CALL_TO_ACTION = "call-to-action",
  ADD_TO_CART = "add-to-cart",
}

export enum EQuestionSelectionType {
  SINGLE_SELECT = "single-select",
  MULTIPLE_SELECT = "multiple-select",
}

export enum EInteractionCreationType {
  QUIZ = "quiz",
  CALL_TO_ACTION = "call-to-action",
  ADD_TO_CART = "add-to-cart",
  FORM = "form",
  EMPTY = "empty",
}

export enum EVideoSource {
  INSTAGRAM_IMPORT = "Instagram Import",
  TIKTOK_IMPORT = "Tiktok Import",
  UPLOADED_CONTENT = "Uploaded Content",
  REQUESTED_CONTENT = "Requested Content",
  EXTERNAL_UPLOAD = "External Upload",
}

export const EChoiceNextQuestionMetaRedirect = "redirect";

export enum EWidgetType {
  OVERLAY = "overlay",
  EMBEDDED = "embedded",
}

export enum EOverlayStartingState {
  MUTED_GIF = "muted-gif",
  OPEN_STATE = "open-state",
}

export enum EOverlayOpenState {
  OVERLAY = "overlay",
  FULLSCREEN = "fullscreen",
  IN_FRAME = "in-frame",
}

export enum EPlayerShape {
  PORTRAIT_LANDSCAPE = "portrait-landscape",
  SQUARE_CIRCLE = "square-circle",
}

export enum EEmbeddedLayout {
  HORIZONTAL_SCROLL = "horizontal-scroll",
  GRID = "grid",
}

export enum EEmbeddedPlayerStyle {
  SIMULTANEOUS = "simultaneous",
  ON_HOVER = "on-hover",
}

export enum EPreviewTextPosition {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}