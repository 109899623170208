import { Tabs } from 'antd';
import BillingSubscriptionComponent from './billing-subscription-component';
import IntegrationsComponent from './integrations-component';
import NeedHelpCardComponent from './need-help-card-component';
import AccountComponent from './account-component';
import { useEffect, useState } from 'react';
import { ESettingTabs } from 'interfaces';

import './index.scss';


const SettingsPage = () => {
    const [currentTab, setCurrentTab] = useState<ESettingTabs>(ESettingTabs.ACCOUNT);

    useEffect(() => {
        if (window.location.hash === '#billing') {
            setCurrentTab(ESettingTabs?.BILLING);
        }
    }, [])

    return (
        <div className='g-settings-page-container'>
            <Tabs
                className='g-setting-tabs-container'
                activeKey={currentTab}
                onChange={(e: any) => { setCurrentTab(e) }}
                items={[
                    {
                        label: `Account`,
                        key: ESettingTabs.ACCOUNT,
                        children: <AccountComponent isActive={currentTab === ESettingTabs?.ACCOUNT} />,
                    },
                    {
                        label: `Billing and Usage`,
                        key: ESettingTabs?.BILLING,
                        children: <BillingSubscriptionComponent isActive={currentTab === ESettingTabs?.BILLING} />
                    },
                    {
                        label: `Integrations`,
                        key: ESettingTabs?.INTEGRATION,
                        children: <IntegrationsComponent isActive={currentTab === ESettingTabs?.INTEGRATION} />,
                    }
                ]}
            />
            {currentTab === ESettingTabs?.INTEGRATION &&
                <NeedHelpCardComponent />
            }
        </div>
    )
};

export default SettingsPage;