import { IQuestion } from "interfaces/schema";
import { ArrowRightOutlined } from "@ant-design/icons";

import "./index.scss";

const CSS_PREFIX = "g-preview-short-answer-interaction";

const sizeToQuestionTextCSS = {
    default: `${CSS_PREFIX}-question-text-default`,
    large: `${CSS_PREFIX}-question-text-large`,
};

const sizeToResponseContainerCSS = {
    default: `${CSS_PREFIX}-response-container-default`,
    large: `${CSS_PREFIX}-response-container-large`,
};

const sizeToResponseTextContainerCSS = {
    default: `${CSS_PREFIX}-response-text-container-default`,
    large: `${CSS_PREFIX}-response-text-container-large`,
};

const sizeToResponseButtonCSS = {
    default: `${CSS_PREFIX}-response-button-default`,
    large: `${CSS_PREFIX}-response-button-large`,
};

const sizeToFooterTextCSS = {
    default: `${CSS_PREFIX}-footer-text-default`,
    large: `${CSS_PREFIX}-footer-text-large`,
};

interface Props {
    question: IQuestion;
    size?: "default" | "large";
}

const PreviewShortAnswerInteraction = ({ question, size = "default" }: Props) => {
    return (
        <div className="g-interation-edit-preview-panel-short-answer-interaction-container">
            <div className="g-interation-edit-preview-panel-short-answer-question-text-container">
                <div className={`g-interation-edit-preview-panel-short-answer-question-text ${sizeToQuestionTextCSS[size]}`}>{question.text ? question.text : 'Your question here.'}</div>
            </div>
            <div className={`g-interation-edit-preview-panel-short-answer-question-response-container ${sizeToResponseContainerCSS[size]}`}>
                <div className={`g-interation-edit-preview-panel-short-answer-question-response-text-container ${sizeToResponseTextContainerCSS[size]}`}>
                    <span  className="g-interation-edit-preview-panel-short-answer-question-response-text">{question.choices[0].text ?  question.choices[0].text : 'Type your answer here…'}</span>
                </div>
                <div className="g-interation-edit-preview-panel-short-answer-question-response-button-container">
                    <div className={`g-interation-edit-preview-panel-short-answer-question-response-button ${sizeToResponseButtonCSS[size]}`}>
                        <ArrowRightOutlined />
                    </div>
                </div>
            </div>
            <div className="g-interation-edit-preview-panel-short-answer-question-footer-container">
                <div className={`g-interation-edit-preview-panel-short-answer-question-footer-text ${sizeToFooterTextCSS[size]}`}>Skip</div>
            </div>
        </div>
    );
};

export default PreviewShortAnswerInteraction;
