import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GetColumns } from './columns';
import ABTestDrawer from './ab-test-drawer';
import AddEditPageDetailsComponent from './add-edit-page-details-component';
import { AppContext } from 'context/app-context';
import { IPage } from 'interfaces';
import { PagesContext } from 'context/pages-context';
import { apiDeletePage } from 'services/pages.services';
import { toast } from 'utils/toast';
import UpgradeYourAccountModal from 'app/pages/common/upgrade-your-account-modal-component';

import './index.scss';

interface IProps {
    isCreateNewPage: boolean;
    setIsCreateNewPage: (isFlag: boolean) => void;
    setViewCustomizePageComponent: React.Dispatch<React.SetStateAction<IPage | undefined>>;
}

const PagesTableComponent = ({ isCreateNewPage, setIsCreateNewPage, setViewCustomizePageComponent }: IProps) => {
    const [manageContentABTestDrawer, setManageContentABTestDrawer] = useState<IPage | null | undefined>();
    const [viewPageDetailsDrawer, setViewPageDetailsDrawer] = useState<IPage | null | undefined | boolean>();

    const [editPageNameInput, setEditPageNameInput] = useState<IPage | undefined>();

    const { isPagesLoading, pages, pageRequestLastEvaluatedKey, fetchPages } = useContext(PagesContext);
    const { shopDetails, activeSubscription, setIsShowUpgradePlanBanner } = useContext(AppContext);
    const [isShowUpgradePopup, setIsShowUpgradePopup] = useState(false);

    const onNextViewDetailsEvent = () => {
        const currentIndex = pages.findIndex((obj: { meta: string }) => obj?.meta === (viewPageDetailsDrawer as IPage)?.meta);
        if (pages[currentIndex + 1]) {
            setViewPageDetailsDrawer(pages[currentIndex + 1]);
        } else {
            setViewPageDetailsDrawer(pages[0]);
        }
    };

    const onPrevViewDetailsEvent = () => {
        const currentIndex = pages.findIndex((obj: { meta: string }) => obj?.meta === (viewPageDetailsDrawer as IPage)?.meta);
        if (pages[currentIndex - 1]) {
            setViewPageDetailsDrawer(pages[currentIndex - 1]);
        } else {
            setViewPageDetailsDrawer(pages[pages.length - 1]);
        }
    };

    const onNextAbTestEvent = () => {
        const currentIndex = pages.findIndex((obj: { meta: string }) => obj?.meta === (manageContentABTestDrawer as IPage)?.meta);
        console.log(currentIndex);

        if (pages[currentIndex + 1]) {
            setManageContentABTestDrawer(pages[currentIndex + 1]);
        } else {
            setManageContentABTestDrawer(pages[0]);
        }
    };

    const onPrevAbTestEvent = () => {
        const currentIndex = pages.findIndex((obj: { meta: string }) => obj?.meta === (manageContentABTestDrawer as IPage)?.meta);
        console.log(pages);
        if (pages[currentIndex - 1]) {
            setManageContentABTestDrawer(pages[currentIndex - 1]);
        } else {
            setManageContentABTestDrawer(pages[pages.length - 1]);
        }
    };

    useEffect(() => {
        if (isCreateNewPage) {
            setViewPageDetailsDrawer(true);
        }
    }, [isCreateNewPage]);

    useEffect(() => {
        if (!viewPageDetailsDrawer) {
            setIsCreateNewPage(false);
        }
    }, [viewPageDetailsDrawer]);

    const openViewPageDetailDrawer = (pg: IPage | null | undefined | boolean) => {
        if (activeSubscription?.activePlan?.planName?.toLowerCase() === "free") {
            setIsShowUpgradePlanBanner(true);
            return;
        }
        setViewPageDetailsDrawer(pg);
    }

    const openViewCustomizePageComponent = (pg: IPage) => {
        setViewCustomizePageComponent(pg);
    }

    const handleEditPageName = (pageObj: IPage) => {
        if (editPageNameInput !== pageObj) {
            // Toggle on edit name input
            setEditPageNameInput(pageObj);
        } else {
            // Toggle off edit name input
            setEditPageNameInput(undefined);
        }
    }

    const deletePage = async (pageObj: IPage) => {
        if (pageObj && !pageObj?.meta) {
            return;
        }
        try {
            const deletePageRes = await apiDeletePage(pageObj.meta);
            toast.success(deletePageRes?.message);
            fetchPages();
        } catch (e: any) {
            console.log(e);
            toast.error(e?.message);
        }
    }

    const openPageABTestDrawer = (row: IPage) => {
        if (!activeSubscription?.activePlan?.isPageABTest) {
            setIsShowUpgradePopup(true);
            return;
        }
        setManageContentABTestDrawer(row);
    }

    return (
        <div className='g-manage-content-pages-table-container'>
            <Table
                columns={
                    GetColumns(
                        setManageContentABTestDrawer as Dispatch<SetStateAction<IPage>>,
                        setEditPageNameInput,
                        deletePage,
                        openViewPageDetailDrawer,
                        openViewCustomizePageComponent,
                        shopDetails?.domain!,
                        handleEditPageName,
                        editPageNameInput,
                        openPageABTestDrawer
                    )}
                dataSource={pages}
                pagination={false}
                rowKey="meta"
                bordered={false}
                loading={isPagesLoading}
            />
            <br />
            {!!pageRequestLastEvaluatedKey && <Button icon={<PlusOutlined />} block onClick={() => fetchPages(true)} disabled={isPagesLoading}>Load More</Button>}
            {/* ab-test-drawer */}
            {manageContentABTestDrawer && <ABTestDrawer manageContentABTestDrawer={manageContentABTestDrawer} setManageContentABTestDrawer={setManageContentABTestDrawer} onNextAbTestEvent={onNextAbTestEvent} onPrevAbTestEvent={onPrevAbTestEvent} />}
            {/* ab-test-drawer */}

            {/* Page details drawer */}
            {viewPageDetailsDrawer && <AddEditPageDetailsComponent viewPageDetailsDrawer={viewPageDetailsDrawer} setViewPageDetailsDrawer={setViewPageDetailsDrawer} onNextViewDetailsEvent={onNextViewDetailsEvent} onPrevViewDetailsEvent={onPrevViewDetailsEvent} />}

            {isShowUpgradePopup && <UpgradeYourAccountModal setIsShowUpgradePopup={setIsShowUpgradePopup} modalTitle={"Upgrade Your Account!"} modalDescription={"Creative A/B testing is a feature only available on the Growth or Professional Plan. Please upgrade your account on the settings page in order to access this feature."} isClosable={true} />}

            {/* Page details drawer */}
        </div>
    );
}

export default PagesTableComponent;