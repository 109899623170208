import axios from "axios";
import { EQuestionType, IQuestion } from "interfaces/schema";

const { REACT_APP_API_URL } = process.env;

interface Payload {
  interactionId: string;
  type: EQuestionType;
}

interface Response {
  question: IQuestion;
  message: string;
}

const apiCreateQuestion = async (payload: Payload): Promise<Response> => {
  try {
    const url = `${REACT_APP_API_URL}/questions`;
    const response = await axios.post<Response>(url, payload);
    return response.data;
  } catch (e: any) {
    throw e?.response?.data ? e?.response?.data : e;
  }
};

export default apiCreateQuestion;